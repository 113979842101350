import "./kontakte.css";
const Kontakte = () => {
  return (
    <div className="page">
      <div className="page-header">
        <div className="headerTitleLine"></div>
        <h1 className="page-title">Kontakte</h1>
      </div>
      <div className="page-content-kontakte">
        <div className="left-content">
          <div className="contact info">
            <div className="contact-title">Informacioni</div>
            <div className="contact-info">
              <p>
                Tel: <span>+355 4 4541798</span>
              </p>
              <p>
                E-mail: <span>dpa@arkiva.gov.al</span>
              </p>
            </div>
          </div>
          <div className="contact">
            <div className="contact-title">
              Drejtori i Përgjithshëm <br /> Z. Ardit Bido
            </div>
            <div className="contact-info">
              <p>
                E-mail: <span>ardit.bido@arkiva.gov.al</span>
              </p>
              <p>
                Tel: <span>+355 4 4541798</span>
              </p>
            </div>
          </div>
          <div className="contact">
            <div className="contact-title"></div>
            <div className="contact-info"></div>
          </div>{" "}
          <div className="contact">
            <div className="contact-title">
              Koordinatori për të drejtën për informim i <br /> Drejtorisë së
              Përgjithshsme të Arkivave - Znj. Gea Sulo.
            </div>
            <div className="contact-info">
              <p>
                Tel: <span>+355 69 23 89 333</span>
              </p>
              <p>
                E-mail: <span>‍gea.sulo@arkiva.gov.al</span>
              </p>
            </div>
          </div>
        </div>
        <div className="right-content">
          <div className="contact">
            <div className="contact-title">Orari</div>
            <div className="contact-info">
              <p>E Hënë - E Enjte: 08.00 - 16.30</p>
              <p>E Premte: 08.00 - 14.00</p>
            </div>
          </div>
          <div className="contact">
            <div className="contact-title">Adresa</div>
            <div className="contact-info">
              <p>Rr. “Jordan Misja”, 8303</p>
              <p>Tiranë, Shqipëri</p>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2995.7975525140037!2d19.8117729!3d41.3350154!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1350310c7c2be55f%3A0x57a91be7cd6a6b42!2sGeneral%20Directorate%20of%20Archives!5e0!3m2!1sen!2s!4v1731934583961!5m2!1sen!2s"
                title="map"
                width="500"
                height="300"
                style={{ border: 0 }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Kontakte;

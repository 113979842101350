import { useLocation, useNavigate } from "react-router-dom";
import "./muzeu.css";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { setEkspozita } from "../Redux/Actions/ekspozitaActions";
import { setObjekte } from "../Redux/Actions/objekteMuzealeActions";
const MuzeuVirtual = () => {
  const [ekspozitas, setEkspozitas] = useState([]);
  const [objektet, setObjektet] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const parseDate = (dateString) => {
    const [month, day, year] = dateString.split("/").map(Number);
    return new Date(year, month - 1, day);
  };

  useEffect(() => {
    const fetchEkspozitaWithImages = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          "https://api.arkiva.gov.al/api/ekspozita-with-images"
        );

        dispatch(setEkspozita(response.data));
        setEkspozitas(response.data);
      } catch (error) {
        console.error("Error fetching ekspozita and images:", error);
      } finally {
        setLoading(false);
      }
    };

    const fetchObjekteMuzealeWithImages = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          "https://api.arkiva.gov.al/api/objekte-with-images"
        );

        dispatch(setObjekte(response.data));
        setObjektet(response.data);
      } catch (error) {
        console.error("Error fetching objekte muzeale and images:", error);
      } finally {
        setLoading(false);
      }
    };

    if (location.pathname === "/muzeu-virtual/ekspozita") {
      fetchEkspozitaWithImages();
    } else if (location.pathname === "/muzeu-virtual/objekte-muzeale") {
      fetchObjekteMuzealeWithImages();
    }
  }, [location.pathname]);

  return (
    <div className="page">
      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}
      <div className="page-header">
        <div className="headerTitleLine"></div>
        <h1 className="page-title">
          {location.pathname === "/muzeu-virtual/ekspozita"
            ? "Ekspozita"
            : "Objekte Muzeale"}
        </h1>
      </div>
      {location.pathname === "/muzeu-virtual/ekspozita" ? (
        <div className="ekspozita">
          {ekspozitas.map((ekspozita, index) => (
            <div
              key={index}
              className="ekspozita-item"
              onClick={() => navigate(`/ekspozita/${ekspozita.slug}`)}
            >
              <img
                src={`/Assets/Images/Ekspozita/${ekspozita.id}/${ekspozita.coverImage}`}
                alt={ekspozita.title}
                className="ekspozita-image"
              />
              <p className="ekspozita-text">{ekspozita.title}</p>
            </div>
          ))}
        </div>
      ) : (
        <div className="objekte-muzeale">
          {loading && (
            <div className="overlay">
              <div className="spinner"></div>
            </div>
          )}
          {objektet.map((objekt, index) => {
            return (
              <div className="objekt-muzeal" key={index}>
                <img src={objekt.image} alt={objekt.title} />
                <div className="objekt-info">
                  <p className="objekt-titulli">{objekt.title}</p>
                  <p className="objekt-pershkrimi">{objekt.description}</p>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default MuzeuVirtual;

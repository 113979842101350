import { useDispatch, useSelector } from "react-redux";
import "../manage.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  removeEkspozita,
  setEkspozita,
  setEkspozitaOnEdit,
} from "../../Redux/Actions/ekspozitaActions";

const ManageEkspozita = () => {
  const dispatch = useDispatch();
  const [ekspozitas, setEkspozitas] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const loggedUser = useSelector((state) => state.users.loggedUser);
  const isLoggedIn = Object.keys(loggedUser).length > 0;
  const navigate = useNavigate();

  if (!isLoggedIn) navigate("/login");

  useEffect(() => {
    const fetchEkspozita = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `https://api.arkiva.gov.al/api/ekspozita?page=${currentPage}&limit=20`
        );
        setEkspozitas(response.data.ekspozita);
        setTotalPages(response.data.totalPages);
        dispatch(setEkspozita(response.data.ekspozita));
      } catch (error) {
        console.error("Error fetching ekspozitas:", error);
      }
      setLoading(false);
    };

    if (isLoggedIn) {
      fetchEkspozita();
    } else {
      navigate("/manage/dashboard");
    }
  }, [dispatch, isLoggedIn, navigate, currentPage]);

  const editEkspozita = (ekspozita) => {
    navigate(`/manage/ekspozita/${ekspozita._id}`);
    dispatch(setEkspozitaOnEdit(ekspozita));
  };

  const addEkspozita = () => {
    navigate("/manage/ekspozita/add-new");
  };

  const deleteEkspozita = async (ekspozita) => {
    try {
      const response = await axios.delete(
        `https://api.arkiva.gov.al/api/ekspozita/delete/${ekspozita._id}`,
        { data: { id: ekspozita.id } }
      );
      if (response.data.message === "error") {
        alert("Something went wrong!");
      } else {
        setEkspozitas((prevEkspozitas) =>
          prevEkspozitas.filter((e) => e._id !== response.data.ekspozitaId)
        );
        dispatch(removeEkspozita(response.data.ekspozitaId));
      }
    } catch (error) {
      console.error("Error deleting ekspozita:", error);
    }
  };

  const filteredEkspozitas = ekspozitas.filter((item) =>
    item.title.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <div className="manage-page">
      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}
      <div className="manage-page-header">
        <div className="manage-page-header-actions">
          <input
            type="text"
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <button onClick={addEkspozita}>
            <i className="fa-solid fa-plus"></i>Shto Ekspozitë
          </button>
        </div>
      </div>
      <div className="manage-table">
        <table className="table">
          <thead>
            <tr>
              <th>Titulli</th>
              <th>Data</th>
              <th>Veprime</th>
            </tr>
          </thead>
          <tbody>
            {filteredEkspozitas.length > 0 ? (
              filteredEkspozitas.map((item, index) => (
                <tr key={index}>
                  <td data-label="Titulli">{item.title}</td>
                  <td data-label="Data">{item.createdOn}</td>
                  <td className="actions" data-label="Veprime">
                    <button onClick={() => editEkspozita(item)}>
                      <i className="fa-solid fa-pen-to-square"></i>
                    </button>
                    <button onClick={() => deleteEkspozita(item)}>
                      <i className="fa-solid fa-trash"></i>
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="3">Nuk u gjetën ekspozita!</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Pagination Controls */}
      <div className="pagination">
        <button
          className={`pagination-control-btn ${
            currentPage === 1 ? "disabled" : ""
          }`}
          disabled={currentPage === 1}
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
        >
          &#8592; Para
        </button>

        <span className="pagination-current-page">
          Page {currentPage} of {totalPages}
        </span>

        <button
          className={`pagination-control-btn ${
            currentPage === totalPages ? "disabled" : ""
          }`}
          disabled={currentPage === totalPages}
          onClick={() =>
            setCurrentPage((prev) => Math.min(prev + 1, totalPages))
          }
        >
          Pas &#8594;
        </button>
      </div>
    </div>
  );
};

export default ManageEkspozita;

export const SET_LOGGED_USER = "SET_LOGGED_USER";
export const UPDATE_USER = "UPDATE_USER";

export const setLoggedUser = (user) => ({
  type: SET_LOGGED_USER,
  payload: user,
});

export const updateUser = (user) => ({
  type: UPDATE_USER,
  payload: user,
});

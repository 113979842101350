import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../manage.css";
import axios from "axios";
import {
  setRegjistriDokHumburaOnEdit,
  updateRegjistriDokHumbura,
} from "../../Redux/Actions/regjDokHumburaActions";

const EditRegjistriDokHumbura = () => {
  const dispatch = useDispatch();
  const regjistriDokHumburaOnEdit = useSelector(
    (state) => state.regjistriDokHumbura.regjistriDokHumburaOnEdit
  );
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const loggedUser = useSelector((state) => state.users.loggedUser);
  const isLoggedIn = Object.keys(loggedUser).length > 0;

  if (!isLoggedIn) {
    navigate("/login");
  }

  const [formData, setFormData] = useState({
    fondNr: "",
    fond: "",
    year: "",
    folder: "",
    description: "",
    verificationDate: "",
    foundDate: "",
  });

  useEffect(() => {
    setFormData({
      fondNr: regjistriDokHumburaOnEdit.fondNr || "",
      fond: regjistriDokHumburaOnEdit.fond || "",
      year: regjistriDokHumburaOnEdit.year || "",
      folder: regjistriDokHumburaOnEdit.folder || "",
      description: regjistriDokHumburaOnEdit.description || "",
      verificationDate: regjistriDokHumburaOnEdit.verificationDate || "",
      foundDate: regjistriDokHumburaOnEdit.foundDate || "",
    });
  }, [regjistriDokHumburaOnEdit]);

  const validateForm = () => {
    const errors = {};

    if (!formData.fondNr.trim()) {
      errors.fondNr = "Nr. i fondit nuk mund të jetë bosh!";
    }

    if (!formData.fond.trim()) {
      errors.fond = "Fondi nuk mund të jetë bosh!";
    }

    if (!formData.year.trim()) {
      errors.year = "Viti nuk mund të jetë bosh!";
    }

    if (!formData.folder.trim()) {
      errors.folder = "Dosja nuk mund të jetë bosh!";
    }

    if (!formData.description.trim()) {
      errors.description = "Përshkrimi nuk mund të jetë bosh!";
    }

    if (!formData.verificationDate.trim()) {
      errors.verificationDate =
        "Data e fletës së vërtetimit nuk mund të jetë bosh!";
    }

    if (!formData.foundDate.trim()) {
      errors.foundDate =
        "Data e rigjetjes së dokumentit nuk mund të jetë bosh!";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    if (name === "description") {
      resizeTextarea();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    if (!regjistriDokHumburaOnEdit._id) {
      console.error("Error: _id is undefined. Cannot proceed.");
      return;
    }

    try {
      setLoading(true);
      const response = await axios.put(
        `https://api.arkiva.gov.al/api/regjistriDokHumbura/update/${regjistriDokHumburaOnEdit._id}`,
        {
          fondNr: formData.fondNr,
          fond: formData.fond,
          year: formData.year,
          folder: formData.folder,
          description: formData.description,
          verificationDate: formData.verificationDate,
          foundDate: formData.foundDate,
        }
      );

      if (response.data) {
        dispatch(updateRegjistriDokHumbura(response.data));
        navigate("/manage/regjistriDokHumbura");
        dispatch(setRegjistriDokHumburaOnEdit({}));
      } else {
        console.error("Something went wrong");
      }
    } catch (error) {
      console.error("Error updating dokument i humbur:", error);
    }
  };

  const handleCancel = () => {
    navigate("/manage/regjistriDokHumbura");
  };

  const textareaRef = useRef(null);

  const resizeTextarea = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  useEffect(() => {
    resizeTextarea();
  }, [formData.description]);

  const returnHome = () => {
    navigate("/manage/regjistriDokHumbura");
    dispatch(setRegjistriDokHumburaOnEdit({}));
  };

  return (
    <div className="edit-page">
      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}
      <div className="return">
        <button onClick={returnHome}>
          <i className="fa-solid fa-chevron-left"></i>
        </button>
      </div>
      <p>Edito Dokument të Shpallur të Humbura</p>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="fondNr">Nr. i fondit</label>
          <input
            type="text"
            id="fondNr"
            name="fondNr"
            value={formData.fondNr}
            onChange={handleChange}
          />
          {errors.fondNr && <small className="error">{errors.fondNr}</small>}
        </div>
        <div className="form-group">
          <label htmlFor="fond">Fondi</label>
          <input
            type="text"
            id="fond"
            name="fond"
            value={formData.fond}
            onChange={handleChange}
          />
          {errors.fond && <small className="error">{errors.fond}</small>}
        </div>
        <div className="form-group">
          <label htmlFor="year">Viti</label>
          <input
            type="text"
            id="year"
            name="year"
            value={formData.year}
            onChange={handleChange}
          />
          {errors.year && <small className="error">{errors.year}</small>}
        </div>
        <div className="form-group">
          <label htmlFor="folder">Dosja</label>
          <input
            type="text"
            id="folder"
            name="folder"
            value={formData.folder}
            onChange={handleChange}
          />
          {errors.folder && <small className="error">{errors.folder}</small>}
        </div>

        <div className="form-group">
          <label htmlFor="description">
            Përshkrim i shkurtër i dokumentit që mungon
          </label>
          <textarea
            id="description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            ref={textareaRef}
            onInput={resizeTextarea}
            style={{ width: "100%", overflow: "hidden" }}
          />
          {errors.description && (
            <small className="error">{errors.description}</small>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="verificationDate">Data e fletës së vërtetimit</label>
          <input
            type="text"
            id="verificationDate"
            name="verificationDate"
            value={formData.verificationDate}
            onChange={handleChange}
          />
          {errors.verificationDate && (
            <small className="error">{errors.verificationDate}</small>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="foundDate">Data e rigjetjes së dokumentit</label>
          <input
            type="text"
            id="foundDate"
            name="foundDate"
            value={formData.foundDate}
            onChange={handleChange}
          />
          {errors.foundDate && (
            <small className="error">{errors.foundDate}</small>
          )}
        </div>

        <div className="edit-actions">
          <button type="submit" className="save">
            Ruaj ndryshimet
          </button>
          <button type="button" className="cancel" onClick={handleCancel}>
            Anullo
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditRegjistriDokHumbura;

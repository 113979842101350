import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../manage.css";
import axios from "axios";
import { setVideoOnEdit, updateVideo } from "../../Redux/Actions/videoActions";

const EditVideo = () => {
  const dispatch = useDispatch();
  const videoOnEdit = useSelector((state) => state.video.videoOnEdit);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const loggedUser = useSelector((state) => state.users.loggedUser);
  const isLoggedIn = Object.keys(loggedUser).length > 0;
  const [formData, setFormData] = useState({
    title: "",
    createdOn: "",
    slug: "",
    videoUrl: "",
  });

  if (!isLoggedIn) navigate("/login");

  useEffect(() => {
    setFormData({
      title: videoOnEdit.title || "",
      createdOn: videoOnEdit.createdOn || "",
      videoUrl: videoOnEdit.videoUrl || "",
    });
  }, [videoOnEdit]);

  const validateForm = () => {
    const errors = {};
    const dateRegex = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/;
    const monthDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    const youtubeUrlRegex =
      /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/)[a-zA-Z0-9_-]{11}$/;

    const isLeapYear = (year) => {
      return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
    };

    const isValidDate = (day, month, year) => {
      if (month < 1 || month > 12) return false;
      const maxDays =
        month === 2 && isLeapYear(year) ? 29 : monthDays[month - 1];
      return day >= 1 && day <= maxDays;
    };

    if (!formData.title.trim()) errors.title = "Titulli nuk mund të jetë bosh!";

    if (!formData.createdOn.trim()) {
      errors.createdOn = "Data e krijimit nuk mund të jetë bosh!";
    } else {
      const match = formData.createdOn.match(dateRegex);
      if (!match) {
        errors.createdOn =
          "Data e krijimit duhet të jetë në formatin 'dd/mm/yyyy' ose 'd/m/yyyy'!";
      } else {
        const [_, day, month, year] = match.map(Number);
        const enteredDate = new Date(year, month - 1, day);
        const currentDate = new Date();

        if (!isValidDate(day, month, year)) {
          errors.createdOn =
            "Datë jo e saktë!Kontrolloni ditën, muajin dhe vitin!";
        } else if (enteredDate > currentDate) {
          errors.createdOn = "Data nuk mund të jetë në të ardhmen!";
        }
      }
    }

    if (!formData.videoUrl.trim()) {
      errors.videoUrl = "Video URL nuk mund të jetë bosh!";
    } else if (!youtubeUrlRegex.test(formData.videoUrl)) {
      errors.videoUrl = "Video URL jo e saktë!";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    if (!videoOnEdit._id) {
      console.error("Error: _id is undefined. Cannot proceed.");
      return;
    }

    try {
      setLoading(true);
      const response = await axios.put(
        `https://api.arkiva.gov.al/api/video/update/${videoOnEdit._id}`,
        formData
      );

      if (response.data) {
        dispatch(updateVideo(response.data));
        navigate("/manage/video");
        dispatch(setVideoOnEdit({}));
      } else {
        console.error("Something went wrong");
      }
    } catch (error) {
      console.error("Error updating video:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    navigate("/manage/video");
    dispatch(setVideoOnEdit({}));
  };

  const convertToEmbedUrl = (url) => {
    try {
      if (url.includes("youtu.be")) {
        return url.replace("youtu.be/", "www.youtube.com/embed/");
      }
      if (url.includes("watch?v=")) {
        return url.replace("watch?v=", "embed/");
      }
      return "";
    } catch (error) {
      console.error("Error converting to embed URL:", error);
      return "";
    }
  };
  const returnHome = () => {
    navigate("/manage/video");
    dispatch(setVideoOnEdit({}));
  };

  return (
    <div className="edit-page">
      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}
      <div className="return">
        <button onClick={returnHome}>
          <i className="fa-solid fa-chevron-left"></i>
        </button>
      </div>
      <p>Edito Video</p>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="title">Titulli</label>
          <input
            type="text"
            id="title"
            name="title"
            value={formData.title}
            onChange={handleChange}
          />
          {errors.title && <small className="error">{errors.title}</small>}
        </div>

        <div className="form-group">
          <label htmlFor="createdOn">Data e krijimit</label>
          <input
            type="text"
            id="createdOn"
            name="createdOn"
            value={formData.createdOn}
            onChange={handleChange}
          />
          {errors.createdOn && (
            <small className="error">{errors.createdOn}</small>
          )}
        </div>

        <div className="form-group">
          <label htmlFor="videoUrl">Video URL</label>
          <input
            type="text"
            id="videoUrl"
            name="videoUrl"
            value={formData.videoUrl}
            onChange={handleChange}
          />
          {errors.videoUrl && (
            <small className="error">{errors.videoUrl}</small>
          )}
          <div className="video">
            <iframe
              src={convertToEmbedUrl(formData.videoUrl)}
              title={formData.title}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>

        <div className="edit-actions">
          <button type="submit" className="save">
            Ruaj ndryshimet
          </button>
          <button type="button" className="cancel" onClick={handleCancel}>
            Anullo
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditVideo;

import {
  ADD_VIDEO,
  DELETE_VIDEO,
  SET_VIDEO_ON_EDIT,
  SET_VIDEOS,
  UPDATE_VIDEO,
} from "../Actions/videoActions";

const initialState = {
  videos: [],
  videoOnEdit: {},
};

const videoReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_VIDEOS:
      return {
        ...state,
        videos: action.payload,
      };
    case SET_VIDEO_ON_EDIT: {
      return {
        ...state,
        videoOnEdit: action.payload,
      };
    }
    case UPDATE_VIDEO: {
      const updatedVideo = action.payload;

      return {
        ...state,
        videos: state.videos.map((video) =>
          video._id === updatedVideo._id ? { ...video, ...updatedVideo } : video
        ),
      };
    }

    case DELETE_VIDEO:
      const newVideo = state.videos.filter(
        (item) => item._id !== action.payload
      );
      return {
        ...state,
        videoOnEdit: {},
        videos: newVideo,
      };

    case ADD_VIDEO: {
      return {
        ...state,
        videos: [...state.videos, action.payload],
      };
    }

    default: {
      return state;
    }
  }
};

export default videoReducer;

import axios from "axios";
import { useEffect, useState } from "react";

const AllPosts = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState("");

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `https://api.arkiva.gov.al/api/posts?page=${currentPage}&limit=30`
        );

        setPosts((prevPosts) => [...prevPosts, ...response.data.posts]);
        setTotalPages(response.data.totalPages);
      } catch (error) {
        console.error("Error fetching posts:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, [currentPage]);

  const loadMorePosts = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const filteredPosts = posts.filter((post) =>
    post.title.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <div className="all-posts">
      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}
      <div className="all-posts-title">
        Aktivitete të Drejtorisë së Përgjithshme të Arkivave
      </div>
      <div className="all-posts-filters">
        <div className="search-filter">
          <input
            className="input-grey-rounded"
            type="text"
            placeholder="Kërko"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div>
      <div className="all-posts-container">
        {filteredPosts.map((post) => (
          <div
            key={post.id}
            className="post-card"
            onClick={() => window.open(`/aktivitete/${post.slug}`, "_blank")}
          >
            <p className="post-title">{post.title}</p>
            <img
              className="post-img"
              src={`/Assets/Images/Posts/${post.id}/${post.coverImage}`}
              alt={post.title}
            />
            <p className="post-createdOn">{post.createdOn}</p>
          </div>
        ))}
      </div>
      {currentPage < totalPages && (
        <button className="load-more" onClick={loadMorePosts}>
          Më shumë
        </button>
      )}
    </div>
  );
};

export default AllPosts;

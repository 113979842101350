import { useSelector } from "react-redux";
import "../manage.css";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./dashboard.css";
import CountUp from "react-countup";
import PerformanceChart from "./PerformanceChart";
import DonutChart from "./DonutChart";

const Dashboard = () => {
  const loggedUser = useSelector((state) => state.users.loggedUser);
  const isLoggedIn = Object.keys(loggedUser).length > 0;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [postsNr, setPostsNr] = useState(0);
  const [videosNr, setVideosNr] = useState(0);
  const [ekspozitaNr, setEkspozitaNr] = useState(0);
  const [objekteMuzealeNr, setObjekteMuzealeNr] = useState(0);
  const [revistaNr, setRevistaNr] = useState(0);
  const [botimeNr, setBotimeNr] = useState(0);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          "https://api.arkiva.gov.al/api/posts/stats/dashboard"
        );

        if (response.data.message === "error") {
          alert("Something went wrong!");
        } else {
          setPostsNr(response.data.count);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    const fetchVideos = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          "https://api.arkiva.gov.al/api/video/stats/dashboard"
        );

        if (response.data.message === "error") {
          alert("Something went wrong!");
        } else {
          setVideosNr(response.data.count);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    const fetchEkspozita = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          "https://api.arkiva.gov.al/api/ekspozita/stats/dashboard"
        );

        if (response.data.message === "error") {
          alert("Something went wrong!");
        } else {
          setEkspozitaNr(response.data.count);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    const fetchObjekteMuzeale = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          "https://api.arkiva.gov.al/api/objekteMuzeale/stats/dashboard"
        );

        if (response.data.message === "error") {
          alert("Something went wrong!");
        } else {
          setObjekteMuzealeNr(response.data.count);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    const fetchRevista = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          "https://api.arkiva.gov.al/api/revista/stats/dashboard"
        );

        if (response.data.message === "error") {
          alert("Something went wrong!");
        } else {
          setRevistaNr(response.data.count);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    const fetchBotime = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          "https://api.arkiva.gov.al/api/botime/stats/dashboard"
        );

        if (response.data.message === "error") {
          alert("Something went wrong!");
        } else {
          setBotimeNr(response.data.count);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    if (isLoggedIn) {
      fetchPosts();
      fetchVideos();
      fetchEkspozita();
      fetchObjekteMuzeale();
      fetchRevista();
      fetchBotime();
    } else {
      navigate("/login");
    }
  }, [isLoggedIn]);

  if (!isLoggedIn) {
    return null;
  }

  const widgets = [
    {
      icons: "fa-solid fa-newspaper",
      stats: postsNr,
      title: "Aktivitete",
      className: "postime",
      navigate: "/manage/aktivitete",
    },
    {
      icons: "fa-solid fa-circle-play",
      stats: videosNr,
      title: "Videos",
      className: "videos",
      navigate: "/manage/videos",
    },
    {
      icons: "fa-solid fa-person-chalkboard",
      stats: ekspozitaNr,
      title: "Ekspozita",
      className: "ekspozita",
      navigate: "/manage/ekspozita",
    },
    {
      icons: "fa-solid fa-landmark",
      stats: objekteMuzealeNr,
      title: "Objekte Muzeale",
      className: "objekte",
      navigate: "/manage/objekte-muzeale",
    },
    {
      icons: "fa-solid fa-tachograph-digital",
      stats: revistaNr,
      title: "Revista ASh",
      className: "revista",
      navigate: "/manage/revista",
    },
    {
      icons: "fa-solid fa-book-bookmark",
      stats: botimeNr,
      title: "Botime AQSH",
      className: "botime",
      navigate: "/manage/botime",
    },
  ];

  return (
    <div className="dashboard">
      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}
      <div className="dashboard-widgets">
        {widgets.map((widget, index) => (
          <div
            className="widget"
            key={index}
            onClick={() => navigate(widget.navigate)}
          >
            <div className={`widget-icon ${widget.className}`}>
              <i class={widget.icons}></i>
            </div>
            <div className="dashboard-info">
              <CountUp start={0} end={widget.stats} delay={8000}>
                {({ countUpRef }) => (
                  <div>
                    <span ref={countUpRef} />
                  </div>
                )}
              </CountUp>
              <div className="widget-title">{widget.title}</div>
            </div>
          </div>
        ))}
      </div>
      <div className="dashboard-charts">
        <PerformanceChart />

        <div className="donut-chart">
          <DonutChart width={300} height={300} />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

import PasuriArkivore from "../Assets/Images/Icons/PasuriArkivore.png";
import ArkivatKujtojne from "../Assets/Images/Icons/ArkivatKujtojne.png";
import ObjekteMuzeale from "../Assets/Images/Icons/ObjekteMuzeale.png";
import Fototeka from "../Assets/Images/Icons/Fototeka.png";
import Botime from "../Assets/Images/Icons/Botime.png";
import RrjetiArkivorKombetar from "../Assets/Images/Icons/RrjetiArkivorKombetar.png";
import InspektoriatShteteror from "../Assets/Images/Icons/InspektoriatShteteror.png";
import LaboratoriRestaurimit from "../Assets/Images/Icons/LaboratoriRestaurimit.png";
import DokumenteUnifikuese from "../Assets/Images/Icons/DokumenteUnifikuese.png";
import "./arkiva.css";
import { useNavigate } from "react-router-dom";
const Arkiva = () => {
  const navigate = useNavigate();
  const archive = [
    {
      title: "Pasuri Arkivore",
      image: PasuriArkivore,
      url: "/muzeu-virtual/ekspozita",
    },
    {
      title: "Arkivat Kujtojnë",
      image: ArkivatKujtojne,
      url: "/doreshkrime-bizantine-dhe-pasbizantine",
    },
    {
      title: "Objekte Muzeale",
      image: ObjekteMuzeale,
      url: "/muzeu-virtual/objekte-muzeale",
    },
    {
      title: "Fototeka",
      image: Fototeka,
      url: "/kerko/fototeka",
    },
    {
      title: "Botime",
      image: Botime,
      url: "/botime/aqsh",
    },
  ];

  const services = [
    {
      title: "Rrjeti Arkivor Kombëtar",
      description:
        "DPA ka në varësi metodologjike AQSH, ASHV-të, arkivat e sistemit, arkivat e tipologjisë, arkivat e përhershme dhe sekretari-arkivat e çdo institucioni publik në vend.",
      image: RrjetiArkivorKombetar,
      url: "/drejtoria/organizimi-i-rrjetit-arkivor",
    },
    {
      title: "Inspektoriat Shtetëror",
      description:
        "DPA inspekton rrjetin arkivor për formatin, ruajtjen dhe përpunimin e dokumente, si dhe përcaktimin e sanksioneve ndaj titullarëve e specialistëve të institucioneve.",
      image: InspektoriatShteteror,
      url: "/struktura/drejtoria-e-inspektimit-dhe-trajnimeve",
    },
    {
      title: "Laboratori i Restaurimit",
      description:
        "Laboratori Qendror i Restaurimit është unik në Shqipëri, për restaurimin e dokumenteve të dëmtuara jo vetëm të Arkivit Qendror Shtetëror, por të gjithë rrjetit arkivor.",
      image: LaboratoriRestaurimit,
      url: "/laboratori-i-restaurimit",
    },
    {
      title: "Dokumente Unifikuese",
      description:
        "Institucionet shtetërore janë të detyruara të zbatojnë një sërë rregullash për punën me dokumentet, përfshi formatin e dokumentit administrativ.",
      image: DokumenteUnifikuese,
      url: "/legjislacioni/dokumente-dhe-strategji",
    },
  ];
  return (
    <div className="arkiva-icons">
      <div className="arkiva-icons-row1">
        {archive.map((archive, index) => (
          <div
            className="arkiva-item"
            onClick={() => navigate(archive.url)}
            key={index}
          >
            <img src={archive.image} alt={archive.title} />
            <p className="arkiva-title">{archive.title}</p>
          </div>
        ))}
      </div>
      <div className="arkiva-icons-row2">
        {services.map((service, index) => (
          <div
            className="service-item"
            onClick={() => navigate(service.url)}
            key={index}
          >
            <img src={service.image} alt={service.title} />
            <p className="service-title">{service.title}</p>
            <p className="service-desc">{service.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Arkiva;

import { useLocation, useNavigate } from "react-router-dom";
import "./manageNavbar.css";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import User from "../../Assets/Images/user.png";
import { setLoggedUser } from "../../Redux/Actions/userActions";
const ManageNavbar = () => {
  const location = useLocation();
  const [openMenu, setOpenMenu] = useState(false);
  const [openDropdownMenu, setOpenDropdownMenu] = useState(false);
  const loggedUser = useSelector((state) => state.users.loggedUser);
  const isLoggedIn = Object.keys(loggedUser).length > 0;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const menu = [
    {
      title: "Dashboard",
      path: "/manage/dashboard",
      icon: "fa-solid fa-house",
    },
    {
      title: "Aktivitete",
      path: "/manage/aktivitete",
      icon: "fa-regular fa-newspaper",
    },
    { title: "Video", path: "/manage/video", icon: "fa-brands fa-youtube" },
    {
      title: "Ekspozita",
      path: "/manage/ekspozita",
      icon: "fa-solid fa-person-chalkboard",
    },
    {
      title: "Objekte Muzeale",
      path: "/manage/objekte-muzeale",
      icon: "fa-solid fa-building-columns",
    },
    {
      title: "Revista Arkivi Shqiptar",
      path: "/manage/revista",
      icon: "fa-solid fa-newspaper",
    },
    {
      title: "Botime të AQSH",
      path: "/manage/botime",
      icon: "fa-solid fa-book",
    },
    {
      title: "Rregj. i Dok. RHK",
      path: "/manage/regjistriRhk",
      icon: "fa-solid fa-book-open",
    },
    {
      title: "Rregj. i Dok.  të Humbura",
      path: "/manage/regjistriDokHumbura",
      icon: "fa-solid fa-rectangle-list",
    },
    {
      title: `${loggedUser.firstName} ${loggedUser.lastName}`,
      path: "/manage/user",
      icon: "fa-solid fa-user",
    },
  ];

  const redirect = (path) => {
    navigate(path);
    setOpenMenu(false);
  };

  const toggleDropdown = () => {
    setOpenDropdownMenu(!openDropdownMenu);
  };

  const handleLogOut = () => {
    dispatch(setLoggedUser({}));
    localStorage.setItem("loggedUser_id", 0);
    navigate("/login");
  };

  const handleEditUser = () => {
    navigate("/manage/user");
    setOpenDropdownMenu(false);
  };

  return (
    <div className="manage-navbar">
      <div className="logo-text">
        {location.pathname.includes("dashboard")
          ? "Dashboard"
          : location.pathname.includes("aktivitete")
          ? "Aktivitete"
          : location.pathname.includes("video")
          ? "Video"
          : location.pathname.includes("ekspozita")
          ? "Ekspozita"
          : location.pathname.includes("objekte-muzeale")
          ? "Objekte Muzeale"
          : location.pathname.includes("revista")
          ? "Revista të Arkivit Shqiptar"
          : location.pathname.includes("botime")
          ? "Botime të AQSh"
          : location.pathname.includes("regjistriRhk")
          ? "Rregjistri i Dokumenteve RHK  në Pronësi të privatëve"
          : location.pathname.includes("regjistriDokHumbura")
          ? "Rregjistri i Dokumenteve të Shpallura të Humbura"
          : location.pathname.includes("regjistriDokHumbura")
          ? "Menaxho Profilin"
          : ""}
      </div>

      <div className="manage-navbar-icons">
        <div className="navbar-tab">
          <a href="https://arkiva.gov.al" target="_blank" rel="noreferrer">
            ARKIVA.GOV.AL
          </a>
        </div>
      </div>

      <div className="user-profile">
        <button onClick={toggleDropdown}>
          {loggedUser.firstName} {loggedUser.lastName}
          <img src={User} alt="user" />
        </button>
        {openDropdownMenu && (
          <div className="profile-menu">
            <ul>
              <li onClick={handleEditUser}>
                <i className="fa-solid fa-user"></i> Profile
              </li>

              <li onClick={handleLogOut}>
                <i className="fa-solid fa-right-from-bracket"></i> Log out
              </li>
            </ul>
          </div>
        )}
      </div>

      <div className={`navbar-menu ${openMenu ? "expanded" : "collapsed"}`}>
        <div className="burger-menu" onClick={() => setOpenMenu(!openMenu)}>
          {openMenu ? (
            <i className="fa-solid fa-xmark"></i>
          ) : (
            <i className="fa-solid fa-bars"></i>
          )}
        </div>

        <div className={`menu-tabs ${openMenu ? "expanded" : "collapsed"}`}>
          {menu.map((item, index) => (
            <div
              className={`menu-tab ${
                location.pathname.includes(item.path) ? "active" : ""
              }`}
              key={index}
              onClick={() => redirect(item.path)}
            >
              <i className={item.icon}></i>
              <span>{item.title}</span>
            </div>
          ))}
          {isLoggedIn ? (
            <>
              <div className="menu-tab" onClick={() => navigate("/login")}>
                <i className="fa-solid fa-arrow-right-from-bracket fa-flip-horizontal"></i>
                <span>Logout</span>
              </div>
            </>
          ) : (
            <div className="menu-tab" onClick={() => navigate("/login")}>
              <i className="icon">
                <i className="fa-solid fa-right-from-bracket"></i>
              </i>
              <span>Log in</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ManageNavbar;

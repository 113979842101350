export const kerkoData = [
  {
    title: "Udhëzues për regjistrimin dhe kërkimin në faqe",
    path: "/kerko/udhezues-per-regjistrimin-dhe-kerkimin-ne-faqe",
    leftContent: [
      {
        url: "https://cdn.prod.website-files.com/65b91011f3e26e536a8eb1cc/65b91011f3e26e536a8eb2a0_Udhe%CC%88zues-ke%CC%88rkimi-ne%CC%88-Katalogun-on-line-2020.pdf",
        title: "Udhëzues kërkimi në Katalogun on-line",
      },
      {
        url: "",
        title: "Udhëzime për krijimin e llogarisë në faqen online të DPA",
      },
      {
        url: "",
        title: "Udhëzim për kërkimin në Katalogun online përmes pemës arkivore",
      },
      {
        url: "",
        title: "Udhëzim për kërkimin në Katalogun online me fjalë kyçe",
      },
    ],
    rightContent: "",
  },
  {
    title: "Rregullorja e Sallës",
    path: "/kerko/rregullorja-e-salles",

    subtitle:
      "RREGULLORE E SHFRYTËZIMIT TË DOKUMENTEVE ARKIVORE, BIBLIOTEKËS DHE FOTOTEKËS SË DREJTORISË SË PËRGJITHSHME TË ARKIVAVE",
    leftContent: [
      {
        title: "Neni 1",
        subtitle: "Rregulla të përgjithshme",
        content: [
          "Konsultimi i dokumenteve për interesa studimore kryhet në sallën e studimit të Drejtorisë së Përgjithshme të Arkivave dhe në të gjitha këndet arkivore të saj, sipas përcaktimeve në këtë rregullore.",
          "Konsultimi i dokumenteve arkivore për interesa juridike kryhet jashtë sallës së studimit dhe këndeve arkivore, sipas Rregullores për Kërkesat Social-Juridike.",
          "Konsultimi i fotografive të Fototekës së Drejtorisë së Përgjithshme të Arkivave kryhet, kryesisht, online, sipas përcaktimeve në këtë rregullore.",
          "Konsultimi i librave të Bibliotekës së Drejtorisë së Përgjithshme të Arkivave kryhet vetëm në sallën e studimit të Drejtorisë së Përgjithshme të Arkivave, sipas përcaktimeve në këtë rregullore.",
          "Salla e studimit pranë DPA-së shërben me këtë orar: E Martë – e premte: 09.00 – 19.00 E shtunë: 09.00 – 14.00. Dokumentet në origjinal shërbehen deri në jo më vonë se 30 minuta përpara mbylljes së sallës.",
          "Këndet arkivore të çelura nga DPA, në bashkëpunim me partnerë lokalë, shërbejnë sipas orareve të përcaktuara nga këta partnerë.",
          "Salla e studimit është e mbyllur në ditët e pushimit, në festat zyrtare, si dhe nga data 15 Dhjetor deri më 15 Janar të çdo viti kur kryhet verifikimi fizik i mjeteve të kërkimit, si dhe për raste të veçanta, kur përcaktohet me urdhër të Drejtorit të Përgjithshëm.",
        ],
      },
      {
        title: "Neni 2",
        subtitle: "Salla e studimit dhe këndet arkivore",
        content: [
          "Drejtoria e Përgjithshme e Arkivave, në përputhje me nenin 17 të ligjit “Për Arkivat”, ka sallën e vetë të studimit, që funksionon gjithnjë pranë saj dhe ku ezauron detyrimin ligjor.",
          "Drejtoria e Përgjithshme e Arkivave lidh marrëveshje bashkëpunimi me institucione shtetërore ose joshtetërore, brenda ose jashtë Republikës së Shqipërisë, për krijimin e këndeve arkivore në salla studimi ekzistuese në institucione homologe të tilla si arkiva, biblioteka, qendra kulturore dhe kështu me rradhë.",
          "Këndet Arkivore të Drejtorisë së Përgjithshme të Arkivave funksionojnë në mënyrë automatike, duke u lidhur përmes sistemit elektronik “Arkiva offline/Katalogu offline” ku kërkesat e studiuesve ngarkohen në format të skanuar, sipas përcaktimeve të kësaj rregulloreje.",
          "Këndet Arkivore kryejnë funksion të njëjtë me sallën e studimit të DPA-së, me përjashtim të faktit se në Këndet Arkivore dokumentet e kërkuara nga studiuesi shërbehen vetëm në format elektronik dhe jo në origjinal ose mikrofilm.",
          "Përgjegjësi i sallës së studimit është i detyruar që të shtojë në sistemin elektronik online, të gjitha sallat e studimit, sapo të ketë një marrëveshje të nënshkruar nga DPA dhe partneri lokal, ku të evidentohen emri i partnerit lokal, qyteti, adresa, url në google maps, ditët dhe orari i shërbimit, numri i telefonit (nëse ka) dhe fotografi i ndërtesës së partnerit lokal.",
          "Përgjegjësi i sallës së studimit është përgjegjës për ruajtjen e kontaktit me personat e kontaktit të partnerit lokal, kthimin e përgjigjeve për pyetje të studiuesve përmes personave të kontaktit të partnerit lokal, heqjen nga sistemi të datave kur partneri lokal nuk ofron shërbim dhe koordinimin me përgjegjësin e Evidencës Qendrore dhe digjitalizimit për rastet kur evidentohet një problem në sistemin elektronik.",
        ],
      },
      {
        title: "Neni 3",
        subtitle: "Rregulla për studiuesin në sallën e studimit të DPA-së",
        content: [
          "Studiuesi lejohet të ketë me vete në sallën e studimit vetëm:",
          ["Aparate telefonike që nuk kanë kamera", "Laptop"],
          "Studiuesi, përpara se të hyjë në sallën e studimit, është i detyruar të vendosë në zyrën e informacionit çantën, aparate fotografike, telefona smartphone, telefona me kamera dhe aparate të tjera riprodhimi të çfarëdo lloji, stilolapsa, si dhe ushqime. Nuk lejohet në mënyrë kategorike që kërkuesi të ketë me vete dokumente personale.",
          "Studiuesi mund të hyjë në sallën e studimit vetëm përmes kartës së tij individuale elektronike.",
          "Ndalohet hyrja në sallën e studimit e personave me sjellje të papërshtatshme që shkaktojnë bezdi për studiuesit dhe punojësit e sallës së studimit.",
          "Studiuesit nuk i lejohet të lëvizë në mjedise të tjera të DPA-së pa miratimin e përgjegjësit të sektorit të shërbimit dhe praninë e një punonjësi të sallës së studimit.",
          "Studiuesit në mënyrë kategorike nuk i lejohet të bëjë ndërhyrje në dokumente (shënime, nënvizime, palosje apo zhubrosje). Në sallën e studimit atij i lejohet vetëm përdorimi i lapsit dhe i fletëve të bardha, që i vendosen në dispozicion nga punonjësit e sallës.",
          "Ndalohet pirja e duhanit dhe konsumimi i lëngjeve e ushqimeve në të gjitha mjediset e shërbimit.",
          "Studiuesi është përgjegjës për mirëmbajtjen e dokumenteve, mjeteve të kërkimit arkivor, inventarë, katalogë, skedarë dhe aparaturave të mikrofilmimit për aq kohë sa ato i jepen atij në përdorimi. Gjendja e dokumenteve verifikohet nga personeli i sallës së studimit para dhënies dhe marrjes së tyre, në prani të studiuesit.",
        ],
      },
      {
        title: "Neni 4",
        subtitle: "Rregulla për studiuesin në Këndet Arkivore të DPA-së",
        content: [
          "Studiuesi i DPA-së në Këndet Arkivore zbaton rregullat e vendosura nga institucioni partner lokal i DPA-së.",
          "Hyrja e studiuesit në sistemin elektronik në Këndet Arkivore nuk kryhet përmes kartës individuale, por përmes emrit të përdoruesit dhe fjalëkalimit, që i jepet nëçastin e regjistrimit, sipas përcaktimeve në këtë rregullore.",
        ],
      },
      {
        title: "Neni 5",
        subtitle: "Regjistrimi",
        content: [
          "Studiuesi kryen regjistrimin e tij në sistemin elektronik Arkiva Online, si në distancë, ashtu edhe nëpërmjet asistencës në zyrën e informacionit pa hyrë në sallën e studimit të DPA-së.",
          "Përgjegjësi i sallës verifikon çdo ditë, sa më shpesh, por së paku në orën 08.00 dhe në orën 16.00, kërkesat e studiuesve për regjistrim. Nëse verifikon se të dhënat e kërkuesit janë të njëjta me kartën e identitetit e aprovon menjëherë kërkesën. Nëse verifikon mospërputhje, atëherë e refuzon, duke e arsyetuar vendimin e refuzimit, nëpërmjet sistemit online. Kërkesat e kryera me asistencën e punonjësit në dhomën e informacionit aprovohen menjëherë.",
          "Në çastin më të parë të pranisë së kërkuesit në sallën e studimit të DPA-së, punonjësi i zyrës së informacionit ka për detyrë që të komunikojë me të mbi arsyet që ai dëshiron të studioj në sallën e studimit. Nëse kërkesa është e natyrës social-juridike, punonjësi i sallës së studimit e orienton në procedurën përkatëse, sipas Rregullores së Kërkesave Social-Juridike. Nëse kërkesa është e natyrës studimore ose social-juridike, por që bëhet fjalë përdokumentacion që ruhet nga arkivat e sistemit, punonjësi i zyrës së informacionit e orienton drejt atyre arkivave.",
          "Nëse kërkesa ka të bëjë me efekt studimor, ose me efekt kërkesash social-juridike, në rastet e parashikuara në Rregulloren e Kërkesave Social-Juridike, ose në çdo rast tjetër, që pavarësisht se kërkuesi është orientuar sipas pikës 3 të këtij neni, ai sërish kërkon që të studiojë në sallën e studimit, punonjësi i zyrës së informacionit e njeh studiuesin me një kopje të rregullores e sallës së studimit i printon kartën elektronike, të cilën e lidh menjëherë me hapësirën virtuale individuale të studiuesit.",
          "Në rastet kur përgjegjësi i sallës së studimit vëren se kërkesat e kryera nga një studiues në sallën e studimit ose në këndet arkivore nuk janë të natyrës studimore, por të natyrës social-juridike, ai lajmëron menjëherë studiuesin mbi procedurën përkatëse. Nëse përgjegjësi i sallës vëren se kërkesat e natyrës social-juridike nga ana e studiuesit shkelin parimin e kërkimit për efekt të trashëgimtarëve ligjorë, përfaqësuesve të tyre ose institucioneve shtetërore, sipas nenit 64 të ligjit, ai paralajmëron menjëherë studiuesin për pasojat ligjore. Nëse edhe pas këtij paralajmërimi, personi i regjistruar vijon të kërkojë dokumentacion, jo për efekt studimor, dhe në shkelje të nenit 64 të ligjit, përgjegjësi i sallës përgatit urdhrin për përjashtim, me relacion të shtjelluar mbi ndjekjen e procedurave të kësaj pike dhe problematikave të evidentuara dhe ia paraqet në rrugë hierarkike drejtorit të Evidencës Qendrore dhe Komunikimit dhe drejtorit të Përgjithshëm të Arkivave.",
          "Përgjegjësi i sallës ose personi i përcaktuar prej tij printon formularin e regjistrimit të studiuesit, brenda së njëjtës ditë kur është pranuar një studiues i ri, sipas pikës 2 të këtij neni.",
          "Kopjet e formularit të regjistrimit, pas protokollimit, ruhen pranë punonjësit të informacionit të DPA-së. Punonjësi i informacionit, në çastin e pranisë së studiuesit në sallën e studimit të DPA-sëi jep atij kartën elektronike, vetëm pasi studiuesi nënshkruan formularin e regjistrimit.",
          "Formulari i regjistrimit të studiuesit, plotësohet, përveçse herën e parë të regjistrimit, edhe çdo herë të parë në çdo viti, që studiuesi vjen në arkiv.",
          "Punonjësi i zyrës së informacionit verifikon për çdo rast, nëse studiuesi është pajisur më herët me kartë elektronike. Nëse vëren se ai është pajisur më herët, i kërkohet të kryejë pagesën përkatëse përkundrejt mandatit të arkëtimit dhe më pas pajiset me kartë. Nënshkrimi në mandatin e arkëtimit vlerësohet si vërtetim i pajisjes me kartën e re të studiuesit.",
          "Punonjësi i zyrës së informacionit kujdeset, për aq sa është e mundur, për zbatimin e pikave 1 deri në 5 të nenit 3 të kësaj rregulloreje.",
          "Punonjësi i zyrës së informacionit çdo fundvit, nga data 15 dhjetor deri më 31 dhjetor, printon nga sistemi kërkesat e çdo studiuesi dhe ia bashkëlidh formularin e regjistrimit në zyrën e sekretari-arkivit.",
          "Sektori përgjegjës për financat dhe magazinën rakordon periodikisht me mandatet e arkëtimit në zyrën e informacionit dhe formularët e regjistruar në regjistrin e korrespodencës mbi kartat e shërbyera për herë të parë dhe kartat e shërbyera me pagesë sipas këtij neni.",
        ],
      },
      {
        title: "Neni 6",
        subtitle: "Kërkimi i dokumentacionit arkivor",
        content: [
          "Kërkuesi virtual (ai që kërkon me anën e internetit), njihet paraprakisht me mjetet e kërkimit virtual, në sistemin elektronik “Arkiva Online/Katalogu Online”.",
          "Kërkuesi i paraqitur në sallë informohet nga punonjësit e sallës mbi mundësinë e kërkimit virtual përmes internetit, jashtë mjediseve të sallës së studimit, për njësitë e ruajtjes së Arkivit Qendror Shtetëror, përfshi ish Arkivin Qendror të Partisë, si edhe për çdo Arkiv Shtetëror Vendor në çastin që përfundon hedhja në sistemin elektronik të inventarëve të tij. Ai njoftohet për ato Arkiva Shtetërore Vendore, të cilat nuk janë ende në sistemin elektronik, si edhe për rregullat e aplikimit për fototekën dhe bibliotekën.",
          "Nëse studiuesi kërkon të konsultojë mjetet e kërkimit në sallë, punonjësit e njohin me mjetet fizike, inventarë, skedarë, etj. Studiuesi ndihmohet për përdorimin e mjeteve të kërkimit arkivor me specialistët e sallës, si dhe me me specialistët e gjuhëve të rralla, ose të ish arkivit të PPSH-së, nëse diçka e tillë është e domosdoshme. Specialistët e gjuhëve të rralla ose të ish arkivit të PPSH-së thërriten nga punonjësit e sallës, vetëm nëse këta të fundit nuk kanë njohurinë e mjaftueshme për orientimin e studiuesit.",
          "DPA kujdeset, për aq sa është e mundur, të pajisen edhe me inventarë fizikë të fondeve arkivore, të cilat shërbehen sipas rregullave të partnerit lokal, veçanërisht nëse ky partner është bibliotekë.",
          "Pasi ka përzgjedhur informacionin e kërkuar, kërkuesi, si ai virtual, ashtu dhe ai i paraqitur në sallën e studimit ose në këndet arkivore, plotëson në format elektronik porosinë për njësitë e ruajtjes (dosjet) që kërkon të konsultojë. Nëse studiuesi kërkon asistencë për plotësimin në sistemin elektronik, ai ndihmohet nga punonjësit e sallës.",
          "Çdo kërkues është i lirë të kërkojë deri në 25 (njëzetepesë) njësi ruajtje në ditë, me një kapacitet maksimal prej 400 (katërqind) dosjesh në ditë (nga të gjithë studiuesit e DPA), sipas përparësisë së kohës së aplikimit. Nëse një kërkues kërkon më shumë se 25 dosje, atëherë kryen kërkesë të re për një datë tjetër.",
          "Kërkuesi përcakton vetë datën kur kërkon t’i shohë njësitë e ruajtjes, afat i cili fillon pas pesëditësh nga data e kërkimit.",
        ],
      },
      {
        title: "Neni 7",
        subtitle: "Shërbimi i Bibliotekës në Sallën e Studimit të DPA-së",
        content: [
          "Librat që gjenden në Bibliotekën e Drejtorisë së Përgjithshme të Arkivave mund të shërbehen vetëm në sallën e studimit të Drejtorisë së Përgjithshme të Arkivave.",
          "Studiuesi i regjistruar mund të paracaktojë librat që dëshiron të shohë në sallë, përmes sistemit elektronik, si nga shtëpia, edhe në sallë, së paku një ditë pune përpara.",
          "Specialisti i ngarkuar me bibliotekën dorëzon në sallën e studimit në fund të ditës paraardhëse librat e kërkuara për në sallë dhe evidenton në sistemin elektronik dhënien dhe rimarrjen e librit nga salla e studimit.",
          "Studiuesi mund të kërkojë konsultimin e librit për deri në shtatë ditë pune. Ditën që studiuesi përfundon konsultimin e librit, specialist i ngarkuar me bibliotekën është i detyruar të kthejë librin në vendin përkatës në bibliotekë.",
          "Kur studiuesi kërkon dixhitalizimin e disa faqeve të një libri, skanimi i tyre kryhet në të njëjtën procedurë si për dokumentacionin arkivor në sallën e studimit, sipas përcaktimeve të kësaj rregulloreje. Në çdo rast, nuk mund të skanohet më shumë se 20% e një libri.",
          "Çdo fund viti, specialisti i ngarkuar me bibliotekën printon nga sistemi kartelat e studiuesve dhe ia bashkëlidh një kopje të tyre dosjes personale të çdo studiuesipranë punonjësit të informacionit të sallës së studimit, ndërsa një kopje e mban vet për qëllim evidentimi.",
          "Specialisti i ngarkuar me bibliotekën ka për detyrë që të evidentojë në bibliotekë çdo libër të ri që pasuron bibliotekën, përmes të gjitha proceseve të përpunimit, regjistrimit, kontrollit, përcaktimit të çmimit dhe hedhjes në katalogun elektronik brenda një dite pune nga çasti që libri i dorëzohet specialistit.",
          "Nëse sasia e librave është më e lartë se 25 në një ditë pune, specialisti ka afat një ditë pune për çdo 20 libra për përfundimin e procesit të sipërpërmendur.s",
          "Në periudhën 15 dhjetor-15 janar, specialisti i ngarkuar me bibliotekën, përgjegjësi i financës dhe përgjegjësi i sallës kryejnë inventarizimin e plotë të bibliotekës.",
        ],
      },
      {
        title: "Neni 8",
        subtitle: "Shërbimi i Fototekës së DPA-së",
        content: [
          "Fototeka e Drejtorisë së Përgjithshme të Arkivave shërbehet vetëm online, përmes sistemit fototeka.arkiva.gov.al. Shërbimi në sallën e studimit bëhet vetëm me miratim paraprak të drejtorit të Përgjithshëm, në rastet kur studiuesi ka nevojë të studiojë cilësinë e suportit dhe jo përmbajtjen e fotografisë.",
          "Në rastet kur drejtori i Përgjithshëm ka urdhëruar shërbimin në origjinal, studiuesi mbikqyret në mënyrë të vazhdueshme nga specialisti i fototekës.",
          "Studiuesi i regjistruar mund të blejë fotografi me rezolucion të lartë, me watermark, përmes sistemit elektronik në mënyrë automatike.",
          "Specialisti përgjegjës për fototekën është i detyruar që çdo ditë pune që ka blerje, të printojë faturat e gjeneruara automatikisht nga sistemi dhe t’ia dorëzojë ato sektorit përgjegjës për financat përmes një shkrese të protokolluar, i cili rakordon me të ardhurat dytësore të institucionit.",
          "Çdo studiues apo person i interesuar i cili është regjistruar në faqen e fototekës online nëse ka një informacion më të detajuar në lidhje me imazhin e paraqitur mund të sugjerojë të dhënat duke plotësuar hapësirat përkatëse dedikuar sugjerimeve. Specialisti bën konsultimet dhe verifikimet përkatëse dhe pranon ose refuzon sugjerimet e bëra, jo më vonë se shtatë ditë nga paraqitja e sygjerimit. Në rast se ai ka të pamundur verifikimin e sygjerimit, njofton në nivel hierarkik përgjegjësin e sektorit, drejtorin e drejtorisë dhe drejtorin e Përgjithshëm.",
          "Specialisti përgjegjës për fototekën pasuron në mënyrë të vazhdueshme fototekën, bazuar në planin e punës së miratuar për të.",
          "Çdo fundvit, specialisti i fototekës printon nga sistemi veprimtarinë e çdo studiuesi dhe ia bashkëlidh dosjes individuale të tij pranë punonjësit të informacionit të sallës së studimit.",
        ],
      },
      {
        title: "Neni 9",
        subtitle: "Urdhër-porosia e njësive të ruajtjes në sallën e studimit",
        content: [
          "Përgjegjësi i sallës së studimit shpërndan çdo ditë brenda orës 11:00 te përgjegjësi i Evidencës Qendrore dhe digjitalizimit, përgjegjësi i Laboratorit Qendror të Restaurimit, sekretari i KDZH-së, përgjegjësat e aq vendruajtjeve të arkivave vendore, AQSH ose AQP, sa ka porosi dhe te punonjësi i mikrofilmave, draftin e urdhrit për pesë ditë pune më vonë, siç del nga sistemi.",
          "ërgjegjësi i sallës së studimit kontrollon deri në orën 14:00 të ditës së punës, ndër dosjet që nuk janë ende të dixhitalizuara ose të mikrofilmuara, në bashkëpunim aq persona sa nevojiten nga pika 1, nëse:",
          [
            "dosjet e kërkuara janë sekret;",
            "ka pasaktësi në formulim;",
            "dosja gjendet për restaurim.",
          ],
          "Nëse ka njësi ruajtje sipas pikës 1 të këtij neni kontakton menjëherë përmes telefonit me kërkuesin për të rregulluar gabimet e mundshme, ose për t’u kërkuar që të përcaktojnë njësi të tjera ruajtjeje për dosjet që janë sekret apo për restaurim. Dosjet e ndryshuara sipas kësaj pike të këtij neni, ndryshohen në sistemin elektronik nga përgjegjësi i sallës së studimit.",
          "Punonjësi i ngarkuar me mikrofilmat dhe punonjësi i ngarkuar me dixhitalizimin, kontrollon nëse dosjet e përcaktuara si të skanuara ose mikrofilmuara nga sistemi, janë të sakta dhe të lexueshme, si edhe nëse ekzistojnë dosje të urdhëruara për skanim, të cilat janë të skanuara ose të mikrofilmuara. Nëse vërehet se ekzistojnë dosje që janë të palexueshme, ata lajmërojnë përgjegjësat e tyre, si dhe i heqin menjëherë nga databaza përkatëse.",
          "Nëse vërehen se sistemi elektronik ka nxjerrë si të mikrofilmuara ose të skanuara dosje që nuk janë të tilla, ose nxjerr si të paskanuara ose të pamikrofilmuara, dosje që janë të tilla, verifikojnë menjëherë databazën dhe nëse rezulton se ka gabim në databazë, punonjësit e ngarkuar me mikrofilmat dhe dosjet dixhitale, njoftojnë menjëherë përgjegjësin e tyre dhe bëjnë ndryshimet në sistemin elektronik.",
          "Brenda ditës së punës, Drejtori i Evidencës Qendrore dhe Komunikimit, në bashkëpunim me përgjegjësit e sallës, vendruajtjes dhe digjitalizimit, përgatit urdhër-porosinë. Në urdhërin e punës merren parasysh këta faktorë, sipas hierarkisë së rëndësisë:",
          [
            "Njësitë e ruajtjes që janë të skanuara shërbehen të skanuara;",
            "Njësitë e ruajtjes që janë të mikrofilmuara, të shërbyeshme dhe janë kërkuar për në sallën e studimit të DPA-së e jo për këndet arkivore, shërbehen në mikrofilm.",
          ],
          "Për pjesën e mbetur të njësive të ruajtjes kalojnë për digjitalizim, aq njësi ruajtje sa është kapaciteti i sektorit të digjitalizimit për një ditë, sipas normave, me këtë radhë përzgjedhjeje:",
          [
            "Njësitë e ruajtjes të kërkuara nga Këndet Arkivore;",
            "Njësitë e ruajtjes të kërkuara nga shërbimi online;",
            "Njësitë e ruajtjes që përbajnë informacion sensitiv ose pronësor;",
            "Njësitë e ruajtjes më të vjetra",
          ],
          "Në po të njëjtin afat, Drejtori i Evidencës Qendrore dhe Komunikimit, në bashkëpunim me përgjegjësit e sallës, vendruajtjes dhe digjitalizimit, përgatisin porosinë për kalim për evidencim dhe dixhitalizim të aq njësive ruajtjeje, që së bashku me njësitë e përcaktuara në pikën 6 të këtij neni, njësitë e kërkuara nga sistemi Arkiva Online dhe njësitë e tjera të kërkuara për skanim sipas pikës 11 të nenit 15 të kësaj rregulloreje, tejkalojnë 11700 imazhe për evidencim dhe skanim, të ndara sipas sasive dhe tipologjisë të parashikuara në kontratën përkatëse.",
          "Në rastet e veçanta, kur një studiues ka shumicën e njësive të ruajtjes të shërbyeshme në origjinal, ose kur studiuesi studion në kuadër të një marrëveshjeje bashkëpunimi të DPA-së, mund të shërbehen edhe njësitë e mbetura në origjinal. Ky përjashtim mund të bëhet vetëm me miratim të Drejtorit të Përgjithshëm.",
          "Urdhër-porosia i shpërndahet sektorit të digjitalizimit, sektorit të vendruajtjes, si dhe sektorit të ish Arkivit Qendror të Partisë dhe sektorëve të Drejtorisë së Arkivave Shtetërore Vendore, në rastet kur ka njësi të ruajtjes së kërkuara prej tyre.",
        ],
      },
      {
        title: "Neni 10",
        subtitle: "Përgatitja e dokumenteve për dokumentet e AQSH",
        content: [
          "Brenda orës 16:00 të diës së nesërme, sektori i vendruajtjes dhe sektori i ish Arkivit Qendror të Partisë i dorëzojnë përgjegjësit të sektorit të dixhitalizimit të gjitha njësitë e ruajtjes që janë urdhëruar për skanim, si edhe njësitë e kërkuara sipas pikës 7 të nenit 9 të kësaj rregulloreje.",
          "Brenda të njëjtit afat, sektorët e sipërpërmendur përcaktojnë personin përgjegjës për evidentimin e të gjitha njësive që janë përcaktuar për t’u shërbyer në origjinal, duke garantuar që evidentimi do të kryhet brenda ditës së nesërme të punës.",
          "Gjatë nxjerrjes së njësive të ruajtjes, punonjësit e AQSH kontrollojnë nëse njësitë e ruajtjes kanë të domosdoshëm restaurimin dhe i dorëzojnë ato veçmas në sektorin e dixhitalizimit, brenda të njëjtit afat. Përgjegjësit e sektorit të dixhitalizimit dhe të Laboratorit të Restaurimit përcaktojnë nëse dosjet e ndara sipas këtij neni janë të dixhitalizueshme apo jo. Nëse jo, ato dërgohen brenda ditës në Laboratorin Qendror të Restaurimit.",
          "Laboratori Qendror i Restaurimit kryen restaurimin preliminar, me përparësi, të njësive të dorëzuara sipas pikës 2 të këtij neni dhe ia dorëzon sërish sektorit përgjegjës për dixhitalizimin, jo më vonë se ora 15:00 e së pasnesërmes së dorëzimit nga sektori përgjegjës për dixhitalizimin. Evidencimi dhe digjitalizimi i këtyre njësive të ruajtjes nga sektori përgjegjës kryhet me përparësi.",
        ],
      },
      {
        title: "Neni 11",
        subtitle: "Përgatitja e dokumenteve për dokumentet e ASHV-ve",
        content: [
          "Në ditën që bëhet gati për t’u punuar urdhëri i ditës për porosi në Sallën e Studimit, përgjegjësi i Sallës, ia dërgon me e-mail ASHV-ve përkatëse që kanë porosi.",
          "Brenda një ditë, sektorët përkatës përgatisin dosjet e përcaktuara në urdhër.",
          "Brenda dy ditësh nga njoftimi sipas pikës 1 të këtij neni, dosjet duhet të sillen në DPA sipas grafikut të udhëtimit të automjetit që bën Përgjegjësi i Sallës së Studimit në rakordim me Drejtorinë e Financës dhe Shërbimeve Mbështetëse.",
          "Për dosjet që kanë natyrë sensitive apo janë të klasifikuara, përgjegjësi i vendruajtjes përkatëse lajmëron përgjegjësin e Sallës së Studimit nëpërmjet telefonit dhe e-mailit.",
          "Dosjet të cilat gjenden të hedhura në katalog, por që kanë probleme në vendruajtje, përgjegjësi i ASHv-së përkatëse heq dosjen nga katalogu dhe lajmëron me e-mail drejtorin e Evidencës Qendrore dhe Komunikimit, drejtorin e Arkivave Shtetërorë Vendorë, përgjegjësin e Evidencës Qendrore dhe Digjitalizimit dhe përgjegjësin e Sallës së Studimit.",
          "Njësitë e ruajtjes duhen të jenë të shoqëruara me procesverbalin përkatës (shtojca 3) ku të evidentohet fondi, viti, numri i dosjes.",
          "Secila ASHV duhet të ketë kutinë e saj metalike ku do të futen dosjet dhe procesverbali (shtojca 3) dhe do të dylloset dhe vuloset nga përgjegjësi i sektorit përkatës ose personi i ngarkuar prej tij dhe do t’i jepet shoferit të DPA-së përkundrejt procesverbalit të kutive metalike (shtojca 4).",
          "Shoferi do ta dorëzojë kutinë metalike në sallën e studimit përkundrejt firmës në procesverbalin (shtojca 4) dhe kutia do të qëndrojë në ambjentet e Sallës së Studimit deri në çastin e hapjes së saj në praninë e përgjegjësit të sallës dhe personit të ngarkuar nga Sektori i Evidencës Qendrore dhe Digjitalizimit.",
          "Hapja e kutisë do të kryhet në sallën e studimit, në mjedis të monitoruar me kamera. Përgjegjësi i sallës së studimit dhe personi i ngarkuar nga Sektori i Evidencës Qendrore dhe Dixhitalizimit do të kontrollojnë saktësinë mes procesverbalit që gjendet brenda kutisë (shtojca 3) dhe njësive të ruajtjes të gjendura në brendi të kutisë.",
          "Në verifikimin me procesverbalin, nëse ka mungesë dosjesh njoftohet përgjegjësi i vendruajtjes përkatëse. Nëse dosjet e kanë fletën e vërtetimit dhe gjatë procesit të punës në Sektorin e Evidencës Qendrore dhe Digjitalizimit vërehet që ka mungesë fletësh apo kapërcim numri, njoftohet menjëherë nëpërmjet telefonit dhe e-mailit përgjegjësi i Sektorit të ASHV-së përkatëse.",
          "Me përfundimin e procesit të evidencimit, skanimit dhe evidencimit elektronik, administratori i dokumenteve që ka kryer evidencimin elektronik, do të plotësojë procesverbalin (shtojca 3) dhe do ta vendosë, së bashku me dosjet, brenda kutisë metalike dhe do ta dyllosë dhe vulosë, në mjedis me kamera në sallën e studimit, në prani të përgjegjësit të sallës së studimit.",
          "Ditën e parë të dërgimit të automjetit në sektorin përkatës sipas pikës 3 të këtij neni, por në çdo rast, jo më vonë se 15 ditë pune nga dita e ardhjes në DPA, kutia me dosjet përkatëse, e dyllosur dhe e vulosur, do t’i jepet shoferit përkundrejt firmës dhe ai i fundit përgjegjësit të sektorit ose personit të ngarkuar prej tij. Hapja e kutisë dhe verifikimi i dosjeve përkundrejt procesverbalit shtojcë 3, do të kryhet në një mjedis me kamera në sektorin përkatës.",
          "Nëse ka kaluar afati sipas pikës 12 të këtij neni, përgjegjësi i sektorit të vendruajtjes përkatëse të ASHV-ve duhet të lajmërojë në shkallë hierarkike drejtorin e Përgjithshëm.",
          "Shtojcat 3 dhe 4 do të mbahen nga personat që kanë nënshkruar dhe do të trajtohen si dokument sipas nenit 22 të NTP. Përgjegjësia për njësitë e ruajtjes i takon nënshkruesit të fundit, ose në rast të kalimit të afatit sipas pikës 12 pa u njoftuar drejtori i Përgjithshëm, si dhe në rastet kur nuk lajmërohet menjëherë pas mbërritjes së kutisë dhe mospërputhjes mes procesverbalit (shtojca 3) dhe gjendjes faktike në kuti, përgjegjësit të sektorit përkatës të ASHV-ve.",
        ],
      },
      {
        title: "Neni 12",
        subtitle: "Evidencimi dhe skanimi",
        content: [
          "Evidencimi fizik i dokumenteve si nga sektori përgjegjës për evidencimin dhe dixhitalizimin kryhet me normë ditore jo më pak se 3000 imazhe në ditë. Përgjegjësit e sektorëve të përmendur në këtë pikë, ndajnë njësitë e ruajtjes që do të evidencojë çdo specialist evidencimi.",
          "Në rast se në sektorin e dixhitalizimit mungon një specialist, ai zëvendësohet për atë ditë nga specialisti i dixhitalizimit, nga përgjegjësi. Nëse sektori, gjatë një dite ka më pak se 7 punonjës, drejtori i Evidencës Qendrore dhe Komunikimit urdhëron verbalisht zëvendësimin provizor me punonjë të sektorëve të tjerë të varësisë së tij.",
          "Evidencimi do të kryhet sipas të gjitha rregullave në fuqi, përfshi rregullat e mëposhtme:",
          [
            "Specialisti i Evidencës numëron dokumentet e njësisë së ruajtjes duke nxjerrë numrin e fletëve dhe numrin e imazheve. Numri i fletëve do të përcaktohet në fletën e vërtetimit të njësisë së ruajtjes, ndërsa numri i imazheve në një procesverbal të posaçëm të përpiluar nga përgjegjësi i sektorit.",
            "Nëse gjatë numërtimit do të rezultojnë fletë pa numër ato do të numërohen me fraksion. Çdo fletë e numëruar me fraksion do të vendoset në fund të njësisë së ruajtjes.",
            "Nëse gjatë numërimit do të rezultojnë kapërcime numri, specialistët do të verifikojnë dokumentet para dhe pas nëse janë pjesë e një praktike dhe nëse kapërcimi i numërimit është gabim njerëzor. Nëse kapërcimi i numrit është gabim njerëzor atëherë vetëm në sektorin e Evidencës dhe vetëm për procesin e skanimit midis fletëve do të vendoset një fletë bosh me shënimin “kapërcim numri”.",
            "Nëse ka më shumë se një fletë me të njëjtin numër, si pasojë e një gabimi njerëzor, nëse janë pjesë e së njëjtës praktikë, fletëve në vijim do t’u vendoset fraksion.",
            "Nëse njësia e ruajtjes nis me numërtim tjetër përveç numrit 1 dhe nuk është dojse me fraksion dhe në çdo rast tjetër që gjatë verifikimit nuk përcaktohet dot që parregullsia është gabim njerëzor, specialisti do ta lërë mënjanë këtë dosje duke njoftuar përgjegjësin e sektorit. Përgjegjësi i sektorit në fillimin e ditës pasardhëse përgatit urdhërin për hetim administrativ dhe ia dorëzon drejtorit të Përgjithshëm për nënshkrim. Hetimi administrative kryhet rast pas rasti, nga grupi i punës i përbërë nga Drejtori i Evidencës Qendrore dhe Komunikimit, Përgjegjësi i Ruajtjes së Dokumentacionit dhe punonjësi i vendruajtjes ku dokumentacioni është gjendur, për Arkivin Qendror Shtetëror dhe nga Drejtori i Arkivave Shtetërorë Vendorë, Përgjegjësi i Sektorit përkatës në rrethe dhe punonjësi i vendruajtjes ku dokumentacioni është gjendur, për Arkivat Shtetërorë Vendorë.",
            "Fletët e numërtuara jo me laps do të rinumërtohen me laps.",
            "Për njësitë e ruajtjes me më shumë se një numër në fletë, sasia e fletëve do të përcaktohet pasi të jetë numëruar e gjithë njësia duke nxjerrë dhe numrin e fletëve dhe numrin që merr dokumenti/fleta.",
          ],
          "Evidencimi do të përfshijë edhe çdo element tjetër të përcaktuar në urdhërat e mëparshëm në fuqi, përfshi, numërtimin nëse nuk është e numërtuar, shtrirjen e fletëve, nxjerrjen e dokumenteve nga zarfet e fashikujt, heqjen e elementëve që nuk duhet të jenë në njësi si kapse, gjilpëra, qepja me spango, letra shpjeguese, përkthime, tregues etj.",
          "Çdo njësi ruajtjeje për të cilin nuk janë nxjerrë tregues emëror në Arkivin Qendror Shtetëror, do të shënohen veçmas dhe pas procesit të marrjes në dorëzim, do t’i kalojnë Sektorit të Shërbimit në Distancë, për nxjerrjen e treguesve emëror të pronave.",
          "Përgjegjësi i sektorit do të përcaktojë çdo fund dite njësitë e ruajtjes që përmbajnë numrin e përcaktuar të imazheve për skanim, sipas kontratës së nënshkruar me operatorin ekonomik, me prioritet urdhrat e punës. Çdo fillim dite, specialistët në praninë e përgjegjësit të sektorit, do të dorëzojnë te përgjegjësi i skanimit njësitë e ruajtjes, që i kanë kaluar procesit të mësipërm të evidencimit. Po ashtu, çdo fillim dite administratorët e dokumenteve do të marrin, në prani të përgjegjësit të sektorit dhe sipas ndarjes së punës së kryer prej tij, njësitë e ruajtjes të cilat kanë kaluar një ditë më parë në procesin e skanimit.",
          "Në rastet kur një punonjës nuk ndodhet në ditën e dorëzimit të dokumentacionit, dokumentacioni i përgatitur prej tij do të dorëzohet në praninë e të gjithë punonjësve të tjerë të sektorit.",
          "Në raste të ve]anta pamundësie të skanimit të dokumenteve, përgjegjësi i Evidencës do të kërkojë miratimin me shkrim të drejtorit të Përgjithshëm për shërbimin e dokumenteve në origjinal. Nëse këto dokumente vijnë nga rrethet, ato do të dorëzohen nga sektori përgjegjës për evidencën në sektorin përgjegjës për sallën, përkundrejt procesverbalit dhe do të qëndrojnë në sallë sipas afateve të përcaktuara në këtë rregullore. Nëse këto afate tejkalojnë afatin e dyllosjes dhe vulosjes së kutisë metalike, kutia metalike do të vuloset e dylloset fillimisht me aq dosje sa janë skanuar dhe do të qëndrojë në sallë dhe do të rihapet, në mjedis me kamera, do të përfshihen dosjet e shërbyera origjinal e do të mbyllet, vuloset e dylloset sërish, pas përfundimit të shërbimit në origjinal të dokumenteve sipas kësaj pike të këtij neni.",
          "Në çdo rast, imazhet e skanuara duhet të jenë sipas kontratës së nënshkruar me operatorin ekonomik. Përgjegjësi i sallës së studimit dorëzon në fund të ditës së përcaktuar për skanim, në sektorin e sallës së studimit, njësitë e ruajtjes që janë parashikuar për t’u shërbyer në origjinal, si edhe garanton që salla të ketë qasje në njësitë e digjitalizuara për ditën e nesërme.",
        ],
      },
      {
        title: "Neni 13",
        subtitle: "Shërbimi i dokumenteve online",
        content: [
          "Për dokumentet e kërkuara për shërbim online, specialisti i dixhitalizimit verifikon brenda një dite pune nëse dokumentet janë të skanuara më herët ose jo. Nëse janë të skanuara ai vijon me procedurën sipas pikës tre të këtij neni. Nëse nuk janë të skanuara, ai përgatit fletëporosinë që më pas nënshkruhet nga përgjegjësi i sektorit dhe drejtori i drejtorisë së Evidencës Qendrore dhe Komunikimit, brenda një dite pune nga kërkesa.",
          "Njësitë e ruajtjes të kërkuara për shërbim online, do të evidencohen dhe skanohen në të njëjtat afate dhe procedura si për ato që kërkohen në sallën e studimit.",
          "Pas skanimit, specialisti i dixhitalizimit kryen faturimin e studiuesit dhe njëherazi kryen edhe evidencimin elektronik sipas nenit 14 të kësaj rregulloreje.",
          "Jo më vonë se një ditë pune pas pagesës nga studiuesi, specialisti i dixhitalizimit do të ngarkojë në hapësirën e tij virtuale dokumentet e blera.",
        ],
      },
      {
        title: "Neni 14",
        subtitle: "Evidencimi elektronik",
        content: [
          "Evidencimi elektronik për njësitë e skanuara nga operatori ekonomik, si dhe për njësitë e fotografuara sipas pikës 13 të nenit 15 të kësaj rregulloreje, do të kryhet si më poshtë:",
          [
            "Administratorët e dokumenteve do të heqin nga njësia e ruajtjes fletët e shtuara, sipas gërmës c) të pikës 3 të nenit 12 të kësaj rregulloreje, në se ka, dhe do të vendosin në rendin e duhur fletët me fraksion, që janë në fund të njësisë së ruajtjes sipas gërmës b) të pikës 3 të nenit 12 të kësaj rregulloreje.",
            "Për çdo njësi ruajtje, administratorët e dokumenteve do të hyj në Katalogun Online dhe do të rregulloj në pasaktësi të mundshme në titullin e njësisë së ruajtjes;",
            "do të plotësojnë numrin e faqeve të çdo njësi ruajtjeje, sipas fletëvërtetimit të saj.",
          ],
          "Administratorët e dokumenteve do të verifikojnë parametrat cilësorë të imazheve të skanuara, në përputhje me kontratën e nënshkruar dhe vetëm pasi të kenë verifikuar përputhjen e tyre do të:",
          [
            "fshijnë çdo dokument elektronik të skanuar, që përkon me fletën e shtuar, sipas gërmës c) të pikës 3 të nenit 12 të kësaj rregulloreje.",
            "do të rinumërtojnë çdo fletë të skanuar, çdo fletë me fraksion, që ndodhet në fund të listës sipas gërmës b) të pikës 3 të nenit 12 të kësaj rregulloreje, ku numri elektronik duhet të korrespondojë me numrin fizik. Po ashtu, në rastet kur fleta sipas gërmës c) të pikës 3 të nenit 12 të kësaj rregulloreje, është shtuar si pasojë e imazheve që kanë më shumë se një numër, do ta riemërtojë me të dyja numrat, me presje në mes, sipas formatit “N, N”, ku “N” është numri.",
            "do të kalojë në programin elektronik përkatës, sipas teknikës “drag & drop”. Kalimi në programin elektronik vlerësohet si marrje në dorëzim dhe plotësimi të gjitha rregullimeve të mësipërme. Për çdo problem që haset do të njoftohet përgjegjësi i sektorit që do të komunikojë përzgjidhjen e tij me operatorin, sipas kontratës.",
          ],
          "Evidencimi elektronik për njësitë e ruajtjes të skanuara nga sektorët e Drejtorisë së Arkivave Shtetërore Vendore, do të kryhet si më poshtë:",
          [
            "Punonjësi i skanimit do të kryejë numërtimin e tyre në përputhje me numërtimin e njësisë së ruajtjes.",
            "Për çdo njësi ruajtje, punonjësi i dixhitalizimit do të hyj në në Katalogun Online dhedo të krijojë pemën arkivore, deri te njësia e ruajtjes, sipas të dhënave të kërkuara nga sistemi, përfshi çdo të dhënë që ndodhet në kapakun e njësisë së ruajtjes.",
            "Përgjegjësi i sektorit përkatës do të kontrollojë cilësinë e skanimeve të kryera brenda ditës dhe do të urdhërojë hedhjen e dokumenteve të skanuara saktë nga punonjësi i skanimimit në programin elektronik përkatës, sipas teknikës “drag & drop”.",
            "Përgjegjësi i sektorit përgjegjës për dixhitalizimin do të jap miratimin në programin elektronik përkatës për materialet elektronike të skanuara dhe do të gjenerojë raportin ditor të punës të çdo administratori dhe punonjësi në rrethe. Raporti ditor do të merret parasysh edhe për efekt të prezencës ditore në punë për efekt të pagës në fund të muajit.",
            "Pas përfundimit të këtij procesi, njësitë e ruajtjes kthehen në vendruajtje nga administratori i dokumenteve. Në rast se, për çfarëdo arsye, nuk është arritur skanimi i aq njësive ruajtjeje sa ishte parashikuar për një urdhër ditor, administratori i dokumenteve që merr njësinë e ruajtjes në dorëzim ia dorëzon përgjegjësit të sallës për ta shërbyer në origjinal.",
          ],
        ],
      },
      {
        title: "Neni 15",
        subtitle:
          "Shërbimi në sallën e studimit dhe këndet arkivore, si dhe riprodhimi i dokumenteve",
        content: [
          "Një ditë pune përpara ditës së përcaktuar për shërbim të dokumentacionit arkivor, përgjegjësi i sallës së studimit, brenda orës 16:00 për ditët nga e marta në të enjte dhe brenda orës 13:30 për ditën e premte, ndërsa për ditët e hëna specialisti i sallës qëështë i ngarkuar edhe me bibliotekën brenda orës 16:00, garantojnë se:",
          [
            "Punonjësi i mikrofilmit i ka dorëzuar të gjitha bobinat, xhaketat dhe mikrofilmat e përcaktuara në urdhër.",
            "Përgjegjësi i vendruajtjes përkatëse i ka dorëzuar të gjitha njësitë në origjinal, të përcaktuara në urdhër.",
            "Përgjegjësi i dixhitalizimit ka hedhur të gjitha dosjet e kërkuara në sistemin elektronik, ose ka dorëzuar dosje në origjinal nëse nuk është arritur skanimi i tyre në kohë.",
            "Specialisti i bibliotekës ka dorëzuar të gjitha librat e kërkuara për shërbim.",
            "Në rast se përgjegjësi i sallës, ose specialisti i bibliotekës për ditët e hëna, vërejnë se ka mungesa në njësitë e ruajtjes sipas urdhrit, lajmërojnë me email dhe telefon drejtorin e Evidencës Qendrore dhe Komunikimit dhe drejtorin e Përgjithshëm, brenda asaj dite. Moslajmërimi në kohë ngarkon me përgjegjësi administrative për shkelje të rëndë dhe, në rast përsëritjeje, për shkelje shumë të rëndë personin e përcaktuar në pikën 1 të kësaj rregulloreje.",
          ],
          "Sektori i Sallës së Studimit shërben njësitë në origjinal dhe njësitë në mikrofilm, menjëherë sapo paraqitet studiuesi në sallën e studimit të DPA-së.",
          "Nëse, për arsye të ndryshme, studiuesi e ka të pamundur të paraqitet në sallën e studimit ose këndin arkivor për konsultimin e njësive të ruajtjes të përzgjedhura nga ai, si online dhe onsite, njofton të paktën 2 (ditë) përpara në sistemin elektronik.",
          "Në rastin kur kërkuesi nuk paraqitet në sallën e studimit ose këndin arkivor në afatin e kërkuar, materialet mbahen 5 ditë në sallën e studimit, pas së cilës materialet kthehen në vendruajtje.",
          "Nëse studiuesi nuk arrin t’i hulumtojë të gjitha materialet brenda datës së caktuar, ai ka të drejtë t’i shikojë, hulumtojë ato deri në 5 (pesë) ditë, duke vënë në dijeni punonjësin e sallës. Në këtë rast, përgjegjësi i sallës njofton përgjegjësin përkatës të vendruajtjes/digjitalizimit dhe materialet qëndrojnë pranë specilistit përkatës.",
          "Nëse kërkuesi nuk arrin t’i hulumtojë materialet e kërkuara brenda 5 ditëve, kërkesa kthehet e papërmbushur, dhe kërkuesi është i detyruar të plotësojë një porosi të re për një datë tjetër.",
          "Studiuesi që konsulton njësi ruajtjeje të skanuara, mund t’i skanojë/printoj ato automatikisht nga sistemi, ku edhe kryen pagesën përmes kartës së debitit/kreditit ose shërbimit SMS.",
          "Studiuesi që konsulton njësi ruajtjeje në mikrofilm mund t’i skanojë/printoj ato automatikisht. Në këtë rast, përgjegjësi i sallës përgatit mandat-arkëtimin dhe pas pagesës së tij, i jep studiuesit dokumentet e skanuara/printuara, me shkresë përcjellëse të nënshkruar prej drejtorit të Evidencës Qendrore dhe Komunikimit.",
          "Studiuesi që konsulton njësi origjinale, mund të kërkojë skanimin/fotokopjimin e tyre. Në këtë rast, punonjësit e sallës, nisin menjëherë skanimin e dokumenteve në skanerin e posaçëm në sallën e studimit. Përgjegjësi i sallës përgatit mandat-arkëtimin dhe pas pagesës së tij, i jep studiuesit dokumentet e skanuara/fotokopjuara, me shkresë përcjellëse të nënshkruar prej drejtorit të Evidencës Qendrore dhe Komunikimit.",
          "Nëse përgjegjësi evidenton se fluksi i kërkesave për skanim është shumë i lartë, me së paku 50 fletë të kërkuara për person dhe ka pamundësi objektive për kryerjen e skanimit brenda ditës, njësitë e ruajtjes i kalojnë sektorit të dixhitalizimit, që i skanon tërësisht njësitë e ruajtjes përkatëse, me përparësi. Nëçdo rast, kërkesa duhet të realizohet jo më vonë se dy ditë pune nga momenti i dorëzimit prej studiuesit.",
          "Studiuesi që konsulton njësi origjinale, mund të kërkojë që të fotografojë vetë dokumentet, me aparat të siguruar prej tij, vetëm në raste të rralla dhe për arsye tërësisht studimore. Drejtori i Evidencës Qendrore, në bashkëpunim me përgjegjësin e sallës dhe përgjegjësin e dixhitalizimit verifikojnë nëse aparati ka kapacitetet dhe e programojnë atë në mënyrë që të plotësojë të gjitha kriteret e përcaktuara në rregulloren e dixhitalizimit.",
          "Kërkesa e mësipërme duhet të miratohet nga Drejtori i Përgjithshëm. Në këtë rast, studiuesi, është i detyruar të fotografojë të gjitha imazhet e njësisë së ruajtjes, brenda cilësive të kërkuara nga rregullorja e dixhitalizimit, të rregullojë numërtimin e fletëve në imazhe dhe të dorëzojë një kopje të imazheve të skanuara përgjegjësit të sallës. Përgjegjësi i sallës i shërben këto dokumente, pasi ka fshirë memorien e aparatit fotografik, pa pagesë, te studiuesi, nëpërmjet një shkrese përcjellëse të nënshkruar nga drejtori i Evidencës Qendrore dhe Dixhitalizimit, që i referohet numrit të kërkesës së miratuar nga Drejtori i Përgjithshëm.",
          "Dokumentet që ruhen në vendruajtjen nr. 7, si dhe ato për të cilat përgjegjësi i vendruajtjes dhe ai i sallës gjykojnë se duhet të kenë një trajtim të veçantë, shërbehen në origjinal sallë vetëm me miratim të posaçëm të drejtorit të Përgjithshëm, për jo më shumë se 2 orë, nën mbikqyrjen e vazhdueshme të një specialisti të caktuar nga përgjegjësi i sallës.",
        ],
      },
      {
        title: "Neni 16",
        subtitle: "Penalizimet",
        content: [
          "Studiuesi i cili me sjelljen e papërshtatshme shkakton bezdi për studiuesit e tjerë dhe punonjësit e sallës së studimit i ndalohet hyrja apo shërbimi në sallën e studimit.",
          "Studiuesi i cili nuk zbaton rregullat e nenit 2 të kësaj rregulloreje, i ndalohet frekuentimi dhe shërbimi në sallën e studimit nga 1 deri 12 muaj,",
          "Studiuesi i cili keqpërdor mjetet e kërkimit arkivor dhe njësitë e ruajtjes, i ndalohet frekuentimi dhe shërbimi në sallën e studimit nga 1 deri 12 muaj, dhe nëse dëmi është i pariparueshëm, denoncohet pranë organeve përkatëse.",
          "Studiuesi që bën qarkullimin e njësive të ruajtjes nga njëri studiues te tjetri, i ndalohet frekuentimi dhe shërbimi në sallën e studimit nga 1 deri 12 muaj, dhe në rast përsëritje, përgjithmonë.",
          "Studiuesi që nxjerr jashtë sallës së studimit fotokopje, materiale arkivore apo literaturën me të cilat është konsultuar në sallën e studimit, i ndalohet frekuentimi dhe shërbimi në sallën e studimit nga 1 deri 12 muaj, dhe në rast përsëritje, përgjithmonë.",
          "Studiuesi, i cili nuk paraqitet për hulumtimin e njësive arkivore në afatin e përcaktuar prej tij, dhe nuk njofton për mos paraqitjen, penalizohet që përsëritjen e kërkesës ta kryejë vetëm pas 1 (një) muaji nga data e kërkimit. Në rast përsëritje, studiesit i ndalohet frekuentimi dhe shërbimi në sallën e studimit nga 1 deri 12 muaj.",
          "Studiuesi, i cili kërkon për dokumentacion jo për efekt studimor, në shkelje të nenit 64 të ligjit “Për Arkivat” në mënyrë të përsëritur, i ndalohet frekuentimi dhe shërbimi në sallën e studimit nga 1 deri 12 muaj, dhe në rast ripërsëritjeje, përgjithmonë.",
        ],
      },
      {
        title: "Neni 17",
        subtitle: "Ankimimi",
        content: [
          "Nëse studiuesi nuk është i kënaqur me shërbimin, ka të drejtën e ankimimit, sipas formularit bashkëlidhur (Shtojca 1),",
          "Komisioni i Ankimimit, brenda 3 (tre) ditësh nga marrja e ankimit, verifikon dhe vendos për ankimimin, sipas formularit përkatës (Shtojca 2).",
          "Komisioni mund të vendosë fillimin e procedurës së verifikimit, edhe pa pasur ankimim.",
          "Përbërja dhe funksionimi i Komisionit është i njëjtë me Komisionin e Ankimimit të parashikuar në Regulloren për Kërkesat Social-Juridike të Qytetarëve.",
          "Nëse ankimimi rezulton i drejtë, studiuesit i ofrohet shërbimi me përparësi.",
          "Për rastet e sygjeruara nga sektori përgjegjës për sallën e studimit për masa ndaj studiuesve vendos Komisioni i Ankimimit në të njëjtat afate të përcaktuara në këtë nen për ankesat e studiuesve.",
        ],
      },
      {
        title: "Neni 18",
        subtitle: "Masat administrative",
        content: [
          "Për të gjitha shkeljet e detyrimeve të përcaktuara në këtë rregullore, Komisioni njofton zyrtarisht eprorin e drejtpërdrejtë të punonjësit për nisjen e masave disiplinore, sipas legjislacionit në fuqi.",
        ],
      },
      {
        title: "Neni 19",
        subtitle: "Dispozita të tjera",
        content: [
          "Nëse për ndonjë arsye, një datë e përcaktuar bie pushim (me vendim qeverie, apo tjetër), apo për shkaqe të tjera (evenimente të paparishikuara), dhe kërkuesit kanë bërë prenotimet e tyre, atëherë, sipas rradhës që kanë bërë prenotimin, njoftohen që të vijnë nga 10 persona në ditët në vazhdim.",
          "Nëse ndonjë prej tyre ka emergjencë, duhet të njoftojë paraprakisht përgjegjësin e sallës, i cili bën shpërndarjen te specialistët përkatës.",
          "Nëse vendoset mbyllja e sallës në një ditë të caktuar, përgjegjësi i sallës bën anulimin e asaj date nga sistemi elektronik për të gjithë shërbimin.",
          "Nëse vendoset mbyllja e një këndi arkivor për një ditë të caktuar, përgjegjësi i sallës, kur njoftohet nga personi i kontaktit i partnerit lokal, bën anulimin e asaj date nga sistemi elektronik vetëm për këndin arkivor përkatës.",
        ],
      },
    ],
    rightContent: [
      {
        title: "Rregullore e Sallës së Studimit",
        url: "https://cdn.prod.website-files.com/65b91011f3e26e536a8eb1cc/65b91011f3e26e536a8eb2a1_Rregullorja-e-Shfrytezimit-te-Dokumenteve-Arkivore-Bibliotekes-dhe-Fototekes.pdf",
      },
      {
        title: "Formular i Ankesës së Studiuesit në Sallën e Studimit",
      },
    ],
    source: "",
  },
  {
    title: "Biblioteka",
    path: "/kerko/biblioteka",
    leftContent:
      "Biblioteka e AQSH-së kujdeset për pasurimin, sistemimin dhe ruajtjen e çdo materiali bibliografik dhe dokumentar të dobishëm për këtë qëllim, u garanton shërbimin e saj përdoruesve duke ju ofruar informacione të mjaftueshme në lidhje me dokumentet që disponon. Ajo ofron, ruan dhe shërben mbi 12000 mijë njësi si antikuar, librin shqip dhe të huaj dhe periodikun të përpunuar sipas rregullave të teknikës bibliotekare. Biblioteka e AQSH- së vjen me risinë duke përcjell tek të gjithe studiuesit, historianët dhe dashamirësit e librit shërbimin e librit me Bibliotekën Online. Nëpërmjet Bibliotekes Online lexuesi mund te kërkoj librin nëpërmjet autorit, titullit, klasifikimit sipas degeve te shkences, botuesit, termit gjeografik dhe fjalëve kyçe etj. Pra kjo risi e sjell nga DPA ofron një shërbim më të specializuar dhe në kohë për të gjithë lexuesit që do t’i drejtohen Bibliotekës së DPA.",
    rightContent: "",
    source: "",
  },
  {
    title: "Fototeka",
    path: "/kerko/fototeka",
    leftContent: [
      "Fototeka e Arkivit Qendror Shtetëror është një njësi e cila është krijuar me fotografi të mbledhura gjatë viteve që nga themelimi i AQSh si dhe nga dhurimet e ndryshme.",
      "Sot Fototeka e AQSh numëron rreth 135.000 fotografi origjinale. Nga këto, rreth 41000 fotografi i përkasin veprimtarisë politike, sociale dhe kulturore të shqiptarëve dhe Shqipërisë deri në vitet 1944; rreth 43.000 fotografi i përkasin fondit të Insitutit Luce, gjatë periudhës së pushtimit Italian, prej të cilave 17.000 fotografi janë ekzemplarë tepër, ndërsa rreth 13.000 fotografi në negative(xham ose film) I përkasin fotografëve të njohur korçarë Vani Burda dhe Thimi Raci.",
      "Fototeka e DPA-së, që deri më tani mund të aksesohej vetëm në sallën e studimit dhe vetëm pjesërisht, është e dyta më e madhe në Shqipëri për fotografitë e periudhës përpara vtit 1944, pas fototekës Marubi.",
      "Fotografitë e ruajtura në AQSh janë të formateve dhe cilësisë së ndryshme. Fototeka ka një skemë klasifikimi e cila përfshin në pjesën më të madhe çdo fushë të veprimtarisë së shoqërisë (jeta politike, lufta, jeta shoqërore, qyteti, arsimi, kleri, etj.)",
      "Mbi 90 të fotografive të sipërpërmendura janë dixhitalizuar dhe do të mund të aksesohen përmes platformës Fototeka Online. Ndërkaq, materialet fotografike në xham dhe në film të Vani Burdës, Thimi Racit etj, po dixhitalizohen përmes bashkëpunimit me Muzeun Kombëtar të Fotografisë “Marubi” dhe pritet që Brenda vitit 100% e fototekës të jetë online. Një sasi shumë e vogël nga këto foto të cilat i kemi ekspozuar edhe në këtë aktivitet  janë paraqitur për publikun edhe më parë,në Shkodër dhe në Tiranë dhe kemi  kemi parë që interesi ka qënë tepër i madh.",
      "Fototeka tashmë është e gjitha online për të gjithë dhe është tepër e thjeshtë për t’u përdorur nga publiku i cili mund të futet nëpërmjet faqes së fototekës te fototeka.arkiva.gov.al/ dhe të shohi lehtësisht të gjithë pasurinë e Fototekës së Arkivit Qendror Shtetëror.",
    ],
    rightContent: "",
    source: "",
  },
  {
    title: "Fonoteka",
    path: "/kerko/fonoteka",
    leftContent: [
      "Fonoteka e AQSh-së, si pjesë e rëndësishme e arkivave zanorë shqiptarë, për nga hershmëria e dokumenteve zanore që ajo ruan, vlerësohet si një prej më të vjetrave në rajonin tonë e më gjerë.",
      "Janë mbi 18 mijë disqe, me dëshmi të rralla në zë të figurave dhe ngjarjeve më të rëndësishme të historisë botërore e asaj kombëtare. Kjo fonotekë reflekton dy momente të rëndësishme, që i japin asaj vlera të mëdha historike e arkivore.",
      "E para, duke ju referuar teknikave të riprodhimit të zërit, si fonograf, gramafonë, si dhe disqe të prodhuara në fillim të shekullit 20-të e në vazhdim deri tek CD e ditëve tona, ajo mbetet një dëshmi e prekshme e historisë së lindjes e zhvillimit të teknikës së riprodhimit të zërit.",
      "",
      "E dyta, fonoteka ka në përbërjen e saj pjesën më të madhe të krijimtarisë muzikore botërore në formën më të plotë kronologjike, duke filluar nga anonimate të viteve 1300 e duke vazhduar më pas me të gjitha etapat e zhvillimit të historisë së muzikës botërore, përfshirë këtu krijimtarinë e periudhës së Mesjetës, Klasiçizmit, Romantizmit dhe krijimtarinë e shekullit të 20-të me rrymat e saj kryesore.",
      "E veçanta e kësaj Fonoteke është se një pjesë e madhe e krijimtarisë muzikore është interpretuar nga kompozitorët, Grig, Debysi, Ravel, Sen-Sans, Prokofiev, Rahmaninov, Stravinski, Shostakoviç, etj. Këto disqe kanë jo vetëm vlera të larta artistike, por edhe vlera të mëdha historike e studimore, pasi bëhet fjalë për mjeshtra të kompozicionit e të interpretimeve botërore.",
      "Duke vijuar me elementet artistiko-kulturore të kësaj fonoteke, ka edhe mjaft disqe mbi teatrin, kinematografinë, shoun televiziv, etj. Në to spikasin artistë të mëdhenj si Lorenc Oliver, Çarli Çaplin, Frenk Sinatra, Merlin Monro, Sofia Loren, Marlon Brando, etj.",
      "Një vend të rëndësishëm zenë aty dhe disqet me karakter historik. Këto disqe përcjellin zërat e personaliteteve të shquara të kulturës, politikës, e diplomacisë botërore nëpërmjet intervistave e fjalimeve të Leon Tolstoit, Thomas Edisonit, Ajnshtajnit, Gandit, Rusveltit, Çurçillit, De Golit, Kenedit, etj.",
      "Përsa i përket trashgimisë kulturore kombëtare. Bëhet fjalë për artistë që i përkasin gjysmës së parë të shekullit të 20-të, elementë që spikatën në kulturën shqiptare të asaj kohe. Përmendim figura si Fan Noli, artistë si Aleksandër Moisiu, Tefta Tashko, Marije Kraja, Kristaq Antoniu. Është pllaka me meshën e parë shqip, realizuar nga Fan Noli në vitin 1908 dhe riprodhuar në 1978. Interpretime të Aleksandër Moisiut dhe Kristaq Antoniut. Interpretime të Tefta Tashkos dhe Marije Kraja. Ndodhen 17 pllaka me regjistrimet e muzikantit Spiridon Ilo, etj.",
    ],
    rightContent: "",
    source:
      "Burimi: Arkivi Shqiptar, viti 5, Nr. 1-2, 2004. fq. 215, Drejtoria e Përgjithshme e Arkivave, Tiranë, 2004.",
  },
];

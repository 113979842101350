import { Link } from "react-router-dom";
import "./navbar.css";

const Dropdown = ({ items, closeNav }) => {
  return (
    <div className="dropdown-menu">
      {items.map((item, index) => (
        <div key={index} className="dropdown-item">
          <Link to={item.url} onClick={closeNav}>
            {item.name}
          </Link>
        </div>
      ))}
    </div>
  );
};

export default Dropdown;

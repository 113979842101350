import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setRevista } from "../Redux/Actions/revistaAShActions";
import "./botime.css";

const RevistaASH = () => {
  const [revista, setRevistas] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchRevistaWithImages = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          "https://api.arkiva.gov.al/api/revista-with-images"
        );

        const resp = response.data.reverse();

        setRevistas(resp);

        dispatch(setRevista(resp));
      } catch (error) {
        console.error("Error fetching revista and images:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchRevistaWithImages();
  }, []);

  return (
    <div className="page">
      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}
      <div className="page-header">
        <div className="headerTitleLine"></div>
        <h1 className="page-title">Revista Arkivi Shqiptar</h1>
      </div>
      <div className="revista-items-container">
        <div className="revista-items">
          {revista
            .slice()
            .reverse()
            .map((item, index) => (
              <div className="revista-item" key={index}>
                <img src={item.images[0]} alt={item.title} className="image" />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default RevistaASH;

import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import LightGallery from "lightgallery/react";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import "lightgallery/css/lg-video.css";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
import lgVideo from "lightgallery/plugins/video";

const VideoCarousel = ({ videos }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        spaceBetween={20}
        slidesPerView={
          windowWidth >= 1170
            ? 4
            : windowWidth >= 980
            ? 3
            : windowWidth >= 600
            ? 2
            : 1
        }
        slidesPerGroup={
          windowWidth >= 1170
            ? 4
            : windowWidth >= 980
            ? 3
            : windowWidth >= 600
            ? 2
            : 1
        }
        navigation
        pagination={{ clickable: true }}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
      >
        {videos.map((video, index) => (
          <SwiperSlide key={index} style={{ textAlign: "center" }}>
            <LightGallery speed={500} plugins={[lgThumbnail, lgZoom, lgVideo]}>
              <a href={video.videoUrl} data-src={video.videoUrl}>
                <img
                  className="carousel-image"
                  src={`https://img.youtube.com/vi/${getYouTubeID(
                    video.videoUrl
                  )}/hqdefault.jpg`}
                  alt={video.title}
                />
              </a>
            </LightGallery>
            <div className="video-text">{video.title}</div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

const getYouTubeID = (url) => {
  const match = url.match(
    /(?:youtu\.be\/|youtube\.com\/(?:watch\?v=|embed\/|v\/|shorts\/))([\w-]{11})/
  );
  return match ? match[1] : null;
};

export default VideoCarousel;

import { useDispatch, useSelector } from "react-redux";
import "../manage.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  removeRegjistriRhk,
  setRegjistriRhk,
  setRegjistriRhkOnEdit,
} from "../../Redux/Actions/regjistriRhkActions";

const ManageRegjistriRhk = () => {
  const dispatch = useDispatch();
  const [regjistriRhk, setRegjistriDokRhk] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const loggedUser = useSelector((state) => state.users.loggedUser);
  const isLoggedIn = Object.keys(loggedUser).length > 0;
  const navigate = useNavigate();

  if (!isLoggedIn) {
    navigate("/login");
  }

  useEffect(() => {
    const fetchRegjistriRhk = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `https://api.arkiva.gov.al/api/regjistriRhk?page=${currentPage}&limit=20`
        );
        setRegjistriDokRhk(response.data.regjistriRhk);
        setTotalPages(response.data.totalPages);
        dispatch(setRegjistriRhk(response.data.regjistriRhk));
      } catch (error) {
        console.error("Error fetching regjistri RHK:", error);
      }
      setLoading(false);
    };

    if (isLoggedIn) {
      fetchRegjistriRhk();
    } else {
      navigate("/login");
    }
  }, [dispatch, isLoggedIn, navigate, currentPage]);

  const editRegjistriRhk = (regjistriRhk) => {
    navigate(`/manage/regjistriRhk/${regjistriRhk._id}`);
    dispatch(setRegjistriRhkOnEdit(regjistriRhk));
  };

  const addRegjistriRhk = () => {
    navigate("/manage/regjistriRhk/add-new");
  };

  const deleteRegjistriRhk = async (regjistriRhk) => {
    try {
      const response = await axios.delete(
        `https://api.arkiva.gov.al/api/regjistriRhk/delete/${regjistriRhk._id}`
      );
      if (response.data.message === "error") {
        alert("Something went wrong!");
      } else {
        setRegjistriDokRhk((prevRegjistriRhk) =>
          prevRegjistriRhk.filter(
            (item) => item._id !== response.data.regjistriRhkId
          )
        );
        dispatch(removeRegjistriRhk(response.data.regjistriRhkId));
      }
    } catch (error) {
      console.error("Error deleting regjistri RHK:", error);
    }
  };

  const filteredRegjistriRhk = regjistriRhk.filter((item) =>
    item.subject.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <div className="manage-page">
      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}
      <div className="manage-page-header">
        <div className="manage-page-header-actions">
          <input
            type="text"
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <button onClick={addRegjistriRhk}>
            <i className="fa-solid fa-plus"></i> Shto Dokument RHK
          </button>
        </div>
      </div>
      <div className="manage-table">
        <table className="table">
          <thead>
            <tr>
              <th>Emri i Subjektit që e Posedon</th>
              <th>Viti i Dokumentit</th>
              <th>Sasia e fletëve</th>
              <th>Veprime</th>
            </tr>
          </thead>
          <tbody>
            {filteredRegjistriRhk.length > 0 ? (
              filteredRegjistriRhk.map((item, index) => (
                <tr key={index}>
                  <td data-label="Emri i Subjektit që e Posedon">
                    {item.subject}
                  </td>
                  <td data-label="Viti i Dokumentit">{item.year}</td>
                  <td data-label="Sasia e fletëve">{item.pagesNr}</td>
                  <td className="actions" data-label="Veprime">
                    <button onClick={() => editRegjistriRhk(item)}>
                      <i className="fa-solid fa-pen-to-square"></i>
                    </button>
                    <button onClick={() => deleteRegjistriRhk(item)}>
                      <i className="fa-solid fa-trash"></i>
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4">Nuk u gjetën dokumenta RHK!</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="pagination">
        <button
          className={`pagination-control-btn ${
            currentPage === 1 ? "disabled" : ""
          }`}
          disabled={currentPage === 1}
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
        >
          &#8592; Para
        </button>

        <span className="pagination-current-page">
          Page {currentPage} of {totalPages}
        </span>

        <button
          className={`pagination-control-btn ${
            currentPage === totalPages ? "disabled" : ""
          }`}
          disabled={currentPage === totalPages}
          onClick={() =>
            setCurrentPage((prev) => Math.min(prev + 1, totalPages))
          }
        >
          Pas &#8594;
        </button>
      </div>
    </div>
  );
};

export default ManageRegjistriRhk;

import axios from "axios";
import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DefaultImage from "../../Assets/Images/SideBarLogo.png";
import { useDispatch, useSelector } from "react-redux";
import { addObjekt } from "../../Redux/Actions/objekteMuzealeActions";

const AddObjekt = () => {
  const navigate = useNavigate();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [coverImage, setCoverImage] = useState(null);
  const [coverPreview, setCoverPreview] = useState(DefaultImage);
  const [errors, setErrors] = useState({});
  const [newId, setNewId] = useState(null);

  const objekte = useSelector((state) => state.objekte.objekte);
  const dispatch = useDispatch();

  const handleCoverImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      setCoverImage(file);
      setCoverPreview(URL.createObjectURL(file));
    }
  };

  const handleCancel = () => {
    navigate("/manage/objekte-muzeale");
  };

  const validateForm = () => {
    const validationErrors = {};

    if (!title.trim())
      validationErrors.title = "Titulli nuk mund të jetë bosh!";
    if (!description.trim())
      validationErrors.description = "Përshkrimi nuk mund të jetë bosh!";
    if (!coverImage)
      validationErrors.coverImage = "Imazhi nuk mund të jetë bosh!";

    setErrors(validationErrors);

    return Object.keys(validationErrors).length === 0;
  };

  const getLatestId = async () => {
    try {
      const response = await axios.get(
        "https://api.arkiva.gov.al/api/objekteMuzeale/count"
      );
      return response.data.latestId + 1;
    } catch (error) {
      console.error("Error fetching latest ID:", error);
      return null;
    }
  };

  useEffect(() => {
    const fetchLatestId = async () => {
      const newId = await getLatestId();
      setNewId(newId);
    };
    fetchLatestId();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    const newId = objekte.length + 1;
    const objekteData = {
      title,
      description,
      id: newId,
      coverImage: coverImage.name,
    };

    try {
      const response = await axios.post(
        "https://api.arkiva.gov.al/api/objekteMuzeale/create",
        objekteData
      );

      dispatch(addObjekt(response.data));

      const formData = new FormData();
      formData.append("cover", coverImage);

      const resp = await axios.post(
        "https://api.arkiva.gov.al/api/objekteMuzeale/uploadFiles",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },

          params: {
            folderId: newId,
          },
        }
      );

      navigate("/manage/objekte-muzeale");
    } catch (err) {
      console.error("Error adding objekt muzeal:", err);
    }
  };

  const textareaRef = useRef(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [description]);

  return (
    <div className="edit-page">
      <div className="return">
        <button onClick={handleCancel}>
          <i className="fa-solid fa-chevron-left"></i>
        </button>
      </div>
      <p>Shto Objekt Muzeal</p>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="title">Titulli</label>
          <input
            type="text"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          {errors.title && <small className="error">{errors.title}</small>}
        </div>

        <div className="form-group">
          <label htmlFor="description">Përshkrimi</label>
          <textarea
            ref={textareaRef}
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          {errors.description && (
            <small className="error">{errors.description}</small>
          )}
        </div>

        <div className="form-group">
          <label>Imazhi</label>
          <div className="image-wrapper">
            <img
              src={coverPreview}
              alt="preview"
              style={{ width: "120px", height: "120px", objectFit: "cover" }}
            />
          </div>
          <input
            type="file"
            accept="image/*"
            onChange={handleCoverImageChange}
          />
          {errors.coverImage && (
            <small className="error">{errors.coverImage}</small>
          )}
        </div>

        <div className="edit-actions">
          <button type="submit" className="save">
            Shto Objekt
          </button>
          <button type="button" className="cancel" onClick={handleCancel}>
            Anullo
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddObjekt;

import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import "../manage.css";
import axios from "axios";
import {
  setRevistaOnEdit,
  updateRevista,
} from "../../Redux/Actions/revistaAShActions";

const EditRevista = () => {
  const dispatch = useDispatch();
  const revistaOnEdit = useSelector((state) => state.revista.revistaOnEdit);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const loggedUser = useSelector((state) => state.users.loggedUser);
  const isLoggedIn = Object.keys(loggedUser).length > 0;
  const [formData, setFormData] = useState({
    title: "",
    year: "",
    coverImage: "",
  });
  const fileInputRef = useRef(null);
  const location = useLocation();

  if (!isLoggedIn) navigate("/login");

  useEffect(() => {
    setFormData({
      title: revistaOnEdit.title || "",
      year: revistaOnEdit.year || "",
      coverImage: revistaOnEdit.image,
    });

    const fetchImage = async () => {
      if (location.pathname !== `/manage/revista/${revistaOnEdit._id}`) return;
      try {
        const response = await axios.get(
          `https://api.arkiva.gov.al/api/revista/image/${revistaOnEdit.id}`
        );

        if (response.data.image) {
          setFormData((prevData) => ({
            ...prevData,
            coverImage: response.data.image,
          }));
        }
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    };

    fetchImage();
  }, [revistaOnEdit]);

  const validateForm = () => {
    const errors = {};

    if (!formData.title.trim()) errors.title = "Titulli nuk mund të jetë bosh!";
    if (!formData.year.trim()) {
      errors.year = "Viti nuk mund të jetë bosh!";
    } else if (!formData.coverImage)
      errors.coverImage = "Kopertina nuk mund të jetë bosh!";
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleReplaceCoverImage = async (e) => {
    e.preventDefault();

    if (!formData.coverImage) {
      alert("No cover image to replace.");
      return;
    }

    const imagePath = formData.coverImage;
    setLoading(true);

    try {
      const response = await fetch(
        "https://api.arkiva.gov.al/api/revista/delete-image",
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            imagePath: `/Assets/Images/Revista/${revistaOnEdit.id}/${imagePath}`,
            revistaId: revistaOnEdit._id,
            folderId: revistaOnEdit.id,
          }),
        }
      );

      if (!response.ok) {
        console.error("Failed to delete current cover image.");
        return;
      }
      setLoading(true);
      fileInputRef.current.click();
    } catch (error) {
      console.error("Error deleting image:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCoverImageUpload = async (e) => {
    const selectedFile = e.target.files[0];

    if (!selectedFile) return;

    setLoading(true);

    try {
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("folderId", revistaOnEdit.id);

      const uploadResponse = await axios.post(
        "https://api.arkiva.gov.al/api/revista/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const { fileName } = uploadResponse.data;
      setLoading(true);
      const updateResponse = await axios.put(
        `https://api.arkiva.gov.al/api/revista/update/${revistaOnEdit._id}`,
        {
          coverImage: fileName,
        }
      );

      if (updateResponse.data) {
        setFormData((prevData) => ({
          ...prevData,
          coverImage: `/Assets/Images/Revista/${revistaOnEdit.id}/${fileName}`,
        }));
      } else {
        console.error("Failed to update the cover image in the database.");
      }
    } catch (error) {
      console.error("Error uploading new cover image:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    if (!revistaOnEdit._id) {
      console.error("Error: _id is undefined. Cannot proceed.");
      return;
    }

    try {
      const response = await axios.put(
        `https://api.arkiva.gov.al/api/revista/update/${revistaOnEdit._id}`,
        {
          title: formData.title,
          year: formData.year,
        }
      );

      if (response.data) {
        dispatch(updateRevista(response.data));
        navigate("/manage/revista");
        dispatch(setRevistaOnEdit({}));
      } else {
        console.error("Something went wrong");
      }
    } catch (error) {
      console.error("Error updating revista:", error);
    }
  };

  const handleCancel = () => {
    navigate("/manage/revista");
  };

  const returnHome = () => {
    navigate("/manage/revista");
    dispatch(setRevistaOnEdit({}));
  };

  return (
    <div className="edit-page">
      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}
      <div className="return">
        <button onClick={returnHome}>
          <i className="fa-solid fa-chevron-left"></i>
        </button>
      </div>
      <p>Edito Revistë</p>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="title">Titulli</label>
          <input
            type="text"
            id="title"
            name="title"
            value={formData.title}
            onChange={handleChange}
          />
          {errors.title && <small className="error">{errors.title}</small>}
        </div>
        <div className="form-group">
          <label htmlFor="year">Viti</label>
          <input
            type="text"
            id="year"
            name="year"
            value={formData.year}
            onChange={handleChange}
          />
          {errors.year && <small className="error">{errors.year}</small>}
        </div>

        <div className="form-group cover">
          <label>Kopertina</label>
          {formData.coverImage && (
            <div className="image-wrapper">
              <img
                src={formData.coverImage}
                alt="preview"
                className="preview"
                style={{ width: "120px", height: "120px", objectFit: "cover" }}
              />
            </div>
          )}
          <input
            type="file"
            id="imageUpload"
            name="imageUpload"
            ref={fileInputRef}
            accept="image/*"
            onChange={handleCoverImageUpload}
            style={{ display: "none" }}
          />
          {errors.coverImage && (
            <small className="error">{errors.coverImage}</small>
          )}
          <button className="replace" onClick={handleReplaceCoverImage}>
            Zëvendëso kopertinën
          </button>
        </div>

        <div className="edit-actions">
          <button type="submit" className="save">
            Ruaj ndryshimet
          </button>
          <button type="button" className="cancel" onClick={handleCancel}>
            Anullo
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditRevista;

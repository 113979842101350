import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import "../manage.css";
import axios from "axios";
import {
  setBotimeOnEdit,
  updateBotime,
} from "../../Redux/Actions/botimeActions";

const EditBotime = () => {
  const dispatch = useDispatch();
  const loggedUser = useSelector((state) => state.users.loggedUser);
  const isLoggedIn = Object.keys(loggedUser).length > 0;
  const botimeOnEdit = useSelector((state) => state.botime.botimeOnEdit);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    title: "",
    author: "",
    image: "",
  });
  const fileInputRef = useRef(null);

  const location = useLocation();

  if (!isLoggedIn) {
    navigate("/login");
  }

  useEffect(() => {
    setFormData({
      title: botimeOnEdit.title || "",
      author: botimeOnEdit.author || "",
      image: botimeOnEdit.image,
    });

    const fetchImage = async () => {
      if (location.pathname !== `/manage/botime/${botimeOnEdit._id}`) return;
      try {
        const response = await axios.get(
          `https://api.arkiva.gov.al/api/botime/image/${botimeOnEdit.id}`
        );

        if (response.data.image) {
          setFormData((prevData) => ({
            ...prevData,
            image: response.data.image[0],
          }));
        }
      } catch (error) {
        console.error("Error fetching image:", error);
      }
    };

    if (isLoggedIn) {
      fetchImage();
    } else {
      navigate("/manage/dashboard");
    }
  }, [botimeOnEdit]);

  const validateForm = () => {
    const errors = {};

    if (!formData.title.trim()) errors.title = "Titulli nuk mund të jetë bosh!";
    if (!formData.author.trim())
      errors.author = "Autori nuk mund të jetë bosh!";
    else if (!formData.image) errors.image = "Imazhi nuk mund të jetë bosh!";
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleReplaceImage = async (e) => {
    e.preventDefault();

    if (!formData.image) {
      alert("No  image to replace.");
      return;
    }

    const imagePath = formData.image;
    setLoading(true);

    try {
      const response = await fetch(
        "https://api.arkiva.gov.al/api/botime/delete-image",
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            imagePath: `/Assets/Images/Botime/${botimeOnEdit.id}/${imagePath}`,
            botimeId: botimeOnEdit._id,
            folderId: botimeOnEdit.id,
          }),
        }
      );

      if (!response.ok) {
        console.error("Failed to delete current image.");
        return;
      }
      setLoading(true);
      fileInputRef.current.click();
    } catch (error) {
      console.error("Error deleting image:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleImageUpload = async (e) => {
    const selectedFile = e.target.files[0];

    if (!selectedFile) return;

    setLoading(true);

    try {
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("folderId", botimeOnEdit.id);

      console.log(formData);

      const uploadResponse = await axios.post(
        "https://api.arkiva.gov.al/api/botime/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const { fileName } = uploadResponse.data;
      setLoading(true);
      const updateResponse = await axios.put(
        `https://api.arkiva.gov.al/api/botime/update/${botimeOnEdit._id}`,
        {
          image: fileName,
        }
      );

      if (updateResponse.data) {
        setFormData((prevData) => ({
          ...prevData,
          image: `/Assets/Images/Botime/${botimeOnEdit.id}/${fileName}`,
        }));
      } else {
        console.error("Failed to update the image in the database.");
      }
    } catch (error) {
      console.error("Error uploading new image:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    if (!botimeOnEdit._id) {
      console.error("Error: _id is undefined. Cannot proceed.");
      return;
    }

    try {
      const response = await axios.put(
        `https://api.arkiva.gov.al/api/botime/update/${botimeOnEdit._id}`,
        {
          title: formData.title,
          description: formData.description,
        }
      );

      if (response.data) {
        dispatch(updateBotime(response.data));
        navigate("/manage/botime");
        dispatch(setBotimeOnEdit({}));
      } else {
        console.error("Something went wrong");
      }
    } catch (error) {
      console.error("Error updating botime:", error);
    }
  };

  const handleCancel = () => {
    navigate("/manage/botime");
  };

  const returnHome = () => {
    navigate("/manage/botime");
    dispatch(setBotimeOnEdit({}));
  };

  return (
    <div className="edit-page">
      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}
      <div className="return">
        <button onClick={returnHome}>
          <i className="fa-solid fa-chevron-left"></i>
        </button>
      </div>
      <p>Edito Botim të AQSH</p>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="title">Titulli</label>
          <input
            type="text"
            id="title"
            name="title"
            value={formData.title}
            onChange={handleChange}
          />
          {errors.title && <small className="error">{errors.title}</small>}
        </div>

        <div className="form-group">
          <label htmlFor="author">Autori</label>
          <input
            type="text"
            id="author"
            name="author"
            value={formData.author}
            onChange={handleChange}
          />
          {errors.author && <small className="error">{errors.author}</small>}
        </div>

        <div className="form-group cover">
          <label>Imazhi</label>
          {formData.image && (
            <div className="image-wrapper">
              <img
                src={formData.image}
                alt="preview"
                className="preview"
                style={{ width: "120px", height: "120px", objectFit: "cover" }}
              />
            </div>
          )}
          <input
            type="file"
            id="imageUpload"
            name="imageUpload"
            ref={fileInputRef}
            accept="image/*"
            onChange={handleImageUpload}
            style={{ display: "none" }}
          />
          {errors.image && <small className="error">{errors.image}</small>}
          <button className="replace" onClick={handleReplaceImage}>
            Zëvendëso imazhin
          </button>
        </div>

        <div className="edit-actions">
          <button type="submit" className="save">
            Ruaj ndryshimet
          </button>
          <button type="button" className="cancel" onClick={handleCancel}>
            Anullo
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditBotime;

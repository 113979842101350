import React from "react";
import { useLocation } from "react-router-dom";
import "./drejtoria.css";
import { sektoretData } from "./sektoretData";
const Sektori = () => {
  const sectorPath = useLocation();
  let sectorData;

  sektoretData.forEach((sector) => {
    if (sector.path === sectorPath.pathname) {
      sectorData = sector;
    }
  });

  if (!sectorData) {
    return <div>Sector not found</div>;
  }

  return (
    <div className="page">
      <div className="page-header">
        <div className="headerTitleLine"></div>
        <h1 className="page-title">{sectorData.title}</h1>
      </div>
      <div className="page-content-sektori">
        <div className="left-content-sektori">
          {sectorData.image && (
            <img
              src={sectorData.image}
              alt={sectorData.name}
              className="image"
            />
          )}
          {sectorData.name && <p className="name">{sectorData.name}</p>}
          <div className="sektori-content">{sectorData.content}</div>
        </div>
        <div className="right-content-sektori"></div>
      </div>
    </div>
  );
};

export default Sektori;

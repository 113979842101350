import { useEffect, useState } from "react";
import "./videos.css";
import { useDispatch } from "react-redux";
import axios from "axios";
import { setVideos } from "../Redux/Actions/videoActions";
import VideoCarousel from "./VideoCarousel";
const Videos = () => {
  const [loading, setLoading] = useState(false);
  const [videos, setVideo] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          "https://api.arkiva.gov.al/api/video/all"
        );

        setVideo(response.data);

        dispatch(setVideos(response.data));
      } catch (error) {
        console.error("Error fetching posts and images:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchVideos();
  }, []);

  const convertToEmbedUrl = (url) => {
    if (!url) return "";

    const youtubeRegex =
      /(?:youtu\.be\/|youtube\.com\/(?:watch\?v=|embed\/|v\/|shorts\/))([\w-]{11})/;
    const match = url.match(youtubeRegex);

    return match ? `https://www.youtube.com/embed/${match[1]}` : url;
  };

  const videosWithEmbedUrls = videos.map((video) => ({
    ...video,
    videoUrl: convertToEmbedUrl(video.videoUrl),
  }));

  return (
    <div className="videos-container">
      <h2 className="videos-title">Videos</h2>
      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}
      <VideoCarousel videos={videosWithEmbedUrls} />
    </div>
  );
};

export default Videos;

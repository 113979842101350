import {
  ADD_BOTIME,
  DELETE_BOTIME,
  SET_BOTIME,
  SET_BOTIME_ON_EDIT,
  UPDATE_BOTIME,
} from "../Actions/botimeActions";

const initialState = {
  botime: [],
  botimeOnEdit: {},
};

const botimeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BOTIME:
      return {
        ...state,
        botime: action.payload,
      };
    case SET_BOTIME_ON_EDIT: {
      return {
        ...state,
        botimeOnEdit: action.payload,
      };
    }
    case UPDATE_BOTIME: {
      const updatedBotime = action.payload;
      return {
        ...state,
        botime: state.botime.map((botime) =>
          botime._id === updatedBotime._id
            ? { ...botime, ...updatedBotime }
            : botime
        ),
      };
    }

    case DELETE_BOTIME:
      const newBotime = state.botime.filter(
        (item) => item._id !== action.payload
      );
      return {
        ...state,
        botimeOnEdit: {},
        botime: newBotime,
      };

    case ADD_BOTIME: {
      return {
        ...state,
        botime: [...state.botime, action.payload],
      };
    }

    default: {
      return state;
    }
  }
};

export default botimeReducer;

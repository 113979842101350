import { useDispatch, useSelector } from "react-redux";
import "./manageUser.css";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { setLoggedUser, updateUser } from "../../Redux/Actions/userActions";

const ManageUser = () => {
  const loggedUser = useSelector((state) => state.users.loggedUser);
  const isLoggedIn = Object.keys(loggedUser).length > 0;
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    password: "",
  });

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    }
    setFormData({
      firstName: loggedUser.firstName || "",
      lastName: loggedUser.lastName || "",
      email: loggedUser.email || "",
      phone: loggedUser.phone || "",
      password: loggedUser.password || "",
    });
  }, [loggedUser, isLoggedIn, navigate]);

  const validateForm = () => {
    const errors = {};
    const nameRegex = /^[A-Za-zÇçËëÜü ]{2,}$/;
    const phoneRegex = /^\+?[0-9]{10,15}$/;
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*?&]{6,}$/;

    if (!formData.firstName.trim()) {
      errors.firstName = "Emri nuk mund të jetë bosh!";
    } else if (!nameRegex.test(formData.firstName)) {
      errors.firstName = "Emri duhet të përmbajë vetëm shkronja!";
    }

    if (!formData.lastName.trim()) {
      errors.lastName = "Mbiemri nuk mund të jetë bosh!";
    } else if (!nameRegex.test(formData.lastName)) {
      errors.lastName = "Mbiemri duhet të përmbajë vetëm shkronja!";
    }

    if (!formData.email) {
      errors.email = "Email nuk mund të jetë bosh!";
    }

    if (!formData.phone) {
      errors.phone = "Numri i telefonit nuk mund të jetë bosh!";
    } else if (!phoneRegex.test(formData.phone)) {
      errors.phone = "Numri i telefonit nuk është i saktë!";
    }

    if (formData.password && !passwordRegex.test(formData.password)) {
      errors.password =
        "Fjalëkalimi duhet të përmbajë të paktën 6 karaktere, një shkronjë dhe një numër!";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const saveChanges = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      const response = await axios.put(
        `https://api.arkiva.gov.al/api/users/update/${loggedUser.id}`,
        {
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          phone: formData.phone,
          password: formData.password,
        }
      );
      if (response.data) {
        const updatedUser = response.data.user;
        dispatch(updateUser(response.data.user));
        dispatch(
          setLoggedUser({
            id: updatedUser._id,
            firstName: updatedUser.firstName,
            lastName: updatedUser.lastName,
            email: updatedUser.email,
            phone: updatedUser.phone,
            password: updatedUser.password,
          })
        );
      } else {
        console.error("Something went wrong");
      }
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  return (
    <div className="manage-user-container">
      <div className="profile-input-box">
        <label htmlFor="firstName">Emri</label>
        <input
          type="text"
          name="firstName"
          placeholder="Emri"
          value={formData.firstName}
          onChange={handleChange}
        />
      </div>

      {errors.firstName && <p className="error">{errors.firstName}</p>}
      <div className="profile-input-box">
        <label htmlFor="lastName">Mbiemri</label>
        <input
          type="text"
          name="lastName"
          placeholder="Mbiemri"
          value={formData.lastName}
          onChange={handleChange}
        />
      </div>

      {errors.lastName && <p className="error">{errors.lastName}</p>}
      <div className="profile-input-box">
        <label htmlFor="email">Email / Username</label>
        <input
          type="text"
          name="email"
          placeholder="Email"
          value={formData.email}
          onChange={handleChange}
        />
      </div>

      {errors.email && <p className="error">{errors.email}</p>}
      <div className="profile-input-box">
        <label htmlFor="phone">Numri i telefonit</label>
        <input
          type="text"
          name="phone"
          placeholder="Numri i telefonit"
          value={formData.phone}
          onChange={handleChange}
        />
      </div>

      {errors.phone && <p className="error">{errors.phone}</p>}
      <div className="profile-input-box">
        <label htmlFor="password">Fjalëkalimi</label>
        <input
          type="password"
          name="password"
          placeholder="Fjalëkalimi"
          value={formData.password}
          onChange={handleChange}
        />
      </div>
      {errors.password && <p className="error">{errors.password}</p>}
      <div className="profile-input-box">
        <button className="save-btn" onClick={saveChanges}>
          Ruaj Ndryshimet
        </button>
      </div>
    </div>
  );
};

export default ManageUser;

import kerkesa from "../Assets/Images/Sherbimet/kerkesa.png";
import restaurim from "../Assets/Images/Sherbimet/restauro.png";
import thirrje from "../Assets/Images/Sherbimet/botime.png";
import katalogu from "../Assets/Images/Sherbimet/katalogu.png";
import fototeka from "../Assets/Images/Sherbimet/fototeka.png";
import biblioteka from "../Assets/Images/Sherbimet/biblioteka.png";
import videoteka from "../Assets/Images/Sherbimet/videoteka.png";
import teater from "../Assets/Images/Sherbimet/liber.png";
import suvenire from "../Assets/Images/Sherbimet/suvenire.png";
import liber from "../Assets/Images/Sherbimet/liber.png";

export const citizenServices = [
  {
    name: kerkesa,
    url: "https://arkiva.gov.al/kerkesa-social-juridike",
  },
  { name: restaurim, url: "https://arkiva.gov.al/restaurim" },
  { name: thirrje, url: "https://botime.arkiva.gov.al/auth/login" },
];

export const onlineServices = [
  { name: katalogu, url: "https://katalogu.arkiva.gov.al" },
  { name: fototeka, url: "https://fototeka.arkiva.gov.al" },
  { name: biblioteka, url: "https://biblioteka.arkiva.gov.al" },
  { name: videoteka, url: "https://videoteka.arkiva.gov.al" },
];

export const onlineShop = [
  { name: teater, url: "https://teater.arkiva.gov.al" },
  { name: suvenire, url: "https://suvenire.arkiva.gov.al" },
  { name: liber, url: "https://libra.arkiva.gov.al" },
];

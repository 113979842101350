import {
  ADD_EKSPOZITA,
  DELETE_EKSPOZITA,
  SET_EKSPOZITA,
  SET_EKSPOZITA_ON_EDIT,
  UPDATE_EKSPOZITA,
} from "../Actions/ekspozitaActions";

const initialState = {
  ekspozita: [],
  ekspozitaOnEdit: {},
};

const ekspozitaReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_EKSPOZITA:
      return {
        ...state,
        ekspozita: action.payload,
      };
    case SET_EKSPOZITA_ON_EDIT: {
      return {
        ...state,
        ekspozitaOnEdit: action.payload,
      };
    }
    case UPDATE_EKSPOZITA: {
      const updatedEkspozita = action.payload;

      return {
        ...state,
        ekspozita: state.ekspozita.map((ekspozita) =>
          ekspozita._id === updatedEkspozita._id
            ? { ...ekspozita, ...updatedEkspozita }
            : ekspozita
        ),
      };
    }

    case DELETE_EKSPOZITA:
      const newPost = state.ekspozita.filter(
        (item) => item._id !== action.payload
      );
      return {
        ...state,
        ekspozitaOnEdit: {},
        ekspozita: newPost,
      };

    case ADD_EKSPOZITA: {
      return {
        ...state,
        ekspozita: [...state.ekspozita, action.payload],
      };
    }

    default: {
      return state;
    }
  }
};

export default ekspozitaReducer;

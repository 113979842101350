import { useMemo, useRef } from "react";
import * as d3 from "d3";
import { ResponsiveContainer } from "recharts";

const MARGIN_X = 50;
const MARGIN_Y = 50;
const INFLEXION_PADDING = 10;

const colors = ["#4CAF50", "#E0E0E0"];

const DonutChart = ({ width, height }) => {
  const ref = useRef(null);

  const data = [
    { name: "Performance", value: 95 },
    { name: "", value: 5 },
  ];

  const radius = Math.min(width - 2 * MARGIN_X, height - 2 * MARGIN_Y) / 2;
  const innerRadius = radius / 2;

  const pie = useMemo(() => {
    const pieGenerator = d3.pie().value((d) => d.value);
    return pieGenerator(data);
  }, [data]);

  const arcGenerator = d3.arc();

  const shapes = pie.map((grp, i) => {
    const sliceInfo = {
      innerRadius,
      outerRadius: radius,
      startAngle: grp.startAngle,
      endAngle: grp.endAngle,
    };
    const centroid = arcGenerator.centroid(sliceInfo);
    const slicePath = arcGenerator(sliceInfo);

    const inflexionInfo = {
      innerRadius: radius + INFLEXION_PADDING,
      outerRadius: radius + INFLEXION_PADDING,
      startAngle: grp.startAngle,
      endAngle: grp.endAngle,
    };
    const inflexionPoint = arcGenerator.centroid(inflexionInfo);

    if (grp.data.name === "")
      return <path key={i} d={slicePath} fill={colors[i]} />;

    const isRightLabel = inflexionPoint[0] > 0;
    const labelPosX = inflexionPoint[0] + 30 * (isRightLabel ? 1 : -1);
    const textAnchor = isRightLabel ? "start" : "end";
    const label = grp.data.name + " (" + grp.value + "%)";

    return (
      <g
        key={i}
        className="slice"
        onMouseEnter={() => ref.current?.classList.add("hasHighlight")}
        onMouseLeave={() => ref.current?.classList.remove("hasHighlight")}
      >
        <path d={slicePath} fill={colors[i]} />
        <circle cx={centroid[0]} cy={centroid[1]} r={2} />
        <line
          x1={centroid[0]}
          y1={centroid[1]}
          x2={inflexionPoint[0]}
          y2={inflexionPoint[1]}
          stroke="black"
          fill="black"
        />
        <line
          x1={inflexionPoint[0]}
          y1={inflexionPoint[1]}
          x2={labelPosX}
          y2={inflexionPoint[1]}
          stroke="black"
          fill="black"
        />
        <text
          x={labelPosX + (isRightLabel ? 2 : -2)}
          y={inflexionPoint[1]}
          textAnchor={textAnchor}
          dominantBaseline="middle"
          fontSize={16}
        >
          {label}
        </text>
      </g>
    );
  });

  return (
    <ResponsiveContainer width={"100%"} height={320}>
      <svg
        width={width}
        height={height}
        style={{
          display: "inline-block",
          backgroundColor: "white",
          borderRadius: "10px",
        }}
      >
        <g
          transform={`translate(${width / 2}, ${height / 2})`}
          className="container"
          ref={ref}
        >
          {shapes}
        </g>
      </svg>
    </ResponsiveContainer>
  );
};

export default DonutChart;

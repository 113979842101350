import ArditBido from "../Assets/Images/KeshilliArkivave/ArditBido.jpg";
import BidoArdit from "../Assets/Images/KeshilliArkivave/Ardit-Bido.jpg";
import EndritMusaj from "../Assets/Images/KeshilliArkivave/Endrit Musaj.jpg";
import ArbenDervishllari from "../Assets/Images/KeshilliArkivave/Arben Dervishllari.png";
import ArmandoBoçe from "../Assets/Images/KeshilliArkivave/Armando Boce.jpg";
import IliraÇaushi from "../Assets/Images/KeshilliArkivave/Ilira Çaushi.png";
import SabriLaçi from "../Assets/Images/KeshilliArkivave/Sabri Laçi.jpg";
import GëzimHoxha from "../Assets/Images/KeshilliArkivave/Gezim Hoxha.png";
import MariglenKasmi from "../Assets/Images/KeshilliArkivave/Mariglen Kasmi.jpg";
import KristinaJorgaqi from "../Assets/Images/KeshilliArkivave/Kristina Jorgaqi.jpg";
import MarinelaNdria from "../Assets/Images/KeshilliArkivave/Marinela Ndria.jpg";
import PirroMisha from "../Assets/Images/KeshilliArkivave/Pirro Misha.png";
import EtlevaGoxhaj from "../Assets/Images/KeshilliArkivave/Etleva Goxhaj.jpeg";
import ArmantVogli from "../Assets/Images/KeshilliArkivave/Armant Vogli.png";
import ElsaSaka from "../Assets/Images/KeshilliArkivave/Elsa Saka.jpeg";
import EugenNako from "../Assets/Images/KeshilliArkivave/Eugen Nako.png";
export const drejtoriaData = [
  {
    title: "Historiku",
    path: "/drejtoria/historiku",
    leftContent: (
      <>
        <p>
          Fillimet e veprimtarisë arkivore në Shqipëri datojnë që nga
          antikiteti. Historia dëshmon se në bibliotekat që ekzistonin në
          shtetet ilire, në pricipatat feudale shqiptare, në shtetin e
          Skënderbeut, në shoqëritë patriotike e kulturore, në institucionet
          fetare, në firmat tregtare, në esnafët, në familje dhe individë të
          veçantë, krahas librave ruheshin edhe dokumente.
        </p>
        <p>
          Zhvillimet politike e shoqërore në hapsirat shqiptare dhe më gjerë, u
          shoqëruan me dukuri të reja edhe për arkivat dhe dokumentet. Në
          përgjithësi arkivat konceptoheshin si bibliotekë-arkiva dhe kujdesi
          për dokumentet vinte në rritje.
        </p>
        <p>
          Shpallja e Pavarësisë, më 1912 dhe krijimi i Shtetit të Pavarur
          Shqiptar, krijoi kushte edhe për organizimin e punës për kujdes më të
          madh për dokumentet. Për herë të parë në histori vlerësimi i
          dokumenteve dhe administrimi e ruajtja e tyre gjen pasqyrim në aktin
          më të lartë normativ të shtetit. Në kanunin e Përkohshëm të Qeverisë
          së Vlorës, u sanksionua veprimtaria e njësive të posaçme strukturore
          në organet e administratës shtetërore, për punën me dokumentet.
        </p>
        <p>
          Në të gjitha aktet ligjore e nënligjore, në vazhdimësi, për krijimin
          dhe funksionimin e organeve të administratës shtetërore përfshiheshin
          detyra edhe për administrimin dhe për ruajtjen e dokumenteve.
        </p>
        <p>
          Krahas këtyre masave nuk reshtën dhe përpjekjet për krijim e një
          arkivi qëndror, ku do të përqëndroheshin dokumentet më të rëndësishëm
          për historinë kombëtare.Dokumenti i parë që ruhet në Arkivin Qendror
          Shtetëror, që bën fjalë për përpjekjet drejt krijimit të një Arkivi
          Qendror, është vendimi i datës 02.01.1932 i Këshillit të Naltë të
          Shtetit, për shqyrtimin e projekt-rregullores, për regjistrimin e
          ngjarjeve me rëndësi. Në këtë projekt-rregullore, parashikohej edhe
          krijimi i Arkivit të Përgjithshëm të Shtetit.
        </p>
        <p>
          Moment i rëndësishëm për historinë e arkivave shqiptare është
          projekt-ligji i vitit 1932 “Per krijimin e Arkivës së Përgjithshme të
          Shtetit dhe të Kronikës Historike”, në nenin 1 të të cilit
          përcaktohej: “Krijohet në kryeqytet një Arkivë e Përgjithshme e
          Shtetit , për me përmblesh e ruajtë gjithë dokumentet zyrtare”.
          Megjithëse projekt-ligji nuk u zbatua, ai i dha një nxitje zhvillimit
          të veprimtarisë arkivore.
        </p>
        <p>
          Lufta e Dytë Botërore, ngadalësoi nismat e marra nga organet
          shtetërore për arkivat dhe ata deri në përfundim të saj mbetëtn në
          nivelin e sekretari-arkivave, si njësi strukturore të institucioneve,
          që ndiqnin dhe përgjigjeshin vetëm për administrimin e dokumenteve të
          tyre. Megjithatë në sajë të veprimtarisë së tyre, u bë e mundur që të
          ruhen e të trashëgohen sasi të vlerësueshme dokumentesh me shtrirje
          pothuajse gjithëpërfshirëse subjektesh, kohore, hapsinore dhe në
          përmbajtje.
        </p>
        <p>
          Përpjekjet për krijim e një arkivi qendror vazhduan edhe pas Luftës së
          Dytë Botërore. Në vitin 1947, pranë Institutit të Studimeve u krijua
          “Arkivi Dokumentar i Institutit”. Ai grumbulloi një sasi të
          konsiderueshme dokumentesh historikë që ruheshin në organet shtetërorë
          apo individë të veçantë, duke marrë kështu dhe atributet e një Arkivi
          Qëndror, por jo me të gjitha karakteristikat e një organi të tillë.
        </p>
        <p>
          Arkivi Qendror Shtetëror (AQSH), u krijua si organ qëndror, megjithëse
          në varësi të Institutit të Studimeve, me urdhëresën e Këshillit të
          Ministrave nr 21 datë 08.06.1949.Krijimi i tij shënon një moment
          kulmor për historinë e arkivave shqiptare.
        </p>
        <p>
          Për herë të parë, krahas arkivave të organeve shtetërore, filloi
          funksionimi i një organi të mirëfilltë arkivor me shtrirje
          gjithështetërore. Në vitin 1951, Arkivi Qendror i Shtetit kaloi në
          varësi të Ministrisë së Punëve të Brendshme, varësi të cilën e ruajti
          deri në vitin 1962, kur u krijua Drejtoria e Përgjithshme e Arkivave
          të Shtetit (sot Drejtoria e Përgjithshme e Arkivave) , në varësi të
          Këshillit të Ministrave. Krahas të qenit organ qëndror, kjo Drejtori
          kishte dhe ka edhe atributet e Arkivit Qendror Shtetëror, si arkivi më
          i rëndësishëm dhe më i pasur i rrjetit arkivor të vendit.
        </p>
      </>
    ),
    rightContent: "",
  },
  {
    title: "Këshilli i Lartë i Arkivave",
    path: "/drejtoria/kla-kpe-kdzh-kqe",
    leftContent: (
      <>
        <p>
          Këshilli i Lartë i Arkivave është organ ndërinstitucional
          vendimmarrës, para të cilit Drejtoria e Përgjithshme e Arkivave
          raporton për veprimtarinë e saj. Ky organ mblidhet jo më pak se dy
          herë në vit. KLA-së ka për detyrë të miratojë strategjitë afatgjata,
          afatmesme dhe afatshkurtra të zhvillimit të shërbimit arkivor, që kanë
          të bëjnë me politikën arkivore të shtetit.
        </p>
        <p>
          KLA-ja përbëhet nga 17 anëtarë, 8 janë punonjës të Drejtorisë së
          Përgjithshme të Arkivave dhe 9 të jashtëm. KLA ka në përbërjen e tij
          titullarët e instucioneve më të rëndësishme në fushën e trashgimisë
          kulturore dhe akademike. Mbledhjet e KLA zhvillohen pranë mjediseve të
          Drejtorisë së Përgjithshme të Arkivave. Para zhvillimit të çdo
          mbledhjeje anëtarët e tij njoftohen prej sekretarit për zhvillimin e
          saj. Atyre u dërgohet rendi ditës dhe materialet përkatëse një javë
          para zhvillimit të mbledhjes. KLA nxjer vendime të cilat janë të
          detyrueshme dhe zbatueshme në të gjithë territorin e Shqipërisë.
        </p>
        <p>
          Sekretari i KLA-së zgjidhet me shumicën e votave të numrit të
          përgjithshëm të anëtarëve. Sekretar mund të zgjidhet anëtari i KLA-së
          që ka specializim në fushën e arkivave. Ai ushtron përgjegjësinë e
          kryetarit për drejtimin e mbledhjes së KLA-së në mungesë dhe me urdhër
          të tij.
        </p>
        <p>KLA-ja miraton, paraprakisht:</p>
        <ul>
          <li>
            të gjitha aktet që kanë të bëjnë me strategjinë afatmesme dhe
            afatgjatë të zhvillimit të shërbimit arkivor kombëtar;
          </li>
          <li>
            projektaktet ligjore e nënligjore që DPA-ja rekomandon, për
            organizimin dhe funksionimin eshërbimit arkivor kombëtar;
          </li>
          <li>
            “Listën tip” të dokumenteve me rëndësi historike kombëtare, të
            njësuar në shkallë vendi;
          </li>
          <li>
            rregullat tekniko-profesionale dhe meto-dologjike të punës me
            dokumentet (administrative, teknikoshkencore, në figurë, zë,
            lëvizje, në formë të numerizuar);
          </li>
          <li>
            rregullat tekniko-profesionale dhe meto-dologjike të punës me
            dokumentet (administrative, teknikoshkencore, në figurë, zë,
            lëvizje, në formë të numerizuar);
          </li>
          <li>
            rregullat tekniko-profesionale dhe meto-dologjike të punës me
            dokumentet (administrative, teknikoshkencore, në figurë, zë,
            lëvizje, në formë të numerizuar);
          </li>
        </ul>
      </>
    ),
    rightContent: [
      {
        image: BidoArdit,
        name: "Ardit Bido",
        memberPosition: "Kryetar",
        institution: "Drejtoria e Përgjithshme e Arkivave",
        position: "Drejtor i Përgjithshëm",
      },
      {
        image: EndritMusaj,
        name: "Endrit Musaj",
        memberPosition: "Sekretar",
        institution: "DPA",
        position: "Drejtor i Inspektimit",
      },
      {
        image: ArbenDervishllari,
        name: "Arben Dervishllari",
        memberPosition: "Anëtar",
        institution: "DPA",
        position: "Drejtor i Financës",
      },
      {
        image: ArmandoBoçe,
        name: "Armando Boçe",
        memberPosition: "Anëtar",
        institution: "DPA",
        position: "Drejtor i AQSH",
      },
      {
        image: IliraÇaushi,
        name: "Ilira Çaushi",
        memberPosition: "Anëtar",
        institution: "Fakulteti i Historisë dhe i Filologjisë",
        position: "",
      },
      {
        image: SabriLaçi,
        name: "Sabri Laçi",
        memberPosition: "Anëtar",
        institution: "Dekani - Fakulteti i Historisë dhe i Filologjisë",
        position: "",
      },
      {
        image: GëzimHoxha,
        name: "Gëzim Hoxha",
        memberPosition: "Anëtar",
        institution: "Qendra e Studimeve Albanologjike",
        position: "",
      },
      {
        image: MariglenKasmi,
        name: "Mariglen Kasmi",
        memberPosition: "Anëtar",
        institution: "Drejtori i Qendrës së Botimeve Albanologjike",
        position: "",
      },
      {
        image: KristinaJorgaqi,
        name: "Kristina Jorgaqi",
        memberPosition: "Anëtar",
        institution: "Akademia e Shkencave",
        position: "",
      },
      {
        image: MarinelaNdria,
        name: "Marinela Ndria",
        memberPosition: "Anëtar",
        institution: "Drejtoreshë, AQF",
        position: "",
      },
      {
        image: PirroMisha,
        name: "Pirro Misha",
        memberPosition: "Anëtar",
        institution: "Drejtor i Bibliotekës Kombëtare",
        position: "",
      },
      {
        image: EtlevaGoxhaj,
        name: "Etleva Goxhaj",
        memberPosition: "Anëtar",
        institution: "",
        position: "",
      },
      {
        image: ArmantVogli,
        name: "Armant Vogli",
        memberPosition: "Anëtar",
        institution: "DPA",
        position: "Drejtor i DEDR",
      },
      {
        image: ElsaSaka,
        name: "Elsa Saka",
        memberPosition: "Anëtar",
        institution: "DPA",
        position: "Drejtor i DK",
      },
      {
        image: EugenNako,
        name: "Eugen Nako",
        memberPosition: "Anëtar",
        institution: "DPA",
        position: "Përgjegjës ASHV",
      },
    ],
  },
  {
    title: "Organizimi i Rrjetit Arkivor",
    path: "/drejtoria/organizimi-i-rrjetit-arkivor",
    leftContent: (
      <p>
        Drejtoria e Përgjithshme e Arkivave është drejtuesja rrjetit arkivor
        kombëtar në Republikën e Shqipërisë. Arkivi Qendror Shtetëror dhe
        Arkivat Shtetërorë Vendorë janë në varësi administrative dhe
        profesionale të DPA-së. Ndërkaq, arkivat e tjera janë në varësi
        metodologjike e profesionale të Drejtorisë së Përgjithshme të Arkivave.
        Këto arkiva janë Arkivat e Sistemit dhe Arkivat e Tipologjisë, si arkiva
        të përhershme dhe sekretari-arkivat e të gjitha autoriteteve publike e
        institucioneve shtetërore në Shqipëri. Ky rrjet, ruan, përpunon,
        administron dhe shërben Fondin Arkivor Kombëtar të Republikës së
        Shqipërisë.
      </p>
    ),
    rightContent: [
      {
        title: "Arkiva të Sistemit",
        subtitle:
          "(arkivat shtetërore të sistemit që funksionojnë në mënyrë të përhershme pranë institucioneve qendrore fondkrijuese)",
        list: [
          {
            name: "Drejtoria e Arkivit Historik dhe Dokumentacionit pranë Ministrisë së Punëve të Jashtme",
            url: "http://www.punetejashtme.gov.al/",
          },
          {
            name: "Arkivi Qendror i Forcave të Armatosura",
            url: "http://www.mod.gov.al/index.php/ministria/strukturat-vartese/117-arkivi-i-fa",
          },
          {
            name: "Arkivi Shtetëror i Sistemit Gjyqësor",
            url: "http://www.drejtesia.gov.al/",
          },
          {
            name: "Arkivi i Ministrisë Punëve të Brendshme",
            url: "http://www.punetebrendshme.gov.al/",
          },
          {
            name: "Arkivi i Kuvendit të Shqipërisë",
            url: "http://www.parlament.al/",
          },
          {
            name: "Arkivi i Presidentit të Republikës",
            url: "http://president.al/",
          },
          {
            name: "Arkivi i Institutit të Sigurimeve Shoqërore",
            url: "http://www.issh.gov.al/",
          },
          {
            name: " Autoriteti për Informimin mbi Dokumentat e Ish-Sigurimit të Shtetit",
            url: "http://autoritetidosjeve.gov.al/",
          },
        ],
      },
      {
        title: "Arkiva Private",
        subtitle:
          "(arkiva të licensuara për të ushtruar veprimtarinë e arkivit privat)",
        list: [
          "Albanian Archives shpk",
          "Vendim Nr.7, datë 29.07.2020 i Këshillit të Lartë të Arkivave",
          "Licencë Aktive",
        ],
      },
      {
        title: "Arkiva të së Njëjtës Tipologji",
        subtitle:
          "(arkiva që ruajnë,  administrojnë dhe shërbejnë materiale dokumentare të një lloji, si psh. Harta, materiale në zë dhe figurë)",
        list: [
          {
            name: "Arkivi Qendror Teknik i Ndërtimit",
            url: "http://www.aqtn.gov.al/",
          },
          {
            name: "Arkivi Qendror Shtetëror i Filmit",
            url: "http://www.aqshf.gov.al/",
          },
        ],
      },
    ],
  },
  {
    title: "Struktura",
    path: "/drejtoria/struktura",
    content: [
      {
        name: "Drejtori i Përgjithshëm i Arkivave",
        image: ArditBido,
        path: "",
        sektoret: [
          {
            name: "Drejtori i Përgjithshëm i Arkivave",
            path: "#",
          },
          {
            name: "Funksionet e Drejtorit të Përgjithshëm",
            path: "/struktura/funksionet-e-drejtorit-te-pergjithshem",
          },
          {
            name: "Drejtorët në vite",
            path: "/struktura/drejtoret-ne-vite",
          },
        ],
      },
      {
        name: "Drejtoria e Arkivit Qendror Shtetëror",
        path: "/struktura/drejtoria-e-arkivit-qendror-shteteror",
        sektoret: [
          {
            name: "Drejtori i Arkivit Qendror Shtetëror",
            path: "/struktura/drejtoria-e-arkivit-qendror-shteteror/drejtori-i-arkivit-qendror-shteteror",
          },
          {
            name: "Sektori i Përpunimit",
            path: "/struktura/drejtoria-e-arkivit-qendror-shteteror/sektori-i-dokumentacionit-perpara-vitit-1944",
          },
          {
            name: "Sektori i Gjuhëve të Rralla",
            path: "/struktura/drejtoria-e-arkivit-qendror-shteteror/sektori-i-dokumentacionit-pas-vitit-1944",
          },
          {
            name: "Sektori i Ruajtjes së Dokumentacionit",
            path: "/struktura/drejtoria-e-arkivit-qendror-shteteror/sektori-i-ruajtjes-se-dokumentacionit",
          },
          {
            name: "Sektori i Ish-Arkivit të PPSH-së",
            path: "/struktura/drejtoria-e-arkivit-qendror-shteteror/sektori-i-ish-arkivit-te-ppsh-se",
          },
        ],
      },
      {
        name: "Drejtoria e Komunikimit",
        path: "/struktura/drejtoria-e-evidences-qendrore-dhe-komunikimit",
        sektoret: [
          {
            name: "Drejtoresha e Komunikimit",
            path: "/struktura/drejtoria-e-evidences-qendrore-dhe-komunikimit/drejtoresha-i-evidences-qendrore-dhe-komunikimit",
          },
          {
            name: "Sektori i Muzeut dhe Promovimit",
            path: "/struktura/drejtoria-e-evidences-qendrore-dhe-komunikimit/sektori-i-evidences-qendrore",
          },
          {
            name: "Sektori i Edukimit dhe Botimeve",
            path: "/struktura/drejtoria-e-evidences-qendrore-dhe-komunikimit/sektori-i-promovimit-dhe-komunikimit",
          },
          {
            name: "Sektori i Sallës së Studimit dhe Bibliotekës",
            path: "/struktura/drejtoria-e-evidences-qendrore-dhe-komunikimit/sektori-i-salles-se-studimit-dhe-bibliotekes",
          },
          {
            name: "Sektori i Kërkesave Social-Juridike",
            path: "/struktura/drejtoria-e-evidences-qendrore-dhe-komunikimit/sektori-i-sherbimit-te-dokumentacionit-ne-distance",
          },
        ],
      },
      {
        name: "Drejtoria e Financës dhe Shërbimeve Mbështetëse",
        path: "/struktura/drejtoria-e-finances-dhe-sherbimeve-mbeshtetese",
        sektoret: [
          {
            name: "Drejtori i Financës dhe Shërbimeve Mbështetëse",
            path: "/struktura/drejtoria-e-finances-dhe-sherbimeve-mbeshtetese/drejtori-i-finances-dhe-sherbimeve-mbeshtetese",
          },
          {
            name: "Sektori i Financës dhe Buxhetit",
            path: "/struktura/drejtoria-e-finances-dhe-sherbimeve-mbeshtetese/sektori-juridik-i-prokurimeve-dhe-burimeve-njerezore",
          },
          {
            name: "Sektori i Burimeve Njerëzore dhe Shërbimeve",
            path: "/struktura/drejtoria-e-finances-dhe-sherbimeve-mbeshtetese/sektori-i-sherbimeve-te-brendshme",
          },
          {
            name: "Sektori i Juridik dhe i Prokurimeve",
            path: "/struktura/drejtoria-e-finances-dhe-sherbimeve-mbeshtetese/sektori-juridik-i-prokurimeve-dhe-bruimeve-njerezore",
          },
          {
            name: "Sektori i Sekretari/ Arkiv",
            path: "/struktura/sektori-i-auditimit-te-brendshem/sektori-i-sekretari-arkivit",
          },
        ],
      },
      {
        name: "Drejtoria e Inspektimit dhe Trajnimeve",
        path: "/struktura/drejtoria-e-inspektimit-dhe-trajnimeve",
        sektoret: [
          {
            name: "Drejtori i Inspektimit dhe Trajnimeve",
            path: "/struktura/drejtoria-e-inspektimit-dhe-trajnimeve/drejtori-i-inspektimit-dhe-trajnimeve",
          },
          {
            name: "Sektori i Inspektimit e Kontrollit Qëndror",
            path: "/struktura/drejtoria-e-inspektimit-dhe-trajnimeve/sektori-i-inspektimit-e-kontrollit-qendror-dhe-trajnimeve",
          },
          {
            name: "Sektori i Inspektimit dhe Kontrollit Veri-Qendror",
            path: "/struktura/drejtoria-e-inspektimit-dhe-trajnimeve/sektori-i-inspektimit-dhe-kontrollit-rajonal",
          },
        ],
      },

      {
        name: "Drejtoria e Arkivave Shtetërore Vendore",
        path: "",
        sektoret: [
          {
            name: "Drejtori i Arkivave Shtetërore Vendore",
            path: "/struktura/drejtori-i-arkivave-shteterore-vendore/drejtori-i-arkivave-shteterore-vendore",
          },
          {
            name: "Sektori i Arkivave Shtetërore Vendore Tiranë dhe Durrës",
            path: "/struktura/drejtori-i-arkivave-shteterore-vendore/sektori-i-arkivave-shteterore-vendore-tirane-dhe-durres",
          },
          {
            name: "Sektori i Arkivave Shtetërore Vendore Shkodër, Lezhë, Dibër dhe Kukës",
            path: "/struktura/drejtori-i-arkivave-shteterore-vendore/sektori-i-arkivave-shteterore-vendore-shkoder-lezhe-diber-dhe-kukes",
          },
          {
            name: "Sektori i Arkivave Shtetërore Vendore Fier, Vlorë dhe Gjirokastër",
            path: "/struktura/drejtori-i-arkivave-shteterore-vendore/sektori-i-arkivave-shteterore-vendore-fier-vlore-dhe-gjirokaster",
          },
          {
            name: "Sektori i Arkivave Shtetërore Vendore Elbasan, Berat dhe Korçë",
            path: "/struktura/drejtori-i-arkivave-shteterore-vendore/sektori-i-arkivave-shteterore-vendore-elbasan-berat-dhe-korce",
          },
        ],
      },

      {
        name: "Drejtoria e Evidencës, Digjitalizimit dhe Restaurimit",
        path: "/struktura/drejtoria-e-evidences-digjitalizimit-dhe-restaurimit",
        sektoret: [
          {
            name: "Drejtori i Evidencës, Digjitalizimit dhe Restaurimit",
            path: "",
          },
          {
            name: "Sektori i Evidencës Qendrore",
            path: "/struktura/drejtoria-e-evidences-qendrore-dhe-komunikimit/sektori-i-evidences-qendrore",
          },
          {
            name: "Sektori i Mjeteve të Informimit",
            path: "/struktura/drejtoria-e-evidences-digjitalizimit-dhe-restaurimit/sektori-i-mjeteve-te-informimit",
          },
          {
            name: "Sektori i Digjitalizimit",
            path: "/struktura/drejtoria-e-evidences-digjitalizimit-dhe-restaurimit/sektori-i-digjitalizimit-dhe-mikrofilmit",
          },
          {
            name: "Sektori i Laboratorit të Restaurimit",
            path: "/struktura/drejtoria-e-evidences-digjitalizimit-dhe-restaurimit/sektori-laboratori-qendror-i-restaurimit",
          },
        ],
      },
    ],
  },
];

export const SET_VIDEOS = "SET_VIDEOS";
export const SET_VIDEO_ON_EDIT = "SET_VIDEO_ON_EDIT";
export const UPDATE_VIDEO = "UPDATE_VIDEO";
export const DELETE_VIDEO = "DELETE_VIDEO";
export const ADD_VIDEO = "ADD_VIDEO";

export const setVideos = (videos) => ({
  type: SET_VIDEOS,
  payload: videos,
});

export const setVideoOnEdit = (videoOnEdit) => ({
  type: SET_VIDEO_ON_EDIT,
  payload: videoOnEdit,
});

export const updateVideo = (updatedVideo) => ({
  type: UPDATE_VIDEO,
  payload: updatedVideo,
});

export const removeVideo = (videoId) => ({
  type: DELETE_VIDEO,
  payload: videoId,
});

export const addVideo = (video) => ({
  type: ADD_VIDEO,
  payload: video,
});

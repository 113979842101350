export const SET_REVISTA = "SET_REVISTA";
export const SET_REVISTA_ON_EDIT = "SET_REVISTA_ON_EDIT";
export const UPDATE_REVISTA = "UPDATE_REVISTA";
export const DELETE_REVISTA = "DELETE_REVISTA";
export const ADD_REVISTA = "ADD_REVISTA";

export const setRevista = (revista) => ({
  type: SET_REVISTA,
  payload: revista,
});

export const setRevistaOnEdit = (revistaOnEdit) => ({
  type: SET_REVISTA_ON_EDIT,
  payload: revistaOnEdit,
});

export const updateRevista = (updatedRevista) => ({
  type: UPDATE_REVISTA,
  payload: updatedRevista,
});

export const removeRevista = (revistaId) => ({
  type: DELETE_REVISTA,
  payload: revistaId,
});

export const addRevista = (revista) => ({
  type: ADD_REVISTA,
  payload: revista,
});

import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../manage.css";
import axios from "axios";
import {
  setRegjistriRhkOnEdit,
  updateRegjistriRhk,
} from "../../Redux/Actions/regjistriRhkActions";

const EditRegjistriRhk = () => {
  const dispatch = useDispatch();
  const regjistriRhkOnEdit = useSelector(
    (state) => state.regjistriRhk.regjistriRhkOnEdit
  );
  const loggedUser = useSelector((state) => state.users.loggedUser);
  const isLoggedIn = Object.keys(loggedUser).length > 0;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    subject: "",
    location: "",
    year: "",
    fond: "",
    ownMethod: "",
    description: "",
    pagesNr: "",
    phoneNr: "",
    notes: "",
  });

  if (!isLoggedIn) {
    navigate("/login");
  }

  useEffect(() => {
    setFormData({
      subject: regjistriRhkOnEdit.subject || "",
      location: regjistriRhkOnEdit.location || "",
      year: regjistriRhkOnEdit.year || "",
      fond: regjistriRhkOnEdit.fond || "",
      ownMethod: regjistriRhkOnEdit.ownMethod || "",
      description: regjistriRhkOnEdit.description || "",
      pagesNr: regjistriRhkOnEdit.pagesNr || "",
      phoneNr: regjistriRhkOnEdit.phoneNr || "",
      notes: regjistriRhkOnEdit.notes || "",
    });
  }, [regjistriRhkOnEdit]);

  const validateForm = () => {
    const errors = {};

    if (!formData.subject.trim()) {
      errors.subject = "Subjekt nuk mund të jetë bosh!";
    }

    if (!formData.location.trim()) {
      errors.location = "Vendndodhja nuk mund të jetë bosh!";
    }

    if (!formData.year.trim()) {
      errors.year = "Viti nuk mund të jetë bosh!";
    }

    if (!formData.fond.trim()) {
      errors.fond = "Fondi nuk mund të jetë bosh!";
    }

    if (!formData.ownMethod.trim()) {
      errors.ownMethod =
        "Mënyra e posedimit të dokumentit RHK nuk mund të jetë bosh!";
    }

    if (!formData.description.trim()) {
      errors.description = "Përshkrimi nuk mund të jetë bosh!";
    }

    if (!formData.pagesNr) {
      errors.pagesNr = "Numri i faqeve nuk mund të jetë bosh!";
    } else if (isNaN(formData.pagesNr)) {
      errors.pagesNr = "Numri i faqeve duhet të jetë numër!";
    }

    if (!formData.phoneNr.trim()) {
      errors.phoneNr = "Nr. telefonit nuk mund të jetë bosh!";
    } else if (isNaN(formData.phoneNr)) {
      errors.phoneNr = "Nr. telefonit duhet të jetë numër!";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    if (name === "description") {
      resizeTextarea();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    if (!regjistriRhkOnEdit._id) {
      console.error("Error: _id is undefined. Cannot proceed.");
      return;
    }

    try {
      setLoading(true);
      const response = await axios.put(
        `https://api.arkiva.gov.al/api/regjistriRhk/update/${regjistriRhkOnEdit._id}`,
        {
          subject: formData.subject,
          location: formData.location,
          year: formData.year,
          fond: formData.fond,
          ownMethod: formData.ownMethod,
          description: formData.description,
          pagesNr: formData.pagesNr,
          phoneNr: formData.phoneNr,
          notes: formData.notes,
        }
      );

      if (response.data) {
        dispatch(updateRegjistriRhk(response.data));
        navigate("/manage/regjistriRhk");
        dispatch(setRegjistriRhkOnEdit({}));
      } else {
        console.error("Something went wrong");
      }
    } catch (error) {
      console.error("Error updating dokument RHK:", error);
    }
  };

  const handleCancel = () => {
    navigate("/manage/regjistriRhk");
  };

  const textareaRef = useRef(null);

  const resizeTextarea = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  useEffect(() => {
    resizeTextarea();
  }, [formData.description]);

  const returnHome = () => {
    navigate("/manage/regjistriRhk");
    dispatch(setRegjistriRhkOnEdit({}));
  };

  return (
    <div className="edit-page">
      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}
      <div className="return">
        <button onClick={returnHome}>
          <i className="fa-solid fa-chevron-left"></i>
        </button>
      </div>
      <p>Edito Dokument RHK në Pronësi të privatëve</p>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="subject">Emri i Subjektit që e Posedon</label>
          <input
            type="text"
            id="subject"
            name="subject"
            value={formData.subject}
            onChange={handleChange}
          />
          {errors.subject && <small className="error">{errors.subject}</small>}
        </div>
        <div className="form-group">
          <label htmlFor="location">
            Vendi në të cilin ndodhet Dokumenti (adresa)
          </label>
          <input
            type="text"
            id="location"
            name="location"
            value={formData.location}
            onChange={handleChange}
          />
          {errors.location && (
            <small className="error">{errors.location}</small>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="year">Viti i Dokumentit</label>
          <input
            type="text"
            id="year"
            name="year"
            value={formData.year}
            onChange={handleChange}
          />
          {errors.year && <small className="error">{errors.year}</small>}
        </div>
        <div className="form-group">
          <label htmlFor="fond">Emërtimi i Fondkrijuesit</label>
          <input
            type="text"
            id="fond"
            name="fond"
            value={formData.fond}
            onChange={handleChange}
          />
          {errors.fond && <small className="error">{errors.fond}</small>}
        </div>
        <div className="form-group">
          <label htmlFor="ownMethod">
            Mënyra e posedimit të dokumentit RHK
          </label>
          <input
            type="text"
            id="ownMethod"
            name="ownMethod"
            value={formData.ownMethod}
            onChange={handleChange}
          />
          {errors.ownMethod && (
            <small className="error">{errors.ownMethod}</small>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="description">
            Përshkrimi i shkurtër i dokumentit
          </label>
          <textarea
            id="description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            ref={textareaRef}
            onInput={resizeTextarea}
            style={{ width: "100%", overflow: "hidden" }}
          />
          {errors.description && (
            <small className="error">{errors.description}</small>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="pagesNr">Sasia e fletëve</label>
          <input
            type="text"
            id="pagesNr"
            name="pagesNr"
            value={formData.pagesNr}
            onChange={handleChange}
          />
          {errors.pagesNr && <small className="error">{errors.pagesNr}</small>}
        </div>
        <div className="form-group">
          <label htmlFor="phoneNr">Numri i kontaktit i subjektit</label>
          <input
            type="text"
            id="phoneNr"
            name="phoneNr"
            value={formData.phoneNr}
            onChange={handleChange}
          />
          {errors.phoneNr && <small className="error">{errors.phoneNr}</small>}
        </div>
        <div className="form-group">
          <label htmlFor="notes">Shënime</label>
          <textarea
            ref={textareaRef}
            id="notes"
            name="notes"
            value={formData.notes}
            onChange={handleChange}
          />
        </div>
        <div className="edit-actions">
          <button type="submit" className="save">
            Ruaj ndryshimet
          </button>
          <button type="button" className="cancel" onClick={handleCancel}>
            Anullo
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditRegjistriRhk;

import { combineReducers } from "@reduxjs/toolkit";
import botimeAQShReducer from "./botimeAQShReducer";
import ekspozitaReducer from "./ekspozitaReducer";
import objekteMuzealeReducer from "./objekteMuzealeReducer";
import revistaAShReducer from "./revistaAShReducer";
import videoReducer from "./videoReducer";
import userReducer from "./userReducer.js";
import postsReducer from "./postsReducer.js";
import regjistriRhkReducer from "./regjistriRhkReducer.js";
import regjDokHumburaReducer from "./regjDokHumburaReducer.js";

const rootReducer = combineReducers({
  users: userReducer,
  botime: botimeAQShReducer,
  ekspozita: ekspozitaReducer,
  posts: postsReducer,
  objekte: objekteMuzealeReducer,
  revista: revistaAShReducer,
  video: videoReducer,
  regjistriRhk: regjistriRhkReducer,
  regjistriDokHumbura: regjDokHumburaReducer,
});

export default rootReducer;

import {
  ADD_OBJEKTE,
  DELETE_OBJEKTE,
  SET_OBJEKTE,
  SET_OBJEKTE_ON_EDIT,
  UPDATE_OBJEKTE,
} from "../Actions/objekteMuzealeActions";

const initialState = {
  objekte: [],
  objektOnEdit: {},
};

const objekteReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_OBJEKTE:
      return {
        ...state,
        objekte: action.payload,
      };
    case SET_OBJEKTE_ON_EDIT: {
      return {
        ...state,
        objektOnEdit: action.payload,
      };
    }
    case UPDATE_OBJEKTE: {
      const updatedObjekt = action.payload;

      return {
        ...state,
        objekte: state.objekte.map((objekt) =>
          objekt._id === updatedObjekt._id
            ? { ...objekt, ...updatedObjekt }
            : objekt
        ),
      };
    }

    case DELETE_OBJEKTE:
      const newObjekt = state.objekte.filter(
        (item) => item._id !== action.payload
      );
      return {
        ...state,
        objektOnEdit: {},
        objekte: newObjekt,
      };

    case ADD_OBJEKTE: {
      return {
        ...state,
        objekte: [...state.objekte, action.payload],
      };
    }

    default: {
      return state;
    }
  }
};

export default objekteReducer;

import { useDispatch, useSelector } from "react-redux";
import "../manage.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  removeRevista,
  setRevista,
  setRevistaOnEdit,
} from "../../Redux/Actions/revistaAShActions";

const ManageRevista = () => {
  const dispatch = useDispatch();
  const [revistat, setRevistat] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const loggedUser = useSelector((state) => state.users.loggedUser);
  const isLoggedIn = Object.keys(loggedUser).length > 0;
  const navigate = useNavigate();

  if (!isLoggedIn) {
    navigate("/login");
  }

  useEffect(() => {
    const fetchRevista = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `https://api.arkiva.gov.al/api/revista?page=${currentPage}&limit=20`
        );
        setRevistat(response.data.revista);
        setTotalPages(response.data.totalPages);
        dispatch(setRevista(response.data.revista));
      } catch (error) {
        console.error("Error fetching revista:", error);
      }
      setLoading(false);
    };

    if (isLoggedIn) {
      fetchRevista();
    } else {
      navigate("/login");
    }
  }, [dispatch, loggedUser, navigate, currentPage]);

  const editRevista = (revista) => {
    navigate(`/manage/revista/${revista._id}`);
    dispatch(setRevistaOnEdit(revista));
  };

  const addRevista = () => {
    navigate("/manage/revista/add-new");
  };

  const deleteRevista = async (revista) => {
    try {
      const response = await axios.delete(
        `https://api.arkiva.gov.al/api/revista/delete/${revista._id}`,
        { data: { id: revista.id } }
      );
      if (response.data.message === "error") {
        alert("Something went wrong!");
      } else {
        setRevistat((prevRevista) =>
          prevRevista.filter((rev) => rev._id !== response.data.revistaId)
        );
        dispatch(removeRevista(response.data.revistaId));
      }
    } catch (error) {
      console.error("Error deleting revista:", error);
    }
  };

  return (
    <div className="manage-page">
      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}
      <div className="manage-page-header">
        <div className="manage-page-header-actions">
          <input
            type="text"
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <button onClick={addRevista}>
            <i className="fa-solid fa-plus"></i> Shto Revistë
          </button>
        </div>
      </div>
      <div className="manage-table">
        <table className="table revista">
          <thead>
            <tr>
              <th>Titulli</th>
              <th>Viti</th>
              <th className="act">Veprime</th>
            </tr>
          </thead>
          <tbody>
            {revistat.length > 0 ? (
              revistat.map((item, index) => (
                <tr key={index}>
                  <td data-label="Titulli">{item.title}</td>
                  <td data-label="Viti">{item.year}</td>
                  <td className="actions" data-label="Veprime">
                    <button onClick={() => editRevista(item)}>
                      <i className="fa-solid fa-pen-to-square"></i>
                    </button>
                    <button onClick={() => deleteRevista(item)}>
                      <i className="fa-solid fa-trash"></i>
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="3">Nuk u gjetën revista!</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="pagination">
        <button
          className={`pagination-control-btn ${
            currentPage === 1 ? "disabled" : ""
          }`}
          disabled={currentPage === 1}
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
        >
          &#8592; Para
        </button>

        <span className="pagination-current-page">
          Page {currentPage} of {totalPages}
        </span>
        <button
          className={`pagination-control-btn ${
            currentPage === totalPages ? "disabled" : ""
          }`}
          disabled={currentPage === totalPages}
          onClick={() =>
            setCurrentPage((prev) => Math.min(prev + 1, totalPages))
          }
        >
          Pas &#8594;
        </button>
      </div>
    </div>
  );
};

export default ManageRevista;

import ArditBido from "../Assets/Images/KeshilliArkivave/ArditBido.jpg";
import ArbenDervishllari from "../Assets/Images/KeshilliArkivave/Arben Dervishllari.png";
import ArmandoBoce from "../Assets/Images/KeshilliArkivave/Armando Boce.jpg";
import ElsaSaka from "../Assets/Images/KeshilliArkivave/Elsa Saka.jpeg";
import EndritMusaj from "../Assets/Images/KeshilliArkivave/Endrit Musaj.jpg";
import LorencAgalliu from "../Assets/Images/KeshilliArkivave/LorencAgalliu.jpeg";
import ZefMala from "../Assets/Images/DrejtoretNeVite/ZefMala.jpg";
import GaqoPeristeri from "../Assets/Images/DrejtoretNeVite/GaqoPeristeri.jpg";
import VeliDedi from "../Assets/Images/DrejtoretNeVite/VeliDedi.jpg";
import SkendërHajro from "../Assets/Images/DrejtoretNeVite/SkenderHajro.jpg";
import ThomaMurzaku from "../Assets/Images/DrejtoretNeVite/ThomaMurzaku.jpg";
import LuanMalltezi from "../Assets/Images/DrejtoretNeVite/LuanMalltezi.jpg";
import ShabanSinani from "../Assets/Images/DrejtoretNeVite/ShabanSinani.jpg";
import GjetNdoj from "../Assets/Images/DrejtoretNeVite/GjetNdoj.jpg";
import IsmailNuhiu from "../Assets/Images/DrejtoretNeVite/IsmailNuhiu.jpg";
import SefedinMeçe from "../Assets/Images/DrejtoretNeVite/SefedinMeçe.jpg";
import DhimitërKotini from "../Assets/Images/DrejtoretNeVite/DhimiterKotini.jpg";
import SpiroRusho from "../Assets/Images/DrejtoretNeVite/SpiroRusho.jpg";
import GazmendShpuza from "../Assets/Images/DrejtoretNeVite/GazmendShpuza.jpg";
import ZamirShtylla from "../Assets/Images/DrejtoretNeVite/ZamirShtylla.jpg";
import NevilaNika from "../Assets/Images/DrejtoretNeVite/NevilaNika.jpg";
export const sektoretData = [
  {
    path: "",
    title: "Drejtori i Përgjithshëm i Arkivave",
    image: null,
    name: "Ardit Bido",
    content: (
      <>
        <p>
          Ardit Bido ka mbrojtur gradën «Doktor i Shkencave» në Marrëdhënie
          Ndërkombëtare në Universitetin e Tiranës, lëmë në të cilën ka kryer
          edhe studimet Master. Ciklin e parë të shkollës së lartë e ka
          përfunduar për Gazetari në të njëjtin universitet. Punimet akademike
          përfshijnë studime të botuara në revista shkencore brenda e jashtë
          vendit, kumtesa të referuara në konferenca kombëtare e ndërkombëtare,
          si edhe dy monografi: “Kisha Ortodokse Shqiptare: një histori politike
          (1878-1937)” dhe “Koncesioni editorial i medias shqiptare”.
        </p>
        <p>
          Ardit Bido ka një përvojë disavjeçare në shtypin e shkruar dhe mediat
          audiovizive, përfshi drejtimin e redaksive të lajmeve të «TV Ora
          News», gazetës «Standard», gazetës «Kombëtare», etj. Po ashtu, ai ka
          menaxhuar departamentet e komunikimit të disa institucioneve
          akademike, shtetërore dhe private. Prej vitit 2012, është pedagog
          pranë Departamentit të Gazetarisë të Universitetit të Tiranës dhe
          pranë Universitetit Metropolitan Tirana. Ai ka pasur angazhime publike
          dhe komunitare, përfshi drejtimin e disa organizatave rinore,
          pjesëmarrjen në borde drejtuese universitare e kërkimore dhe zgjedhjen
          si nënkryetar i Këshillit Bashkiak të Njësisë nr. 9 në Tiranë.
        </p>
        <p>
          Ardit Bido ka ndjekur një seri kursesh e trajnimesh profesionale
          lidhur me komunikimin dhe marrëdhëniet ndërkombëtare të organizuara
          nga NATO, Ambasada Amerikane në Tiranë, etj., në Shqipëri, Belgjikë,
          Luksemburg, Serbi, e disa vende të tjera. Përveç gjuhës amtare shqipe,
          zotëron gjuhët angleze e greke dhe ka njohuri të gjuhëve italiane e
          franceze. Ardit Bido është lindur në Sarandë më 20 korrik 1987 dhe
          është babai i një vajze, Deas.
        </p>
      </>
    ),
  },
  {
    path: "/struktura/funksionet-e-drejtorit-te-pergjithshem",
    title: "Funksionet e Drejtorit të Përgjithshëm",
    image: null,
    name: "",
    content: (
      <>
        <p>
          Drejtori i Përgjithshëm i Arkivave është titullari i institucionit.
          Drejtori i Përgjithshëm i Arkivave ka këto detyra: Është kryesuesi i
          rrjetit arkivor kombëtar dhe ushtron drejtimin metodik-profesional të
          punës në arkivat shtetërore të sistemit, arkivat e institucioneve të
          administratës shtetërore në veprim dhe në arkivat e tjera, të parapara
          në ligjin “Për arkivat”;
        </p>
        <p>
          Nënshkruan marrëveshjet e bashkëpunimit ndërinstitucional; Përfaqëson
          arkivat shtetërore të vendit në organizmat dhe institucionet
          ndërkombëtare, ku ato janë anëtarësuar, dhe ushtron të drejtën e votës
          në emër të tyre;
        </p>
        <p>
          Nënshkruan, në emër të institucionit që drejton, marrëveshje dy ose më
          shumë palëshe përbashkëpunim ndërarkivistik me partnerë të huaj;
          Përgjigjet për veprimtarinë e Drejtorisë së Përgjithshme të Arkivave;
          U rekomandon organeve përgjegjëse, institucioneve të pushtetit e të
          administratës qendrore e vendore dhe subjekteve joshtetërore
          shfuqizimin e akteve administrative joligjore për punën me dokumente,
          të miratuara prej tyre, në përputhje me legjislacionin në fuqi;
          Bashkërendon punën e strukturave të Drejtorisë së Përgjithshme të
          Arkivave, i ndihmon ato për funksionimin normal dhe zgjidh çështjet që
          tejkalojnë përgjegjësitë e tyre si dhe miraton rregullore, urdhra e
          udhëzime për funksionimin e brendshëm të strukturave; Miraton planet
          vjetore të drejtorive që funksionojnë pranë Drejtorisë së
          Përgjithshme; U delegon autoritet përfaqësimi drejtorëve të drejtorive
          për probleme të ndryshme të punës, sipas Kodit të Procedurave
          Administrative. Lëshon autorizimin e inspektimit, në përputhje dhe në
          bazë të programit të miratuar të inspektimit; Vendos për shtyrjen e
          kohëzgjatjes së autorizimit, zëvendësimin e inspektorëve, gjatë
          inspektimit, shqyrton ankimin e veçantë ndaj vendimeve për masat
          urgjente të marra nga inspektorët, sipas ligjit.
        </p>
      </>
    ),
  },
  {
    path: "/struktura/drejtoret-ne-vite",
    title: "Drejtorët në vite",
    image: null,
    name: "",
    content: (
      <div className="directors">
        <div className="director">
          <img src={ZefMala} alt="Zef Mala" />
          <div className="director-info">
            <p className="name">Zef Mala</p>
            <p className="period">Qershor 1949 - Shtator 1952</p>
          </div>
        </div>
        <div className="director">
          <img src={IsmailNuhiu} alt="Ismail Nuhiu" />
          <div className="director-info">
            <p className="name">Ismail Nuhiu</p>
            <p className="period">Tetor 1952 - Tetor 1953</p>
          </div>
        </div>
        <div className="director">
          <img src={GaqoPeristeri} alt="Gaqo Peristeri" />
          <div className="director-info">
            <p className="name">Gaqo Peristeri</p>
            <p className="period">Tetor 1953 - Dhjetor 1953</p>
          </div>
        </div>
        <div className="director">
          <img src={SefedinMeçe} alt="Sefedin Meçe" />
          <div className="director-info">
            <p className="name">Sefedin Meçe</p>
            <p className="period">Janar 1954 - Shtator 1955</p>
          </div>
        </div>
        <div className="director">
          <img src={VeliDedi} alt="Veli Dedi" />
          <div className="director-info">
            <p className="name">Veli Dedi</p>
            <p className="period">Shtator 1956 - Qershor 1962</p>
          </div>
        </div>
        <div className="director">
          <img src={DhimitërKotini} alt="Dhimitër Kotini" />
          <div className="director-info">
            <p className="name">Dhimitër Kotini</p>
            <p className="period">Qershor 1962 - Nëntor 1966</p>
          </div>
        </div>
        <div className="director">
          <img src={SkendërHajro} alt="Skendër Hajro" />
          <div className="director-info">
            <p className="name">Skendër Hajro</p>
            <p className="period">Dhjetor 1966 - Gusht 1973</p>
          </div>
        </div>
        <div className="director">
          <img src={SpiroRusho} alt="Spiro Rusho" />
          <div className="director-info">
            <p className="name">Spiro Rusho</p>
            <p className="period">Shtator 1973 - Maj 1979</p>
          </div>
        </div>
        <div className="director">
          <img src={ThomaMurzaku} alt="Thoma Murzaku" />
          <div className="director-info">
            <p className="name">Thoma Murzaku</p>
            <p className="period">Maj 1979 - Mars 1989</p>
          </div>
        </div>
        <div className="director">
          <img src={GazmendShpuza} alt="Gazmend Shpuza" />
          <div className="director-info">
            <p className="name">Gazmend Shpuza</p>
            <p className="period">Mars 1989 - Gusht 1992</p>
          </div>
        </div>
        <div className="director">
          <img src={LuanMalltezi} alt="Luan Malltezi" />
          <div className="director-info">
            <p className="name">Luan Malltezi</p>
            <p className="period">Gusht 1992 - Gusht 1997</p>
          </div>
        </div>
        <div className="director">
          <img src={ZamirShtylla} alt="Zamir Shtylla" />
          <div className="director-info">
            <p className="name">Zamir Shtylla</p>
            <p className="period">Shtator 1997 - Nëntor 1999</p>
          </div>
        </div>
        <div className="director">
          <img src={ShabanSinani} alt="Shaban Sinani" />
          <div className="director-info">
            <p className="name">Shaban Sinani</p>
            <p className="period">Nëntor 1999 - Tetor 2005</p>
          </div>
        </div>
        <div className="director">
          <img src={NevilaNika} alt="Nevila Nika" />
          <div className="director-info">
            <p className="name">Nevila Nika</p>
            <p className="period">Tetor 2005 - Tetor 2013</p>
          </div>
        </div>
        <div className="director">
          <img src={GjetNdoj} alt="Gjet Ndoj" />
          <div className="director-info">
            <p className="name">Gjet Ndoj</p>
            <p className="period">Tetor 2013 - Shtator 2016</p>
          </div>
        </div>
        <div className="director">
          <img src={ArditBido} alt="Ardit Bido" />
          <div className="director-info">
            <p className="name">Ardit Bido</p>
            <p className="period">Shtator 2016 - Mars 2025</p>
          </div>
        </div>
      </div>
    ),
  },
  {
    path: "/struktura/drejtoria-e-arkivit-qendror-shteteror",
    title: "Drejtoria e Arkivit Qendror Shtetëror",
    image: null,
    name: "",
    content: (
      <>
        <p>
          Drejtoria e Arkivit Qendror Shtetëror (AQSH) është nënstrukturë e
          Drejtorisë së Përgjithshme të Arkivave, që kryen ruajtjen, përpunimin,
          administrimin dhe shërbimin e dokumentacionit arkivor, sipas
          përcaktimeve të ligjit “Për Arkivat”.
        </p>
        <p>
          Arkivi Qendror Shtetëror është i organizuar në 4 (katër) sektorë:
          <ol>
            <li>sektori i dokumentacionit përpara vitit 1944,</li>
            <li>sektori i dokumentacionit pas vitit 1944,</li>
            <li>sektori i ish-arkivit të PPSH-së,</li>
            <li>sektori i ruajtjes së dokumentacionit.</li>
          </ol>
        </p>
        <p>
          Gjithashtu, koordinon veprimtarinë e kësaj strukture me strukturat e
          tjera të Drejtorisë së Përgjithshme të Arkivave dhe ndërmjet sektorëve
          që e përbëjnë, ka vendruajtjet e veta, mjediset e shërbimit për
          publikun dhe studiuesit, laboratorët, zonat e sigurisë. Vendruajtjet
          qendrore të dokumenteve të Arkivit Qendror të Shtetit (AQSH) janë
          objekt i rëndësisë së veçantë.
        </p>
        <p>
          Godinat dhe i gjithë territori i vendruajtjes ruhen nga reparti i
          policisë i caktuar nga Ministria e Punëve të Brendshme, me detyra dhe
          rregulla të përcaktuara në rregulloren e brendshme të regjimit të
          hyrje-daljeve dhe sigurimin e godinave të DPA-së, të AQSH-së dhe
          ASHV-ve, miratuar nga Drejtori i Përgjithshëm i Arkivave. Drejtoria e
          Arkivit Qendror Shtetëror merr pjesë në përgatitjen e
          projektrregulloreve e manualeve, për të gjitha nivelet e Drejtorisë së
          Përgjithshme të Arkivave, pas konsultimeve në bazë e më gjerë.
        </p>
        <p>
          Drejtori i AQSH-së ushtron përgjegjësinë e drejtimit operativ të
          drejtorisë, bashkërendon punën ndërmjet sektorëve të kësaj drejtorie
          dhe ndihmon funksionimin e efektshëm të tyre, miraton paraprakisht
          planet vjetore dhe miraton planet mujore të sektorëve që përbëjnë
          AQSH-në, bën ndryshimet e nevojshme në përputhje me detyrat e
          përgjithshme dhe prioritetet e kësaj drejtorie dhe ia paraqet për
          miratim Drejtorit të Përgjithshëm të Arkivave, thërret dhe organizon
          konsulta për nevoja të përmirësimit të mjeteve të kërkimit, të skemave
          të klasifikimit dhe të topografisë fikse në vendruajtje, unifikon
          praktikat e punës mes sektorëve të së njëjtës tipologji dhe harton
          proceset e punës, propozon urdhra pune për përmirësimin e drejtimit të
          veprimtarisë së AQSH-së, ndihmon në veprimtaritë publike (ekspozita,
          botime) të DPA-në, përgjigjet për punën e sektorëve të AQSH-së e të
          vendruajtjeve të tyre dhe informon Drejtorin e Përgjithshëm të
          Arkivave për problematikat e hasura dhe zgjidhjet konkrete, kryen
          detyrat e përcaktuara nga ligji për eprorin direkt, nivelin e mesëm
          drejtues, menaxherin e nivelit të dytë dhe detyrime të tjera të
          ngarkuara nga Drejtori i Përgjithshëm.
        </p>
      </>
    ),
  },
  {
    path: "/struktura/drejtoria-e-arkivit-qendror-shteteror/drejtori-i-arkivit-qendror-shteteror",
    title: "Drejtori i Arkivit Qendror Shtetëror",
    image: ArmandoBoce,
    name: "Armando Boçe",
    content: (
      <>
        <p>
          Armando Boçe ka përfunduar studimet “Master Profesional” në
          Arkivistikë pranë Universitetit të Tiranës, Fakulteti Histori
          Filologji. Gjatë viteve 2003-2004 ka ndjekur studimet pranë Fakultetit
          Histori Filologji, Dega Histori. Prej vitit 2008 punon pranë
          Drejtorisë së Përgjithshme të Arkivave. Specialist arkivist në
          sektorin e Ish-Arkvit të Komiteti Qendror, specialist arkivist në
          Drejtorinë Rajonale të Arkivit Shtetëror Tiranë. Nga viti 2013-2017
          Përgjegjës i sektorit të Digjitalizim dhe Audiovizual, nga maji 2017 e
          vazhdim përgjegjës i sektorit të Promovimit dhe Komunikimit si dhe i
          ngarkuar me punët e Drejtorit të Drejtorisë së Evidencës Qendrore dhe
          Komunikimit.
        </p>
        <p>
          Pjesëmarrës në Konferenca shkencore me temë historike dhe arkivore
          brenda dhe jashtë vendit. Autor i studimeve botuar në fletoren Arkivi
          Shqiptar të DPA dhe Vjetarit të Agjencisë së Arkivave të Kosovës.
          Armando Boçe ka lindur në Tiranë më 27 nëntor 1980. Është i martuar
          dhe baba i dy fëmijëve, një vajze dhe një djali.
        </p>
      </>
    ),
  },
  {
    path: "/struktura/drejtoria-e-arkivit-qendror-shteteror/sektori-i-dokumentacionit-perpara-vitit-1944",
    title: "Sektori i Dokumentacionit Përpara Vitit 1944",
    image: null,
    name: "",
    content: (
      <>
        <p>
          Kryen klasifikimin, përpunimin tekniko-shkencor të dokumenteve të
          periudhës së hershme deri në përfundim të Luftës së Dytë Botërore, si
          dhe sigurimin e kushteve të përshtatshme për evidentimin, ruajtjen dhe
          shfrytëzimin e dokumenteve.
        </p>
        <p>
          Parashikon dhe planifikon veprimtari në lidhje me detyrat vjetore të
          përcaktuara për sektorin, me qëllim realizimin në kohë të tyre, si dhe
          siguron që puna të kryhet brenda normave të përgjithshme, në afatet e
          përcaktuara sipas standardeve administrative dhe procedurave teknike.
          Kontrollon dhe monitoron veprimtaritë e sektorit në përmbushjen e tyre
          sipas plan- evidencave mujore. Mbikëqyr klasifikimin dhe përpunimin
          tekniko-shkencor të dokumenteve, ndarjen në fond, vit, strukturë,
          krijimin e njësisë së ruajtjes, organizimin e brendshëm dhe
          përshkrimin e titullit, në përputhje me normat tekniko-profesionale
          dhe metodologjike të shërbimit arkivor në RSH. Propozon për miratim
          skemat e reja të klasifikimit te drejtori i AQSH-së, kur këto
          mungojnë. Kontrollon përpilimin e inventarëve të fondeve, duke
          plotësuar kërkesat e parashikuara, deri në dorëzim në depo të fondit
          të përpunuar. Shpërndan punën mes specialistëve të sektorit në
          përputhje me legjislacionin dhe rregulloren e brendshme të
          institucionit dhe informon Drejtorin e Përgjithshëm apo Drejtorin e
          AQSH-së. Mbikëqyr dhe udhëzon specialistët, në lidhje me detyrat e
          ngarkuara dhe jep mbështetje dhe këshillim për problematika të
          ndryshme. Mbikëqyr zbatimin e rregullores së brendshme të
          institucionit, me qëllim realizimin e veprimtarisë së sektorit, në
          përputhje me udhëzimet e dhëna dhe informon Drejtorin e AQSH-së mbi
          mbarëvajtjen e punës apo problematikat e hasura. Mbështet kërkesat e
          punonjësve të rinj apo ekzistues në lidhje me mjetet materiale për
          ushtrimin e detyrës. Përgatit prezencën ditore dhe mujore të
          punonjësve dhe raporton te Drejtori i AQSH-së për disiplinën në punë
          në sektor.
        </p>
        <p>
          Zbaton detyra të tjera të përcaktuara nga Drejtori i Përgjithshëm i
          Arkivave.
        </p>
      </>
    ),
  },
  {
    path: "/struktura/drejtoria-e-arkivit-qendror-shteteror/sektori-i-dokumentacionit-pas-vitit-1944",
    title: "Sektori i Dokumentacionit Pas Vitit 1944",
    image: null,
    name: "",
    content: (
      <>
        <p>
          Kryen klasifikimin, sistemimin dhe përshkrimin e dokumenteve, si dhe
          sigurimin e kushteve të përshtatshme për evidentimin, ruajtjen dhe
          shfrytëzimin e dokumenteve. Ai kryen këto detyra organizative dhe
          menaxheriale: Parashikon dhe planifikon veprimtari në lidhje me
          detyrat vjetore të përcaktuara për sektorin, me qëllim realizimin në
          kohë të tyre, si dhe siguron që puna të kryhet brenda normave të
          përgjithshme, në afatet e përcaktuara sipas standardeve administrative
          dhe procedurave teknike. Kontrollon dhe monitoron veprimtaritë e
          sektorit në përmbushjen e tyre, sipas plan- evidencave mujore.
          Mbikëqyr klasifikimin dhe përpunimin tekniko-shkencor të dokumenteve,
          ndarjen në fond, vit, strukturë, krijimin e njësisë së ruajtjes,
          organizimin e brendshëm dhe përshkrimin e titullit, në përputhje me
          normat tekniko-profesionale dhe metodologjike të shërbimit arkivor në
          RSH. Propozon skemat e reja të klasifikimit për miratim te drejtori i
          AQSH-së, kur këto mungojnë. Kontrollon përpilimin e inventarëve të
          fondeve, duke plotësuar kërkesat e parashikuara, deri në dorëzim në
          depo të fondit të përpunuar. Shpërndan punën mes specialistëve të
          sektorit në përputhje me legjislacionin dhe rregulloren e brendshme të
          institucionit dhe informon Drejtorin e Përgjithshëm apo drejtorin e
          AQSH-së. Mbikëqyr dhe udhëzon specialistët, në lidhje me detyrat e
          ngarkuara dhe jep mbështetje e këshillim për problematika të ndryshme.
          Mbikëqyr zbatimin e rregullores së brendshme të institucionit, me
          qëllim realizimin e veprimtarisë së sektorit, në përputhje me
          udhëzimet e dhëna dhe informon drejtorin e AQSH-së mbi mbarëvajtjen e
          punës apo problematikat e hasura. Mbështet kërkesat e punonjësve të
          rinj apo ekzistues në lidhje me mjetet materiale për ushtrimin e
          detyrës. Përgatit prezencën ditore dhe mujore të punonjësve dhe
          raporton tek drejtori i AQSH-së për disiplinën e punës në sektor.
          Përgatit materialet për mbledhjen e Komisionit Qendror të Ekspertizës
          dhe Komisionin e Deklasifikimit dhe Zhvlerësimit të Dokumenteve që
          kanë hyrë të klasifikuara në arkiv. Zbaton detyra të tjera të
          përcaktuara nga Drejtori i Përgjithshëm i Arkivave.
        </p>
      </>
    ),
  },
  {
    path: "/struktura/drejtoria-e-arkivit-qendror-shteteror/sektori-i-ruajtjes-se-dokumentacionit",
    title: "Sektori i Ruajtjes së Dokumentacionit",
    image: null,
    name: "",
    content: (
      <>
        <p>
          Sektori i ruajtjes së dokumentacionit përbëhet nga përgjegjësi i
          sektorit, specialistë-arkivistë dhe administratorë dokumentacioni.
          Përgjegjësi i sektorit të ruajtjes së dokumentacionit kujdeset dhe
          përgjigjet për ruajtjen e mbrojtjen e fondeve arkivore. Ai parashikon
          dhe planifikon veprimtari në lidhje me detyrat vjetore të përcaktuara
          për sektorin, me qëllim realizimin në kohë të tyre, si dhe siguron që
          puna të kryhet brenda normave të përgjithshme, në afatet e përcaktuara
          sipas standardeve administrative dhe procedurave teknike, kontrollon
          dhe monitoron veprimtaritë e sektorit në përmbushjen e tyre sipas
          plan- evidencave mujore, shpërndan punën mes specialistëve të sektorit
          në përputhje me legjislacionin dhe rregulloren e brendshme të
          institucionit dhe informon Drejtorin e Përgjithshëm apo drejtorin e
          AQSH-së, mbikëqyr dhe udhëzon specialistët, në lidhje me detyrat e
          ngarkuara dhe jep mbështetje dhe këshillim për problematika të
          ndryshme, mbikëqyr zbatimin e rregullores së brendshme të
          institucionit, me qëllim realizimin e veprimtarisë së sektorit, në
          përputhje me udhëzimet e dhëna dhe informon drejtorin e AQSH-së për
          mbarëvajtjen e punës apo problematikat e hasura, mbështet kërkesat e
          punonjësve të rinj apo ekzistues në lidhje me mjetet materiale për
          ushtrimin e detyrës, përgatit prezencën ditore dhe mujore të
          punonjësve dhe raporton tek drejtori i AQSH-së për disiplinën e punës
          në sektor, drejton, kontrollon dhe ndihmon punonjësit për pasurimin e
          fondeve arkivore të ardhur nga institucionet, nga dhuruesit individë,
          si dhe nga sektorët e AQSH-së, përgatit materialet për asgjësim në
          bazë të vendimeve të Komisionit Qendror të Ekspertizës, harton plane
          pune për zhvendosjen e fondeve shumë të kërkuara nga kërkues/studiues
          nga vendruajtja e Shkozës në AQSH, si dhe për zhvendosjen e fondeve më
          pak të kërkuara apo të papërpunuara nga AQSH në vendruajtjen Shkozë,
          si dhe ndjek me përgjegjësi evakuimin e fondeve dhe zbaton detyra të
          tjera të përcaktuara nga Drejtori i Përgjithshëm i Arkivave.
          Specialisti-arkivist i sektorit të ruajtjes së dokumentacionit
          realizon në kohë kërkesat e punonjësve të DPA-së dhe AQSH-së me
          dokumente origjinale, zëvendëson dosjet dhe kutitë e dëmtuara sipas
          metodologjisë arkivore, identifikon dhe dërgon për restaurim
          dokumentet e dëmtuara, administron e mban përgjegjësi për mjediset e
          vendruajtjes dhe mirëmban bazën materiale dhe kancelarike të nevojshme
          për kryerjen normale të funksioneve të sektorit dhe më gjerë, sipas
          përcaktimeve në normat tekniko-profesionale e rregulloret e tjera,
          bashkëpunon me punonjësit e DPA-së dhe sektorëve të AQSH-së për
          realizimin e veprimtarive të ndryshme të institucionit në shkallë
          kombëtare, merr në dorëzim dokumentet nga sektorët e përpunimit
          tekniko-shkencor, sipas rregullave metodologjike arkivore, merr në
          dorëzim dokumente me afat ruajtjeje përgjithmonë nga institucionet
          qendrore sipas ligjit për arkivat, bën regjistrimin e dokumenteve të
          dhuruara e dorëzuara, si dhe dokumenteve të ardhura jashtë
          institucionit dhe zbaton detyra të tjera të përcaktuara nga
          përgjegjësi i sektorit apo eprori direkt. Administratori i dokumenteve
          nxjerr dosjet në vendruajtjen e AQSH-së dhe Shkozës, sipas kërkesave
          të studiuesve dhe sistemimin e tyre mbas kthimit, skanon ose
          fotokopjon dokumente origjinale sipas urdhrave të punës të studiuesve,
          të miratuara nga Drejtori i Përgjithshëm dhe kërkesave të punonjësve
          të AQSH-së, që merren me problem pronësie, bën shpluhurizimin e kutive
          të fondeve arkivore të administruara në vendruajtjen e AQSH-së dhe
          Shkozë, bën plotësimin e regjistrave me të dhënat e fondeve të hyra në
          vendruajtjen e AQSH-së dhe Shkozës dhe regjistrimin e dosjeve të dhëna
          për shfrytëzim, Evakuon fondete ardhura nga sektorët e AQSH-së apo
          institucionet shtetërore në vendruajtjen e Shkozës, Mat temperature
          dhe lagështirën në mjediset e ruajtjes së dokumenteve në AQSH dhe
          Shkozë dhe zbaton detyra të tjera të përcaktuara nga përgjegjësi i
          sektorit apo eprori direkt.
        </p>
      </>
    ),
  },
  {
    path: "/struktura/drejtoria-e-evidences-qendrore-dhe-komunikimit",
    title: "Drejtoria e Komunikimit",
    image: null,
    name: "",
    content: (
      <>
        <p>
          Kryen ruajtjen, përpunimin, administrimin dhe shërbimin e
          dokumentacionit të ish- Arkivit Qendror të Partisë së Punës së
          Shqipërisë.: Parashikon dhe planifikon veprimtari në lidhje me detyrat
          vjetore të përcaktuara për sektorin, me qëllim realizimin në kohë të
          tyre, si dhe siguron që puna të kryhet brenda normave të përgjithshme,
          në afatet e përcaktuara sipas standardeve administrative dhe
          procedurave teknike. Kontrollon dhe monitoron veprimtaritë e sektorit
          në përmbushjen e tyre sipas plan- evidencave mujore. Mbikëqyr
          klasifikimin dhe përpunimin tekniko-shkencor të dokumenteve, ndarjen
          në fond, vit, strukturë, krijimin e njësisë së ruajtjes, organizimin e
          brendshëm dhe përshkrimin e titullit, në përputhje me normat
          tekniko-profesionale dhe metodologjike të shërbimit arkivor në RSH.
          Propozon skemat e reja të klasifikimit për miratim te drejtori i
          AQSH-së, kur këto mungojnë. Kontrollon përpilimin e inventarëve të
          fondeve, duke plotësuar kërkesat e parashikuara, deri në dorëzim në
          depo të fondit të përpunuar. Shpërndan punën mes specialistëve të
          sektorit në përputhje me legjislacionin dhe rregulloren e brendshme të
          institucionit dhe informon Drejtorin e Përgjithshëm apo drejtorin e
          AQSH-së. Mbikëqyr dhe udhëzon specialistët, në lidhje me detyrat e
          ngarkuara dhe jep mbështetje dhe këshillim për problematika të
          ndryshme. Mbikëqyr zbatimin e rregullores së brendshme të
          institucionit, me qëllim realizimin e veprimtarisë së sektorit, në
          përputhje me udhëzimet e dhëna dhe informon drejtorin e AQSH-së për
          mbarëvajtjen e punës apo problematikat e hasura. Mbështet kërkesat e
          punonjësve të rinj apo ekzistues në lidhje me mjetet materiale për
          ushtrimin e detyrës. Përgatit prezencën ditore dhe mujore të
          punonjësve dhe raporton te drejtori i AQSH-së për disiplinën e punës
          në sektor. Përgatit materialet për mbledhjen e Komisionit të
          Deklasifikimit dhe Zhvlerësimit të Dokumenteve, që kanë hyrë të
          klasifikuara në arkiv. Administron dokumentet që ka në përgjegjësi të
          drejtpërdrejtë në vendruajtje, duke kryer të gjitha shërbimet, sipas
          rregullave të AQSH-së. Drejton, kontrollon dhe ndihmon punonjësit e
          sektorit për realizimin e kërkesave për shërbimin e dokumenteve
          origjinale, në mikrofilm si dhe fotokopje të tyre. Kryen detyra që i
          ngarkohen për plotësimin e kërkesave të studiuesve, institucioneve dhe
          qytetarëve, në përputhje me rregulloren e shfrytëzimit të dokumenteve
          arkivore dhe në bashkëpunim me sektorin e shërbimit të
          dokumentacionit. Harton plane pune për zhvendosjen e fondeve shumë të
          kërkuara nga kërkues/studiues nga vendruajtja e Shkozës në AQSH, si
          dhe për zhvendosjen e fondeve më pak të kërkuara apo të papërpunuara
          nga AQSH në vendruajtjen Shkozë, si dhe ndjek me përgjegjësi evakuimin
          e fondeve. Drejton, kontrollon dhe ndihmon punonjësit e sektorit për
          digjitalizimin e materialeve audio-video që janë pjesë e këtij
          sektori. Zbaton detyra të tjera të përcaktuara nga Drejtori i
          Përgjithshëm i Arkivave.
        </p>
      </>
    ),
  },
  {
    path: "/struktura/drejtoria-e-arkivit-qendror-shteteror/sektori-i-ish-arkivit-te-ppsh-se",
    title: "Sektori i Ish-Arkivit të PPSH-së",
    image: null,
    name: "",
    content: (
      <>
        <p>
          Kryen ruajtjen, përpunimin, administrimin dhe shërbimin e
          dokumentacionit të ish- Arkivit Qendror të Partisë së Punës së
          Shqipërisë.: Parashikon dhe planifikon veprimtari në lidhje me detyrat
          vjetore të përcaktuara për sektorin, me qëllim realizimin në kohë të
          tyre, si dhe siguron që puna të kryhet brenda normave të përgjithshme,
          në afatet e përcaktuara sipas standardeve administrative dhe
          procedurave teknike. Kontrollon dhe monitoron veprimtaritë e sektorit
          në përmbushjen e tyre sipas plan- evidencave mujore. Mbikëqyr
          klasifikimin dhe përpunimin tekniko-shkencor të dokumenteve, ndarjen
          në fond, vit, strukturë, krijimin e njësisë së ruajtjes, organizimin e
          brendshëm dhe përshkrimin e titullit, në përputhje me normat
          tekniko-profesionale dhe metodologjike të shërbimit arkivor në RSH.
          Propozon skemat e reja të klasifikimit për miratim te drejtori i
          AQSH-së, kur këto mungojnë. Kontrollon përpilimin e inventarëve të
          fondeve, duke plotësuar kërkesat e parashikuara, deri në dorëzim në
          depo të fondit të përpunuar. Shpërndan punën mes specialistëve të
          sektorit në përputhje me legjislacionin dhe rregulloren e brendshme të
          institucionit dhe informon Drejtorin e Përgjithshëm apo drejtorin e
          AQSH-së. Mbikëqyr dhe udhëzon specialistët, në lidhje me detyrat e
          ngarkuara dhe jep mbështetje dhe këshillim për problematika të
          ndryshme. Mbikëqyr zbatimin e rregullores së brendshme të
          institucionit, me qëllim realizimin e veprimtarisë së sektorit, në
          përputhje me udhëzimet e dhëna dhe informon drejtorin e AQSH-së për
          mbarëvajtjen e punës apo problematikat e hasura. Mbështet kërkesat e
          punonjësve të rinj apo ekzistues në lidhje me mjetet materiale për
          ushtrimin e detyrës. Përgatit prezencën ditore dhe mujore të
          punonjësve dhe raporton te drejtori i AQSH-së për disiplinën e punës
          në sektor. Përgatit materialet për mbledhjen e Komisionit të
          Deklasifikimit dhe Zhvlerësimit të Dokumenteve, që kanë hyrë të
          klasifikuara në arkiv. Administron dokumentet që ka në përgjegjësi të
          drejtpërdrejtë në vendruajtje, duke kryer të gjitha shërbimet, sipas
          rregullave të AQSH-së. Drejton, kontrollon dhe ndihmon punonjësit e
          sektorit për realizimin e kërkesave për shërbimin e dokumenteve
          origjinale, në mikrofilm si dhe fotokopje të tyre. Kryen detyra që i
          ngarkohen për plotësimin e kërkesave të studiuesve, institucioneve dhe
          qytetarëve, në përputhje me rregulloren e shfrytëzimit të dokumenteve
          arkivore dhe në bashkëpunim me sektorin e shërbimit të
          dokumentacionit. Harton plane pune për zhvendosjen e fondeve shumë të
          kërkuara nga kërkues/studiues nga vendruajtja e Shkozës në AQSH, si
          dhe për zhvendosjen e fondeve më pak të kërkuara apo të papërpunuara
          nga AQSH në vendruajtjen Shkozë, si dhe ndjek me përgjegjësi evakuimin
          e fondeve. Drejton, kontrollon dhe ndihmon punonjësit e sektorit për
          digjitalizimin e materialeve audio-video që janë pjesë e këtij
          sektori. Zbaton detyra të tjera të përcaktuara nga Drejtori i
          Përgjithshëm i Arkivave.
        </p>
      </>
    ),
  },
  {
    path: "/struktura/drejtoria-e-evidences-qendrore-dhe-komunikimit/drejtoresha-i-evidences-qendrore-dhe-komunikimit",
    title: "Drejtoresha e Evidencës Qendrore dhe Komunikimit",
    image: ElsaSaka,
    name: "Elsa Saka",
    content: <></>,
  },
  {
    path: "struktura/drejtoria-e-evidences-qendrore-dhe-komunikimit/sektori-i-evidences-qendrore",
    title: "Sektori i Evidencës Qendrore",
    image: null,
    name: "",
    content: (
      <>
        <p>
          Sektori i evidences qendrore përbëhet nga përgjegjës sektori,
          specialist (arkivist), administrator dokumentash.
        </p>
        <p>
          Përgjegjësi i sektorit të evidencës qëndrore parashikon dhe
          planifikonveprimtari në lidhje meSektori i evidences qendrore përbëhet
          nga përgjegjës sektori, specialist (arkivist), administrator
          dokumentash.Përgjegjësi i sektorit të evidencës qëndrore parashikon
          dhe planifikon veprimtari në lidhje me detyrat vjetore të përcaktuara
          për sektorin, me qëllim realizimin në kohë të tyre, si dhe siguron që
          puna të kryhet brenda normave të përgjithshme, në afatet e përcaktuara
          sipas standardeve administrative dhe procedurave teknike, kontrollon
          dhe monitoron veprimtaritë e sektorit në përmbushjen e tyre sipas
          plan- evidencave mujore, shpërndan punën mes specialistëve të sektorit
          në përputhje me legjislacionin dhe rregulloren e brendshme të
          institucionit dhe informon Drejtorin e Përgjithshëm apo drejtorin e
          DEDR-së, mbikëqyr dhe udhëzon specialistët, në lidhje me detyrat e
          ngarkuara dhe jep mbështetje dhe këshillim për problematika të
          ndryshme, mbikëqyr zbatimin e rregullores së brendshme të
          institucionit, me qëllim realizimin e veprimtarisë së sektorit, në
          përputhje me udhëzimet e dhëna dhe informon drejtorin e DEDR-së për
          mbarëvajtjen e punës apo problematikat e hasura, mbështet kërkesat e
          punonjësve të rinj apo ekzistues në lidhje me mjetet materiale për
          ushtrimin e detyrës, përgatit prezencën ditore dhe mujore të
          punonjësve dhe raporton tek drejtori i DEDR-së për disiplinën e punës
          në sektor, kontrollon procesin e evidencimit fizik dokumenteve, sipas
          rregullores së procesit të punës, zbaton detyra të tjera të
          përcaktuara nga eprori direkt dhe Drejtori i Përgjithshëm i Arkivave.
          Specialistët e evidencës qendrore bëjnë regjistrimin e fondeve bazë e
          shtesave në regjistrin fizik dhe elektronik të evidencës, i cili
          përfshin kontrollin, verifikimin, korrigjimin ortografik të gabimeve
          teknike dhe rakordon dy regjistrat, administrojnë dhe përmirësojnë
          mjetet e informacionit dhe të kërkimit, për t’u shërbyer
          studiuesve.Ofrojnë ndihmë me mjetet e evidencës për sektorët e AQSH-së
          për zgjidhjen e problemeve që dalin gjatë proceseve të punës, Bëjnë
          kontrollin e vazhdueshëm të emërtesave të strukturave të fondeve dhe
          rekomandon ndreqjen e gabimeve, bëjnë verifikimin fizik të njësive të
          ruajtjes si dhe unifikimin e tyre me inventarin përkatës, bëjnë
          plotësimin e të metave të elementeve të inventarëve, si titullin e
          njësive të ruajtjes, vitin, datat ekstreme, sasinë e fletëve,
          plotësimin e etiketave e ndërrimin e kutive dhe unifikimin e tyre,
          Plotësojnë titujt e dosjeve me elementet formale në rast të mungesave,
          merren me evidentimin dhe propozimin e zgjidhjes për mangësitë e
          konstatuara në sasinë e fletëve të dosjeve apo në përmbajtjen e tyre
          nga AQSH-ja, bëjnë ndarjen e procesverbaleve të dorëzimit të
          dokumenteve sipas fondeve, bëjnë zëvendësimin e regjistrave të vjetër
          me të rinj me të gjitha mekanizmat e duhur për lehtësi përdorimi duke
          i verifikuar e plotësuar me origjinalin, Dhënie për shfrytëzim të
          inventarëve të njësive të ruajtjes, bëjnë bashkimin e shtesave të
          fondeve me bazat në një inventor, verifikojnë njësitë e ruajtjes që
          rezultojnë me numër të njëjtë dhe kalimin e kësaj njësie si shtesë
          fondi. Unifikojnë inventarët e fondeve që ndodhen në sallën e studimit
          me ato të evidences, dërgojnë në laboratorin e restaurimit për
          restaurim të dokumenteve, nëse është e nevojshme dhe zbatojnë detyra
          të tjera të përcaktuara nga drejtori i Evidencës Qendrore,
          Digjitalizimit dhe Restaurimit. detyrat vjetore të përcaktuara për
          sektorin, me qëllimrealizimin në kohë të tyre, si dhe siguron që puna
          të kryhet brenda normave tëpërgjithshme, në afatet e përcaktuara sipas
          standardeve administrative dheprocedurave teknike, kontrollon dhe
          monitoron veprimtaritë e sektorit nëpërmbushjen e tyre sipas plan-
          evidencave mujore, shpërndan punën messpecialistëve të sektorit në
          përputhje me legjislacionin dhe rregulloren ebrendshme të
          institucionit dhe informon Drejtorin e Përgjithshëm apo drejtorine
          DEDR-së, mbikëqyr dhe udhëzon specialistët, në lidhje me detyrat e
          ngarkuaradhe jep mbështetje dhe këshillim për problematika të
          ndryshme, mbikëqyrzbatimin e rregullores së brendshme të
          institucionit, me qëllim realizimin eveprimtarisë së sektorit, në
          përputhje me udhëzimet e dhëna dhe informondrejtorin e DEDR-së për
          mbarëvajtjen e punës apo problematikat e hasura,mbështet kërkesat e
          punonjësve të rinj apo ekzistues në lidhje me mjetetmateriale për
          ushtrimin e detyrës, përgatit prezencën ditore dhe mujore tëpunonjësve
          dhe raporton tek drejtori i DEDR-së për disiplinën e punës nësektor,
          kontrollon procesin e evidencimit fizik dokumenteve, sipas
          rregulloressë procesit të punës, zbaton detyra të tjera të përcaktuara
          nga eprori direktdhe Drejtori i Përgjithshëm i Arkivave. Specialistët
          e evidencës qendrore bëjnëregjistrimin e fondeve bazë e shtesave në
          regjistrin fizik dhe elektronik tëevidencës, i cili përfshin
          kontrollin, verifikimin, korrigjimin ortografik tëgabimeve teknike dhe
          rakordon dy regjistrat, administrojnë dhe përmirësojnëmjetet e
          informacionit dhe të kërkimit, për t’u shërbyer studiuesve.
        </p>
        <p>
          Ofrojnë ndihmë me mjetet e evidencës për sektorët e AQSH-së për
          zgjidhjen eproblemeve që dalin gjatë proceseve të punës, Bëjnë
          kontrollin e vazhdueshëm tëemërtesave të strukturave të fondeve dhe
          rekomandon ndreqjen e gabimeve, bëjnëverifikimin fizik të njësive të
          ruajtjes si dhe unifikimin e tyre me inventarinpërkatës, bëjnë
          plotësimin e të metave të elementeve të inventarëve, sititullin e
          njësive të ruajtjes, vitin, datat ekstreme, sasinë e
          fletëve,plotësimin e etiketave e ndërrimin e kutive dhe unifikimin e
          tyre, Plotësojnëtitujt e dosjeve me elementet formale në rast të
          mungesave, merren meevidentimin dhe propozimin e zgjidhjes për
          mangësitë e konstatuara në sasinë efletëve të dosjeve apo në
          përmbajtjen e tyre nga AQSH-ja, bëjnë ndarjen eprocesverbaleve të
          dorëzimit të dokumenteve sipas fondeve, bëjnë zëvendësimin
          eregjistrave të vjetër me të rinj me të gjitha mekanizmat e duhur për
          lehtësipërdorimi duke i verifikuar e plotësuar me origjinalin, Dhënie
          për shfrytëzim tëinventarëve të njësive të ruajtjes, bëjnë bashkimin e
          shtesave të fondeve mebazat në një inventor, verifikojnë njësitë e
          ruajtjes që rezultojnë me numër tënjëjtë dhe kalimin e kësaj njësie si
          shtesë fondi. Unifikojnë inventarët efondeve që ndodhen në sallën e
          studimit me ato të evidences, dërgojnë nëlaboratorin e restaurimit për
          restaurim të dokumenteve, nëse është e nevojshmedhe zbatojnë detyra të
          tjera të përcaktuara nga drejtori i Evidencës Qendrore,Digjitalizimit
          dhe Restaurimit.
        </p>
      </>
    ),
  },
  {
    path: "/struktura/drejtoria-e-evidences-qendrore-dhe-komunikimit/sektori-i-promovimit-dhe-komunikimit",
    title: "Sektori i Promovimit dhe Komunikimit",
    image: null,
    name: "",
    content: (
      <>
        <p>
          Sektori i Promovimit dhe KomunikimitParashikon dhe planifikon
          veprimtari në lidhje me detyrat vjetore të përcaktuara për sektorin,
          me qëllim realizimin në kohë të tyre, si dhe siguron që puna të kryhet
          brenda normave të përgjithshme, në afatet e përcaktuara sipas
          standardeve administrative dhe procedurave teknike. Kontrollon dhe
          monitoron veprimtaritë e sektorit në përmbushjen e tyre, sipas plan-
          evidencave mujore. Administron objektet muzeale të DPA-së dhe
          organizon e mundëson ekspozimin e tyre të vazhdueshëm, në përputhje me
          rregullat e ekspozimit të objekteve muzeale të DPA-së. Harton projekte
          për procese të ndryshme pune të Drejtorisë së Përgjithshme të
          Arkivave, në bashkëpunim me sektorët e drejtoritë e tjera. Mbikëqyr
          zbatimin e rregullores së brendshme të institucionit, me qëllim
          realizimin e veprimtarisë së sektorit, në përputhje me udhëzimet e
          dhëna dhe informon drejtorin e DEQK-së mbi mbarëvajtjen e punës apo
          problematikat e hasura. Mbështet kërkesat e punonjësve të rinj apo
          ekzistues në lidhje me mjetet materiale për ushtrimin e detyrës.
          Promovon pasurinë dokumentare dhe jodokumentare të DPA-së, nëpërmjet
          rrjeteve sociale, ekspozitave, botimeve e formave të tjera. Përgatit
          botimin e revistave arkivore. Bën përpunimin shkencor të pasurisë së
          fototekës dhe fonotekës, administron dhe përgjigjet për ruajtjen e saj
          dhe të aparaturave që ka në përdorim dhe kryen shërbimin e fotografive
          sipas kërkesave të qytetarëve, përpilon mjete informimi për fonotekën
          dhe fototekën dhe kryen përgjigjen e kërkesave të qytetarëve dhe
          institucioneve për fotografi ose materiale audio e video dhe zbaton
          rregullat për fototekën dhe fonotekën e DPA-së. Planifikon dhe kryen
          përgatitjet teknike për konferenca shkencore, simpoziume dhe çdo
          veprimtari tjetër shkencore. Koordinon realizimin e planit të
          veprimtarive midis vendit tonë dhe vendeve të tjera, konform planeve
          dhe marrëveshjeve të nënshkruara. Koordinimin dhe ndjekjen
          veprimtarive bilaterale dhe multilaterale në fushën e arkivave, si dhe
          përgatitjen e propozimeve dhe informacioneve përkatëse. Ndjek dhe merr
          pjesë në veprimtari që lidhen me planet e bashkëpunimit dypalësh dhe
          përcaktimin e prioriteteve. Përgatitjen e materialeve njohëse dhe
          informuese, si dhe praktikave dokumentare rreth vizitave të titullarit
          në vendet e tjera, apo të autoriteteve të huaja në vendin tone.
          Përkthen në anglisht materiale të ndryshme, fakse, postën elektronike
          etj. Zbaton detyra të tjera të përcaktuara nga përgjegjësi i sektorit
          apo eprori direkt. Kujdeset për shpërndarjen e akteve dhe materialeve
          informative lidhur me aktivitetin institucional në organet e
          informimit publik. Merr pjesë në pritjet dhe veprimtaritë e
          organizuara nga DPA-ja. Komunikon me mediat për ndjekjen dhe
          pasqyrimin e këtyre veprimtarive. Ndjek veprimtaritë dhe bashkërendon
          me to punën për ruajtjen dhe promovimin e një imazhi pozitiv për
          DPA-në. Organizon dhe drejton konferencat për shtyp të Drejtorit të
          Përgjithshëm apo personave të autorizuar prej tij. Evidenton dhe
          përpunon pasqyrën e shtypit të përditshëm dhe javor, si dhe evidenton
          shkrimet e botuara në shtyp ose të transmetuara në mediat elektronike,
          kur trajtojnë probleme të veprimtarisë së institucionit. Bashkërendon
          dhe kryen përgatitjet e nevojshme në kuadër të eventeve dhe projekteve
          të ndryshme. Kryen detyrat e koordinatorit për të drejtën e
          informimit.Zbaton detyra të tjera të përcaktuara nga përgjegjësi i
          sektorit apo eprori direkt.
        </p>
      </>
    ),
  },
  {
    path: "/struktura/drejtoria-e-evidences-qendrore-dhe-komunikimit/sektori-i-salles-se-studimit-dhe-bibliotekes",
    title: "Sektori i Sallës së Studimit dhe Bibliotekës",
    image: null,
    name: "",
    content: (
      <>
        <p>
          Sektori i sallës së studimit dhe bibliotekës kujdeset për shërbimin në
          kohë të qytetarëve, studiuesve, punonjësve të autorizuar nga
          ministritë dhe institucionet e tjera fondkrijuese, si dhe personave
          juridikë privatë, duke vënë në dispozicion të tyre mjetet e kërkimit
          dhe vetë dokumentet sipas kërkesës së porositësit dhe rregullave për
          shërbimin me dokumente ky sektor përbëhet nga përgjegjësi i sektorit,
          specialistët-arkivistë dhe administrator dokumentash. Përgjegjësi i
          sektorit të sallës së studimit parashikon dhe planifikon veprimtari në
          lidhje me detyrat vjetore të përcaktuara për sektorin, me qëllim
          realizimin në kohë të tyre, si dhe siguron që puna të kryhet brenda
          normave të përgjithshme, në afatet e përcaktuara sipas standardeve
          administrative dhe procedurave teknike, kontrollon dhe monitoron
          veprimtaritë e sektorit në përmbushjen e tyre sipas plan- evidencave
          mujore, administron dhe ndjek mbarëvajtjen e punës në bibliotekë, në
          funksion të shërbimit të studiuesve dhe qytetarëve, në përputhje me
          rregulloren e bibliotekës së DPA-së, mbikëqyr dhe siguron zbatimin e
          rregullores së shfrytëzimit të dokumenteve arkivore, mbikëqyr zbatimin
          e rregullores së brendshme të institucionit, me qëllim realizimin e
          veprimtarisë së sektorit, në përputhje me udhëzimet e dhëna dhe
          informon drejtorin e DEQK-së për mbarëvajtjen e punës apo
          problematikat e hasura, mbikëqyr dhe siguron zbatimin e regjimit të
          hyrje-daljeve në institucion, siguron ndalimin e shërbimit me
          dokumente që akoma nuk janë deklasifikuar dhe i propozon Komisionit të
          Deklasifikimit dhe Zhvlerësimit deklasifikimin e tyre, shpërndan punën
          mes specialistëve të sektorit në përputhje me legjislacionin dhe
          rregulloren e brendshme të institucionit dhe informon Drejtorin e
          Përgjithshëm apo drejtorin e DEQK-së, mbikëqyr dhe udhëzon
          specialistët, në lidhje me detyrat e ngarkuara dhe jep mbështetje dhe
          këshillim për problematika të ndryshme, mbështet kërkesat e punonjësve
          të rinj apo ekzistues në lidhje me mjetet materiale për ushtrimin e
          detyrës, përgatit prezencën ditore dhe mujore të punonjësve dhe
          raporton tek drejtori i DEQK-së për disiplinën e punës në sektor dhe
          zbaton detyra të tjera të përcaktuara nga Drejtori i Përgjithshëm i
          Arkivave. Specialisti i sektorit të sallës së studimit zbaton me
          përgjegjësi rregulloren e shfrytëzimit të dokumenteve arkivore në
          sallën e studimit të AQSH-së, krijon një dosje të veçantë për çdo
          kërkues, ku administron fletë regjistrimi dhe dokumente të tjera, që
          mund të krijohen nga studiuesi/kërkuesi, në sisemin elektronik, kryen
          shërbimin ndaj publikut me dokumente arkivore duke zbatuar Ligjin
          ‘’Për arkivat’’ dhe shërbimin me mjete informacioni, ndihmon në
          plotësimin e fletë regjistrimit apo në përgatitjen e urdhrave të punës
          së studiuesve për probleme studimore. merr dhe dorëzon kërkesat për
          dosje në vendruajtje, kryen verifikimin fizik të dosjeve me inventarët
          përkatës, verifikon në mikrofilm mungesat e konstatuara në dosjet e
          porositura në bashkëpunim me sektorin e evidencës qendrore dhe bën
          evidentimin në rast mangësish, shërben me bobina dhe mikrofilma në
          sallën e aparaturave, si dhe me dokumente arkivore në sallën e
          studimit, zbaton me korrektësi detyrat, si dhe urdhrat e eprorëve dhe
          raporton periodikisht te përgjegjesi i sektorit, zbaton detyra të
          tjera të përcaktuara nga përgjegjësi i sektorit apo eprori direkt.
          Administratori i dokumentacionit zbaton me përgjegjësi regjimin e
          hyrje-daljes të Drejtorisë së Përgjithshme të Arkivave.
        </p>
        <ol>
          <li>
            Mbikëqyr hyrjen dhe daljen e punonjësve nga dera e brendshme e
            institucionit dhe raporton në shkallë hierarkike për shkelje të
            këtij rregulli.
          </li>
          <li>
            Informon qytetarët mbi përcaktimet e rregullores së kërkesave
            social-juridike të qytetarëve dhe kryen detyrimet e punonjësit të
            kontaktit të përcaktuar në të.
          </li>
          <li>
            U dorëzon studiuesve, përkundrejt nënshkrimit të tyre dhe mandatit
            të pagesës, kur kërkohet, përgjigjet e kërkesave për fotokopjim dhe
            digjitalizim të dokumenteve arkivore.
          </li>
          <li>
            Kujdeset me përgjegjësi për hapjen, mbylljen, dyllosjen e vulosjen e
            hyrjes kryesore të godinës në DPA brenda orarit zyrtar.
          </li>
          <li>
            Zbaton detyra të tjera të përcaktuara nga përgjegjësi i sektorit apo
            eprori direkt.
          </li>
        </ol>
      </>
    ),
  },
  {
    path: "",
    title: "",
    image: null,
    name: "",
    content: (
      <>
        <p></p>
        <p></p>
        <p></p>
        <p></p>
      </>
    ),
  },
  {
    path: "/struktura/drejtoria-e-evidences-qendrore-dhe-komunikimit/sektori-i-sherbimit-te-dokumentacionit-ne-distance",
    title: "Sektori i Shërbimit të Dokumentacionit në Distancë",
    image: null,
    name: "",
    content: (
      <>
        <p>
          Përbëhet nga përgjegjësi i sektorit dhe specialistët arkivistë.
          Përgjegjësi i sektorit të shërbimit në distancë kujdeset për shërbimin
          në kohë dhe me eficencë të personave fizikë, privatë, dhe
          institucioneve të ndryshme, duke vënë në shërbim të tyre
          dokumentacionin e kërkuar për të dhëna biografike, pronësore, etj.,
          sipas kërkesave dhe afateve të përcaktuara. Ai organizon dhe drejton
          punën në sektor, në bazë të Ligjit Për Arkivat, dhe akteve të tjera
          nën-ligjore në fuqi, kontrollon dhe monitoron aktivitetet e sektorit
          në përmbushjen e tyre sipas Rregullores për Kërkesat Social-Juridike
          të Qytetarëve, mbikqyr zbatimin e rregullores së brendshme të
          institucionit me qëllim realizimin e veprimtarisë së sektorit, u jep
          zgjidhje kërkesave dhe ankesave të qytetarëve dhe institucioneve,
          verifikon aplikimet e kërkesave social-juridike dhe bën ndarjen e tyre
          për ASHV dhe specialistët e AQSH, shpërndan punën mes specialistëve të
          sektorit, verifikon afatet e përgjigjeve, mban korrespondencë me ASHV
          mbi afatet e përgjigjeve, mban korrespondencë me ofertuesin e jashtëm
          për problematika të hasura, mbikqyr dhe udhëzon specialistët mbi
          detyrat e ngarkuara, propozon ndryshime në rregulloren e kërkesave
          social-juridike, zbaton detyra të tjera, sipas udhëzimeve të eprorit
          direct. Specialisti i trajtimit të kërkesave social-juridike zbaton me
          përpikmëri rregulloren për kërkesat social-juridike të qytetarëve,
          nxjerr indikacionet arkivore nga programi kompjuterik TEP, sipas
          kërkesave të qytetarëve dhe institucioneve, përgatit fletë-porosinë
          për cdo indikacion të nxjerrë ose të porositur, verifikon të dhënat e
          nxjerra me dosjet fizike dhe përgatit fletët përkatëse për fotokopjim,
          dhe printon dokumentet e skanuara/dixhitalizuara, kthen përgjigje të
          kërkesave social juridike me karakter pronësor sipas kërkesave të
          qytetarëve dhe institucioneve, zbaton detyrat e ngarkuara dhe raporton
          problematikat e hasura, zbaton detyra të tjera, sipas udhëzimeve të
          eprorit direct, specialisti IV-a ka këto detyra, plotëson sistemin TEP
          me të dhëna arkivore të reja, sipas rregullores së kërkesave
          social-juridike të qytetarëve, bën skanimin e dërgimin e kërkesave
          social-juridike tek sektorët e ASHV dhe anasjelltas, sipas udhëzimeve
          të përgjegjësit të sektorit, zbaton me përpikmëri rregulloren për
          kërkesat social-juridike të qytetarëve, nxjerr indikacionet arkivore
          nga programi kompjuterik TEP, sipas kërkesave të qytetarëve dhe
          institucioneve, përgatit fletë-porosinë për cdo indikacion të nxjerrë
          ose të porositur, verifikon të dhënat e nxjerra me dosjet fizike dhe
          përgatit fletët përkatëse për fotokopjim, dhe printon dokumentet e
          skanuara/dixhitalizuara, kthen përgjigje të kërkesave social juridike
          me karakter pronësor sipas kërkesave të qytetarëve dhe institucioneve,
          zbaton detyrat e ngarkuara dhe raporton problematikat e hasura, zbaton
          detyra të tjera, sipas udhëzimeve të eprorit direkt.
        </p>
      </>
    ),
  },
  {
    path: "/struktura/drejtoria-e-finances-dhe-sherbimeve-mbeshtetese",
    title: "Drejtoria e Financës dhe Shërbimeve Mbështetëse",
    image: null,
    name: "",
    content: (
      <>
        <p>
          Drejtoria e Financës dhe Shërbimeve Mbështetëse (DFSHM) është
          nënstrukturë e Drejtorisë së Përgjithshme të Arkivave, që menaxhon dhe
          administron me efikasitet burimet njerëzore e financiare të DPA-së për
          të siguruar përmbushjen e misionit të veprimtarisë së saj, si dhe
          siguron që kjo veprimtari të jetë në përputhje me legjislacionin dhe
          politikat institucionale.
        </p>
        <p>
          Drejtoria e Financës dhe Shërbimeve Mbështetëse është e organizuar në
          3 (tre) sektorë:
          <ol>
            <li>sektori i financës dhe buxhetit,</li>
            <li>sektori juridik, prokurimeve dhe burimeve njerëzore,</li>
            <li>sektori i shërbimeve të brendshme.</li>
          </ol>
        </p>
        <p>
          Gjithashtu koordinon veprimtarinë e kësaj strukture me strukturat e
          tjera të Drejtorisë së Përgjithshme të Arkivave dhe ndërmjet sektorëve
          që e përbëjnë dhe merr pjesë në përgatitjen e projekt-rregulloreve,
          manualeve, për të gjitha nivelet e Drejtorisë së Përgjithshme të
          Arkivave, mbas konsultimeve në bazë e më gjerë.
        </p>
        <p>
          Drejtori i Drejtorisë së Financës dhe Shërbimeve Mbështetëse ka këto
          detyra ushtron përgjegjësinë e drejtimit operativ të drejtorisë,
          bashkërendon punën ndërmjet sektorëve të kësaj drejtorie dhe ndihmon
          në funksionimin e efektshëm të tyre, miraton paraprakisht planet
          vjetore dhe mujore të sektorëve që përbëjnë DFSHM-në, bën ndryshimet e
          nevojshme në përputhje me detyrat e përgjithshme dhe prioritetet e
          kësaj drejtorie dhe ia paraqet për miratim Drejtorit të Përgjithshëm
          të Arkivave, propozon marrjen e masave për plotësimin e nevojave të
          DPA-së lidhur me burimet njerëzore, mbikëqyr realizimin e veprimtarive
          financiare, si dhe hartimin e pasqyrave financiare vjetore, rakordimin
          dhe përgatitjen e situacioneve progresive me degën e thesarit mbi
          shpenzimet dhe të ardhurat, si dhe kontabilizimin dhe likuidimin në
          përputhje me dispozitat përkatëse ligjore, ndjek procedurat e
          prokurimit të fondeve buxhetore, duke zbatuar me rigorozitet ligjin
          “Për prokurimin publik” dhe gjithë legjislacionin në fuqi, kujdeset
          për administrimin, inventarizimin dhe mirëmbajtjen e pasurive të
          paluajtshme të DPA-së, përgjigjet për mirëmbajtjen e mjediseve te
          ruajtjes dhe shërbimit, për sigurimin e pajisjeve, aparaturave,
          makinerive dhe mirëmbajtjen e tyre, kujdeset për funksionimin e
          mjeteve të komunikimit, si dhe përmbushjen e shërbimeve të tjera të
          nevojshme për veprimtarinë e strukturave të DPA-së, koordinon punën
          për shpërndarjen e vlerave materiale në kohë, sipas kërkesave nga
          sektorët, drejtoritë, thërret dhe organizon konsulta për nevoja të
          përmirësimit të mjeteve të kërkimit, të skemave të klasifikimit dhe të
          topografisë fikse në vendruajtje, unifikon praktikat e punës dhe
          harton proceset e punës dhe kryen detyrat e përcaktuara nga ligji për
          eprorin direkt, nivelin e mesëm drejtues, menaxherin e nivelit të
          dytë, nëpunësin zbatues dhe detyrime të tjera të ngarkuara nga
          Drejtori i Përgjithshëm.
        </p>
      </>
    ),
  },
  {
    path: "/struktura/drejtoria-e-finances-dhe-sherbimeve-mbeshtetese/drejtori-i-finances-dhe-sherbimeve-mbeshtetese",
    title: "Drejtori i Financës dhe Shërbimeve Mbështetëse",
    image: ArbenDervishllari,
    name: "Arben Dervishllari",
    content: (
      <>
        <p>
          Ka nisur punë në administratë në ish-Nb Maliq në vitin 1985. Pas
          ndryshimit të sistemit politik ka zhvilluar aktivitetin në sektorin
          publik. Ka një kontribut në pjesëmarrjen dhe ngritjen e lëvizjeve
          politike në pluralizëm. Ka mbuluar pozicione drejtuese si Kryetar i PS
          rrethit Korçë. Ka një eksperiencë drejtuese në intitucionet e
          qeverisjes vendore, si drejtor kabineti e këshilltar bashkie. Ka
          mbajtur vendin e Prefektit të Qarkut. Pas viteve 2000 ka mbajtur
          pozicionin e drejtor Drejtorie në Ministrinë e Mbrojtjes. Ka
          organiziuar dhe ka qenë pjesëmarrës në konferenca me objekt rritjen e
          pjesëmarrjes së qytetarëve në proceset shoqërore, rritjen e cilësisë
          së intitucioneve vendimarrëse në nivel vendor dhe qendror.
        </p>
        <p>
          Nga viti 2014 e vazhdim mban vendin e drejtorit të Drejtorisë së
          Financës dhe Shërbimeve Mbështetëse. Është i martuar dha baba i dy
          fëmijëve.
        </p>
      </>
    ),
  },
  {
    path: "/struktura/drejtoria-e-finances-dhe-sherbimeve-mbeshtetese/sektori-juridik-i-prokurimeve-dhe-burimeve-njerezore",
    title: "Sektori Juridik, i Prokurimeve dhe Burimeve Njerëzore",
    image: null,
    name: "",
    content: (
      <>
        <p>
          Sektori juridik, i prokurimeve dhe shërbimeve të brendshme përbëhet
          nga përgjegjësi i sektorit, specialist-jurist, specialisti i
          prokurimeve dhe specialist i burimeve njerëzore. Pavarësisht detyrave
          të ndara të çdo specialisti, Sektori Juridik, i Prokurimeve dhe
          Burimeve Njerëzore, në tërësi, kryen funksionet e përcaktuara në
          legjislacionin në fuqi, si Njësi e Burimeve Njerëzore, Njësi e
          Prokurimit/Personat përgjegjës për prokurimin, Komisionin e Prokurimit
          me Vlerë të Vogël dhe Struktura Përgjegjëse për Parandalimin e
          Konfliktit të Interesit. Përgjegjësi i sektorit parashikon dhe
          planifikon veprimtari në lidhje me detyrat vjetore të përcaktuara për
          sektorin, me qëllim realizimin në kohë të tyre, si dhe siguron që puna
          të kryhet brenda normave të përgjithshme, në afatet e përcaktuara
          sipas standardeve administrative dhe procedurave teknike, veçanërisht
          në lidhje me nevojat e përmirësimit të mirëmbajtjes, infrastrukturës
          së godinës, të sistemeve hidraulike dhe elektrike, harton, propozon
          për miratim, kontrollon dhe monitoron bazën e të dhënave të vendimeve
          gjyqësore, planin e pjesëmarrjes në proceset gjyqësore të DPA-së,
          përfshi edhe DRASH-ët dhe planin e prokurimeve e ankandeve dhe
          procedurat e praktikën ligjore të zbatimit të tyre, në bashkëpunim me
          titullarët e DRASH-ve, ngre paditë, përgatit prapësimet dhe ndjek
          ecurinë e proceseve gjyqësore, kur palë janë DRASH-të, shpërndan punën
          mes specialistëve të sektorit në përputhje me legjislacionin dhe
          rregulloren e brendshme të institucionit dhe informon Drejtorin e
          Përgjithshëm apo drejtorin e DFSHM-së, jep mendime për bazueshmërinë
          formale-juridike të akteve kontraktuale dy a më shumë palëshe që kanë
          efekte ekonomiko-financiare, pas kërkesës së eprorit dhe përgatit ose
          mbikëqyr kontratat dhe projektmarrëveshjet, shqyrton ligjshmërinë e
          projektakteve normative të Drejtorisë se Përgjithshme të Arkivave në
          përgjithësi, në përputhje me kërkesat që i drejtohen drejtorisë ku bën
          pjesë, mbikëqyr dhe udhëzon specialistët, në lidhje me detyrat e
          ngarkuara dhe jep mbështetje dhe këshillim për problematika të
          ndryshme, kryen detyrat e strukturës përgjegjëse në kuadër të
          legjislacionit për parandalimin e konfliktit të interesave dhe
          deklarimit të pasurisë, mbikëqyr zbatimin e rregullores së brendshme
          të institucionit, me qëllim realizimin e veprimtarisë së sektorit, në
          përputhje me udhëzimet e dhëna dhe informon Drejtorin e DFSHM-së mbi
          mbarëvajtjen e punës apo problematikat e hasura, mbështet kërkesat e
          punonjësve të rinj apo ekzistues në lidhje me mjetet materiale për
          ushtrimin e detyrës, përgatit prezencën ditore dhe mujore të
          punonjësve dhe raporton tek drejtori i DFSHM-së për disiplinën e punës
          në sektor dhe zbaton detyra të tjera të përcaktuara nga Drejtori i
          Përgjithshëm i Arkivave.
        </p>
        <p>
          Specialisti-jurist përfaqëson institucionin ose përgatit autorizimet e
          përfaqësimit për specialistë të tjerë në gjykatat respektive, përgatit
          prapësimet, ngre paditë, ndjek proceset ligjore, në përputhje me
          autorizimin e Drejtorit të Përgjithshëm, merr pjesë në hartimin e
          kontratave që nënshkruan institucioni me palë të treta, pas kërkesës
          së eprorit direkt përditëson kuadrin ligjor me aktet normative më të
          fundit që i përkasin fushës së përgjegjësisë së institucionit dhe
          shpërndan këtë informacion brenda institucionit për zbatim, kryen
          detyrat kolektive të sektorit të parashikuara në pikën 1 të këtij neni
          dhe zbaton detyra të tjera të përcaktuara nga përgjegjësi i sektorit
          apo eprori direkt. Specialisti i prokurimeve dhe shërbimeve harton
          kushtet kryesore teknike të thirrjes së tenderave për prokurimet
          publike e ankandeve dhe organizon procesin e marrjes së ofertave,
          ndjek procedurat e prokurimit, ndjek zbatimin e regjistrit të
          prokurimeve dhe raporton për zbatimin e tij çdo muaj në shkallë
          hierarkike dhe çdo katër muaj në APP, sipas ligjit, organizon procesin
          e shqyrtimit dhe analizimit të ofertave.
        </p>
        <p>
          Harton kriteret sipas ftesës për ofertë të kontratës përfundimtare,
          monitoron realizimin e kontratës, kryen detyrat kolektive të sektorit
          të parashikuara në pikën 1 të këtij neni dhe zbaton detyra të tjera të
          përcaktuara nga përgjegjësi i sektorit apo eprori direkt. Specialisti
          i burimeve njerëzore ndjek dhe zbaton procedurat ligjore për
          menaxhimin e çështjeve të burimeve njerëzore si rekrutimin në
          shërbimin civil, lëvizjen paralele, ngritjen në detyrë etj, mbikëqyr
          procesin e sistemit të vlerësimit të performancës në punë dhe të
          përshkrimit të pozicioneve të punës, siguron mirëmbajtjen, sistemimin
          dhe përditësimin e informacionit në dosjet e personelit sipas
          legjislacionit, administron dosjet duke evidentuar dhe regjistruar
          ndryshimet në karrierë për çdo punonjës, përgatit vërtetimet që
          kërkohen nga punonjësit në lidhje me kualifikimet, emërimet, largimet,
          vjetërsinë në punë etj, administron dhe përditëson regjistrin e
          personelit, hedh të dhëna të mjaftueshme për çdo punonjës për
          pozicionin e tij të punës, përfitimet dhe faktet e tjera që lidhen me
          punësimin, ndjek programin e punës për trajnimin dhe formimin
          profesional të punonjësve të shërbimit civil, ndjek procedurat për
          daljet në pension të punonjësve të institucionit dhe bën veprimet
          përkatëse sipas legjislacionit, përgatit kontratat e punës dhe ndjek
          procedurat ligjore për punonjësit, sipas Kodit të Punës, kryen detyrat
          e anëtarit të strukturës përgjegjëse për sinjalizimin dhe mbrojtjen e
          sinjalizuesve, plotëson dhe përditëson sistemin HRMIS me të dhënat e
          kërkuara sipas përcaktimeve ligjore dhe nënligjore, kryen detyrat
          kolektive të sektorit të parashikuara në pikën 1 të këtij neni dhe
          zbaton detyra të tjera të përcaktuara nga eprori direkt ose Drejtori i
          Përgjithshëm.
        </p>
      </>
    ),
  },
  {
    path: "/struktura/drejtoria-e-finances-dhe-sherbimeve-mbeshtetese/sektori-i-sherbimeve-te-brendshme",
    title: "Sektori i Shërbimeve të Brendshme",
    image: null,
    name: "",
    content: (
      <>
        <p>
          Sektori i shërbimeve të brendshme përbëhet nga specialist, teknik,
          shofer dhe punonjësit e pastrimit. Specialisti i shërbimeve të
          brendshme ndjek në vijimësi mirëmbajtjen dhe gatishmërinë teknike të
          mjeteve të transportit dhe monitoron lëvizjet e tyre dhe përdorimin e
          karburantit, harton dhe ndjek zbatimin e grafikëve të shërbimit e
          pastrimit nga personat e caktuar, ndjek mirëmbajtjen dhe riparimin e
          pajisjeve të zyrave, të higjenës në godinën e DPA-së, bën
          identifikimin, inventarizimin dhe verifikimin e të gjitha pronave të
          paluajtshme të administruara nga DPA, hartimin e dokumentacionit të
          nevojshëm për regjistrimin e tyre në ZRPP-të përkatëse dhe përgatit
          dosjet për çdo prone dhe zbaton detyra të tjera të përcaktuara nga
          eprori direkt ose Drejtori i Përgjithshëm i Arkivave. Punonjësit
          mbështetës të Sektorit, përkatësisht tekniku, shoferët dhe pastruesit,
          kryejnë detyrat e përcaktuara në kontratën individuale të punës, në
          përputhje me planin dhe nën mbikqyrjen e specialistit të shërbimeve.
        </p>
      </>
    ),
  },
  {
    path: "/struktura/drejtoria-e-finances-dhe-sherbimeve-mbeshtetese/sektori-juridik-i-prokurimeve-dhe-bruimeve-njerezore",
    title: "Sektori Juridik, i Prokurimeve dhe Bruimeve Njerëzore",
    image: null,
    name: "",
    content: (
      <>
        <p>
          Sektori juridik, i prokurimeve dhe shërbimeve të brendshme përbëhet
          nga përgjegjësi i sektorit, specialist-jurist, specialisti i
          prokurimeve dhe specialist i burimeve njerëzore. Pavarësisht detyrave
          të ndara të çdo specialisti, Sektori Juridik, i Prokurimeve dhe
          Burimeve Njerëzore, në tërësi, kryen funksionet e përcaktuara në
          legjislacionin në fuqi, si Njësi e Burimeve Njerëzore, Njësi e
          Prokurimit/Personat përgjegjës për prokurimin, Komisionin e Prokurimit
          me Vlerë të Vogël dhe Struktura Përgjegjëse për Parandalimin e
          Konfliktit të Interesit. Përgjegjësi i sektorit parashikon dhe
          planifikon veprimtari në lidhje me detyrat vjetore të përcaktuara për
          sektorin, me qëllim realizimin në kohë të tyre, si dhe siguron që puna
          të kryhet brenda normave të përgjithshme, në afatet e përcaktuara
          sipas standardeve administrative dhe procedurave teknike, veçanërisht
          në lidhje me nevojat e përmirësimit të mirëmbajtjes, infrastrukturës
          së godinës, të sistemeve hidraulike dhe elektrike, harton, propozon
          për miratim, kontrollon dhe monitoron bazën e të dhënave të vendimeve
          gjyqësore, planin e pjesëmarrjes në proceset gjyqësore të DPA-së,
          përfshi edhe DRASH-ët dhe planin e prokurimeve e ankandeve dhe
          procedurat e praktikën ligjore të zbatimit të tyre, në bashkëpunim me
          titullarët e DRASH-ve, ngre paditë, përgatit prapësimet dhe ndjek
          ecurinë e proceseve gjyqësore, kur palë janë DRASH-të, shpërndan punën
          mes specialistëve të sektorit në përputhje me legjislacionin dhe
          rregulloren e brendshme të institucionit dhe informon Drejtorin e
          Përgjithshëm apo drejtorin e DFSHM-së, jep mendime për bazueshmërinë
          formale-juridike të akteve kontraktuale dy a më shumë palëshe që kanë
          efekte ekonomiko-financiare, pas kërkesës së eprorit dhe përgatit ose
          mbikëqyr kontratat dhe projektmarrëveshjet, shqyrton ligjshmërinë e
          projektakteve normative të Drejtorisë se Përgjithshme të Arkivave në
          përgjithësi, në përputhje me kërkesat që i drejtohen drejtorisë ku bën
          pjesë, mbikëqyr dhe udhëzon specialistët, në lidhje me detyrat e
          ngarkuara dhe jep mbështetje dhe këshillim për problematika të
          ndryshme, kryen detyrat e strukturës përgjegjëse në kuadër të
          legjislacionit për parandalimin e konfliktit të interesave dhe
          deklarimit të pasurisë, mbikëqyr zbatimin e rregullores së brendshme
          të institucionit, me qëllim realizimin e veprimtarisë së sektorit, në
          përputhje me udhëzimet e dhëna dhe informon Drejtorin e DFSHM-së mbi
          mbarëvajtjen e punës apo problematikat e hasura, mbështet kërkesat e
          punonjësve të rinj apo ekzistues në lidhje me mjetet materiale për
          ushtrimin e detyrës, përgatit prezencën ditore dhe mujore të
          punonjësve dhe raporton tek drejtori i DFSHM-së për disiplinën e punës
          në sektor dhe zbaton detyra të tjera të përcaktuara nga Drejtori i
          Përgjithshëm i Arkivave.
        </p>
        <p>
          Specialisti-jurist përfaqëson institucionin ose përgatit autorizimet e
          përfaqësimit për specialistë të tjerë në gjykatat respektive, përgatit
          prapësimet, ngre paditë, ndjek proceset ligjore, në përputhje me
          autorizimin e Drejtorit të Përgjithshëm, merr pjesë në hartimin e
          kontratave që nënshkruan institucioni me palë të treta, pas kërkesës
          së eprorit direkt përditëson kuadrin ligjor me aktet normative më të
          fundit që i përkasin fushës së përgjegjësisë së institucionit dhe
          shpërndan këtë informacion brenda institucionit për zbatim, kryen
          detyrat kolektive të sektorit të parashikuara në pikën 1 të këtij neni
          dhe zbaton detyra të tjera të përcaktuara nga përgjegjësi i sektorit
          apo eprori direkt. Specialisti i prokurimeve dhe shërbimeve harton
          kushtet kryesore teknike të thirrjes së tenderave për prokurimet
          publike e ankandeve dhe organizon procesin e marrjes së ofertave,
          ndjek procedurat e prokurimit, ndjek zbatimin e regjistrit të
          prokurimeve dhe raporton për zbatimin e tij çdo muaj në shkallë
          hierarkike dhe çdo katër muaj në APP, sipas ligjit, organizon procesin
          e shqyrtimit dhe analizimit të ofertave.
        </p>
        <p>
          Harton kriteret sipas ftesës për ofertë të kontratës përfundimtare,
          monitoron realizimin e kontratës, kryen detyrat kolektive të sektorit
          të parashikuara në pikën 1 të këtij neni dhe zbaton detyra të tjera të
          përcaktuara nga përgjegjësi i sektorit apo eprori direkt. Specialisti
          i burimeve njerëzore ndjek dhe zbaton procedurat ligjore për
          menaxhimin e çështjeve të burimeve njerëzore si rekrutimin në
          shërbimin civil, lëvizjen paralele, ngritjen në detyrë etj, mbikëqyr
          procesin e sistemit të vlerësimit të performancës në punë dhe të
          përshkrimit të pozicioneve të punës, siguron mirëmbajtjen, sistemimin
          dhe përditësimin e informacionit në dosjet e personelit sipas
          legjislacionit, administron dosjet duke evidentuar dhe regjistruar
          ndryshimet në karrierë për çdo punonjës, përgatit vërtetimet që
          kërkohen nga punonjësit në lidhje me kualifikimet, emërimet, largimet,
          vjetërsinë në punë etj, administron dhe përditëson regjistrin e
          personelit, hedh të dhëna të mjaftueshme për çdo punonjës për
          pozicionin e tij të punës, përfitimet dhe faktet e tjera që lidhen me
          punësimin, ndjek programin e punës për trajnimin dhe formimin
          profesional të punonjësve të shërbimit civil, ndjek procedurat për
          daljet në pension të punonjësve të institucionit dhe bën veprimet
          përkatëse sipas legjislacionit, përgatit kontratat e punës dhe ndjek
          procedurat ligjore për punonjësit, sipas Kodit të Punës, kryen detyrat
          e anëtarit të strukturës përgjegjëse për sinjalizimin dhe mbrojtjen e
          sinjalizuesve, plotëson dhe përditëson sistemin HRMIS me të dhënat e
          kërkuara sipas përcaktimeve ligjore dhe nënligjore, kryen detyrat
          kolektive të sektorit të parashikuara në pikën 1 të këtij neni dhe
          zbaton detyra të tjera të përcaktuara nga eprori direkt ose Drejtori i
          Përgjithshëm. © 2022 Drejtoria e Për
        </p>
      </>
    ),
  },
  {
    path: "/struktura/sektori-i-auditimit-te-brendshem/sektori-i-sekretari-arkivit",
    title: "Sektori i Sekretari/ Arkivit",
    image: null,
    name: "",
    content: (
      <>
        <p>
          Sektori i Sekretari/ Arkivit përbëhet nga përgjegjësi i sektorit dhe
          specialist (arkiv- protokoll). Përgjegjësi i sektorit të sekretari/
          arkivit parashikon dhe planifikon veprimtari në lidhje me detyrat
          vjetore të përcaktuara për sektorin, me qëllim realizimin në kohë të
          tyre, si dhe siguron që puna të kryhet brenda normave të përgjithshme,
          në afatet e përcaktuara sipas standardeve administrative dhe
          procedurave teknike, kontrollon dhe monitoron veprimtaritë e sektorit
          në përmbushjen e tyre sipas plan- evidencave mujore, mbikëqyr
          klasifikimin dhe përpunimin tekniko-shkencor të dokumenteve, ndarjen
          në fond, vit, strukturë, krijimin e njësisë së ruajtjes, organizimin e
          brendshëm dhe përshkrimin e titullit, në përputhje me normat
          tekniko-profesionale dhe metodologjike të shërbimit arkivor në RSH,
          kontrollon përpilimin e inventarëve të fondeve, duke plotësuar
          kërkesat e parashikuara, deri në dorëzim në depo të fondit të
          përpunuar, shpërndan punën mes specialistëve të sektorit në përputhje
          me legjislacionin dhe rregulloren e brendshme të institucionit dhe
          informon Drejtorin e Përgjithshëm, mbikëqyr dhe udhëzon specialistët,
          në lidhje me detyrat e ngarkuara dhe jep mbështetje dhe këshillim për
          problematika të ndryshme, mbikëqyr zbatimin e rregullores së brendshme
          të institucionit, me qëllim realizimin e veprimtarisë së sektorit, në
          përputhje me udhëzimet e dhëna dhe informon Drejtorin e AQSH-së mbi
          mbarëvajtjen e punës apo problematikat e hasura, mbështet kërkesat e
          punonjësve të rinj apo ekzistues në lidhje me mjetet materiale për
          ushtrimin e detyrës, përgatit prezencën ditore dhe mujore të
          punonjësve dhe raporton për disiplinën në punë në sektor, kryen
          detyrat e përcaktuara për përgjegjësin e sekretari-arkivit në Normat
          Tekniko-Profesionale dhe rregulloret njësuese në fuqi, kryen detyrat e
          sekretarit të Komisionit të Ekspertizës së DPA-së, kryen detyrat e
          Koordinatorit për të Drejtën e Informimit dhe të Oficerit të Sigurisë,
          sipas legjislacionit në fuqi për të drejtën e informimit dhe për
          informacionin e klasifikuar “sekret-shtetëror” dhe zbaton detyra të
          tjera të përcaktuara nga Drejtori i Përgjithshëm i Arkivave.
          Specialisti i Arkivit kryen bashkërendon punën me sekretarinë duke
          marë pjesë në mbylljen e dosjeve të hapura sipas pasqyrës emërtuese,
          bën përpunimin tekniko-shkencor dhe ekspertizën e vlerës së ruajtjes
          së dokumentave, organizon punën dhe përgatit për shqyrtim listat e
          vendimit per asgjësimin e dokumentave që kanë plotësuar afatin e
          ruajtjes, bën dorëzimin në sektorin e ruajtjes të dokumentave me
          ruajtje përgjithmonë dhe zbaton detyra të tjera të përcaktuara nga
          eprori direkt ose Drejtori i Përgjithshëm i Arkivave.
        </p>
        <p>
          Specialisti i Sekretarisë kryen pranon, regjistron korespondencat e
          institucioneve i shpërndan dhe I dërgon ato, bën kontrollin dhe
          zbatimin e kërkesave që duhet të përmbushë dokumenti, mirëmban
          administrimin e vulave dhe dokumenteve të insitucionit, Hap dosjet
          sipas pasqyrës emërtuese të çeljes së tyre dhe vendos e sistemon në to
          dokumentet që krijohen gjatë vitit, plotëson kërkesat për shfrytëzim
          operativ të dokumentave nga njësitë përbërëse të institucionit, bën
          mbylljen e dosjeve të çelura sipas pasqyrës emërtuese dhe dorëzimin e
          tyre në arkiv dhe zbaton detyra të tjera të përcaktuara nga eprori
          direkt ose Drejtori i Përgjithshëm i Arkivave.
        </p>
      </>
    ),
  },
  {
    path: "/struktura/drejtoria-e-inspektimit-dhe-trajnimeve",
    title: "Drejtoria e Inspektimit dhe Trajnimeve",
    image: null,
    name: "",
    content: (
      <>
        <p>
          Drejtoria e Inspektimit dhe Trajnimeve (DIT) është nënstrukturë e
          Drejtorisë së Përgjithshme të Arkivave, që organizon dhe ndjek
          zbatueshmërinë metodologjike-profesionale të veprimtarisë arkivore në
          të gjithë vendin, në arkivat shtetërore e ato joshtetërore, përfshi në
          nënstrukturat e DPA-së, në sekretari-arkivat e institucioneve qendrore
          e vendore në veprim, propozon përsosjen e shërbimit për njësi të
          veçanta apo për gjithë rrjetin arkivor kombëtar.
        </p>
        <p>
          Drejtoria e Inspektimit dhe Trajnimeve është e organizuar në 2 (dy)
          sektorë:
          <ol>
            <li>sektori i inspektimit e kontrollit qëndror,</li>
            <li>
              trajnimeve dhe sektori i inspektimit dhe kontrollit rajonal.
            </li>
          </ol>
        </p>
        <p>
          Drejtoria e Inspektimit dhe Trajnimeve koordinon veprimtarinë e kësaj
          strukture me strukturat e tjera të Drejtorisë së Përgjithshme të
          Arkivave dhe ndërmjet sektorëve që e përbëjnë.
        </p>
        <p>
          Gjithashtu inicion përgatitjen e projektrregulloreve, manualeve, për
          të gjitha nivelet e Drejtorisë së Përgjithshme të Arkivave, pas
          konsultimeve në bazë e më gjerë dhe propozon akte ligjore e nënligjore
          për përmirësimin e rrjetit arkivor kombëtar. Drejtori i Drejtorisë së
          Inspektimit dhe Trajnimeve ushtron përgjegjësinë e drejtimit operativ
          të drejtorisë, bashkërendon punën ndërmjet sektorëve të kësaj
          drejtorie dhe ndihmon funksionimin e efektshëm të tyre.
        </p>
        <p>
          Ai miraton paraprakisht planet vjetore dhe mujore të sektorëve që
          përbëjnë DIT, bën ndryshimet e nevojshme në përputhje me detyrat e
          përgjithshme dhe prioritetet e kësaj drejtorie dhe ia paraqet për
          miratim Drejtorit të Përgjithshëm të Arkivave, ndjek dhe nënshkruan
          planet 3-mujore dhe evidencat e realizimit të punës të Arkivit Qendror
          Shtetëror dhe Arkivin Shtetëror Vendor, propozon për shqyrtim te
          Drejtori i Përgjithshëm i Arkivave planin vjetor të inspektimit, në
          përputhje me legjislacionin në fuqi për inspektimet, propozon për
          miratim planin mujor të inspektimit dhe ruan kontakt të vazhdueshëm me
          Inspektoriatin Qendror për detyrat në kuadër të inspektimit, përgatit
          autorizimin e inspektimit, në përputhje dhe në bazë të programit të
          miratuar të inspektimit, propozon shtyrjen e kohëzgjatjes së
          autorizimit, zëvendësimin e inspektorëve, gjatë inspektimit, sipas
          ligjit, thërret dhe organizon konsulta për nevoja të përmirësimit të
          mjeteve të kërkimit, të skemave të klasifikimit dhe të topografisë
          fikse në vendruajtje, unifikon praktikat e punës mes Drejtorinë e
          Arkivave Shtetëror Vendor dhe harton proceset e punës, propozon urdhra
          pune për përmirësimin e drejtimit të veprimtarisë së DIT dhe DPA-së,
          përgjigjet për punën e sektorëve të DIT dhe informon Drejtorin e
          Përgjithshëm të Arkivave për problematikat e hasura dhe zgjidhjet
          konkrete, kryen detyrat e përcaktuara nga ligji për eprorin direkt,
          nivelin e mesëm drejtues, menaxherin e nivelit të dytë dhe detyrime të
          tjera të ngarkuara nga Drejtori i Përgjithshëm. © 2022 D
        </p>
      </>
    ),
  },
  {
    path: "/struktura/drejtoria-e-inspektimit-dhe-trajnimeve/drejtori-i-inspektimit-dhe-trajnimeve",
    title: "Drejtori i Inspektimit dhe Trajnimeve",
    image: EndritMusaj,
    name: "Endrit Musaj",
    content: (
      <>
        <p>
          Përfundon studimet universitare me kohë të plotë 1997 – 2001, në
          Fakultetin e Historisë dhe Filologjisë në Universitetit e Tiranës,
          dega Histori, specialist historie. Nga viti 2004 dhe në vazhdim, i
          punësuar pranë Drejtorisë së Përgjithshme të Arkivave, fillimisht si
          administrator dokumentesh, më pas specialist arkivist, përgjegjës i
          sektorit Audiovizual, i sektorit të Komunikimit, Drejtor i Drejtorisë
          së Menaxhimit, Dokumentacionit dhe Shërbimeve të Publikut dhe
          aktualisht drejtor i Drejtorisë së Inspektimit dhe Trajnimit.
        </p>
        <p>
          Përfundon studimet në sistemin “Master i Nivelit të Dytë” në degën
          Histori, drejtimi “Histori”, në vitin 2009 – 2011. Në përfundim
          diplomohet “Master i Nivelit të Dytë” në Histori. Në vitin 2018 merr
          titullin Doktor Shkencash në degën e Historisë.
        </p>
        <p>
          Njohës shumë i mirë i gjuhës gjermane dhe angleze. Autor i shumë
          botimeve shkencore brenda dhe jashtë vendit. Ka ndjekur të gjitha
          nivelet e specializimit në fushën arkivore të organizuara nga DPA. Ka
          lindur në Tiranë më 29 nëntor 1978.
        </p>
      </>
    ),
  },
  {
    path: "/struktura/drejtoria-e-inspektimit-dhe-trajnimeve/sektori-i-inspektimit-e-kontrollit-qendror-dhe-trajnimeve",
    title: "Sektori i Inspektimit e Kontrollit Qëndror dhe Trajnimeve",
    image: null,
    name: "",
    content: (
      <>
        <p>
          Sektori i inspektimit përbëhet nga inspektorë dhe specialist.
          Inspektori ka përgjegjësi për inspektimin për ligjshmërinë dhe
          standardet metodologjike-profesionale në të gjitha institucionet ku
          krijohen e ruhen dokumente arkivore, sipas ligjit në fuqi “Për
          arkivat”, ndjekjen dhe zbatimin e detyrave të përcaktuara në
          legjislacionin në fuqi për inspektimet, pezullon procese të punës
          arkivore, kur ato kryhen jashtë kërkesave të akteve ligjore e
          nënligjore dhe merr masa administrative dhe sanksione ligjore në
          përputhje të plotë me legjislacionin në fuqi, zgjidh problematikat në
          fushën e arkivave në rang kombëtar, nëpërmjet përmirësimit të akteve
          ligjore dhe nënligjore, procedurave dhe akteve të tjera
          administrative, organizon konsulta e takime në të gjithë rrjetin
          arkivor për mbarëvajtjen e punëve arkivore, ndihmon funksionimin e
          komisioneve të ekspertizës në DPA dhe institucionet qëndrore, zbaton
          detyra të tjera të përcaktuara nga eprori direkt. Specialisti mban
          kontakte pune dhe zyrtare me institucionet që ligji i detyron të
          dorëzojnë si pasuri kombëtare pranë AQSH-së dokumentet e krijuara prej
          tyre, në afatin e parashikuar nga ligji, asiston e kontrollon
          përpunimin e dokumenteve të institucioneve të administratës qendrore,
          që përgatiten për t’u dorëzuar në AQSH, mbikqyr projektet e përpunimit
          tekniko-shkencor të dokumentacionit të gjendur në AQSH, nga punonjësit
          e institucioneve në veprim, në kuadër të marrëveshjeve dypalëshe.
          Përgatit planet e punës, kontrollon zbatimin e tyre, udhëzon dhe
          këshillon zgjidhjen e problematikave gjatë procesit të përpunimit
          tekniko-shkencor deri në dorëzimin në vendruajtje me inventarë dhe
          plotëson detyrimet e tjera që rrjedhin nga marrëveshja dypalëshe me
          institucionet, zbaton detyra të tjera të përcaktuara nga eprori direkt
          ose Drejtori i Përgjithshëm, mban kontakte pune dhe zyrtare me
          institucionet që ligji i detyron të dorëzojnë si pasuri kombëtare
          pranë AQSH-së dokumentet e krijuara prej tyre, në afatin e parashikuar
          nga ligji, Asiston e kontrollon përpunimin e dokumenteve të
          institucioneve të administratës qendrore, që përgatiten për t’u
          dorëzuar në AQSH, organizon, menaxhon dhe zhvillon seminaret për
          kualifikimin e arkivistëve dhe të punonjësve të sekretari-arkivave të
          institucioneve shtetërore, në koordinim me Shkollën Shqiptare të
          Administratës Publike, inspektorët rajonalë dhe lektorët dhe zbaton
          detyra të tjera të përcaktuara nga eprori direkt ose Drejtori i
          Përgjithshëm.
        </p>
      </>
    ),
  },
  {
    path: "/struktura/drejtoria-e-inspektimit-dhe-trajnimeve/sektori-i-inspektimit-dhe-kontrollit-rajonal",
    title: "Sektori i Inspektimit dhe Kontrollit Rajonal",
    image: null,
    name: "",
    content: (
      <>
        <p>
          Sektori i inspektimit dhe kontrollit rajonal përbëhet nga përgjegjës
          sektori dhe Inspektorë. Përgjegjësi i këtij sektori parashikon dhe
          planifikon veprimtari në lidhje me detyrat vjetore të përcaktuara për
          sektorin, me qëllim realizimin në kohë të tyre, si dhe siguron që puna
          të kryhet brenda normave të përgjithshme, në afatet e përcaktuara
          sipas standardeve administrative dhe procedurave teknike, kontrollon
          dhe monitoron veprimtaritë e sektorit në përmbushjen e tyre sipas
          plan- evidencave mujore, shpërndan punën mes specialistëve të sektorit
          në përputhje me legjislacionin dhe rregulloren e brendshme të
          institucionit dhe informon Drejtorin e Përgjithshëm Drejtorin e DIT,
          mbikëqyr dhe udhëzon inspektorët, në lidhje me detyrat e ngarkuara dhe
          jep mbështetje dhe këshillim për problematika të ndryshme, mbikëqyr
          zbatimin e rregullores së brendshme të institucionit, me qëllim
          realizimin e veprimtarisë së sektorit, në përputhje me udhëzimet e
          dhëna dhe informon drejtorin e DIT-së për mbarëvajtjen e punës apo
          problematikat e hasura, mbështet kërkesat e punonjësve të rinj apo
          ekzistues në lidhje me mjetet materiale për ushtrimin e detyrës,
          drejton, kontrollon dhe ndihmon inspektorët për pasurimin e fondeve
          arkivore të ardhur nga institucionet dhe zbaton detyra të tjera të
          përcaktuara nga Drejtori i Përgjithshëm i Arkivave. Inspektorët e
          sektorit të inspektimit dhe kontrollit rajonal bëjnë Inspektimin për
          ligjshmërinë dhe standardet metodologjike-profesionale në të gjitha
          institucionet ku krijohen e ruhen dokumente arkivore, sipas ligjit në
          fuqi “Për arkivat”, ndjekin dhe zbatojnë detyrat e përcaktuara në
          legjislacionin në fuqi për inspektimet, Pezullojnë procese të punës
          arkivore, kur ato kryhen jashtë kërkesave të akteve ligjore e
          nënligjore dhe merr masa administrative dhe sanksione ligjore në
          përputhje të plotë me legjislacionin në fuqi, zgjidhin problematikat
          në fushën e arkivave në rang kombëtar, nëpërmjet përmirësimit të
          akteve ligjore dhe nënligjore, procedurave dhe akteve të tjera
          administrative, Organizojnë konsulta e takime me arkivistët dhe
          titularët e institucioneve të qarkut të tij të veprimit për
          mbarëvajtjen e punëve arkivore, ndihmojnë në funksionimin e
          komisioneve të ekspertizës në institucionet vendore, mbajnë kontakte
          pune dhe zyrtare me institucionet që ligji i detyron të dorëzojnë si
          pasuri kombëtare pranë ASHV-së së qarkut të veprimit të tij dokumentet
          e krijuara prej tyre, në afatin e parashikuar nga ligji, asistojnë dhe
          kontrollojnë përpunimin e dokumenteve të institucioneve të
          administratës vendore, që përgatiten për t’u dorëzuar në ASHV-në
          përkatëse dhe merr në dorëzim dhe ia kalon ASHV-së përkatëse
          dokumentacionin e përpunuar të institucioneve vendore, koordinojnë me
          specialistin përgjegjës për seminaret për zhvillimin e seminareve për
          kualifikimin e arkivistëve dhe të punonjësve të sekretari-arkivave të
          institucioneve shtetërore të qarkut të veprimit të tij dhe zbatojnë
          detyra të tjera të përcaktuara nga eprori direkt.
        </p>
      </>
    ),
  },
  {
    path: "/struktura/drejtori-i-arkivave-shteterore-vendore/drejtori-i-arkivave-shteterore-vendore",
    title: "Drejtori i Arkivave Shtetërore Vendore",
    image: LorencAgalliu,
    name: "Lorenc Agalliu",
    content: (
      <>
        <p>
          Ka përfunduar studimet e larta në degën Histori pranë Fakultetit të
          Historisë dhe Filologjisë në Universitetin e Tiranës (1997-2001). Në
          vitin 2010 ka kryer Master Shkencor në Histori. Në vitin 2016, ka
          mbrojtur titullin Doktor i Shkencave në fushën e Historisë, në
          Universitetin e Tiranës.
        </p>
        <p>
          Që nga viti 2001 punon pranë Drejtorisë e Përgjithshme të Arkivave.
          Gjatë kësaj periudhe ka punuar në sektorë të ndryshëm; 2001-2006
          specialist/arkivist; 2006-2009 Drejtor i Arkivit Shtetëror Vendor
          Durrës; 2009-2019 specialist/arkivist; 2019-2021 Përgjegjës Sektori në
          Sektorin e Përpunimit të Dokumentacionit të ASHV-ve.
        </p>
        <p>
          Ka kryer disa trajnime në fushën e arkivistikës, të organizuara nga
          DPA Tiranë dhe arkivat e vendeve të ndryshme të Evropës.
        </p>
        <p>
          Ka kryer Stazhin Teknik Ndërkombëtar të Arkivave në Arkivin Nacional
          të Parisit, Francë (prill-qershor 2004).
        </p>
        <p>
          Ka kryer Stazhin Teknik Ndërkombëtar të Arkivave në Arkivin Nacional
          të Parisit, Francë (prill-qershor 2004).
        </p>
        <p>
          Ka marrë pjesë në projekte të ndryshme që lidhen me fushën arkivore.
          Pjesëmarrës në zbatimin e Projektit STAR I, Document Management
          System, Protocol and Archives Inventory dhe trajnues në projektin
          STAR-2, “Për trajnimin e punonjësve të Arkivit dhe Protokollit të
          Njësive të Qeverisjes Vendore”.
        </p>
        <p>
          Referues në disa konferenca shkencore kombëtare dhe ndërkombëtare dhe
          autor i një sërë artikujve shkencorë të publikuara në revista
          shkencore kombëtare dhe ndërkombëtare.
        </p>
        <p>
          Autor i monografisë shkencore me titull “Çështja çame gjatë Luftës së
          Dytë Botërore”. Njohës i mirë i gjuhës italiane, franceze dhe angleze.
          Lindur në Berat në vitin 1976.
        </p>
      </>
    ),
  },
  {
    path: "/struktura/drejtori-i-arkivave-shteterore-vendore/sektori-i-arkivave-shteterore-vendore-tirane-dhe-durres",
    title: "Sektori i Arkivave Shtetërore Vendore Tiranë dhe Durrës",
    image: null,
    name: "",
    content: (
      <>
        <p>
          Sektori i Arkivave Shtetërore Vendore Tiranë dhe Durrës është në
          varësi të drejtpërdrejtë të Drejtorit të Arkivave Shtetërorë Vendorë
          dhe përbëhet nga specialistë-arkivistë dhe administrator dokumentesh.
          Specialisti arkivist ka për detyrë të realizoj në kohë kërkesat e
          punonjësve të DPA-së dhe studiuesve me dokumente origjinale, sipas
          rregullores së shfrytëzimit të dokumentacionit, zëvendëson dosjet dhe
          kutitë e dëmtuara sipas metodologjisë arkivore, identifikon dokumentet
          e dëmtuara, administron e mban përgjegjësi për mjediset e vendruajtjes
          dhe mirëmban bazën materiale dhe kancelarike të nevojshme për kryerjen
          normale të funksioneve të sektorit dhe më gjerë, sipas përcaktimeve në
          normat tekniko-profesionale e rregulloret e tjera, bashkëpunon me
          punonjësit e DPA-së për realizimin e veprimtarive të ndryshme të
          institucionit në shkallë kombëtare, merr në dorëzim dokumente me afat
          ruajtjeje përgjithmonë nga institucionet vendore, nëpërmjet
          inspektorëve rajonalë, sipas ligjit për arkivat, bën regjistrimin e
          dokumenteve të dhuruara e dorëzuara, si dhe dokumenteve të ardhura
          jashtë institucionit, harton inventarët e fondeve arkivore të ASHV-së
          përkatëse, zbaton detyra të tjera të përcaktuara nga përgjegjësi i
          sektorit apo eprori direkt. Administratori i dokumenteve ka për detyrë
          të skanoj ose fotokopjoj dokumente origjinale sipas urdhrave të punës
          të studiuesve, të miratuara nga Drejtori i Përgjithshëm dhe kërkesave
          të punonjësve të AQSH-së, që merren me problem pronësie, gjithashtu
          Shpluhurizon kutitë e fondeve arkivore që administrohen në
          vendruajtjen e AQSH-së dhe në Shkozë, merret me plotësimin e
          regjistrave me të dhënat e fondeve të hyra në vendruajtjen e ASHV dhe
          regjistrimin e dosjeve të dhëna për shfrytëzim, me matjet e
          temperaturave dhe lagështirës në mjediset e ruajtjes së dokumenteve në
          ASHV dhe zbaton detyra të tjera të përcaktuara nga përgjegjësi i
          sektorit apo eprori direkt.
        </p>
      </>
    ),
  },
  {
    path: "/struktura/drejtori-i-arkivave-shteterore-vendore/sektori-i-arkivave-shteterore-vendore-shkoder-lezhe-diber-dhe-kukes",
    title:
      "Sektori i Arkivave Shtetërore Vendore Shkodër, Lezhë, Dibër dhe Kukës",
    image: null,
    name: "",
    content: (
      <>
        <p>
          Sektori i Arkivave Shtetërore Vendore Shkodër, Lezhë, Dibër dhe Kukës
          përbëhet nga përgjegjësi i sektorit dhe specialistët. Përgjegjësi i
          sektorit ka këto detyra parashikon dhe planifikon veprimtari në lidhje
          me detyrat vjetore të përcaktuara për sektorin, me qëllim realizimin
          në kohë të tyre, si dhe siguron që puna të kryhet brenda normave të
          përgjithshme, në afatet e përcaktuara sipas standardeve administrative
          dhe procedurave teknike, kontrollon dhe monitoron veprimtaritë e
          sektorit në përmbushjen e tyre sipas plan- evidencave mujore,
          shpërndan punën mes specialistëve të sektorit në përputhje me
          legjislacionin dhe rregulloren e brendshme të institucionit dhe
          informon Drejtorin e Përgjithshëm apo drejtorin e ASHV-ve, mbikëqyr
          dhe udhëzon specialistët, në lidhje me detyrat e ngarkuara dhe jep
          mbështetje dhe këshillim për problematika të ndryshme, mbikëqyr
          zbatimin e rregullores së brendshme të institucionit, me qëllim
          realizimin e veprimtarisë së sektorit, në përputhje me udhëzimet e
          dhëna dhe informon drejtorin e e ASHV-ve për mbarëvajtjen e punës apo
          problematikat e hasura, mbështet kërkesat e punonjësve të rinj apo
          ekzistues në lidhje me mjetet materiale për ushtrimin e detyrës,
          përgatit prezencën ditore dhe mujore të punonjësve dhe raporton tek
          drejtori i ASHV-ve për disiplinën e punës në sektor, drejton,
          kontrollon dhe ndihmon punonjësit për pasurimin e fondeve arkivore të
          ardhur nga institucionet, në koordinim me inspektorët rajonalë,
          përgatit materialet për asgjësim në bazë të vendimeve të Komisionit
          Qendror të Ekspertizës, kontrollon saktësinë e përgjigjeve të
          kërkesave social-juridike të qytetarëve, në përputhje me rregullohet e
          proceseve të punës dhe në bashkërendim me Sektorin e Shërbimit të
          Dokumentacionit në Distancë, dorëzon pranë DFSHM-së faturat për
          pagesë, sa më shpejt, por jo më vonë se 15 ditë nga afati përfundimtar
          i pagesës, i përcaktuar në legjislacionin në fuqi, aktet nënligjore
          ose aktet administrative dhe zbaton detyra të tjera të përcaktuara nga
          Drejtori i Përgjithshëm i Arkivave. Specialisti arkivist realizon në
          kohë kërkesat e punonjësve të DPA-së dhe studiuesve me dokumente
          origjinale, sipas rregullores së shfrytëzimit të dokumentacionit,
          zëvendëson dosjet dhe kutitë e dëmtuara sipas metodologjisë arkivore,
          identifikon dokumentet e dëmtuara, administron e mban përgjegjësi për
          mjediset e vendruajtjes dhe mirëmban bazën materiale dhe kancelarike
          të nevojshme për kryerjen normale të funksioneve të sektorit dhe më
          gjerë, sipas përcaktimeve në normat tekniko-profesionale e rregulloret
          e tjera, bashkëpunon me punonjësit e DPA-së për realizimin e
          veprimtarive të ndryshme të institucionit në shkallë kombëtare, merr
          në dorëzim dokumente me afat ruajtjeje përgjithmonë nga institucionet
          vendore, nëpërmjet inspektorëve rajonalë, sipas ligjit për arkivat,
          bën regjistrim të dokumenteve të dhuruara e dorëzuara, si dhe
          dokumenteve të ardhura jashtë institucionit, harton inventarët e
          fondeve arkivore të ASHV-së përkatëse, zbaton detyra të tjera të
          përcaktuara nga përgjegjësi i sektorit apo eprori direct, skanon ose
          fotokopjon dokumente origjinale sipas urdhrave të punës të studiuesve,
          sipas rregulloreve të proceseve të punës, bën Shpluhurizimin e kutive
          të fondeve arkivore të administruara në vendruajtjen e ASHV-ve, bën
          plotësimin e regjistrave me të dhënat e fondeve të hyra në
          vendruajtjen e ASHV dhe regjistrimin e dosjeve të dhëna për
          shfrytëzim, mat temperature dhe lagështirën në mjediset e ruajtjes së
          dokumenteve në ASHV dhe zbaton detyra të tjera të përcaktuara nga
          përgjegjësi i sektorit apo eprori direkt.
        </p>
      </>
    ),
  },
  {
    path: "/struktura/drejtori-i-arkivave-shteterore-vendore/sektori-i-arkivave-shteterore-vendore-fier-vlore-dhe-gjirokaster",
    title: "Sektori i Arkivave Shtetërore Vendore Fier, Vlorë dhe Gjirokastër",
    image: null,
    name: "",
    content: (
      <>
        <p>
          Sektori i Arkivave Shtetërore Vendore Fier, Vlorë dhe Gjirokastër
          përbëhet nga përgjegjësi i sektorit, specialistët dhe administratorët
          e dokumenteve. Përgjegjësi i sektorit parashikon dhe planifikon
          veprimtari në lidhje me detyrat vjetore të përcaktuara për sektorin,
          me qëllim realizimin në kohë të tyre, si dhe siguron që puna të kryhet
          brenda normave të përgjithshme, në afatet e përcaktuara sipas
          standardeve administrative dhe procedurave teknike, kontrollon dhe
          monitoron veprimtaritë e sektorit në përmbushjen e tyre sipas plan-
          evidencave mujore, shpërndan punën mes specialistëve të sektorit në
          përputhje me legjislacionin dhe rregulloren e brendshme të
          institucionit dhe informon Drejtorin e Përgjithshëm apo drejtorin e
          ASHV-ve, mbikëqyr dhe udhëzon specialistët, në lidhje me detyrat e
          ngarkuara dhe jep mbështetje dhe këshillim për problematika të
          ndryshme, mbikëqyr zbatimin e rregullores së brendshme të
          institucionit me qëllim realizimin e veprimtarisë së sektorit, në
          përputhje me udhëzimet e dhëna dhe informon drejtorin e e ASHV-ve për
          mbarëvajtjen e punës apo problematikat e hasura, mbështet kërkesat e
          punonjësve të rinj apo ekzistues në lidhje me mjetet materiale për
          ushtrimin e detyrës, përgatit prezencën ditore dhe mujore të
          punonjësve dhe raporton tek drejtori i ASHV-ve për disiplinën e punës
          në sektor, drejton, kontrollon dhe ndihmon punonjësit për pasurimin e
          fondeve arkivore të ardhur nga institucionet, në koordinim me
          inspektorët rajonalë, përgatit materialet për asgjësim në bazë të
          vendimeve të Komisionit Qendror të Ekspertizës, kontrollon saktësinë e
          përgjigjeve të kërkesave social-juridike të qytetarëve, në përputhje
          me rregullohet e proceseve të punës dhe në bashkërendim me Sektorin e
          Shërbimit të Dokumentacionit në Distancë, dorëzon pranë DFSHM-së
          faturat për pagesë, sa më shpejt, por jo më vonë se 15 ditë nga afati
          përfundimtar i pagesës, i përcaktuar në legjislacionin në fuqi, aktet
          nënligjore ose aktet administrative, zbaton detyra të tjera të
          përcaktuara nga Drejtori i Përgjithshëm i Arkivave.Specialisti
          arkivist realizon në kohë kërkesat e punonjësve të DPA-së dhe
          studiuesve me dokumente origjinale, sipas rregullores së shfrytëzimit
          të dokumentacionit, zëvendëson dosjet dhe kutitë e dëmtuara sipas
          metodologjisë arkivore, identifikon dokumentet e dëmtuara, administron
          e mban përgjegjësi për mjediset e vendruajtjes dhe mirëmban bazën
          materiale dhe kancelarike të nevojshme për kryerjen normale të
          funksioneve të sektorit dhe më gjerë, sipas përcaktimeve në normat
          tekniko-profesionale e rregulloret e tjera, bashkëpunon me punonjësit
          e DPA-së për realizimin e veprimtarive të ndryshme të institucionit në
          shkallë kombëtare, merr në dorëzim dokumente me afat ruajtjeje
          përgjithmonë nga institucionet vendore, nëpërmjet inspektorëve
          rajonalë, sipas ligjit për arkivat, bën regjistrim të dokumenteve të
          dhuruara e dorëzuara, si dhe dokumenteve të ardhura jashtë
          institucionit, harton inventarët e fondeve arkivore të ASHV-së
          përkatëse dhe zbaton detyra të tjera të përcaktuara nga përgjegjësi i
          sektorit apo eprori direkt. Administratori i dokumenteve skanon ose
          fotokopjon dokumente origjinale sipas urdhrave të punës të studiuesve,
          sipas rregulloreve të proceseve të punës, bën Shpluhurizimin e kutive
          të fondeve arkivore të administruara në vendruajtjen e ASHV-ve, merret
          me plotësimin e regjistrave me të dhënat e fondeve të hyra në
          vendruajtjen e ASHV dhe regjistrimin e dosjeve të dhëna për
          shfrytëzim, bën matjet e temperaturave dhe lagështirës në mjediset e
          ruajtjes së dokumenteve në ASHVdhe zbaton detyra të tjera të
          përcaktuara nga përgjegjësi i sektorit apo eprori direkt.
        </p>
      </>
    ),
  },
  {
    path: "/struktura/drejtori-i-arkivave-shteterore-vendore/sektori-i-arkivave-shteterore-vendore-elbasan-berat-dhe-korce",
    title: "Sektori i Arkivave Shtetërore Vendore Elbasan, Berat dhe Korçë",
    image: null,
    name: "",
    content: (
      <>
        <p>
          Sektori i Arkivave Shtetërore Vendore Elbasan, Berat dhe Korçë
          përbëhet nga përgjegjësi i sektorit, specialistët dhe administratorët
          e dokumenteve. Ky sektor parashikon dhe planifikon veprimtari në
          lidhje me detyrat vjetore të përcaktuara për sektorin, me qëllim
          realizimin në kohë të tyre, si dhe siguron që puna të kryhet brenda
          normave të përgjithshme, në afatet e përcaktuara sipas standardeve
          administrative dhe procedurave teknike. Kontrollon dhe monitoron
          veprimtaritë e sektorit në përmbushjen e tyre sipas plan- evidencave
          mujore, Përgjegjësi i sektorit shpërndan punën mes specialistëve të
          sektorit në përputhje me legjislacionin dhe rregulloren e brendshme të
          institucionit dhe informon Drejtorin e Përgjithshëm apo drejtorin e
          ASHV-ve. Mbikëqyr dhe udhëzon specialistët, në lidhje me detyrat e
          ngarkuara dhe jep mbështetje dhe këshillim për problematika të
          ndryshme, mbikëqyr zbatimin e rregullores së brendshme të
          institucionit, me qëllim realizimin e veprimtarisë së sektorit, në
          përputhje me udhëzimet e dhëna dhe informon drejtorin e e ASHV-ve për
          mbarëvajtjen e punës apo problematikat e hasura, mbështet kërkesat e
          punonjësve të rinj apo ekzistues në lidhje me mjetet materiale për
          ushtrimin e detyrës, përgatit prezencën ditore dhe mujore të
          punonjësve dhe raporton tek drejtori i e ASHV-ve për disiplinën e
          punës në sektor, drejton, kontrollon dhe ndihmon punonjësit për
          pasurimin e fondeve arkivore të ardhur nga institucionet, në koordinim
          me inspektorët rajonalë, përgatit materialet për asgjësim në bazë të
          vendimeve të Komisionit Qendror të Ekspertizës, kontrollon saktësinë e
          përgjigjeve të kërkesave social-juridike të qytetarëve, në përputhje
          me rregullohet e proceseve të punës dhe në bashkërendim me Sektorin e
          Shërbimit të Dokumentacionit në Distancë, dorëzon pranë DFSHM-së
          faturat për pagesë, sa më shpejt, por jo më vonë se 15 ditë nga afati
          përfundimtar i pagesës, i përcaktuar në legjislacionin në fuqi, aktet
          nënligjore ose aktet administrative dhe zbaton detyra të tjera të
          përcaktuara nga Drejtori i Përgjithshëm i Arkivave, ndërsa
          specialisti-arkivist ka për detyrë të realizoj në kohë kërkesat e
          punonjësve të DPA-së dhe studiuesve me dokumente origjinale, sipas
          rregullores së shfrytëzimit të dokumentacionit, zëvendëson dosjet dhe
          kutitë e dëmtuara sipas metodologjisë arkivore, identifikon dokumentet
          e dëmtuara, administron e mban përgjegjësi për mjediset e vendruajtjes
          dhe mirëmban bazën materiale dhe kancelarike të nevojshme për kryerjen
          normale të funksioneve të sektorit dhe më gjerë, sipas përcaktimeve në
          normat tekniko-profesionale e rregulloret e tjera, bashkëpunon me
          punonjësit e DPA-së për realizimin e veprimtarive të ndryshme të
          institucionit në shkallë kombëtare, merr në dorëzim dokumente me afat
          ruajtjeje përgjithmonë nga institucionet vendore, nëpërmjet
          inspektorëve rajonalë, sipas ligjit për arkivat, bën regjistrimin e
          dokumenteve të dhuruara e dorëzuara, si dhe dokumenteve të ardhura
          jashtë institucionit, harton inventarët e fondeve arkivore të ASHV-së
          përkatëse si dhe zbaton detyra të tjera të përcaktuara nga përgjegjësi
          i sektorit apo eprori direkt. Administratori i dokumenteve skanon ose
          fotokopjon dokumente origjinale sipas urdhrave të punës të studiuesve,
          sipas rregulloreve të proceseve të punës, bën shpluhurizimin e kutive
          të fondeve arkivore të administruara në vendruajtjen e ASHV-ve,
          plotësimin e regjistrave me të dhënat e fondeve të hyra në
          vendruajtjen e ASHV dhe regjistrimin e dosjeve të dhëna për
          shfrytëzim, Mat temperature dhe lagështirën në mjediset e ruajtjes së
          dokumenteve në ASHV si dhe zbaton detyra të tjera të përcaktuara nga
          përgjegjësi i sektorit apo eprori direkt
        </p>
      </>
    ),
  },
  {
    path: "/struktura/drejtoria-e-evidences-digjitalizimit-dhe-restaurimit",
    title: "Drejtoria e Evidencës, Digjitalizimit dhe Restaurimit",
    image: null,
    name: "",
    content: (
      <>
        <p>
          Drejtoria e Evidencës, Digjitalizimit dhe Restaurimit (DEDR) është
          nënstrukturë e Drejtorisë së Përgjithshme të Arkivave, që ka
          përgjegjësi mbi evidencimin e fondit arkivor të AQSH-së dhe ASHV-ve,
          digjitalizimin, inovacionin, mbështetjen teknologjike dhe restaurimin
          e dokumentave të dëmtuara.
        </p>
        <p>
          Drejtoria e Evidencës, Digjitalizimit dhe Restaurimit (DEDR) është e
          organizuar në 4 (katër) sektorë:
          <ol>
            <li>Sektori i Evidencës Qendrore</li>
            <li>Sektori i Digjitalizimit dhe Mikrofilmit,</li>
            <li>Sektori i Mjeteve të Informimit,</li>
            <li>Laboratori Qendror i Restaurimit,</li>
          </ol>
        </p>
        <p>
          Drejtoria e Evidencës, Digjitalizimit dhe Restaurimit koordinon
          veprimtarinë e kësaj strukture me strukturat e tjera të Drejtorisë së
          Përgjithshme të Arkivave dhe ndërmjet sektorëve që e përbëjnë. Merr
          pjesë në përgatitjen e projekt-rregulloreve, manualeve, për të gjitha
          nivelet e Drejtorisë së Përgjithshme të Arkivave, pas konsultimeve në
          bazë e më gjerë.
        </p>
        <p>
          Drejtori i Drejtorisë së Evidencës, Digjitalizimit dhe Restaurimit
          ushtron përgjegjësinë e drejtimit operativ të drejtorisë, bashkërendon
          punën ndërmjet sektorëve të kësaj drejtorie dhe ndihmon funksionimin e
          efektshëm të tyre, miraton paraprakisht planet vjetore dhe mujore të
          sektorëve që përbëjnë DEDR, bën ndryshimet e nevojshme në përputhje me
          detyrat e përgjithshme dhe prioritetet e kësaj drejtorie dhe ia
          paraqet për miratim Drejtorit të Përgjithshëm të Arkivave, thërret dhe
          organizon konsulta për nevoja të përmirësimit të mjeteve të kërkimit,
          të skemave të klasifikimit dhe të topografisë fikse në vendruajtje,
          unifikon praktikat e punës dhe harton proceset e punës në DEDR,
          propozon urdhra pune për përmirësimin e drejtimit të veprimtarisë së
          DEDR-së, përgjigjet për punën e sektorëve të DEDR-së dhe informon
          Drejtorin e Përgjithshëm të Arkivave për problematikat e hasura dhe
          zgjidhjet konkrete, kryen detyrat e përcaktuara nga ligji për eprorin
          direkt, nivelin e mesëm drejtues, menaxherin e nivelit të dytë dhe
          detyrime të tjera të ngarkuara nga Drejtori i Përgjithshëm.
        </p>
      </>
    ),
  },
  {
    path: "/struktura/drejtoria-e-evidences-qendrore-dhe-komunikimit/sektori-i-evidences-qendrore",
    title: "Sektori i Evidencës Qendrore",
    image: null,
    name: "",
    content: (
      <>
        <p>
          Sektori i evidences qendrore përbëhet nga përgjegjës sektori,
          specialist (arkivist), administrator dokumentash.
        </p>
        <p>
          Përgjegjësi i sektorit të evidencës qëndrore parashikon dhe
          planifikonveprimtari në lidhje meSektori i evidences qendrore përbëhet
          nga përgjegjës sektori, specialist (arkivist), administrator
          dokumentash.Përgjegjësi i sektorit të evidencës qëndrore parashikon
          dhe planifikon veprimtari në lidhje me detyrat vjetore të përcaktuara
          për sektorin, me qëllim realizimin në kohë të tyre, si dhe siguron që
          puna të kryhet brenda normave të përgjithshme, në afatet e përcaktuara
          sipas standardeve administrative dhe procedurave teknike, kontrollon
          dhe monitoron veprimtaritë e sektorit në përmbushjen e tyre sipas
          plan- evidencave mujore, shpërndan punën mes specialistëve të sektorit
          në përputhje me legjislacionin dhe rregulloren e brendshme të
          institucionit dhe informon Drejtorin e Përgjithshëm apo drejtorin e
          DEDR-së, mbikëqyr dhe udhëzon specialistët, në lidhje me detyrat e
          ngarkuara dhe jep mbështetje dhe këshillim për problematika të
          ndryshme, mbikëqyr zbatimin e rregullores së brendshme të
          institucionit, me qëllim realizimin e veprimtarisë së sektorit, në
          përputhje me udhëzimet e dhëna dhe informon drejtorin e DEDR-së për
          mbarëvajtjen e punës apo problematikat e hasura, mbështet kërkesat e
          punonjësve të rinj apo ekzistues në lidhje me mjetet materiale për
          ushtrimin e detyrës, përgatit prezencën ditore dhe mujore të
          punonjësve dhe raporton tek drejtori i DEDR-së për disiplinën e punës
          në sektor, kontrollon procesin e evidencimit fizik dokumenteve, sipas
          rregullores së procesit të punës, zbaton detyra të tjera të
          përcaktuara nga eprori direkt dhe Drejtori i Përgjithshëm i Arkivave.
          Specialistët e evidencës qendrore bëjnë regjistrimin e fondeve bazë e
          shtesave në regjistrin fizik dhe elektronik të evidencës, i cili
          përfshin kontrollin, verifikimin, korrigjimin ortografik të gabimeve
          teknike dhe rakordon dy regjistrat, administrojnë dhe përmirësojnë
          mjetet e informacionit dhe të kërkimit, për t’u shërbyer
          studiuesve.Ofrojnë ndihmë me mjetet e evidencës për sektorët e AQSH-së
          për zgjidhjen e problemeve që dalin gjatë proceseve të punës, Bëjnë
          kontrollin e vazhdueshëm të emërtesave të strukturave të fondeve dhe
          rekomandon ndreqjen e gabimeve, bëjnë verifikimin fizik të njësive të
          ruajtjes si dhe unifikimin e tyre me inventarin përkatës, bëjnë
          plotësimin e të metave të elementeve të inventarëve, si titullin e
          njësive të ruajtjes, vitin, datat ekstreme, sasinë e fletëve,
          plotësimin e etiketave e ndërrimin e kutive dhe unifikimin e tyre,
          Plotësojnë titujt e dosjeve me elementet formale në rast të mungesave,
          merren me evidentimin dhe propozimin e zgjidhjes për mangësitë e
          konstatuara në sasinë e fletëve të dosjeve apo në përmbajtjen e tyre
          nga AQSH-ja, bëjnë ndarjen e procesverbaleve të dorëzimit të
          dokumenteve sipas fondeve, bëjnë zëvendësimin e regjistrave të vjetër
          me të rinj me të gjitha mekanizmat e duhur për lehtësi përdorimi duke
          i verifikuar e plotësuar me origjinalin, Dhënie për shfrytëzim të
          inventarëve të njësive të ruajtjes, bëjnë bashkimin e shtesave të
          fondeve me bazat në një inventor, verifikojnë njësitë e ruajtjes që
          rezultojnë me numër të njëjtë dhe kalimin e kësaj njësie si shtesë
          fondi. Unifikojnë inventarët e fondeve që ndodhen në sallën e studimit
          me ato të evidences, dërgojnë në laboratorin e restaurimit për
          restaurim të dokumenteve, nëse është e nevojshme dhe zbatojnë detyra
          të tjera të përcaktuara nga drejtori i Evidencës Qendrore,
          Digjitalizimit dhe Restaurimit. detyrat vjetore të përcaktuara për
          sektorin, me qëllimrealizimin në kohë të tyre, si dhe siguron që puna
          të kryhet brenda normave tëpërgjithshme, në afatet e përcaktuara sipas
          standardeve administrative dheprocedurave teknike, kontrollon dhe
          monitoron veprimtaritë e sektorit nëpërmbushjen e tyre sipas plan-
          evidencave mujore, shpërndan punën messpecialistëve të sektorit në
          përputhje me legjislacionin dhe rregulloren ebrendshme të
          institucionit dhe informon Drejtorin e Përgjithshëm apo drejtorine
          DEDR-së, mbikëqyr dhe udhëzon specialistët, në lidhje me detyrat e
          ngarkuaradhe jep mbështetje dhe këshillim për problematika të
          ndryshme, mbikëqyrzbatimin e rregullores së brendshme të
          institucionit, me qëllim realizimin eveprimtarisë së sektorit, në
          përputhje me udhëzimet e dhëna dhe informondrejtorin e DEDR-së për
          mbarëvajtjen e punës apo problematikat e hasura,mbështet kërkesat e
          punonjësve të rinj apo ekzistues në lidhje me mjetetmateriale për
          ushtrimin e detyrës, përgatit prezencën ditore dhe mujore tëpunonjësve
          dhe raporton tek drejtori i DEDR-së për disiplinën e punës nësektor,
          kontrollon procesin e evidencimit fizik dokumenteve, sipas
          rregulloressë procesit të punës, zbaton detyra të tjera të përcaktuara
          nga eprori direktdhe Drejtori i Përgjithshëm i Arkivave. Specialistët
          e evidencës qendrore bëjnëregjistrimin e fondeve bazë e shtesave në
          regjistrin fizik dhe elektronik tëevidencës, i cili përfshin
          kontrollin, verifikimin, korrigjimin ortografik tëgabimeve teknike dhe
          rakordon dy regjistrat, administrojnë dhe përmirësojnëmjetet e
          informacionit dhe të kërkimit, për t’u shërbyer studiuesve.
        </p>
        <p>
          Ofrojnë ndihmë me mjetet e evidencës për sektorët e AQSH-së për
          zgjidhjen eproblemeve që dalin gjatë proceseve të punës, Bëjnë
          kontrollin e vazhdueshëm tëemërtesave të strukturave të fondeve dhe
          rekomandon ndreqjen e gabimeve, bëjnëverifikimin fizik të njësive të
          ruajtjes si dhe unifikimin e tyre me inventarinpërkatës, bëjnë
          plotësimin e të metave të elementeve të inventarëve, sititullin e
          njësive të ruajtjes, vitin, datat ekstreme, sasinë e
          fletëve,plotësimin e etiketave e ndërrimin e kutive dhe unifikimin e
          tyre, Plotësojnëtitujt e dosjeve me elementet formale në rast të
          mungesave, merren meevidentimin dhe propozimin e zgjidhjes për
          mangësitë e konstatuara në sasinë efletëve të dosjeve apo në
          përmbajtjen e tyre nga AQSH-ja, bëjnë ndarjen eprocesverbaleve të
          dorëzimit të dokumenteve sipas fondeve, bëjnë zëvendësimin
          eregjistrave të vjetër me të rinj me të gjitha mekanizmat e duhur për
          lehtësipërdorimi duke i verifikuar e plotësuar me origjinalin, Dhënie
          për shfrytëzim tëinventarëve të njësive të ruajtjes, bëjnë bashkimin e
          shtesave të fondeve mebazat në një inventor, verifikojnë njësitë e
          ruajtjes që rezultojnë me numër tënjëjtë dhe kalimin e kësaj njësie si
          shtesë fondi. Unifikojnë inventarët efondeve që ndodhen në sallën e
          studimit me ato të evidences, dërgojnë nëlaboratorin e restaurimit për
          restaurim të dokumenteve, nëse është e nevojshmedhe zbatojnë detyra të
          tjera të përcaktuara nga drejtori i Evidencës Qendrore,Digjitalizimit
          dhe Restaurimit.
        </p>
      </>
    ),
  },
  {
    path: "/struktura/drejtoria-e-evidences-digjitalizimit-dhe-restaurimit/sektori-i-mjeteve-te-informimit",
    title: "Sektori i Mjeteve të Informimit",
    image: null,
    name: "",
    content: (
      <>
        <p>
          Sektori i Mjeteve të informimit, përbëhet nga përgjegjësi i sektorit
          dhe specialistë arkivistë. 1. Përgjegjësi i sektorit të Mjeteve të
          Informimit është përgjegjës për realizimin e detyrave të sektorit
          përshkrimin arkivor të dokumentacionit, si dhe sugjerimin e teknikave
          të përshtatshme për evidentimin e elementeve për përshkrim arkivor. Ai
          kryen këto detyra:a. Parashikon dhe planifikon veprimtari në lidhje me
          detyrat vjetore të përcaktuara për sektorin, me qëllim realizimin në
          kohë të tyre, si dhe siguron që puna të kryhet brenda normave të
          përgjithshme, në afatet e përcaktuara sipas standardeve administrative
          dhe procedurave teknike.b. Kontrollon dhe monitoron veprimtaritë e
          sektorit në përmbushjen e tyre, sipas plan evidencave mujore.c.
          Mbikëqyr përshkrimin arkivore dhe elektronik, në përputhje me
          standardin ndërkombëtar ISAD (G), RIC dhe standarde të tjera.d.
          Shpërndan punën mes specialistëve të sektorit në përputhje me
          legjislacionin dhe rregulloren e brendshme të institucionit dhe
          informon drejtorin e EDR-së.e. Mbikëqyr dhe udhëzon specialistët, në
          lidhje me detyrat e ngarkuara dhe jep mbështetje e këshillim për
          problematika të ndryshme.f. Mbikëqyr zbatimin e rregullores së
          brendshme të institucionit, me qëllim realizimin e veprimtarisë së
          sektorit, në përputhje me udhëzimet e dhëna dhe informon drejtorin
          eEDR-së mbi mbarëvajtjen e punës apo problematikat e hasura.g.
          Mbështet kërkesat e punonjësve të rinj apo ekzistues në lidhje me
          mjetet materiale për ushtrimin e detyrës.. Përgatit prezencën mujore
          të punonjësve dhe raporton ke drejtori i EDR-së përhdisiplinën e punës
          në sektor.i. Krijimin e mjeteve të informacionit të fondeve që ka në
          administrim sektori.j. Parashikimin dhe përzgjedhjen e fondeve për
          përshkrim.k. Zbaton detyra të tjera të përcaktuara nga drejtori i
          Përgjithshëm i Arkivave.2. Specialist-arkivisti IV-B i sektorit të
          përpunimit dhe mjeteve të informimit ka për detyrë:a. Përshkrimin
          teknik të dokumenteve sipas normave tekniko profesionale në
          gjuhënshqipe dhe në atë gjuhë të huaj që zotëron.b. Përshkrimin
          (elektronik) arkivor të dokumentit, sipas standardit ndërkombëtar
          ISAD(G) dhe standardeve të tjera për dokumentacionin në gjuhën shqipe
          dhe në atë gjuhë të huaj që zotëron në përputhje me rregulloren
          përkatëse.c. Krijimin e mjeteve të tjera të informacionit të fondeve
          që ka në administrim sektori. d. Zbaton detyra të tjera të përcaktuara
          nga përgjegjësi i sektorit apo eprori direkt.
        </p>
      </>
    ),
  },
  {
    path: "/struktura/drejtoria-e-evidences-digjitalizimit-dhe-restaurimit/sektori-i-digjitalizimit-dhe-mikrofilmit",
    title: "Sektori i Digjitalizimit dhe Mikrofilmit",
    image: null,
    name: "",
    content: (
      <>
        <p>
          Sektori i digjitalizimit përbëhet nga përgjegjës sektori,
          laborantmikrofilmues, administrator dokumentash.
        </p>
        <p>
          Përgjegjësi i digjitalzimit parashikon dhe planifikon veprimtari në
          lidhje medetyrat vjetore të përcaktuara për sektorin, me qëllim
          realizimin në kohë tëtyre, si dhe siguron që puna të kryhet brenda
          normave të përgjithshme, nëafatet e përcaktuara sipas standardeve
          administrative dhe procedurave teknike,kontrollon dhe monitoron
          veprimtaritë e sektorit në përmbushjen e tyre sipasplan- evidencave
          mujore, shpërndan punën mes specialistëve të sektorit nëpërputhje me
          legjislacionin dhe rregulloren e brendshme të institucionit
          dheinformon Drejtorin e Përgjithshëm apo drejtorin e DEDR-së, mbikëqyr
          dhe udhëzonspecialistët, në lidhje me detyrat e ngarkuara dhe jep
          mbështetje dhe këshillimpër problematika të ndryshme, mbikëqyr
          zbatimin e rregullores së brendshme tëinstitucionit, me qëllim
          realizimin e veprimtarisë së sektorit, në përputhje meudhëzimet e
          dhëna dhe informon drejtorin e DEQK-së për mbarëvajtjen e punës
          apoproblematikat e hasura, mbështet kërkesat e punonjësve të rinj apo
          ekzistues nëlidhje me mjetet materiale për ushtrimin e detyrës,
          përgatit prezencën mujoretë punonjësve dhe raporton tek drejtori i
          DEDR-së për disiplinën e punës nësektor, kontrollon procesin e
          digjtializimit të dokumenteve, sipas rregulloressë procesit të punës,
          zbaton detyra të tjera të përcaktuara nga eprori direktdhe Drejtori i
          Përgjithshëm i Arkivave.
        </p>
        <p>
          Punonjësi i digjitalizimit bën kontrollin e cilësisë së procesit
          tëdigjitalizimit, sipas rregullores së brendshme të institucionit,
          merret mekëshillimin, trajnimin dhe zgjidhjen e problemeve teknike që
          dalin mbipërmbajtjen në sistemin elektronik, komunikon me skanuesit
          për çështje tëkarakterit teknik dhe propozimin e zgjidhjeve dhe zbaton
          detyra të tjera tëpërcaktuara nga përgjegjësi i sektorit apo eprori
          direkt.
        </p>
        <p>
          Laboranti i mikrofilmimit ka bën tërheqjen e materialit për mikrofilm
          ngavendruajtja, duke e marrë në dorëzim dosje për dosje, fotokopjon
          kapakët edosjeve, verifikon dokumentet brenda dosjes fletë për fletë,
          mikrofilmoni dokumentetduke marrë referencat arkivore.{" "}
        </p>
        <p>
          Bën larjen e bobinave të filmit të krijuara nga mikrofilmimi,
          kontrolloncilësinë e filmit, kontrollon dhe përgatit pajisjen e
          skanimit, bën skanimin edokumenteve të filmuara duke përdorur
          referencat që janë dhënë gjatëmikrofilmimit, bën krijimin e kopjes së
          sigurisë (“backup”-it) dheshfrytëzimit, mirëmban pajisjet që ka në
          ngarkim, zbaton detyra të tjera tëpërcaktuara nga përgjegjësi i
          sektorit apo eprori direkt. Administratori idokumentave bën
          evidencimin elektronik të dokumenteve të skanuara, sipasrregullores së
          procesit të punës, kontrollin e cilësisë së materialeve tëskanuara,
          plotësimin e të metave të elementeve të katalogut elektronik,
          sititullin e njësive të ruajtjes, vitin, datat ekstreme, sasinë e
          fletëve, etj,krijimin e një kopjeje të sigurisë, mirëmbajtjen e
          pajisjeve që ka në ngarkimdhe zbaton detyra të tjera të përcaktuara
          nga përgjegjësi i sektorit apo eproridirekt.
        </p>
      </>
    ),
  },
  {
    path: "/struktura/drejtoria-e-evidences-digjitalizimit-dhe-restaurimit/sektori-laboratori-qendror-i-restaurimit",
    title: "Sektori (Laboratori) Qëndror i Restaurimit",
    image: null,
    name: "",
    content: (
      <>
        <p>
          Sektori (Laboratori) Qëndror i Restaurimit përbëhet nga përgjegjësi i
          sektorit, specialist dhe restaurator dokumenta. Përgjegjësi i Sektorit
          (Laboratorit) Qëndror të Restaurimit parashikon dhe planifikon
          veprimtari në lidhje me detyrat vjetore të përcaktuara për sektorin,
          me qëllim realizimin në kohë të tyre, si dhe siguron që puna të kryhet
          brenda normave të përgjithshme, në afatet e përcaktuara sipas
          standardeve administrative dhe procedurave teknike, kontrollon dhe
          monitoron veprimtaritë e sektorit në përmbushjen e tyre sipas plan-
          evidencave mujore, shpërndan punën mes specialistëve të sektorit në
          përputhje me legjislacionin dhe rregulloren e brendshme të
          institucionit dhe informon Drejtorin e Përgjithshëm, mbikëqyr dhe
          udhëzon specialistët, në lidhje me detyrat e ngarkuara dhe jep
          mbështetje dhe këshillim për problematika të ndryshme, mbikëqyr
          zbatimin e rregullores së brendshme të institucionit, me qëllim
          realizimin e veprimtarisë së sektorit, në përputhje me udhëzimet e
          dhëna dhe informon drejtorin e përgjithshëm për mbarëvajtjen e punës
          apo problematikat e hasura, mbështet kërkesat e punonjësve të rinj apo
          ekzistues në lidhje me mjetet materiale për ushtrimin e detyrës,
          përgatit prezencën ditore dhe mujore të punonjësve dhe raporton tek
          drejtori i përgjithshëm për disiplinën e punës në sektor, harton
          planin e punës mujor dhe punon për zbatimin e tij pas miratimit në
          shkallë hierarkike, si dhe raporton te eprori direkt për problematika
          të hasura gjatë procesit të punës dhe zbaton detyra të tjera të
          përcaktuara nga Drejtori i Përgjithshëm i Arkivave. Specialisti në
          Laboratorin Kimik në AQSH kryen verifikimin fizik të dokumenteve që
          merren në laborator dhe pajisjen me fletë vërtetimi për dosjet, bëjnë
          evidentimin e dokumentave të dëmtuara duke i klasifikuar sipas shkakut
          të dëmtimit, bëjnë pastrimin e dokumenteve nga të gjitha mbishtresat e
          huaja që sjanë pjesë e dokumentit, bëjnë shplurëzimin fizik të
          dokumenteve që trajtohen, trajtimin kimik antimykotik, trajtimin kimik
          të dokumenteve duke kryer procesin e deacifikimit, bën lidhjen e
          mjeteve të informacionit (inventarëve), bashkëpunon me punonjësit e të
          gjithë sektorëve për evidentimin e dokumenteve të dëmtuara dhe zbaton
          detyra të tjera të përcaktuara nga eprori direct dhe Drejtori i
          Përgjithshëm i Arkivave. Restauratori restauron okumentet me shkallë
          të lartë dëmtimi, bën kollaritjen e dokumentit me metilcelulozë për
          vitalizimin e tij, bën shtrirjen e dokumentit me presë me metodën e
          lagështimit duke e vendosur çdo dokument midis filtrave dhe më pas në
          presë, Arnon dokumentet me plotësimin e pjesëve të dëmtuara me letër
          restaurimi, Përforcon dokumentet me letër japoneze mbas procesit të
          arnimit, bën laminim të dokumenteve duke bërë plastifikimin me bifiks
          dhe sereks, në rastet kur dokumenti është në shkallën më të lartë të
          dëmtimit dhe zbaton detyra të tjera të përcaktuara nga eprori direkt
          dhe Drejtori i Përgjithshëm i Arkivave.
        </p>
      </>
    ),
  },
];

import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import "../manage.css";
import axios from "axios";

import {
  setObjektOnEdit,
  updateObjekt,
} from "../../Redux/Actions/objekteMuzealeActions";

const EditObjekteMuzeal = () => {
  const dispatch = useDispatch();
  const objektOnEdit = useSelector((state) => state.objekte.objektOnEdit);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const loggedUser = useSelector((state) => state.users.loggedUser);
  const isLoggedIn = Object.keys(loggedUser).length > 0;
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    coverImage: "",
  });
  const fileInputRef = useRef(null);
  const location = useLocation();

  if (!isLoggedIn) navigate("/login");

  useEffect(() => {
    setFormData({
      title: objektOnEdit.title || "",
      description: objektOnEdit.description || "",
      coverImage: objektOnEdit.coverImage,
    });

    const fetchImage = async () => {
      if (location.pathname !== `/manage/objekte-muzeale/${objektOnEdit._id}`)
        return;
      try {
        const response = await axios.get(
          `https://api.arkiva.gov.al/api/objekteMuzeale/image/${objektOnEdit.id}`
        );

        if (response.data.image) {
          setFormData((prevData) => ({
            ...prevData,
            coverImage: response.data.image,
          }));
        }
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    };

    fetchImage();
  }, [objektOnEdit]);

  const validateForm = () => {
    const errors = {};

    if (!formData.title.trim()) errors.title = "Titulli nuk mund të jetë bosh!";
    if (!formData.description.trim())
      errors.description = "Përshkrimi nuk mund të jetë bosh!";
    else if (!formData.coverImage)
      errors.coverImage = "Imazhi nuk mund të jetë bosh!";
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    if (name === "description") {
      resizeTextarea();
    }
  };

  const handleReplaceCoverImage = async (e) => {
    e.preventDefault();

    if (!formData.coverImage) {
      alert("No cover image to replace.");
      return;
    }

    const imagePath = formData.coverImage;
    setLoading(true);

    try {
      const response = await fetch(
        "https://api.arkiva.gov.al/api/ojekteMuzeale/delete-image",
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            imagePath: `/Assets/Images/Objekte/${objektOnEdit.id}/${imagePath}`,
            objektId: objektOnEdit._id,
            folderId: objektOnEdit.id,
          }),
        }
      );

      if (!response.ok) {
        console.error("Failed to delete current cover image.");
        return;
      }
      setLoading(true);
      fileInputRef.current.click();
    } catch (error) {
      console.error("Error deleting image:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCoverImageUpload = async (e) => {
    const selectedFile = e.target.files[0];

    if (!selectedFile) return;

    setLoading(true);

    try {
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("folderId", objektOnEdit.id);

      console.log(formData);

      const uploadResponse = await axios.post(
        "https://api.arkiva.gov.al/api/objekteMuzeale/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const { fileName } = uploadResponse.data;
      setLoading(true);
      const updateResponse = await axios.put(
        `https://api.arkiva.gov.al/api/objekteMuzeale/update/${objektOnEdit._id}`,
        {
          coverImage: fileName,
        }
      );

      if (updateResponse.data) {
        setFormData((prevData) => ({
          ...prevData,
          coverImage: `/Assets/Images/ObjekteMuzeale/${objektOnEdit.id}/${fileName}`,
        }));
      } else {
        console.error("Failed to update the cover image in the database.");
      }
    } catch (error) {
      console.error("Error uploading new cover image:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    if (!objektOnEdit._id) {
      console.error("Error: _id is undefined. Cannot proceed.");
      return;
    }

    try {
      const response = await axios.put(
        `https://api.arkiva.gov.al/api/objekteMuzeale/update/${objektOnEdit._id}`,
        {
          title: formData.title,
          description: formData.description,
        }
      );

      if (response.data) {
        dispatch(updateObjekt(response.data));
        navigate("/manage/objekte-muzeale");
        dispatch(setObjektOnEdit({}));
      } else {
        console.error("Something went wrong");
      }
    } catch (error) {
      console.error("Error updating objekt muzeal:", error);
    }
  };

  const handleCancel = () => {
    navigate("/manage/objekte-muzeale");
  };

  const textareaRef = useRef(null);

  const resizeTextarea = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  useEffect(() => {
    resizeTextarea();
  }, [formData.description]);

  const returnHome = () => {
    navigate("/manage/objekte-muzeale");
    dispatch(setObjektOnEdit({}));
  };

  return (
    <div className="edit-page">
      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}
      <div className="return">
        <button onClick={returnHome}>
          <i className="fa-solid fa-chevron-left"></i>
        </button>
      </div>
      <p>Edito Objekt Muzeal</p>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="title">Titulli</label>
          <input
            type="text"
            id="title"
            name="title"
            value={formData.title}
            onChange={handleChange}
          />
          {errors.title && <small className="error">{errors.title}</small>}
        </div>

        <div className="form-group">
          <label htmlFor="description">Përshkrimi</label>
          <textarea
            id="description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            ref={textareaRef}
            onInput={resizeTextarea}
            style={{ width: "100%", overflow: "hidden" }}
          />
          {errors.description && (
            <small className="error">{errors.description}</small>
          )}
        </div>

        <div className="form-group cover">
          <label>Imazhi</label>
          {formData.coverImage && (
            <div className="image-wrapper">
              <img
                src={formData.coverImage}
                alt="preview"
                className="preview"
                style={{ width: "120px", height: "120px", objectFit: "cover" }}
              />
            </div>
          )}
          <input
            type="file"
            id="imageUpload"
            name="imageUpload"
            ref={fileInputRef}
            accept="image/*"
            onChange={handleCoverImageUpload}
            style={{ display: "none" }}
          />
          {errors.coverImage && (
            <small className="error">{errors.coverImage}</small>
          )}
          <button className="replace" onClick={handleReplaceCoverImage}>
            Zëvendëso imazhin
          </button>
        </div>

        <div className="edit-actions">
          <button type="submit" className="save">
            Ruaj ndryshimet
          </button>
          <button type="button" className="cancel" onClick={handleCancel}>
            Anullo
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditObjekteMuzeal;

export const SET_REGJSTRI_RHK = "SET_REGJSTRI_RHK";
export const SET_REGJSTRI_RHK_ON_EDIT = "SET_REGJSTRI_RHK_ON_EDIT";
export const UPDATE_REGJSTRI_RHK = "UPDATE_REGJSTRI_RHK";
export const DELETE_REGJSTRI_RHK = "DELETE_REGJSTRI_RHK";
export const ADD_REGJSTRI_RHK = "ADD_REGJSTRI_RHK";

export const setRegjistriRhk = (regjistriRhk) => ({
  type: SET_REGJSTRI_RHK,
  payload: regjistriRhk,
});

export const setRegjistriRhkOnEdit = (regjistriRhkOnEdit) => ({
  type: SET_REGJSTRI_RHK_ON_EDIT,
  payload: regjistriRhkOnEdit,
});

export const updateRegjistriRhk = (updatedRegjistriRhk) => ({
  type: UPDATE_REGJSTRI_RHK,
  payload: updatedRegjistriRhk,
});

export const removeRegjistriRhk = (regjistriRhkId) => ({
  type: DELETE_REGJSTRI_RHK,
  payload: regjistriRhkId,
});

export const addRegjistriRhk = (regjistriRhk) => ({
  type: ADD_REGJSTRI_RHK,
  payload: regjistriRhk,
});

import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setRegjistriDokHumbura } from "../Redux/Actions/regjDokHumburaActions";

const RegjistriDokHumbura = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [regjistriDokHumbura, setRegjistriDokHumb] = useState([]);
  const location = useLocation();

  const fetchRegjistriDokHumbura = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        "https://api.arkiva.gov.al/api/regjistriDokHumbura/all"
      );
      setRegjistriDokHumb(response.data);
      dispatch(setRegjistriDokHumbura(response.data));
    } catch (error) {
      console.error("Error fetching regjistri dokumentave te humbura:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (location.pathname === "/inspektimi/tabela-e-rigjetjes-se-dokumentave") {
      fetchRegjistriDokHumbura();
    }
  }, [location.pathname]);

  return (
    <div className="page">
      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}
      <div className="page-header">
        <div className="headerTitleLine"></div>
        <h1 className="page-title">
          Rregjistri i Dokumenteve të Shpallura të Humbura
        </h1>
      </div>
      <div className="tabela-rigjetjes">
        {regjistriDokHumbura.length > 0 ? (
          <div className="table-container">
            <div className="grid-row grid-header">
              <div>Nr. Fondit</div>
              <div>Fondi</div>
              <div>Viti</div>
              <div>Dosja</div>
              <div>Përshkrim i shkurtër i dokumentit që mungon</div>
              <div>Data e fletës së vërtetimit</div>
              <div>Data e rigjetjes së dokumentit</div>
            </div>
            {regjistriDokHumbura.map((item, index) => (
              <div className="grid-row rows" key={item._id}>
                <div data-label="Nr. Fondit:">{item.fondNr}</div>
                <div data-label="Fondi:">{item.fond}</div>
                <div data-label="Viti:">{item.year}</div>
                <div data-label="Dosja:">{item.folder}</div>
                <div data-label="Përshkrim:">{item.description}</div>
                <div data-label="Data e vërtetimit:">
                  {item.verificationDate}
                </div>
                <div data-label="Data e rigjetjes:">{item.foundDate}</div>
              </div>
            ))}
          </div>
        ) : (
          <p>No data available.</p>
        )}
        <a
          href="https://cdn.prod.website-files.com/65b91011f3e26e536a8eb1cc/65b91011f3e26e536a8eb536_URDHER-NR.598-DATE-10.09.2021.pdf"
          target="_blank"
          rel="noreferrer"
          className="download-link"
        >
          Shkarkoni urdhërin përkatës
        </a>
      </div>
    </div>
  );
};

export default RegjistriDokHumbura;

import React, { useEffect, useState } from "react";
import axios from "axios";
import CountUp from "react-countup";
import "./statistics.css";

const StatisticsPage = () => {
  const [loading, setLoading] = useState(true);
  const [userCount, setUserCount] = useState(0);
  const [mikrofilmCount, setMikrofilmCount] = useState(0);
  const [categories, setCategories] = useState({ dosje: 0, fonde: 0 });
  const [temat, setTemat] = useState({
    studimore: 0,
    biografike: 0,
    pronesore: 0,
  });
  const [sallaStudimit, setSallaStudimit] = useState({
    users: 0,
    ordersFiles: 0,
  });
  const [sallaOnline, setSallaOnline] = useState({
    users: 0,
    orders: 0,
    ordersFiles: 0,
  });
  const [salla24, setSalla24] = useState({ orders: 0, ordersFiles: 0 });
  const [sallaOnline24, setSallaOnline24] = useState({
    orders: 0,
    ordersFiles: 0,
  });
  const [salla25, setSalla25] = useState({ orders: 0, ordersFiles: 0 });
  const [sallaOnline25, setSallaOnline25] = useState({
    orders: 0,
    ordersFiles: 0,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responses = await Promise.all([
          axios.get("https://api.arkiva.gov.al/api/salla-studimit"),
          axios.get("https://api.arkiva.gov.al/api/salla-online"),
          axios.get("https://api.arkiva.gov.al/api/mikrofilm"),
          axios.get("https://api.arkiva.gov.al/api/temat"),
          axios.get("https://api.arkiva.gov.al/api/katalogu/users"),
          axios.get("https://api.arkiva.gov.al/api/katalogu/categories"),
          axios.get("https://api.arkiva.gov.al/api/salla24"),
          axios.get("https://api.arkiva.gov.al/api/sallaOnline24"),
          axios.get("https://api.arkiva.gov.al/api/salla25"),
          axios.get("https://api.arkiva.gov.al/api/sallaOnline25"),
        ]);

        setSallaStudimit(responses[0].data ?? { users: 0, ordersFiles: 0 });
        setSallaOnline(
          responses[1].data || { users: 0, orders: 0, ordersFiles: 0 }
        );
        setMikrofilmCount(responses[2].data?.mikrofilmCount || 0);
        setTemat(
          responses[3].data || { studimore: 0, biografike: 0, pronesore: 0 }
        );
        setUserCount(responses[4].data?.userCount || 0);
        setCategories(responses[5].data || { dosje: 0, fonde: 0 });
        setSalla24(responses[6].data || { orders: 0, ordersFiles: 0 });
        setSallaOnline24(responses[7].data || { orders: 0, ordersFiles: 0 });
        setSalla25(responses[8].data || { orders: 0, ordersFiles: 0 });
        setSallaOnline25(responses[9].data || { orders: 0, ordersFiles: 0 });
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="statistics-page">
      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}
      <div className="statistics-title">DPASH - Open Data</div>
      <div className="statistics-data">
        {/* Salla Studimit */}
        <div className="salla-data-widgets">
          <div className="salla-data-title">
            Përgjithshme - Salla e studimit
          </div>
          <div className="salla-widgets">
            <div className="data-widget user">
              <div className="widget-info">
                <CountUp
                  start={0}
                  end={sallaStudimit?.users || 0}
                  duration={2.5}
                />
              </div>
              <div className="widget-title users">Përdorues</div>
            </div>
            <div className="data-widget dosje">
              <div className="widget-info">
                <CountUp
                  start={0}
                  end={sallaStudimit?.ordersFiles || 0}
                  duration={2.5}
                />
              </div>
              <div className="widget-title">Dosje të porositura</div>
            </div>
            <div className="data-widget mikrofilm">
              <div className="widget-info">
                <CountUp start={0} end={mikrofilmCount} duration={2.5} />
              </div>
              <div className="widget-title">Mikrofilm</div>
            </div>
          </div>
        </div>

        {/* Salla Online */}
        <div className="salla-online-widgets">
          <div className="salla-online-title">Salla Online</div>
          <div className="salla-widgets">
            <div className="data-widget user">
              <div className="widget-info">
                <CountUp
                  start={0}
                  end={sallaOnline?.users || 0}
                  duration={2.5}
                />
              </div>
              <div className="widget-title users">Përdorues</div>
            </div>
            <div className="data-widget dosje">
              <div className="widget-info">
                <CountUp
                  start={0}
                  end={sallaOnline?.orders || 0}
                  duration={2.5}
                />
              </div>
              <div className="widget-title">Kërkesa</div>
            </div>
            <div className="data-widget mikrofilm">
              <div className="widget-info">
                <CountUp
                  start={0}
                  end={sallaOnline?.ordersFiles || 0}
                  duration={2.5}
                />
              </div>
              <div className="widget-title">Dosje të porositura</div>
            </div>
          </div>
        </div>

        {/* Temat */}
        <div className="tema-widgets">
          <div className="tema-title">Temat</div>
          <div className="tema-data-widgets">
            {temat &&
              Object.entries(temat).map(([key, value], index) => (
                <div className={`data-widget ${key}`} key={index}>
                  <div className="widget-info">
                    <CountUp
                      start={0}
                      end={value}
                      duration={2.5}
                      decimals={2}
                      suffix="%"
                    />
                  </div>
                  <div className="widget-title">
                    {key.charAt(0).toUpperCase() + key.slice(1)}
                  </div>
                </div>
              ))}
          </div>
        </div>

        {/* Katalogu */}
        <div className="katalogu-widgets">
          <div className="katalogu-title">Katalogu</div>
          <div className="katalogu-data-widgets">
            <div className="data-widget katalogu-user">
              <div className="widget-info">
                <CountUp start={0} end={userCount} duration={2.5} />
              </div>
              <div className="widget-title users">Përdorues</div>
            </div>
            <div className="data-widget fonde">
              <div className="widget-info">
                <CountUp
                  start={0}
                  end={categories?.fonde || 0}
                  duration={2.5}
                />
              </div>
              <div className="widget-title">Fonde</div>
            </div>
            <div className="data-widget katalogu-dosje">
              <div className="widget-info">
                <CountUp
                  start={0}
                  end={categories?.dosje || 0}
                  duration={2.5}
                />
              </div>
              <div className="widget-title">Dosje</div>
            </div>
          </div>
        </div>

        {/* Salla 2018 */}
        <div className="salla-18-widgets">
          <div className="salla-18-title">Salla e studimit 2018</div>
          <div className="salla-data-18-widgets">
            <div className="data-widget kerkesa-18">
              <div className="widget-info">
                <CountUp start={0} end={2658} duration={2.5} />
              </div>
              <div className="widget-title kerkesa-18">Kërkesa</div>
            </div>
            <div className="data-widget dosje-18">
              <div className="widget-info">
                <CountUp start={0} end={22538} duration={2.5} />
              </div>
              <div className="widget-title kerkesa-18">Dosje të porositura</div>
            </div>
          </div>
        </div>

        {/* Salla 2019 */}
        <div className="salla-19-widgets">
          <div className="salla-19-title">Salla e studimit 2019</div>
          <div className="salla-data-19-widgets">
            <div className="data-widget kerkesa-19">
              <div className="widget-info">
                <CountUp start={0} end={4409} duration={2.5} />
              </div>
              <div className="widget-title kerkesa-19">Kërkesa</div>
            </div>
            <div className="data-widget dosje-19">
              <div className="widget-info">
                <CountUp start={0} end={29159} duration={2.5} />
              </div>
              <div className="widget-title kerkesa-19">Dosje të porositura</div>
            </div>
          </div>
        </div>

        {/* Salla 2020 */}
        <div className="salla-20-widgets">
          <div className="salla-20-title">Salla e studimit 2020</div>
          <div className="salla-data-20-widgets">
            <div className="data-widget kerkesa-20">
              <div className="widget-info">
                <CountUp start={0} end={3417} duration={2.5} />
              </div>
              <div className="widget-title kerkesa-20">Kërkesa</div>
            </div>
            <div className="data-widget dosje-20">
              <div className="widget-info">
                <CountUp start={0} end={26999} duration={2.5} />
              </div>
              <div className="widget-title kerkesa-20">Dosje të porositura</div>
            </div>
          </div>
        </div>

        {/* Salla 2024 */}
        <div className="salla-24-widgets">
          <div className="salla-24-title">Salla e studimit 2024</div>
          <div className="salla-data-24-widgets">
            <div className="data-widget kerkesa-24">
              <div className="widget-info">
                <CountUp start={0} end={salla24.orders} duration={2.5} />
              </div>
              <div className="widget-title kerkesa-24">Kërkesa</div>
            </div>
            <div className="data-widget dosje-24">
              <div className="widget-info">
                <CountUp start={0} end={salla24.ordersFiles} duration={2.5} />
              </div>
              <div className="widget-title kerkesa-24">Dosje të porositura</div>
            </div>
          </div>
        </div>

        {/* Salla Online 2024 */}
        <div className="salla-online24-widgets">
          <div className="salla-online24-title">Salla online 2024</div>
          <div className="salla-data-24-widgets">
            <div className="data-widget kerkesa-online24">
              <div className="widget-info">
                <CountUp start={0} end={sallaOnline24.orders} duration={2.5} />
              </div>
              <div className="widget-title kerkesa-online24">Kërkesa</div>
            </div>
            <div className="data-widget dosje-online24">
              <div className="widget-info">
                <CountUp
                  start={0}
                  end={sallaOnline24.ordersFiles}
                  duration={2.5}
                />
              </div>
              <div className="widget-title kerkesa-online24">
                Dosje të porositura
              </div>
            </div>
          </div>
        </div>

        {/* Salla 2025 */}
        <div className="salla-25-widgets">
          <div className="salla-25-title">Salla e studimit 2025</div>
          <div className="salla-data-25-widgets">
            <div className="data-widget kerkesa-25">
              <div className="widget-info">
                <CountUp start={0} end={salla25.orders} duration={2.5} />
              </div>
              <div className="widget-title kerkesa-25">Kërkesa</div>
            </div>
            <div className="data-widget dosje-25">
              <div className="widget-info">
                <CountUp start={0} end={salla25.ordersFiles} duration={2.5} />
              </div>
              <div className="widget-title kerkesa-25">Dosje të porositura</div>
            </div>
          </div>
        </div>

        {/* Salla Online 2025 */}
        <div className="salla-online25-widgets">
          <div className="salla-online25-title">Salla online 2025</div>
          <div className="salla-data-25-widgets">
            <div className="data-widget kerkesa-online25">
              <div className="widget-info">
                <CountUp start={0} end={sallaOnline25.orders} duration={2.5} />
              </div>
              <div className="widget-title kerkesa-online25">Kërkesa</div>
            </div>
            <div className="data-widget dosje-online25">
              <div className="widget-info">
                <CountUp
                  start={0}
                  end={sallaOnline25.ordersFiles}
                  duration={2.5}
                />
              </div>
              <div className="widget-title kerkesa-online25">
                Dosje të porositura
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatisticsPage;

export const SET_OBJEKTE = "SET_OBJEKTE";
export const SET_OBJEKTE_ON_EDIT = "SET_OBJEKTE_ON_EDIT";
export const UPDATE_OBJEKTE = "UPDATE_OBJEKTE";
export const DELETE_OBJEKTE = "DELETE_OBJEKTE";
export const ADD_OBJEKTE = "ADD_OBJEKTE";

export const setObjekte = (objekte) => ({
  type: SET_OBJEKTE,
  payload: objekte,
});

export const setObjektOnEdit = (objektOnEdit) => ({
  type: SET_OBJEKTE_ON_EDIT,
  payload: objektOnEdit,
});

export const updateObjekt = (updatedObjekt) => ({
  type: UPDATE_OBJEKTE,
  payload: updatedObjekt,
});

export const removeObjekt = (objektId) => ({
  type: DELETE_OBJEKTE,
  payload: objektId,
});

export const addObjekt = (objekt) => ({
  type: ADD_OBJEKTE,
  payload: objekt,
});

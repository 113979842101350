import { SET_LOGGED_USER, UPDATE_USER } from "../Actions/userActions";

const initialState = {
  users: [],
  loggedUser: {},
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOGGED_USER:
      return {
        ...state,
        loggedUser: action.payload,
      };
    case UPDATE_USER:
      const updatedUser = action.payload;
      return {
        ...state,
        users: state.users.map((user) =>
          user._id === updatedUser._id ? { ...user, ...updatedUser } : user
        ),
      };
    default: {
      return state;
    }
  }
};

export default userReducer;

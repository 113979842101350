import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import doreshkrime from "../Assets/Documents/Doreshkrime bizantine dhe pasbizantine.docx";
const Doreshkrime = () => {
  const [loading, setLoading] = useState(false);
  const [doreshkrimeImages, setDoreshkrimeImages] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const pagePath = useLocation();

  const fetchDoreshkrimeImages = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        "https://api.arkiva.gov.al/api/doreshkrime/images"
      );
      setDoreshkrimeImages(response.data.images);
    } catch (error) {
      console.error("Error fetching doreshkrime images:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (pagePath.pathname === "/doreshkrime-bizantine-dhe-pasbizantine") {
      fetchDoreshkrimeImages();
    }
  }, [pagePath.pathname]);

  const openModal = (index) => {
    setCurrentImageIndex(index);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const nextSlide = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex + 1) % doreshkrimeImages.length
    );
  };

  const prevSlide = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? doreshkrimeImages.length - 1 : prevIndex - 1
    );
  };
  return (
    <div className="page">
      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}
      <div className="page-header">
        <div className="headerTitleLine"></div>
        <h1 className="page-title">Dorëshkrime bizantine dhe pasbizantine</h1>
      </div>
      <div className="page-content-doreshkrime">
        <div className="left-content-doreshkrime">
          <p className="subtitle">
            Dorëshkrime bizantine dhe pasbizantine në Arkivin Qendror Shtetëror
          </p>
          <p className="subtitle">
            Kodikët mesjetarë të Fondit “Koleksioni i kodikëve” të AQSh
          </p>
          <p>
            Në fondin arkivor të Arkivit Qendror Shtetëror ruhet një koleksion i
            veçantë dokumentesh, i emërtuar Fondi “Kodikët e Shqipërisë”. Ky
            fond mban numrin 488 dhe përmban 119 njësi ruajtjeje, të cilat janë
            materiale dokumentare që i përkasin të periudhës bizantine dhe pas
            bizantine. Nga këto njësi ruajtjeje 102 prej tyre janë të plota,
            kurse 17 janë fragmente.
          </p>
          <a href={doreshkrime} download>
            Lexo të plotë materialin
          </a>
        </div>
        <div className="right-content-doreshkrime">
          {doreshkrimeImages.map((item, index) => (
            <img
              src={item}
              alt="doreshkrime"
              onClick={() => openModal(index)}
              key={index}
            />
          ))}
        </div>
      </div>
      {isModalOpen && (
        <div className="modal-doreshkrime">
          <button className="modal-close" onClick={closeModal}>
            &times;
          </button>
          <button className="modal-prev-post" onClick={prevSlide}>
            &#10094;
          </button>
          <div className="modal-content-doreshkrime">
            <img
              src={doreshkrimeImages[currentImageIndex]}
              alt={`Slide ${currentImageIndex + 1}`}
            />
          </div>
          <button className="modal-next-post" onClick={nextSlide}>
            &#10095;
          </button>
        </div>
      )}
    </div>
  );
};

export default Doreshkrime;

import { Link } from "react-router-dom";
import "./footer.css";
const Footer = () => {
  return (
    <div className="footer">
      <p>
        © 2025 Drejtoria e Përgjithshme e Arkivave. Të gjitha të drejtat e
        rezervuara.
      </p>
      <Link to="/politika-e-privatesise">Politika e Privatësisë</Link>
    </div>
  );
};

export default Footer;

export const kerkimMePageseData = [
  {
    url: "/kerkim-me-pagese/dokumente-pronesie",
    title: "Dokumente Pronësie",
    content: [
      {
        title: "A kam të drejtë të aplikoj?",
        contentText: [
          "Çdo qytetar që do të aplikojë për të dhëna pronësie në Arkivin Qendror Shtetëror, fillimisht duhet të bëjë lidhjen trashëgimore (gjinore) ndërmjet kërkuesit dhe pronarit, proçes ky që duhet të vërtetohet me dokumente si: (çertifikata që vërtetojnë lidhjen direkte të kërkuesit me pronarin e kërkuar, prokurë, akt trashëgimie, vendim gjykate, etj…).",
          "Nuk mund të aplikojnë për të dhëna biografike të gjithë ata persona që pavarësisht lidhjes së gjakut, nuk janë trashëgimtarë të vijës së parë (trashëgimtarë direkt), si psh: nipi/mbesa për xhaxhain, dajën, tezen, hallën, etj. Nëse këta persona duan të marrin këton të dhëna, duhet të kenë me patjetër prokurë, akt trashëgime ose vendim gjykate që vërteton që ata janë trashëgimtarë të personit në emër të të cilit kërkohen të dhënat.",
          "Nëse nuk ka lidhje gjinore/trashëgimore, ai duhet të vërtetojë se ka të drejtë të kërkojë të dhëna për pronarin në fjalë (i kërkohet nga institucion tjetër shtetëror).",
        ],
      },
      {
        title: "Si mund të aplikoj?",
        contentText: [
          "Ju duhet të paraqiteni në sportelet e Postës Shqiptare dhe të plotësoni formularin e posaçëm për Drejtorinë e Përgjithshme të Arkivave, duke dorëzuar bashkalidhur edhe dokumentet e kërkuara dhe duke paguar tarifën përkatëse. Për procedurën e përshpejtuar shihni seksionin përkatës më poshtë. Dokumentacioni bashkëlidhur për aplikim duhet të jetë origjinal dhe i 6 muajve të fundit. <a href='https://cdn.prod.website-files.com/623d8d774e095722fd35306c/62bbf1f6a1ccdb58196263d7_jpg2pdf-3.pdf' target='_blank' rel='noreferrer'>Formularin</a> do ta gjeni në pikat qendrore të Postës. Ruani mandatin e pagesës së Postës, pasi me anë të tij do të mund të bëni tërheqjen, ose një ankesë eventuale.",
        ],
      },
      {
        title: "Si duhet plotësuar formulari?",
        contentText: [
          "Nëse aplikuesi (plotësuesi i formularit) nuk është vetë personi i cili kërkon të dhëna pronësie, por një përfaqësues i tij, ky i fundit duhet të vërtetojë me dokumentacion (prokurë, autorizim i noterizuar) që ka të drejtë të kërkojë të dhëna në emër të kërkuesit. Dokumentacioni duhet të jetë gjithmonë origjinal. Kërkuesi duhet të vendosë gjeneralitetet e pronarit sipas rastit, duke qenë sa më i qartë dhe i saktë.",
          "Nëse ka ndryshime në njërën nga gjeneralitetet, si për kërkuesin ashtu edhe për pronarin, ndryshimi duhet të vërtetohet me dokumentacion (sipas rastit, çertifikatë, vendim gjykate, bashkie, etj.)",
          "Emri, atësia dhe mbiemri i pronarit duhet të reflektojnë emrin, atësinë dhe mbiemrin (mbiemrat) që vërtetohen me dokumentacion (certifikata, vërtetime nga institucione shtetërore, etj.), dhe jo emra (ose mbiemra) të cilat ndoshta dihen nga kërkuesi, por nuk mbështeten me dokumentat përkatës.",
          "Kërkuesi duhet të specifikojë sipas rastit vendin në të cilin ndodhet prona e tij (qarkun, qytetin/qytetet, sipas rastit lagjen ose fshatin/fshatrat) duke qenë si gjithmonë sa më i qartë dhe konciz në informacion, (psh., Qarku-Vlorë, Qyteti-Sarandë, fshati-Shën Vasil;). Në rastet kur kërkuesi kërkon të dhëna në disa fshatra ose qytete, duhet të shënohen të gjithë fshatrat ose qytetet e kërkuara.",
          "Aplikuesi duhet të zgjedhë njërën ose të gjitha periudhat kohore për të cilën ai mendon se shtrihet prona e pretenduar. Duhet të kihet parasysh se kërkimi për periudhën osmane zgjat më shumë se afati i cilësuar.",
          "Kërkuesi mund të shënojë të dhëna më specifike për pronën e pretenduar , duke specifikuar dokumentacionin që kërkon, sipas pikave të përcaktuara, duke përcaktuar saktë, periudhën kohore, organin që ka nxjerrë ose lëshuar vendimin, etj., si psh:",
          [
            "leje ndërtimi (vendime të Komiteteve Ekzekutive, rajoneve përkatëse, Këshillit Popullor të qyteteve apo fshatrave, komunave, bashkive, KRRT të Rretheve),",
            "vendime shpronësimi/shtetëzimi/sekuestrimi/konfiskimi;të dhëna për marrje kredi (dokumente nga dosjet e kredive, leje ndërtimi, skica, etj)",
            "akte noteriale, shitblerjeje apo dhënie shtëpi qytetarëve, etj.",
            "dokumente privatizimi",
          ],
          "Nëse kërkuesi kërkon të dhëna pronësie të tjera, të pacilësuara në aplikim, ai mund të plotësojë me shkrim dore pjesën e specifkimit, duke përcaktuar qartë çfarë kërkon, si dhe duhet të ketë parasysh që <strong>përgjigjia do të kthehet vetëm për kërkesën specifike</strong>.",
          "Kërkuesi, sipas rastit, duhet të plotësojë nëse ka marrë të dhëna më parë në AQSH.",
          "Nëse po, kërkuesi duhet të bashkëlidhë shkresën përcjellëse e cila i është dhënë më parë, në rastin kur janë marrë të dhëna pronësie, ose të dhëna specifike të saj, si nr. i protokollit dhe data.",
          "Nëse kërkuesi ka marrë më parë të dhëna pronësie, ai duhet të specifikojë nëse kërkon të marrë përsëri këto të dhëna, nuk kërkon asnjë prej këtyre të dhënave (dmth kërkon të dhëna përveç atyre të marra më parë), ose kërkon disa prej tyre, duke specifikuar indikacionet arkivore të caktuara (të cilat ndodhen në shkresën përcjellëse ose në fund të fletës fotokopje dokumenti). Nëse ka më shumë indikacione se sa ka vend në formular, ai mund t’i shënojë ato në një fletë të të bardhë dhe t’ia bashkëlidhë atë formularit.",
          "Në fund të formularit kërkuesi plotëson emrin dhe mbiemrin duke e nënshkruar atë.",
        ],
      },
      {
        title: "Çfarë dokumentash ruhen nga DPA?",
        contentText: [
          "Dokumentacioni i ruajtur pranë AQSH dhe ASHV përfshin dokumente administrative të krijuara nga administrata e shtetit shqiptar në vite, dhe jo dokumente specifike pronësie si ato që prodhojnë, ruajnë dhe administrojnë hipotekat, apo kadastrat.",
          "Dokumentacioni i ruajtur në Arkivat Shtetërore Vendorë përfshin dokumente të institucioneve shtetërore vendore dhe qeverisjes vendore brenda njësisë administrative territoriale përkatëse, të trashëguara ose të krijuara prej tyre, përgjithësisht pas vitit 1945.",
          "AQSH/ASHV nuk disponon harta, skica, plane rilevimi apo planimetri, etj. përveç rasteve kur institucioni në veprim i kohës (psh, Ministria e Punëve Botore, Ministria e Bujqësisë, Komiteti Ekzekutiv) hartonte, për interesat publike të kohës, planimetri ose skica, të objekteve që do të ndërtoheshin apo shpronësoheshin, sipas rastit; ose ka raste kur aktet noteriale të shit – blerjeve të pasurive janë të shoqëruara me skica.",
          "Një lloj dokumentacioni i kërkuar në AQSH janë dhe aktet noteriale, me kufinjtë kohorë si vijon:",
          [
            "Noteria Tiranë (1929-1968)",
            "Noteria Vlorë (1916-1969)",
            "Noteria Korçë (1920-1967)",
            "Noteria Sarandë (1934-2000)",
            "Noteria Kavajë (1929-1994)",
            "Noteria Ersekë (1960-1994)",
          ],
          "Specifikojmë që pavarësisht kufijve kohorë të sipërpërmendur, dokumentacioni nuk ka ardhur i plotë në AQSH dhe ASHV.",
        ],
      },
      {
        title: "Sa është afati i kthimit të përgjigjes dhe ku mund ta tërheq?",
        contentText: [
          "Përgjigjia do të tërhiqet pranë degës postare ku është kryer aplikimi 23 (njëzet e tre) ditë pune pas kryejes së aplikimit.",
          "Duhet pasur parasysh që DPA kthen përgjigje në bazë të të dhënave të formularit të plotësuar nga kërkuesi, dhe në bazë të dokumentacionit që ajo disponon. Sa më specifike të jetë kërkesa, aq më specifike do të jetë dhe përgjigjia (psh. nëse një qytetar kërkon dokumente pronësore për një periudhë të caktuar, do t’i kthehet përgjigje vetëm për këtë të fundit, dhe nëse disponojmë të dhëna për këta të fundit, megjithëse në emrin e tij mund të figurojnë edhe të dhëna në vite të tjera).",
          "Fotokopjet e shërbyera nuk mund të kthehen mbrapsht dhe do të paguhen përpara se të tërhiqen nga qytetari.",
          "Fotokopjet e patërhequra qëndrojnë në zyrën postare deri 30 ditë pas dërgimit në filialin përkatës. Nëse përgjigjia nuk tërhiqet brenda kësaj periudhe ato i kthehen DPA, nga ku mund të tërhiqen brenda 6 muajsh nga kryerja e aplikimit. Pas kësaj periudhe, kërkuesi duhet të kryejë aplikimin nga fillimi.",
        ],
      },
      {
        title: "A ka procedurë të përshpejtuar?",
        contentText:
          "Po, ka procedurë të përshpejtuar. Ju duhet të paraqiteni pranë zyrave të DPA (informacionit) ose Postës Shqiptare, të kryeni pagesën, sipas mandatit që do t’iu jepet dhe të plotësoni formularin. Tarifa e procedurës së pëshpejtuar është 10.000 lekë dhe përgjigja jepet brenda pesë ditëve, nga çasti i aplikimit.",
      },
      {
        title:
          "Përgjigja nuk më duket e saktë.Si mund të ankohem dhe të kërkoj rishikim?",
        contentText:
          "Në rast se ka indicie të vërtetuara me shkresat përkatëse se DPA disponon dokumente të cilat janë pjesë e kërkesave specifike të formularit dhe nuk janë vendosur në dispozicion të kërkuesit, mund të kërkohet rishikimi i kërkesës pranë zyrave të DPA jo më vonë se 15 (pesëmbëdhjetë) ditë pune nga marrja e përgjigjes.",
      },
      {
        title: "Sa është tarifa?",
        contentText:
          "Në momentin e aplikimit do të paguani tarifën e pranimit, që është 1500 lekë dhe pagesën e shërbimit postar. Pasi të merrni përgjigjen do të paguani 100 lekë për çdo fletë të fotokopjuar. DPA është kujdesur që të mbajë tarifat më të ulta në rajon, duke mos i rritur ato prej më shumë se një dekade. Procedura e përshpejtuar ka tarifë tjetër, sipas seksionit të mësipërm.",
      },
    ],
  },
  {
    url: "/kerkim-me-pagese/dokumente-biografike",
    title: "Dokumente Biografike",
    content: [
      {
        title: "A kam të drejtë të aplikoj?",
        contentText: [
          "Çdo qytetar që do të aplikojë për të dhëna biografike në AQSH/ASHV, fillimisht duhet të bëjë lidhjen trashëgimore (gjinore) ndërmjet kërkuesit dhe personit për të cilin kërkon të dhëna, proçes ky që duhet të vërtetohet me dokumente si: (çertifikata që vërtetojnë lidhje direkte me personin për të cilin kërkohen të dhënat, prokurë, akt trashëgimie, vendim gjykate, etj.).",
          "Nuk mund të aplikojnë për të dhëna biografike të gjithë ata persona që pavarësisht lidhjes së gjakut, nuk janë trashëgimtarë të vijës së parë (trashëgimtarë direkt), si psh: nipi/mbesa për xhaxhain, dajën, tezen, hallën, etj. Nëse këta persona duan të marrin këton të dhëna, duhet të kenë me patjetër prokurë, akt trashëgime ose vendim gjykate që vërteton që ata janë trashëgimtarë të personit në emër të të cilit kërkohen të dhënat.",
        ],
      },
      {
        title: "Si mund të aplikoj?",
        contentText: [
          "Ju duhet të paraqiteni në sportelet e Postës Shqiptare dhe të plotësoni formularin e posaçëm për Drejtorinë e Përgjithshme të Arkivave, duke dorëzuar bashkalidhur edhe dokumentet e kërkuara dhe duke paguar tarifën përkatëse. Për procedurën e përshpejtuar shihni seksionin përkatës më poshtë. Dokumentacioni bashkëlidhur për aplikim duhet të jetë origjinal dhe i 6 muajve të fundit. <a href='https://cdn.prod.website-files.com/623d8d774e095722fd35306c/62bbf1f6a1ccdb58196263d7_jpg2pdf-3.pdf' target='_blank' rel='noreferrer'>Formularin</a> do ta gjeni në pikat qendrore të Postës. Ruani mandatin e pagesës së Postës, pasi me anë të tij do të mund të bëni tërheqjen, ose një ankesë eventuale.",
        ],
      },
      {
        title: "Çfarë dokumentesh ruhen nga DPA?",
        contentText: [
          "Dokumentet personale në AQSH përbëhen kryesisht nga rregjistra pagëzimesh, martesash apo vdekjesh, të viteve të ndryshme, të komuniteteve të ndryshme fetare.",
          "Arkivat Shtetërorë Vendorë përfshijnë edhe dokumentacion mbi ndryshime emri/mbiemri, ndarje nga trungu familjar, vendime mbi adoptimin ose dhënien në kujdestari të fëmijëve; ose dokumenta/vendime për dhënien e të drejtës së banimit apo largimit nga vendbanimi.",
          "Specifikojmë që nuk disponojmë çertifikata të çfarëdolloji, ato ruhen dhe administrohen nga zyrat e gjendjes civile.Dokumentet shkollore konsistojnë në rregjistra amzash të shkollave/institucioneve arsimore, si dhe dhënie të drejtë studimi, të cilat disponohen pranë Arkivave Shtetërorë Vendorë. Kërkuesi duhet të specifikojë shkollën përkatëse, vendndodhjen, ciklin shkollor, degën, etj.",
          "Dokumentacioni kryesor për vërtetimin e vjetërsisë në punë, si bordero, libra pagash, etj. i ka kaluar ISSH dhe degëve rajonale të saj. Dokumentacioni që mund të kërkohet përfshin emërime, largime, transferime, dmth dokumente administrative. Nëse kërkohet vendim emërimi/largimi/pushimi duhet të specifikohet periudha e kërkimit, e cila nuk duhet te jetë më shume se 5 vjeçare. Specifikojmë që institucionet qëndrore i depozitojnë dokumentet pranë AQSH, ndërsa institucionet vendore i depozitojnë pranë ASHV përkatëse.",
          "Dokumentet për ish të përndjekurit politikë nuk përfshin vendimet e gjykatave, të cilat ndodhen pranë gjykatave përkatëse, ose arkiva të tjerë, jashtë AQSH. Dokumentacioni pranë AQSH përfshin, vendime të Presidiumit për falje ose shtim dënimi, relacione, raporte ku mund të përmenden të dhëna për personin në fjalë, etj.",
          "Dokumentet për veteranët/patriotët përfshijnë kryesisht medalje, tituj, etj.",
          "Specifikojmë që për çdo kërkesë personale duhet të shënohet vendndodhja për të cilën kërkohet dokumentacioni.",
        ],
      },
      {
        title: "Sa është afati i kthimit të përgjigjes dhe ku mund ta tërheq?",
        contentText: [
          "Përgjigjia do të tërhiqet pranë degës postare ku është kryer aplikimi 15 (pesëmbëdhjetë) ditë pune pas kryejes së aplikimit.",
          "Duhet pasur parasysh që DPA kthen përgjigje në bazë të të dhënave të formularit të plotësuar nga kërkuesi, dhe në bazë të dokumentacionit që ajo disponon. Sa më specifike të jetë kërkesa, aq më specifike do të jetë dhe përgjigjia (psh. nëse një qytetar kërkon vjetërsi pune për një vit të caktuar, do t’i kthehet përgjigje vetëm për këtë të fundit, dhe nëse disponojmë të dhëna për këta të fundit, megjithëse në emrin e tij mund të figurojnë edhe të dhëna në vite të tjera).",
          "Fotokopjet e shërbyera nuk mund të kthehen mbrapsht dhe do të paguhen përpara se të tërhiqen nga qytetari.",
          "AFotokopjet e patërhequra qëndrojnë në zyrën postare deri 30 ditë pas dërgimit në filialin përkatës. Nëse përgjigjia nuk tërhiqet brenda kësaj periudhe ato i kthehen DPA, nga ku mund të tërhiqen brenda 6 muajsh nga kryerja e aplikimit. Pas kësaj periudhe, kërkuesi duhet të kryejë aplikimin nga fillimi.",
        ],
      },
      {
        title: "A ka procedurë të përshpejtuar?",
        contentText:
          "Po, ka procedurë të përshpejtuar. Ju duhet të paraqiteni pranë zyrave të DPA (informacionit) ose Postës Shqiptare, të kryeni pagesën, sipas mandatit që do t’iu jepet dhe të plotësoni formularin. Tarifa e procedurës së pëshpejtuar është 10.000 lekë dhe përgjigja jepet brenda pesë ditëve, nga çasti i aplikimit.",
      },
      {
        title:
          "Përgjigja nuk më duket e saktë.Si mund të ankohem dhe të kërkoj rishikim?",
        contentText:
          "Në rast se ka indicie të vërtetuara me shkresat përkatëse se DPA disponon dokumente të cilat janë pjesë e kërkesave specifike të formularit dhe nuk janë vendosur në dispozicion të kërkuesit, mund të kërkohet rishikimi i kërkesës pranë zyrave të DPA jo më vonë se 15 (pesëmbëdhjetë) ditë pune nga marrja e përgjigjes.",
      },
      {
        title: "Sa është tarifa?",
        contentText:
          "Në momentin e aplikimit do të paguani tarifën e pranimit, që është 500 lekë dhe pagesën e shërbimit postar. Pasi të merrni përgjigjen do të paguani 50 lekë për çdo fletë të fotokopjuar. DPA është kujdesur që të mbajë tarifat më të ulta në rajon, duke mos i rritur ato prej më shumë se një dekade. Procedura e përshpejtuar ka tarifë tjetër, sipas seksionit të mësipërm.",
      },
    ],
  },
];

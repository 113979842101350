export const politikaPrivatesiseData = [
  {
    title: "SI I PËRDORIM NE INFORMACIONET TUAJA?",
    content: [
      "Ky njoftim mbi ruajtjen e privatësisë tuaj ju tregon se çfarë duhet të prisni kur Drejtoria e Përgjithshme e Arkivave (në vijim DPA), të mbledhë të dhënat tuaja personale. Kjo vlen për informacionin e mbledhur në lidhje me:",
      [
        "Aplikuesit për kërkesat Social Juridike.",
        "Vizitorët në faqen tone dhe studiuesit në sallën e studimit dhe online.",
        "Kandidatët për punësim dhe punonjësit tanë të tanishëm si dhe ish-punonjësit.",
      ],
    ],
    subcontent: [
      {
        title: "Aplikuesit për kërkesat Social Juridike DPA",
        contentText: [
          "Kur paraqitet një ankesë nga një person, ajo protokollohet në një regjistër të veçantë nga një punonjës përkatës i cili më parë ka nënshkruar deklaratën e konfidencialitetit dhe është i njohur me përmbajtjen e kodit etik të institucionit si dhe me rregulloren për mbrojtjen e të dhënave personale të Institucionit.",
          "Ne përdorim të dhënat personale që mbledhim vetëm për proçesin e shqyrtimit të kërkesës dhe për të kontrolluar nivelin e shërbimeve që ne ofrojmë. Ne përpilojmë dhe publikojmë statistika duke treguar informacione të tilla si numri i ankesave që marrim, por jo në një formë që të identifikojë ndonjërin prej tyre.",
          "Ne mbajmë të dhëna personale të përfshira në dosjet e kërkesave në përputhje me ligjin dhe politikën tonë të ruajtjes së të dhënave personale. Ato mbahen në një mjedis të sigurt dhe aksesi në të është i kufizuar në bazë të parimit “nevoja për të ditur dhe njohur”.",
        ],
      },
      {
        title: "VIZITORËT NË FAQEN TONË TË INTERNETIT",
        contentText: [
          "DPA disponon faqen zyrtare te institucionit www.arkiva.gov.al Më anë të faqes zyrtare, DPA transmeton dhe mbledh informacione. Qëllimi i transmetimit dhe mbledhjes së informacionit nëpërmjet internetit është që të jetë sa më pranë studiuesve dhe personave të interesuar për shërbimet e DPA.",
          "DPA u vjen në ndihmë edhe subjekteve të të dhënave personale duke publikuar në faqen zyrtare informacione të ndryshme që kanë të bëjnë me legjislacionin, informacione në lidhje me ruajtjen e të aktivitet në jetën e përditshme, përdorimin e mjeteve të komunikimeve elektronike, etj.",
          "Për vizitoret e www.arkiva .gov.al sistemi mbledh informacione standarte të cilat kanë të bëjnë me klikimet e artikujve apo rubrikave në këtë faqe. Ky informacion mblidhet për qëllime statistikore dhe studimi (për të treguar qasjen e vizitorëve, për të parë se cilat janë rubrikat më të ndjeshme dhe për rrjedhojë interesin, gjithnjë pa i bërë ata të identifikueshëm).",
          "Ne e mbledhim këtë informacion në atë mënyrë që të mos identifikojmë asnjë vizitor. Ne nuk bëjmë asnjë përpjekje për të gjetur identitetin e vizitorëve që kanë vizituar faqen tonë të internetit.",
          "DPA nuk përdorë (dhe nuk lejon ndonjë palë të tretë) mjete statistikore analitike për të ndjekur ose për të mbledhur informacione personale që i bëjnë të identifikueshëm vizitorët e faqes tonë zyrtare. Ne nuk lidhim asnjë të dhënë të grumbulluar nga kjo faqe me ndonjë të dhënë personale që e bën vizitorin të identifikueshëm nga çdo burim që të vijë, si pjesë e përdorimit tonë.",
          "Motori i kërkimit në faqen tonë të internetit është projektuar të jetë i fuqishëm dhe i lehtë për t’u përdorur po aq sa kërkimi në Google. Kërkimi është bërë i mundur nga një pjesë hardware (një ‘aplikacion’ kërkim) që furnizohet nga Google i cili është i mbyllur në serverin tonë dhe indekson vazhdimisht përmbajtjen në faqen tonë. Të gjitha kërkesat e kërkimit trajtohen nga aplikacioni dhe informacioni nuk i kalohet ndonjë pale të tretë.",
          "Nëse do të duam të mbledhim informacione personalisht të identifikueshme nëpërmjet faqes sonë të internetit, ne do t`jua bëjmë të qartë rastin kur të mbledhim informacion personal dhe do të shpjegojmë se çfarë kemi ndërmend të bëjmë me të.",
        ],
      },
      {
        title:
          "APLIKANTËT PËR PUNË, TË PUNËSUARIT AKTUAL DHE ISH PUNONJËSIT E DPA",
        contentText: [
          "Kur persona aplikojnë për t’u punësuar pranë DPA, ne përdorim informacionin për proçesin e aplikimit të tyre dhe për të monitoruar statistikat e rekrutimit. Kur ne duam ti përhapim të dhënat një pale të tretë, për shembull, kur ne duam të marrin një referencë, ose të marrim disa të dhëna nga institucione të tjera përkatëse ( p.sh Autoriteti i Gjendjes Gjyqësore ), ne nuk e bëjmë këtë, pa i informuar subjektet më parë, vetëm nëse ky informacion është i kërkuar me ligj.",
          "Ne mbajmë informacion të pa personalizuar për qëllime statistikore në lidhje me aplikantët, për të ndihmuar aktivitetet tona të rekrutimit, por asnjë aplikant nuk është i identifikueshëm nga këto të dhëna.",
          "Kur një punonjës nuk është më në raporte pune me DPA, për të ne përgatisim një dosje në lidhje me periudhën gjatë së cilës ai ka qenë i punësuar. Të dhënat e përfshira në të mbahen të sigurta dhe përdoren vetëm për qëllime me rëndësi të drejtpërdrejtë mbi punësimin e personit. Kur punësimi i tyre pranë DPA përfundon, ne mbajmë dosjen në përputhje me ligjin për statusin e nëpunësit civil, ligjin për arkivat dhe rregullat tona të brendshme.",
        ],
      },
    ],
  },
  {
    title: "LIDHJET ME FAQET E TJERA",
    content:
      "Ky njoftim mbi ruajtjen e privatësisë për të arritur mbrojtjen e të dhënave personale nuk përfshin lidhjet midis kësaj faqeje dhe faqeve të tjera. Ne ju inkurajojmë të lexoni deklaratat e privatësisë në faqet e tjera të internetit që ju vizitoni.",
  },
  {
    title: "NDRYSHIMET NË KËTË NJOFTIM MBI MBROJTJEN E TË DHËNAVE PERSONALE",
    content: [
      "Ne përditësojmë këtë njoftim në mënyrë të rregullt duke vlerësuar këtë politikë të privatësisë si ndër më të rëndësishmet në veprimtarinë e punës së DPA-së.",
      "Ky njoftim mbi mbrojtjen e të dhënave personale u hartua me qëllim për të qenë i qartë dhe i shkurtër. Nuk jepen detaje të plota të të gjitha aspekteve të mbledhjes dhe përdorimit të të dhënave personale nga ana DPA. Megjithatë, ne jemi të gatshëm t’ju japim çdo informacion shtesë apo shpjegim të nevojshëm. Çdo kërkesë për këtë duhet të dërgohet në adresën e – mailit dpa@arkiva.gov.al",
    ],
  },
  {
    title: "SI TË NA KONTAKTONI",
    content:
      "Kërkesat për informacion në lidhje me politikën tonë të privatësisë mund të dërgohen me e-mail në adresën dpa@arkiva.gov.al",
  },
];

import { useLocation } from "react-router-dom";
import "./legjislacioni.css";
import { legjislacioniData } from "./legjislacioniData";
const Legjislacioni = () => {
  const pagePath = useLocation();
  const pageData = legjislacioniData.find(
    (item) => item.path === pagePath.pathname
  );

  if (!pageData) {
    return <p>Page not found</p>;
  }

  return (
    <div className="page">
      <div className="page-header">
        <div className="headerTitleLine"></div>
        <h1 className="page-title">{pageData.title}</h1>
      </div>
      <div
        className={`page-content-legjislacioni ${
          pagePath.pathname === "/legjislacioni/dokumente-dhe-strategji"
            ? "dokumente"
            : "ligje"
        }`}
      >
        <div className="left-content-legjislacioni">
          <ul>
            {pageData.leftContent.map((item, itemIndex) => (
              <li key={itemIndex}>
                {Array.isArray(item.name) ? (
                  item.name.map((el, index) => (
                    <p key={index} dangerouslySetInnerHTML={{ __html: el }}></p>
                  ))
                ) : (
                  <a
                    href={item.document}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {item.name || item.title}
                  </a>
                )}
              </li>
            ))}
          </ul>
        </div>

        <div className="right-content-legjislacioni">
          {Array.isArray(pageData.rightContent) &&
          pageData.rightContent.length > 0
            ? pageData.rightContent.map((item, itemIndex) => (
                <div key={itemIndex}>
                  <p className="right-content-title">{item.title}</p>
                  <ul>
                    {Array.isArray(item.doc) &&
                      item.doc.map((docItem, docIndex) => (
                        <li key={docIndex}>
                          <a
                            href={docItem.document}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {docItem.name || docItem.title}
                          </a>
                        </li>
                      ))}
                  </ul>
                </div>
              ))
            : ""}
        </div>
      </div>
    </div>
  );
};

export default Legjislacioni;

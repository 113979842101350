import axios from "axios";
import {
  removePost,
  setPostOnEdit,
  setPosts,
} from "../../Redux/Actions/postsActions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const ManagePosts = () => {
  const dispatch = useDispatch();
  const [posts, setPosters] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const loggedUser = useSelector((state) => state.users.loggedUser);
  const isLoggedIn = Object.keys(loggedUser).length > 0;
  const navigate = useNavigate();

  if (!isLoggedIn) {
    navigate("/login");
  }
  useEffect(() => {
    const fetchPosts = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `https://api.arkiva.gov.al/api/posts?page=${currentPage}&limit=20`
        );

        const sortedPosts = response.data.posts.sort((a, b) => {
          const dateA = new Date(a.createdOn.split("/").reverse().join("-"));
          const dateB = new Date(b.createdOn.split("/").reverse().join("-"));
          return dateB - dateA;
        });

        setPosters(sortedPosts);
        setTotalPages(response.data.totalPages);
        dispatch(setPosts(sortedPosts));
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
      setLoading(false);
    };

    if (isLoggedIn) {
      fetchPosts();
    } else {
      navigate("/login");
    }
  }, [dispatch, isLoggedIn, navigate, currentPage]);

  if (!isLoggedIn) {
    return null;
  }

  const editPost = (post) => {
    navigate(`/manage/aktivitete/${post._id}`);
    dispatch(setPostOnEdit(post));
  };

  const addPost = () => {
    navigate("/manage/aktivitete/add-new");
  };

  const deletePost = async (post) => {
    try {
      const response = await axios.delete(
        `https://api.arkiva.gov.al/api/posts/delete/${post._id}`,
        { data: { id: post.id } }
      );
      if (response.data.message === "error") {
        alert("Something went wrong!");
      } else {
        setPosters((prevPosts) =>
          prevPosts.filter((item) => item._id !== response.data.postId)
        );
        dispatch(removePost(response.data.postId));
      }
    } catch (error) {
      console.error("Error deleting post:", error);
    }
  };

  const filteredPosts = posts.filter((item) =>
    item.title.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <div className="manage-page">
      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}
      <div className="manage-page-header">
        <div className="manage-page-header-actions">
          <input
            type="text"
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <button onClick={addPost}>
            <i className="fa-solid fa-plus"></i> Shto Aktivitet
          </button>
        </div>
      </div>
      <div className="manage-table">
        <table className="table">
          <thead>
            <tr>
              <th>Titulli</th>
              <th>Data</th>
              <th>Veprime</th>
            </tr>
          </thead>
          <tbody>
            {filteredPosts.length > 0 ? (
              filteredPosts.map((item, index) => (
                <tr key={index}>
                  <td data-label="Titulli">{item.title}</td>
                  <td data-label="Data">{item.createdOn}</td>
                  <td className="actions" data-label="Veprime">
                    <button onClick={() => editPost(item)}>
                      <i className="fa-solid fa-pen-to-square"></i>
                    </button>
                    <button onClick={() => deletePost(item)}>
                      <i className="fa-solid fa-trash"></i>
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="3">Nuk u gjetën postime!</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="pagination">
        <button
          className={`pagination-control-btn ${
            currentPage === 1 ? "disabled" : ""
          }`}
          disabled={currentPage === 1}
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
        >
          &#8592; Para
        </button>
        <span className="pagination-current-page">
          Page {currentPage} of {totalPages}
        </span>
        <button
          className={`pagination-control-btn ${
            currentPage === totalPages ? "disabled" : ""
          }`}
          disabled={currentPage === totalPages}
          onClick={() =>
            setCurrentPage((prev) => Math.min(prev + 1, totalPages))
          }
        >
          Pas &#8594;
        </button>
      </div>
    </div>
  );
};

export default ManagePosts;

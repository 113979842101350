import "./App.css";
import { Route, Routes } from "react-router-dom";
import Navbar from "./Navbar/Navbar.jsx";
import Footer from "./Footer/Footer.jsx";
import ManageNavbar from "./ManageWeb/ManageNavbar/ManageNavbar.jsx";
import Sidebar from "./ManageWeb/Sidebar/Sidebar.jsx";

import Drejtoria from "./Drejtoria/Drejtoria.jsx";
import Sektori from "./Drejtoria/Sektori.jsx";
import Kerko from "./Kerko/Kerko.jsx";
import KerkimMePagese from "./KerkimMePagese/KerkimMePagese.jsx";
import Legjislacioni from "./Legjislacioni/Legjislacioni.jsx";
import ProgramiTransparences from "./Kontakte/ProgramiTransparences/ProgramiTransparences.jsx";
import PolitikaPrivatesise from "./Kontakte/PolitikaPrivatesise/PolitikaPrivatesise.jsx";
import Kontakte from "./Kontakte/Kontakte/Kontakte.jsx";
import LabRestaurimit from "./LaboratoriRestaurimit/LabRestaurimit.jsx";
import Sherbimet from "./Sherbimet/Sherbimet.jsx";

import Login from "./ManageWeb/Login/Login.jsx";
import ManageEkspozita from "./ManageWeb/ManageEkspozita/ManageEkspozita.jsx";
import EditEkspozita from "./ManageWeb/ManageEkspozita/EditEkspozita.jsx";
import AddEkspozita from "./ManageWeb/ManageEkspozita/AddEkspozita.jsx";
import ManageVideo from "./ManageWeb/ManageVideo/ManageVideo.jsx";
import EditVideo from "./ManageWeb/ManageVideo/EditVideo.jsx";
import ManagePosts from "./ManageWeb/ManagePosts/ManagePosts.jsx";
import EditPost from "./ManageWeb/ManagePosts/EditPost.jsx";
import AddPost from "./ManageWeb/ManagePosts/AddPost.jsx";
import MuzeuVirtual from "./MuzeuVirtual/MuzeuVirtual.jsx";
import Ekspozita from "./MuzeuVirtual/Ekspozita.jsx";
import ManageObjekteMuzeale from "./ManageWeb/ManageObjekte/ManageObjekte.jsx";
import EditObjekteMuzeal from "./ManageWeb/ManageObjekte/EditObjekte.jsx";
import AddObjekte from "./ManageWeb/ManageObjekte/AddObjekte.jsx";
import AddVideo from "./ManageWeb/ManageVideo/AddVideo.jsx";
import ManageRevista from "./ManageWeb/ManageRevista/ManageRevista.jsx";
import AddRevista from "./ManageWeb/ManageRevista/AddRevista.jsx";
import RevistaASH from "./Botime/RevistaASH.jsx";
import ManageBotime from "./ManageWeb/ManageBotime/ManageBotime.jsx";
import EditBotime from "./ManageWeb/ManageBotime/EditBotime.jsx";
import AddBotime from "./ManageWeb/ManageBotime/AddBotime.jsx";
import BotimeAQSH from "./Botime/BotimeAQSH.jsx";
import Post from "./Posts/Post.jsx";
import Posts from "./Posts/Posts.jsx";
import Njoftim from "./Warning/Warning.jsx";
import Videos from "./Videos/Videos.jsx";
import Arkiva from "./Arkiva/Arkiva.jsx";
import ManageRegjistriRhk from "./ManageWeb/ManageRegjistriRHK/ManageRegjistriRHK.jsx";
import AddRegjistriRhk from "./ManageWeb/ManageRegjistriRHK/AddRegjistriRHK.jsx";
import RegjistriRHK from "./Inspektimi/RegjistriRHK.jsx";
import EditRegjistriRhk from "./ManageWeb/ManageRegjistriRHK/EditRegjistriRHK.jsx";
import ManageRegjistriDokHumbura from "./ManageWeb/ManageRegjistriDokHumbura/ManageRegjistriDokHumbura.jsx";
import EditRegjistriDokHumbura from "./ManageWeb/ManageRegjistriDokHumbura/EditDokHumbur.jsx";
import AddRegjistriDokHumbura from "./ManageWeb/ManageRegjistriDokHumbura/AddDokHumbur.jsx";
import RegjistriDokHumbura from "./Inspektimi/RegjistriDokHumbura.jsx";
import Manual from "./Inspektimi/Manual.jsx";
import Restaurim from "./Sherbimet/Restaurim.jsx";
import KerkesaSocialJuridike from "./Sherbimet/KerkesaSocialJuridike.jsx";
import EditRevista from "./ManageWeb/ManageRevista/EditRevista.jsx";
import Doreshkrime from "./Arkiva/Doreshkrime.jsx";
import Dashboard from "./ManageWeb/Dashboard/Dashboard.jsx";
import ManageUser from "./ManageWeb/ManageUser/ManageUser.jsx";
import AllPosts from "./Posts/AllPosts.jsx";
import StatisticsPage from "./Statistics/Statistics.jsx";
import { Bounce, ToastContainer } from "react-toastify";

const Layout = ({ children, isManage }) => (
  <>
    {isManage ? <ManageNavbar /> : <Navbar />}
    <div className={isManage ? "layout" : ""}>
      {isManage && <Sidebar />}
      <div className={isManage ? "manage-content" : ""}>{children}</div>
    </div>
    {!isManage && <Footer />}
  </>
);

function App() {
  return (
    <div className="App">
      <Routes>
        {/* Public Routes */}
        <Route
          path="/"
          element={
            <Layout isManage={false}>
              <Sherbimet />
              <Posts />
              <Njoftim />
              <Videos />
              <Arkiva />
            </Layout>
          }
        />
        <Route
          path="/restaurim/"
          element={
            <Layout isManage={false}>
              <Restaurim />
            </Layout>
          }
        />
        <Route
          path="/kerkesa-social-juridike"
          element={
            <Layout isManage={false}>
              <KerkesaSocialJuridike />
            </Layout>
          }
        />
        <Route
          path="/opendata"
          element={
            <Layout isManage={false}>
              <StatisticsPage />
            </Layout>
          }
        />
        <Route
          path="/aktivitete/"
          element={
            <Layout isManage={false}>
              <AllPosts />
            </Layout>
          }
        />
        <Route
          path="/aktivitete/:id"
          element={
            <Layout isManage={false}>
              <Post />
            </Layout>
          }
        />
        <Route
          path="/doreshkrime-bizantine-dhe-pasbizantine"
          element={
            <Layout isManage={false}>
              <Doreshkrime />
            </Layout>
          }
        />
        <Route
          path="/drejtoria/*"
          element={
            <Layout isManage={false}>
              <Drejtoria />
            </Layout>
          }
        />
        <Route
          path="/struktura/*"
          element={
            <Layout isManage={false}>
              <Sektori />
            </Layout>
          }
        />
        <Route
          path="/kerko/*"
          element={
            <Layout isManage={false}>
              <Kerko />
            </Layout>
          }
        />
        <Route
          path="/kerkim-me-pagese/*"
          element={
            <Layout isManage={false}>
              <KerkimMePagese />
            </Layout>
          }
        />
        <Route
          path="/muzeu-virtual/*"
          element={
            <Layout isManage={false}>
              <MuzeuVirtual />
            </Layout>
          }
        />
        <Route
          path="/ekspozita/:id"
          element={
            <Layout isManage={false}>
              <Ekspozita />
            </Layout>
          }
        />
        <Route
          path="/botime/revista"
          element={
            <Layout isManage={false}>
              <RevistaASH />
            </Layout>
          }
        />
        <Route
          path="/botime/aqsh"
          element={
            <Layout isManage={false}>
              <BotimeAQSH />
            </Layout>
          }
        />
        <Route
          path="/legjislacioni/*"
          element={
            <Layout isManage={false}>
              <Legjislacioni />
            </Layout>
          }
        />
        <Route
          path="/inspektimi/rregjistri-i-dokumenteve-rhk-ne-pronesi-te-privateve"
          element={
            <Layout isManage={false}>
              <RegjistriRHK />
            </Layout>
          }
        />
        <Route
          path="/inspektimi/tabela-e-rigjetjes-se-dokumentave"
          element={
            <Layout isManage={false}>
              <RegjistriDokHumbura />
            </Layout>
          }
        />
        <Route
          path="/inspektimi/manual"
          element={
            <Layout isManage={false}>
              <Manual />
            </Layout>
          }
        />
        <Route
          path="/programi-i-transparences"
          element={
            <Layout isManage={false}>
              <ProgramiTransparences />
            </Layout>
          }
        />
        <Route
          path="/politika-e-privatesise"
          element={
            <Layout isManage={false}>
              <PolitikaPrivatesise />
            </Layout>
          }
        />
        <Route
          path="/kontakte"
          element={
            <Layout isManage={false}>
              <Kontakte />
            </Layout>
          }
        />
        <Route
          path="/laboratori-i-restaurimit"
          element={
            <Layout isManage={false}>
              <LabRestaurimit />
            </Layout>
          }
        />
        <Route path="/login" element={<Login />} />
        {/* Manage Routes */}
        <Route
          path="/manage/dashboard"
          element={
            <Layout isManage={true}>
              <Dashboard />
            </Layout>
          }
        />
        <Route
          path="/manage/aktivitete"
          element={
            <Layout isManage={true}>
              <ManagePosts />
            </Layout>
          }
        />
        <Route
          path="/manage/aktivitete/:id"
          element={
            <Layout isManage={true}>
              <EditPost />
            </Layout>
          }
        />
        <Route
          path="/manage/aktivitete/add-new"
          element={
            <Layout isManage={true}>
              <AddPost />
            </Layout>
          }
        />
        <Route
          path="/manage/ekspozita"
          element={
            <Layout isManage={true}>
              <ManageEkspozita />
            </Layout>
          }
        />
        <Route
          path="/manage/ekspozita/:id"
          element={
            <Layout isManage={true}>
              <EditEkspozita />
            </Layout>
          }
        />
        <Route
          path="/manage/ekspozita/add-new"
          element={
            <Layout isManage={true}>
              <AddEkspozita />
            </Layout>
          }
        />
        <Route
          path="/manage/revista"
          element={
            <Layout isManage={true}>
              <ManageRevista />
            </Layout>
          }
        />
        <Route
          path="/manage/revista/:id"
          element={
            <Layout isManage={true}>
              <EditRevista />
            </Layout>
          }
        />
        <Route
          path="/manage/revista/add-new"
          element={
            <Layout isManage={true}>
              <AddRevista />
            </Layout>
          }
        />
        <Route
          path="/manage/objekte-muzeale"
          element={
            <Layout isManage={true}>
              <ManageObjekteMuzeale />
            </Layout>
          }
        />
        <Route
          path="/manage/objekte-muzeale/:id"
          element={
            <Layout isManage={true}>
              <EditObjekteMuzeal />
            </Layout>
          }
        />
        <Route
          path="/manage/objekte-muzeale/add-new"
          element={
            <Layout isManage={true}>
              <AddObjekte />
            </Layout>
          }
        />
        <Route
          path="/manage/botime"
          element={
            <Layout isManage={true}>
              <ManageBotime />
            </Layout>
          }
        />
        <Route
          path="/manage/botime/:id"
          element={
            <Layout isManage={true}>
              <EditBotime />
            </Layout>
          }
        />
        <Route
          path="/manage/botime/add-new"
          element={
            <Layout isManage={true}>
              <AddBotime />
            </Layout>
          }
        />
        <Route
          path="/manage/video"
          element={
            <Layout isManage={true}>
              <ManageVideo />
            </Layout>
          }
        />
        <Route
          path="/manage/video/:id"
          element={
            <Layout isManage={true}>
              <EditVideo />
            </Layout>
          }
        />
        <Route
          path="/manage/video/add-new"
          element={
            <Layout isManage={true}>
              <AddVideo />
            </Layout>
          }
        />
        <Route
          path="/manage/regjistriRhk"
          element={
            <Layout isManage={true}>
              <ManageRegjistriRhk />
            </Layout>
          }
        />
        <Route
          path="/manage/regjistriRhk/:id"
          element={
            <Layout isManage={true}>
              <EditRegjistriRhk />
            </Layout>
          }
        />
        <Route
          path="/manage/regjistriRhk/add-new"
          element={
            <Layout isManage={true}>
              <AddRegjistriRhk />
            </Layout>
          }
        />
        <Route
          path="/manage/regjistriDokHumbura"
          element={
            <Layout isManage={true}>
              <ManageRegjistriDokHumbura />
            </Layout>
          }
        />
        <Route
          path="/manage/regjistriDokHumbura/:id"
          element={
            <Layout isManage={true}>
              <EditRegjistriDokHumbura />
            </Layout>
          }
        />
        <Route
          path="/manage/regjistriDokHumbura/add-new"
          element={
            <Layout isManage={true}>
              <AddRegjistriDokHumbura />
            </Layout>
          }
        />
        <Route
          path="/manage/user"
          element={
            <Layout isManage={true}>
              <ManageUser />
            </Layout>
          }
        />
      </Routes>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Bounce}
      />
    </div>
  );
}

export default App;

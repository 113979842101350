import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DefaultImage from "../../Assets/Images/SideBarLogo.png";
import { useDispatch, useSelector } from "react-redux";
import { addBotime } from "../../Redux/Actions/botimeActions";

const AddBotime = () => {
  const navigate = useNavigate();
  const loggedUser = useSelector((state) => state.users.loggedUser);
  const isLoggedIn = Object.keys(loggedUser).length > 0;
  const [title, setTitle] = useState("");
  const [author, setAuthor] = useState("");
  const [image, setImage] = useState(null);
  const [imageName, setImageName] = useState(null);
  const [imagePreview, setImagePreview] = useState(DefaultImage);
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const [newId, setNewId] = useState(null);

  if (!isLoggedIn) {
    navigate("/login");
  }

  const validateForm = () => {
    const errors = {};

    if (!title.trim()) errors.title = "Titulli nuk mund të jetë bosh!";
    if (!author.trim()) errors.author = "Autori nuk mund të jetë bosh!";
    if (!imageName) errors.image = "Imazhi nuk mund të jetë bosh!";

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const uploadImage = (e) => {
    const file = e.target.files[0];
    setImageName(file.name);

    if (file) {
      setImage(file);
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const handleCancel = () => {
    navigate("/manage/botime");
  };

  const getLatestId = async () => {
    try {
      const response = await axios.get(
        "https://api.arkiva.gov.al/api/botime/count"
      );
      return response.data.latestId + 1;
    } catch (error) {
      console.error("Error fetching latest ID:", error);
      return null;
    }
  };

  useEffect(() => {
    const fetchLatestId = async () => {
      const newId = await getLatestId();
      setNewId(newId);
    };
    fetchLatestId();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    const botimeData = {
      title,
      author,
      id: newId,
      image: image.name,
    };

    try {
      const response = await axios.post(
        "https://api.arkiva.gov.al/api/botime/create",
        botimeData
      );

      dispatch(addBotime(response.data));
      const formData = new FormData();

      formData.append("image", image);

      const resp = await axios.post(
        "https://api.arkiva.gov.al/api/botime/uploadFiles",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },

          params: {
            folderId: newId,
          },
        }
      );

      navigate("/manage/botime");
    } catch (err) {
      console.error("Error adding botime:", err);
    }
  };

  return (
    <div className="edit-page">
      <div className="return">
        <button onClick={handleCancel}>
          <i className="fa-solid fa-chevron-left"></i>
        </button>
      </div>
      <p>Shto Botim të AQSH</p>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="title">Titulli</label>
          <input
            type="text"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          {errors.title && <small className="error">{errors.title}</small>}
        </div>

        <div className="form-group">
          <label htmlFor="author">Autori</label>
          <input
            type="text"
            id="author"
            value={author}
            onChange={(e) => setAuthor(e.target.value)}
          />
          {errors.author && <small className="error">{errors.author}</small>}
        </div>

        <div className="form-group">
          <label>Imazhi</label>
          <div className="image-wrapper">
            <img
              src={imagePreview}
              alt="preview"
              style={{ width: "120px", height: "120px", objectFit: "cover" }}
            />
          </div>
          <input type="file" accept="image/*" onChange={uploadImage} />
          {errors.image && <small className="error">{errors.image}</small>}
        </div>

        <div className="edit-actions">
          <button type="submit" className="save">
            Shto botim
          </button>
          <button type="button" className="cancel" onClick={handleCancel}>
            Anullo
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddBotime;

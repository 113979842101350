import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addVideo } from "../../Redux/Actions/videoActions";

const AddVideo = () => {
  const dispatch = useDispatch();
  const loggedUser = useSelector((state) => state.users.loggedUser);
  const isLoggedIn = Object.keys(loggedUser).length > 0;
  const [title, setTitle] = useState("");
  const [createdOn, setCreatedOn] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});

  if (!isLoggedIn) navigate("/login");

  const validateForm = () => {
    const errors = {};
    const dateRegex = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/;
    const youtubeUrlRegex =
      /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/)[a-zA-Z0-9_-]{11}$/;
    const monthDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    const isLeapYear = (year) => {
      return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
    };

    const isValidDate = (day, month, year) => {
      if (month < 1 || month > 12) return false;
      const maxDays =
        month === 2 && isLeapYear(year) ? 29 : monthDays[month - 1];
      return day >= 1 && day <= maxDays;
    };

    if (!title.trim()) errors.title = "Titulli nuk mund të jetë bosh!";

    if (!createdOn.trim()) {
      errors.createdOn = "Data e krijimit nuk mund të jetë bosh!";
    } else {
      const match = createdOn.match(dateRegex);
      if (!match) {
        errors.createdOn =
          "Data e krijimit duhet të jetë në formatin 'dd/mm/yyyy' ose 'd/m/yyyy'!";
      } else {
        const [_, day, month, year] = match.map(Number);
        const enteredDate = new Date(year, month - 1, day);
        const currentDate = new Date();

        if (!isValidDate(day, month, year)) {
          errors.createdOn =
            "Datë jo e saktë!Kontrolloni ditën, muajin dhe vitin!";
        } else if (enteredDate > currentDate) {
          errors.createdOn = "Data nuk mund të jetë në të ardhmen!";
        }
      }
    }

    if (!videoUrl.trim()) {
      errors.videoUrl = "Video URL nuk mund të jetë bosh!";
    } else if (!youtubeUrlRegex.test(videoUrl)) {
      errors.videoUrl = "Video URL jo e saktë!";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleCancel = () => {
    navigate("/manage/video");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    const formData = new FormData();
    formData.append("title", title);
    formData.append("createdOn", createdOn);
    formData.append("videoUrl", videoUrl);

    try {
      setLoading(true);

      const response = await axios.post(
        "https://api.arkiva.gov.al/api/video/create",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setLoading(false);

      if (response.data.success) {
        dispatch(addVideo(response.data.data));
        navigate("/manage/video");
      } else {
        alert(
          response.data.message || "An error occurred while adding the video."
        );
      }
    } catch (error) {
      console.error("Error adding video:", error);
      setLoading(false);
      alert("An error occurred while adding the video.");
    }
  };

  return (
    <div className="edit-page">
      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}
      <div className="return">
        <button onClick={handleCancel}>
          <i className="fa-solid fa-chevron-left"></i>
        </button>
      </div>
      <p>Shto Video</p>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="title">Titulli</label>
          <input
            type="text"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          {errors.title && <small className="error">{errors.title}</small>}
        </div>

        <div className="form-group">
          <label htmlFor="createdOn">Data e krijimit</label>
          <input
            type="text"
            id="createdOn"
            value={createdOn}
            onChange={(e) => setCreatedOn(e.target.value)}
          />
          {errors.createdOn && (
            <small className="error">{errors.createdOn}</small>
          )}
        </div>

        <div className="form-group">
          <label htmlFor="videoUrl">Video URL</label>
          <input
            type="text"
            id="videoUrl"
            value={videoUrl}
            onChange={(e) => setVideoUrl(e.target.value)}
          />
          {errors.videoUrl && (
            <small className="error">{errors.videoUrl}</small>
          )}
        </div>

        <div className="edit-actions">
          <button type="submit" className="save">
            Shto Video
          </button>
          <button type="button" className="cancel" onClick={handleCancel}>
            Anullo
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddVideo;

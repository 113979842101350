import {
  ADD_REGJSTRI_RHK,
  DELETE_REGJSTRI_RHK,
  SET_REGJSTRI_RHK,
  SET_REGJSTRI_RHK_ON_EDIT,
  UPDATE_REGJSTRI_RHK,
} from "../Actions/regjistriRhkActions";

const initialState = {
  regjistriRhk: [],
  regjistriRhkOnEdit: {},
};

const regjistriRhkReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_REGJSTRI_RHK:
      return {
        ...state,
        regjistriRhk: action.payload,
      };
    case SET_REGJSTRI_RHK_ON_EDIT: {
      return {
        ...state,
        regjistriRhkOnEdit: action.payload,
      };
    }
    case UPDATE_REGJSTRI_RHK: {
      const updatedRegjistriRhk = action.payload;

      return {
        ...state,
        regjistriRhk: state.regjistriRhk.map((regjistriRhk) =>
          regjistriRhk._id === updatedRegjistriRhk._id
            ? { ...regjistriRhk, ...updatedRegjistriRhk }
            : regjistriRhk
        ),
      };
    }

    case DELETE_REGJSTRI_RHK:
      const newRegjistriRhk = state.regjistriRhk.filter(
        (item) => item._id !== action.payload
      );
      return {
        ...state,
        regjistriRhkOnEdit: {},
        regjistriRhk: newRegjistriRhk,
      };

    case ADD_REGJSTRI_RHK: {
      return {
        ...state,
        regjistriRhk: [...state.regjistriRhk, action.payload],
      };
    }

    default: {
      return state;
    }
  }
};

export default regjistriRhkReducer;

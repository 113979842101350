import "./sidebar.css";
import Logo from "../../Assets/Images/SideBarLogo.png";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

const Sidebar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [indicatorHeight, setIndicatorHeight] = useState(0);
  const [indicatorTop, setIndicatorTop] = useState(0);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const loggedUser = useSelector((state) => state.users.loggedUser);
  const isLoggedIn = Object.keys(loggedUser).length > 0;
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      if (window.innerWidth > 1500) {
        setIsSidebarOpen(true);
      } else if (window.innerWidth <= 880) {
        setIsSidebarOpen(false);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleToggle = () => {
    if (screenWidth <= 1500 && screenWidth > 880) {
      setIsSidebarOpen((prev) => !prev);
    }
  };

  const handleHover = (e) => {
    const itemHeight = e.target.offsetHeight;
    const offsetTop = e.target.offsetTop;
    setIndicatorHeight(itemHeight);
    setIndicatorTop(offsetTop);
  };

  const menu = [
    {
      title: "Dashboard",
      path: "/manage/dashboard",
      icon: "fa-solid fa-house",
    },
    {
      title: "Aktivitete",
      path: "/manage/aktivitete",
      icon: "fa-regular fa-newspaper",
    },
    { title: "Video", path: "/manage/video", icon: "fa-brands fa-youtube" },
    {
      title: "Ekspozita",
      path: "/manage/ekspozita",
      icon: "fa-solid fa-person-chalkboard",
    },
    {
      title: "Objekte Muzeale",
      path: "/manage/objekte-muzeale",
      icon: "fa-solid fa-building-columns",
    },
    {
      title: "Revista Arkivi Shqiptar",
      path: "/manage/revista",
      icon: "fa-solid fa-newspaper",
    },
    {
      title: "Botime te AQSH",
      path: "/manage/botime",
      icon: "fa-solid fa-book",
    },
    {
      title: "Rregj. i Dok. RHK",
      path: "/manage/regjistriRhk",
      icon: "fa-solid fa-book-open",
    },
    {
      title: "Rregj. i Dok.  të Humbura",
      path: "/manage/regjistriDokHumbura",
      icon: "fa-solid fa-rectangle-list",
    },
  ];

  return (
    <div
      className={`sidebar-container ${
        isSidebarOpen ? "expanded" : "collapsed"
      }`}
    >
      <div className={`sidebar ${isSidebarOpen ? "expanded" : "collapsed"}`}>
        <div className={`head ${isSidebarOpen ? "expanded" : "collapsed"}`}>
          <div className={`logo ${isSidebarOpen ? "expanded" : "collapsed"}`}>
            <img src={Logo} alt="logo" />
          </div>
          {window.innerWidth < 1500 && (
            <div
              className={`menu-btn ${isSidebarOpen ? "expanded" : "collapsed"}`}
              onClick={handleToggle}
            >
              <i
                className={`fa-solid fa-angle-${
                  isSidebarOpen ? "left" : "right"
                }`}
              ></i>
            </div>
          )}
        </div>
        <div className={`nav ${isSidebarOpen ? "expanded" : "collapsed"}`}>
          <div className={`menu ${isSidebarOpen ? "expanded" : "collapsed"}`}>
            <p className="title">{isSidebarOpen && "Menu"}</p>
            <ul>
              {menu.map((menuItem) => (
                <li
                  key={menuItem.path}
                  onMouseOver={handleHover}
                  onClick={() => navigate(menuItem.path)}
                  className={
                    location.pathname.includes(menuItem.path) ? "active" : ""
                  }
                  title={menuItem.title}
                >
                  <i className="icon">
                    <i className={menuItem.icon}></i>
                  </i>
                  {isSidebarOpen && (
                    <span className="text">{menuItem.title}</span>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;

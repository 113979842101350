import "./labRestaurimit.css";
const LabRestaurimit = () => {
  return (
    <div className="page">
      <div className="page-header">
        <div className="headerTitleLine"></div>
        <h1 className="page-title">Laboratori Restaurimit</h1>
      </div>

      <div className="page-content restaurimi">
        <p>
          Laboratori i Restaurimit përbëhet nga përgjegjësi i sektorit,
          specialist dhe restaurator dokumenta. Përgjegjësi i Sektorit
          (Laboratorit) Qëndror të Restaurimit parashikon dhe planifikon
          veprimtari në lidhje me detyrat vjetore të përcaktuara për sektorin,
          me qëllim realizimin në kohë të tyre, si dhe siguron që puna të kryhet
          brenda normave të përgjithshme, në afatet e përcaktuara sipas
          standardeve administrative dhe procedurave teknike, kontrollon dhe
          monitoron veprimtaritë e sektorit në përmbushjen e tyre sipas plan-
          evidencave mujore, shpërndan punën mes specialistëve të sektorit në
          përputhje me legjislacionin dhe rregulloren e brendshme të
          institucionit dhe informon Drejtorin e Përgjithshëm, mbikëqyr dhe
          udhëzon specialistët, në lidhje me detyrat e ngarkuara dhe jep
          mbështetje dhe këshillim për problematika të ndryshme, mbikëqyr
          zbatimin e rregullores së brendshme të institucionit, me qëllim
          realizimin e veprimtarisë së sektorit, në përputhje me udhëzimet e
          dhëna dhe informon drejtorin e përgjithshëm për mbarëvajtjen e punës
          apo problematikat e hasura, mbështet kërkesat e punonjësve të rinj apo
          ekzistues në lidhje me mjetet materiale për ushtrimin e detyrës,
          përgatit prezencën ditore dhe mujore të punonjësve dhe raporton tek
          drejtori i përgjithshëm për disiplinën e punës në sektor, harton
          planin e punës mujor dhe punon për zbatimin e tij pas miratimit në
          shkallë hierarkike, si dhe raporton te eprori direkt për problematika
          të hasura gjatë procesit të punës dhe zbaton detyra të tjera të
          përcaktuara nga Drejtori i Përgjithshëm i Arkivave. Specialisti në
          Laboratorin Kimik në AQSH kryen verifikimin fizik të dokumenteve që
          merren në laborator dhe pajisjen me fletë vërtetimi për dosjet, bëjnë
          evidentimin e dokumentave të dëmtuara duke i klasifikuar sipas shkakut
          të dëmtimit, bëjnë pastrimin e dokumenteve nga të gjitha mbishtresat e
          huaja që sjanë pjesë e dokumentit, bëjnë shplurëzimin fizik të
          dokumenteve që trajtohen, trajtimin kimik antimykotik, trajtimin kimik
          të dokumenteve duke kryer procesin e deacifikimit, bën lidhjen e
          mjeteve të informacionit (inventarëve), bashkëpunon me punonjësit e të
          gjithë sektorëve për evidentimin e dokumenteve të dëmtuara dhe zbaton
          detyra të tjera të përcaktuara nga eprori direct dhe Drejtori i
          Përgjithshëm i Arkivave. Restauratori restauron okumentet me shkallë
          të lartë dëmtimi, bën kollaritjen e dokumentit me metilcelulozë për
          vitalizimin e tij, bën shtrirjen e dokumentit me presë me metodën e
          lagështimit duke e vendosur çdo dokument midis filtrave dhe më pas në
          presë, Arnon dokumentet me plotësimin e pjesëve të dëmtuara me letër
          restaurimi, Përforcon dokumentet me letër japoneze mbas procesit të
          arnimit, bën laminim të dokumenteve duke bërë plastifikimin me bifiks
          dhe sereks, në rastet kur dokumenti është në shkallën më të lartë të
          dëmtimit dhe zbaton detyra të tjera të përcaktuara nga eprori direkt
          dhe Drejtori i Përgjithshëm i Arkivave.
        </p>
      </div>
    </div>
  );
};

export default LabRestaurimit;

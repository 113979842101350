import { useEffect, useState } from "react";
import "./inspektimi.css";
import axios from "axios";
import { setRegjistriRhk } from "../Redux/Actions/regjistriRhkActions";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

const RegjistriRHK = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [regjistriRhk, setRegjistriDokRhk] = useState([]);
  const location = useLocation();

  const fetchRegjistriRhk = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        "https://api.arkiva.gov.al/api/regjistriRhk"
      );
      setRegjistriDokRhk(response.data.regjistriRhk);
      dispatch(setRegjistriRhk(response.data.regjistriRhk));
    } catch (error) {
      console.error("Error fetching regjistri RHK:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      location.pathname ===
      "/inspektimi/rregjistri-i-dokumenteve-rhk-ne-pronesi-te-privateve"
    ) {
      fetchRegjistriRhk();
    }
  }, [location.pathname]);

  return (
    <div className="page">
      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}
      <div className="page-header">
        <div className="headerTitleLine"></div>
        <h1 className="page-title">
          Rregjistri i Dokumenteve RHK në Pronësi të privatëve
        </h1>
      </div>
      <div className="regjistri-rkh">
        {regjistriRhk.length > 0 ? (
          <div className="table-container">
            <div className="grid-row grid-header">
              <div>Nr</div>
              <div>Emri i Subjektit që e Posedon</div>
              <div>Vendi në të cilin ndodhet Dokumenti (adresa)</div>
              <div>Viti i Dokumentit</div>
              <div>Emërtimi i Fondkrijuesit</div>
              <div>Mënyra e posedimit të dokumentit RHK</div>
              <div>Përshkrimi i shkurtër i dokumentit</div>
              <div>Sasia e fletëve</div>
              <div>Numri i kontaktit i subjektit</div>
              <div>Shënime</div>
            </div>
            {regjistriRhk.map((item, index) => (
              <div className="grid-row rows" key={item._id}>
                <div data-label="Nr:">{index + 1}</div>
                <div data-label="Emri i Subjektit që e Posedon:">
                  {item.subject}
                </div>
                <div data-label="Vendi në të cilin ndodhet Dokumenti (adresa):">
                  {item.location}
                </div>
                <div data-label="Viti i Dokumentit:">{item.year}</div>
                <div data-label="Emërtimi i Fondkrijuesit:">{item.fond}</div>
                <div data-label="Mënyra e posedimit të dokumentit RHK:">
                  {item.ownMethod}
                </div>
                <div data-label="Përshkrimi i shkurtër i dokumentit:">
                  {item.description}
                </div>
                <div data-label="Sasia e fletëve:">{item.pagesNr}</div>
                <div data-label="Numri i kontaktit i subjektit:">
                  {item.phoneNr}
                </div>
                <div data-label="Shënime:">{item.notes}</div>
              </div>
            ))}
          </div>
        ) : (
          <p>No data available.</p>
        )}
      </div>
    </div>
  );
};

export default RegjistriRHK;

import { useState } from "react";
import "./sherbimet.css";
import axios from "axios";
import { Bounce, toast } from "react-toastify";

const Restaurim = () => {
  const [valid, setValid] = useState(false);
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [docType, setDocType] = useState("");
  const [damage, setDamage] = useState("");
  const [errors, setErrors] = useState({});

  const regEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const regex = /^[A-Za-z]+$/;

  const validateForm = (e) => {
    e.preventDefault();
    const validationErrors = {};

    if (!name) {
      validationErrors.name = "Emri nuk mund të jetë bosh!";
    } else if (!regex.test(name)) {
      validationErrors.name = "Emri duhet të përmbajë vetëm shkronja!";
    }

    if (!lastName) {
      validationErrors.lastName = "Mbiemri nuk mund të jetë bosh!";
    } else if (!regex.test(lastName)) {
      validationErrors.lastName = "Emri duhet të përmbajë vetëm shkronja!";
    }

    if (!email) {
      validationErrors.email = "Email nuk mund të jetë bosh!";
    } else if (!regEmail.test(email)) {
      validationErrors.email = "Email jo i saktë!";
    }

    if (!phone) {
      validationErrors.phone = "Numri i telefoni nuk mund të jetë bosh!";
    } else if (!/^\d{10,15}$/.test(phone)) {
      validationErrors.phone =
        "Numri i telefonit duhet të përmbajë vetëm numra!";
    }

    if (!docType) {
      validationErrors.docType = "Lloji i dokumentit nuk mund të jetë bosh!";
    }

    if (!damage) {
      validationErrors.damage = "Përshkrimi i dëmtimit nuk mund të jetë bosh!";
    }

    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      submitForm();
    }
  };

  const submitForm = async () => {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("lastName", lastName);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("docType", docType);
    formData.append("damage", damage);
    formData.append(
      "file",
      document.querySelector('input[type="file"]').files[0]
    );

    try {
      await axios.post("https://api.arkiva.gov.al/api/restaurim", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      toast.success("Kërkesa u dërgua në email!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });

      setValid(true);
      setName("");
      setLastName("");
      setEmail("");
      setPhone("");
      setDocType("");
      setDamage("");
      setErrors({});
    } catch (error) {
      console.error("Error sending message:", error);
      setValid(false);
    }
  };

  return (
    <div className="restaurim">
      <h1 className="restaurim-title">Laboratori Qëndror i Restaurimit</h1>
      <div className="restaurim-content">
        <p>
          Laboratori Qendror i Restaurimit në DPA është i vetmi laborator për
          restaurimin e dokumenteve në Shqipëri. Krahas dokumenteve tona, ne
          restaurojmë edhe dokumente që janë në pronësi të privatëve,
          pavarësisht moshës dhe shkallës së dëmtimit të tij. Tarifa e
          restaurimit përllogaritet bazuar në proceset e punës që duhen kryer.
        </p>
        <p>
          Nëse keni një dokument për restaurim, plotësoni të dhënat e
          poshtëshënuara dhe do të kontaktoheni brenda dy ditësh pune nga
          restauratorët tanë për të lënë një takim me qëllim kqyrjen e
          ndërhyrjeve të nevojshme dhe llogaritjen e tarifës përkatëse
          shtetërore.
        </p>
      </div>
      <form className="restaurim-form" onSubmit={validateForm}>
        <div className="form-row">
          <div className="form-row-element">
            <label htmlFor="emri">Emri</label>
            <input
              type="text"
              name="emri"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            {errors.name && <small className="error">{errors.name}</small>}
          </div>
          <div className="form-row-element">
            <label htmlFor="mbiemri">Mbiemri</label>
            <input
              type="text"
              name="mbiemri"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            {errors.lastName && (
              <small className="error">{errors.lastName}</small>
            )}
          </div>
        </div>
        <div className="form-row">
          <div className="form-row-element">
            <label htmlFor="email">Email</label>
            <input
              type="text"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {errors.email && <small className="error">{errors.email}</small>}
          </div>
          <div className="form-row-element">
            <label htmlFor="tel">Nr.Telefonit</label>
            <input
              type="text"
              name="tel"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            {errors.phone && <small className="error">{errors.phone}</small>}
          </div>
        </div>
        <div className="form-row">
          <div className="form-row-element doc">
            <label htmlFor="doc">Lloji i dokumentit</label>
            <span>Përshkruani llojin e dokumentit me sa më shumë detaje</span>
            <textarea
              name="doc"
              value={docType}
              onChange={(e) => setDocType(e.target.value)}
            ></textarea>
            {errors.docType && (
              <small className="error">{errors.docType}</small>
            )}
          </div>
        </div>
        <div className="form-row">
          <div className="form-row-element doc">
            <label htmlFor="damage">Lloji i dëmtimit</label>
            <span>
              Përshkruani dëmtimin që ka dokumenti me sa më shumë detaje
            </span>
            <textarea
              name="damage"
              value={damage}
              onChange={(e) => setDamage(e.target.value)}
            ></textarea>
            {errors.damage && <small className="error">{errors.damage}</small>}
          </div>
        </div>
        <div className="form-row">
          <div className="form-row-element">
            <label htmlFor="image">Ngarko foto të dokumentit</label>
            <span>
              foto duhet të tregoj të gjithë dokumentin, përfshi dëmtimin
            </span>
            <input type="file" name="image" />
            {errors.image && <small className="error">{errors.image}</small>}
          </div>
        </div>
        <div className="notification">
          <div className="notification-title">Shënim</div>
          <div className="notification-content">
            Nëse dërgoni të dhëna të pasakta, veçanërisht numër telefoni ose
            email, DPA nuk mban përgjegjësi për pamundësinë e kontaktit me ju.
            Ditën që do të caktohet me restauratorin për të vizituar DPA duhet
            të keni me vete letërnjoftimin/pasaportën tuaj dhe dokumentin që
            doni të restauroni.
          </div>
        </div>
        <div className="button">
          <button className="send" type="submit">
            Dërgo
          </button>
        </div>
      </form>
    </div>
  );
};

export default Restaurim;

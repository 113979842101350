import "./politikaPrivatesise.css";
import { politikaPrivatesiseData } from "./politikaPrivatesiseData";

const renderContent = (content) => {
  if (typeof content === "string") {
    return <p>{content}</p>;
  }

  if (Array.isArray(content)) {
    return content.map((item, index) => {
      if (typeof item === "string") {
        return <p key={index}>{item}</p>;
      }

      if (Array.isArray(item)) {
        return (
          <ul key={index}>
            {item.map((nestedItem, nestedIndex) => (
              <li key={nestedIndex}>{nestedItem}</li>
            ))}
          </ul>
        );
      }

      return null;
    });
  }

  return null;
};

const PolitikaPrivatesise = () => {
  return (
    <div className="page-content privatesise">
      {politikaPrivatesiseData.map((item, index) => (
        <div className="section" key={index}>
          <div className="section-title">{item.title}</div>
          <div className="section-content">
            {renderContent(item.content)}

            {item.subcontent && item.subcontent.length > 0 && (
              <div className="subsections">
                {item.subcontent.map((subitem, subIndex) => (
                  <div className="subsection" key={subIndex}>
                    <div className="subsection-title">{subitem.title}</div>
                    <div className="subsection-content">
                      {renderContent(subitem.contentText)}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default PolitikaPrivatesise;

import {
  ADD_REGJSTRI_DOK_HUMBURA,
  DELETE_REGJSTRI_DOK_HUMBURA,
  SET_REGJSTRI_DOK_HUMBURA,
  SET_REGJSTRI_DOK_HUMBURA_ON_EDIT,
  UPDATE_REGJSTRI_DOK_HUMBURA,
} from "../Actions/regjDokHumburaActions";

const initialState = {
  regjistriDokHumbura: [],
  regjistriDokHumburaOnEdit: {},
};

const regjistriDokHumburaReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_REGJSTRI_DOK_HUMBURA:
      return {
        ...state,
        regjistriDokHumbura: action.payload,
      };
    case SET_REGJSTRI_DOK_HUMBURA_ON_EDIT: {
      return {
        ...state,
        regjistriDokHumburaOnEdit: action.payload,
      };
    }
    case UPDATE_REGJSTRI_DOK_HUMBURA: {
      const updatedRegjistriDokHumbura = action.payload;

      return {
        ...state,
        regjistriDokHumbura: state.regjistriDokHumbura.map(
          (regjistriDokHumbura) =>
            regjistriDokHumbura._id === updatedRegjistriDokHumbura._id
              ? { ...regjistriDokHumbura, ...updatedRegjistriDokHumbura }
              : regjistriDokHumbura
        ),
      };
    }

    case DELETE_REGJSTRI_DOK_HUMBURA:
      const newRegjistriDokHumbura = state.regjistriDokHumbura.filter(
        (item) => item._id !== action.payload
      );
      return {
        ...state,
        regjistriDokHumburaOnEdit: {},
        regjistriDokHumbura: newRegjistriDokHumbura,
      };

    case ADD_REGJSTRI_DOK_HUMBURA: {
      return {
        ...state,
        regjistriDokHumbura: [...state.regjistriDokHumbura, action.payload],
      };
    }

    default: {
      return state;
    }
  }
};

export default regjistriDokHumburaReducer;

import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

const Post = () => {
  const [post, setPost] = useState(null);
  const [latestPosts, setLatestPosts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchPostAndLatestPosts = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `https://api.arkiva.gov.al/api/posts/one/${id}`
        );
        setPost(response.data.post);
        setLatestPosts(response.data.latestPosts);
        setPost((prevPost) => ({
          ...prevPost,
          images: response.data.post.images || [],
        }));
      } catch (error) {
        console.error("Error fetching post and latest posts:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPostAndLatestPosts();
  }, [id]);

  if (!post) {
    return <div>Loading...</div>;
  }

  const coverImage = `/Assets/Images/Posts/${post.id}/${post.coverImage}`;
  const postImages = post.images.filter((image) => image !== coverImage);

  const openModal = (index) => {
    setCurrentImageIndex(index);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const nextSlide = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % postImages.length);
  };

  const prevSlide = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? postImages.length - 1 : prevIndex - 1
    );
  };

  return (
    <>
      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}
      <div className="page-format">
        <div className="page-format-left">
          <div className="page-left-date">{post.createdOn}</div>
          <h1 className="page-left-title">{post.title}</h1>
          <div className="page-left-description">
            <pre>{post.description}</pre>
          </div>
          <div className="page-left-gallery">
            {postImages.map((image, index) => (
              <div className="image-wrapper" key={index}>
                <img
                  src={image}
                  alt={`${index + 1}`}
                  className="image"
                  onClick={() => openModal(index)}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="page-format-right">
          <h3 className="page-right-title">Aktivitete</h3>
          <div className="page-right-posts">
            {latestPosts.map((activity) => (
              <div
                className="page-right-post"
                key={activity._id}
                onClick={() => navigate(`/aktivitete/${activity.slug}`)}
              >
                <div className="page-right-post-image">
                  <img
                    src={`/Assets/Images/Posts/${activity.id}/${activity.coverImage}`}
                    alt={activity.title}
                    className="image"
                  />
                </div>
                <div className="page-right-post-title">{activity.title}</div>
              </div>
            ))}
          </div>
        </div>

        {isModalOpen && (
          <div className="modal-post">
            <button className="modal-close" onClick={closeModal}>
              &times;
            </button>
            <button className="modal-prev-post" onClick={prevSlide}>
              &#10094;
            </button>
            <div className="modal-content-post">
              <img
                src={postImages[currentImageIndex]}
                alt={`Slide ${currentImageIndex + 1}`}
              />
            </div>
            <button className="modal-next-post" onClick={nextSlide}>
              &#10095;
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default Post;

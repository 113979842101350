import {
  ADD_REVISTA,
  DELETE_REVISTA,
  SET_REVISTA,
  SET_REVISTA_ON_EDIT,
  UPDATE_REVISTA,
} from "../Actions/revistaAShActions";

const initialState = {
  revista: [],
  revistaOnEdit: {},
};

const revistaReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_REVISTA:
      return {
        ...state,
        revista: action.payload,
      };
    case SET_REVISTA_ON_EDIT: {
      return {
        ...state,
        revistaOnEdit: action.payload,
      };
    }
    case UPDATE_REVISTA: {
      const updatedRevista = action.payload;
      return {
        ...state,
        revista: state.revista.map((revista) =>
          revista._id === updatedRevista._id
            ? { ...revista, ...updatedRevista }
            : revista
        ),
      };
    }

    case DELETE_REVISTA:
      const newRevista = state.revista.filter(
        (item) => item._id !== action.payload
      );
      return {
        ...state,
        revistaOnEdit: {},
        revista: newRevista,
      };

    case ADD_REVISTA: {
      return {
        ...state,
        revista: [...state.revista, action.payload],
      };
    }

    default: {
      return state;
    }
  }
};

export default revistaReducer;

import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "./botime.css";
import { setBotime } from "../Redux/Actions/botimeActions";
const BotimeAQSH = () => {
  const [botime, setBotimet] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchBotimeWithImages = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          "https://api.arkiva.gov.al/api/botime-with-images"
        );

        setBotimet(response.data);

        dispatch(setBotime(response.data));
      } catch (error) {
        console.error("Error fetching botime and images:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBotimeWithImages();
  }, []);

  return (
    <div className="page">
      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}
      <div className="page-header">
        <div className="headerTitleLine"></div>
        <h1 className="page-title">Botime të AQSH</h1>
      </div>
      <div className="botime-items-container">
        <div className="botime-items">
          {botime.map((item, index) => (
            <div className="botime-item" key={item.id || index}>
              <div className="botime-item-image">
                <img src={item.images[0]} alt={item.title} className="image" />
              </div>
              <p className="botime-item-title">{item.title}</p>
              <p className="botime-item-author">{item.author}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BotimeAQSH;

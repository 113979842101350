import React, { useState, useEffect } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const data = [
  { name: "First Contentful Paint", pv: 735, amt: 735 },
  { name: "Speed Index", pv: 2100, amt: 2100 },
  { name: "Largest Contentful Paint", pv: 1700, amt: 1700 },
  { name: "Time to Interactive", pv: 1600, amt: 1600 },
  { name: "Total Blocking Time", pv: 116, amt: 116 },
  { name: "Onload Time", pv: 593, amt: 593 },
  { name: "Backend Duration", pv: 467, amt: 467 },
];

export default function PerformanceChart() {
  const [chartWidth, setChartWidth] = useState("65%");

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1060) {
        setChartWidth("100%");
      } else if (window.innerWidth < 1140) {
        setChartWidth("50%");
      } else if (window.innerWidth < 1240) {
        setChartWidth("55%");
      } else if (window.innerWidth < 1450) {
        setChartWidth("60%");
      } else {
        setChartWidth("65%");
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <ResponsiveContainer width={chartWidth} height={320}>
      <BarChart
        layout="vertical"
        width={600}
        height={400}
        data={data}
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          type="number"
          label={{
            value: "Time (ms)",
            position: "insideBottom",
            offset: -5,
            style: { textAnchor: "middle" },
          }}
        />
        <YAxis
          dataKey="name"
          type="category"
          width={200}
          label={{
            value: "Performance Metrics",
            angle: -90,
            position: "insideLeft",
            style: { textAnchor: "middle" },
          }}
        />
        <Tooltip />
        <Legend />
        <Bar dataKey="pv" fill="#8884d8" />
      </BarChart>
    </ResponsiveContainer>
  );
}

import "./warning.css";
const Warning = () => {
  return (
    <div className="warning">
      <p className="notify-warning">
        Njoftim: Drejtoria e Përgjithshme e Arkivave njofton se, në rast të
        shfaqjes së problemeve me programin e sallës së studimit, ju lutemi të
        shkarkoni dhe instaloni versionin më të fundit për një funksionim të
        rregullt.
      </p>
    </div>
  );
};

export default Warning;

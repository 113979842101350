import ligjiPerArkivat from "../Assets/Documents/Ligji per arkivat.pdf";
import ligjiPerArkivatNdryshuar from "../Assets/Documents/Ligji 'Per arkivat' i ndryshuar.pdf";
import LigjiPerNepunesinCivil from "../Assets/Documents/Ligji per nepunesin civil.pdf";
import LigjiPPerTeDrejtenInformimit from "../Assets/Documents/Ligji per te drejten e informimit.pdf";
import LigjiPerRregullatRtikesPerAdministratenPublike from "../Assets/Documents/Ligji per rregullat e etikes per administraten publike.pdf";
import LigjiPerInformacioninSekretShteteror from "../Assets/Documents/Ligji per informacionin Sekret Shteteror.pdf";
import LigjiPerInspektiminNeRSH from "../Assets/Documents/Ligj-Nr.-10433-date-16.06.2011-Per-Inspektimin-ne-Republiken-e-Shqiperise.pdf";
import RregullorePerLicensiminArkivavePrivate from "../Assets/Documents/Rregullore per licensimin e arkivave private.pdf";
import LigjKodiProceduraveAdministrative from "../Assets/Documents/Ligj Kodi i procedurave administrative.pdf";
import UrdherRregulloreHetiminAdministrativMbrojtjenFonfidencialitetit from "../Assets/Documents/Urdher Rregullore per Hetimin administrativ dhe mbrojtjen e konfidencialitetit.pdf";
import RregulloreDeklasifikiminZhvleresiminInformacionitSekret from "../Assets/Documents/Rregullore per deklasifikimin dhe zhvleresimin e informacionit sekret.pdf";
import UrdherRregullorePerHyrjeDaljetNeDpa from "../Assets/Documents/Urdher dhe rregullore per hyrje-daljet ne dpa.pdf";
import RregullorePerShfryteziminDokumenteveArkivoreBibliotekesFototekes from "../Assets/Documents/Rregullore per shfrytezimin e dokumenteve arkivore, bibliotekes dhe fototekes.pdf";
import RregullorePerParandaliminKonfliktitInteresave from "../Assets/Documents/Rregullore per parandalimin e konfliktit te interesave.pdf";
import Urdher316PerNdryshimeUrdhrin47 from "../Assets/Documents/Urdher nr.316 25.09.2019 per disa ndryshime ne urdhrin nr.47 31.03.2016.pdf";
import UrdherRregullorePerPraniminDokumenteveNeAqshAshv from "../Assets/Documents/Urdher dhe rregullore per pranimin e dokumenteve ne aqsh dhe ashv.pdf";
import RregulloreMbrojtjenZjarriGodinatDpa from "../Assets/Documents/Rregullore per mbrojtjen nga zjarri ne godinat e dpa.pdf";
import LigjSinjaliziminMbrojtjenSinjalizuesve from "../Assets/Documents/Ligj per sinjalizimin dhe mbrojtjen e sinjalizuesve.pdf";
import RregulloreZhvendosjenShmangienRisistemiminDokumentaveKoheVecante from "../Assets/Documents/Rregullore per zhvendosjen, shmangien dhe risistemimin e dokumentave ne kohe te vecante.pdf";
import UrdherGjurmiminRigjetjenDokumentave from "../Assets/Documents/Urdher per gjurmimin dhe rigjetjen e dokumentave.pdf";
import Vendim821 from "../Assets/Documents/VKM 821 26.12.2024.pdf";
import RregullorePlotesiminKerkesaveSocialJuridike from "../Assets/Documents/Rregullore per plotesimin e kerkesave social-juridike.pdf";
import RregulloreMasatOrganizativeUshtriminVeprimtariseDpaGjateCovid19 from "../Assets/Documents/Rregullore per masat organizative per ushtrimin e veprimtarise se dpa gjate covid 19.pdf";
import UrdherKerkesatSocialJuridikeGjatePeriudhesEmergjences from "../Assets/Documents/Urdher per kerkesat social-juridike gjate periudhes se emergjences.jpg";
import UrdherMasatOrganizativeUshtriminVeprimtariseDpaGjateCovid19 from "../Assets/Documents/Urdher per masat organizative per ushtrimin e veprimtarise se dpa gjate covid 19.pdf";
import RregulloreFunksioniminStrukturesBrendshmeDpa from "../Assets/Documents/Rregullore per funksionimin e struktures se brendshme te dpa.docx";
import UrdherFunksioniminBrendshëmDPA from "../Assets/Documents/Urdher për Funksionimin e Brendshëm të DPA-së.pdf";
import StrategjiaAfatgjatëZhvillimitRrjetitArkivorKombëtar20202030 from "../Assets/Documents/Strategjia afatgjatë e zhvillimit të rrjetit arkivor kombëtar 2020-2030.pdf";
import RregulloreNjësuarPunësDokumentetAutoritetetPublike from "../Assets/Documents/Rregullore e njësuar e punës me dokumentet në autoritetet publike të Republikës së Shqipërisë.docx";
import ModeletDokumenteveAdministrativeWord from "../Assets/Documents/Shkarko Modelet e Dokumenteve Administrative në word.docx";
import NormatTeknikoProfesionaleRSh from "../Assets/Documents/Normat Tekniko Profesionale të Republikës së Shqipërisë.pdf";
import ListaTipAfatetuajtjesDokumenteveRëndësiHistorikeKombëtare from "../Assets/Documents/Lista Tip me Afatet e Ruajtjes së Dokumenteve me Rëndësi Historike Kombëtare.doc";
import StandardetNdërkombëtareArkivave from "../Assets/Documents/Standardet Ndërkombëtare të Arkivave.pdf";
export const legjislacioniData = [
  {
    path: "/legjislacioni/ligje",
    title: "Legjislacioni",
    leftContent: [
      {
        title: "Ligji “Për Arkivat”",
        document: ligjiPerArkivat,
      },
      {
        title:
          "Ligji Nr. 27/2024 për disa shtesa dhe ndryshime në ligjin Nr.9154, “PËR ARKIVAT”",
        document: ligjiPerArkivatNdryshuar,
      },
      {
        title: "Ligji “Për Nëpunësin Civil”",
        document: LigjiPerNepunesinCivil,
      },
      {
        title: "Ligji “Për të Drejtën e Informimit”",
        document: LigjiPPerTeDrejtenInformimit,
      },
      {
        title:
          "Ligji nr.9131, datë - 08.09.2003 - Për rregullat e etikës në administratën publike",
        document: LigjiPerRregullatRtikesPerAdministratenPublike,
      },
      {
        title: "Ligji “Për Informacionin e Klasifikuar Sekret Shtetëror”",
        document: LigjiPerInformacioninSekretShteteror,
      },
      {
        title:
          "Ligji nr. 10433, datë - 16.06.2011- Për Inspektimin në Republikën e Shqipërisë",
        document: LigjiPerInspektiminNeRSH,
      },

      {
        title: "Ligji Nr. 44, 2015 i Kodit të Procedurave Administrative",
        document: LigjKodiProceduraveAdministrative,
      },

      {
        title:
          "VKM nr. 35, datë 24.01.2018 Për miratimin e rregullores 'Për Licencimin e Arkivave Private'",
        document: RregullorePerLicensiminArkivavePrivate,
      },
      {
        title:
          "Vendim - Për miratimin e rregullores për deklasifikimin dhe zhvillimin e informacionit të klasifikuar “Sekret Shtetëror”",
        document: RregulloreDeklasifikiminZhvleresiminInformacionitSekret,
      },
      {
        title:
          "Urdhër dhe Rregullore së Regjimit të Hyrje Daljeve dhe Sigurimin e Godinave të DPA-së",
        document: UrdherRregullorePerHyrjeDaljetNeDpa,
      },
      {
        title:
          "Urdhër mbi miratimin e rregullores për shfrytëzimin e dokumenteve arkivore, bibliotekës dhe fototekës së DPA-së",
        document:
          RregullorePerShfryteziminDokumenteveArkivoreBibliotekesFototekes,
      },
      {
        title:
          "Rregullore për parandalimin e konfliktit të interesave, si dhe për deklarimin e pasurive në ushtrimin e funksioneve publike në Drejtorinë e Përgjithshme të Arkivave. Datë 12.12.2019",
        document: RregullorePerParandaliminKonfliktitInteresave,
      },
      {
        title:
          "Urdhër nr.316 datë 29.05.2019 Për disa ndryshime për urdhërin nr.47 datë 31.03.2016",
        document: Urdher316PerNdryshimeUrdhrin47,
      },
      {
        title:
          "Urdhër dhe Rregullore për Pranimin e Dokumenteve në AQSH dhe ASHV",
        document: UrdherRregullorePerPraniminDokumenteveNeAqshAshv,
      },
      {
        title:
          "Urdhër dhe Rregullore për Hetimin Administrativ të Sinjalizimit dhe Mbrojtjen e Konfidencialitetit në DPA",
        document:
          UrdherRregulloreHetiminAdministrativMbrojtjenFonfidencialitetit,
      },
      {
        title:
          "Urdhër dhe Rregullore për Mbrojtjen nga Zjarri dhe Shpëtimin në Godinat e DPA-së",
        document: RregulloreMbrojtjenZjarriGodinatDpa,
      },
      {
        title:
          "LIGJ Nr. 60/2016 PËR SINJALIZIMIN DHE MBROJTJEN E SINJALIZUESVE",
        document: LigjSinjaliziminMbrojtjenSinjalizuesve,
      },
      {
        title:
          "Vendim për miratimin e rregullores për zhvendosjen (evakuimin) shmangien dhe risistemimin e dokumenteve në kohë të veçantë",
        document:
          RregulloreZhvendosjenShmangienRisistemiminDokumentaveKoheVecante,
      },
      {
        title: "Urdhër për 'Gjurmimin dhe Rigjetjen e Dokumentave RHK'",
        document: UrdherGjurmiminRigjetjenDokumentave,
      },
    ],
    rightContent: [
      {
        title:
          "VENDIM Nr. 821, datë 26.12.2024 PËR MIRATIMIN E RREGULLORES “PËR ORGANIZIMIN DHE FUNKSIONIMIN E DREJTORISË SË PËRGJITHSHME TË ARKIVAVE DHE TË ORGANEVE QË FUNKSIONOJNË PRANË SAJ",
        doc: [
          {
            name: "VKM NR.821",
            document: Vendim821,
          },
        ],
      },
      {
        title:
          "Rregullore e kërkesave social-juridike dhe Urdheri për Situatën e Krijuar nga Covid-19",
        doc: [
          {
            name: "Shkarko rregulloren",
            document: RregullorePlotesiminKerkesaveSocialJuridike,
          },
          {
            name: "Shkarko urdhër",
            document: UrdherKerkesatSocialJuridikeGjatePeriudhesEmergjences,
          },
        ],
      },
      {
        title:
          "Rregullore ' Për marrjen e Masave Organizative për Ushtrimin e Veprimtarisë së Drejtorisë së Përgjithshme të Arkivave gjatë Gjendjes së Epidemisë së Shkaktuar nga COVID-19 dhe Urdheri për Situatën e Krijuar nga Covid-19",
        doc: [
          {
            name: "Shkarko rregulloren",
            document:
              RregulloreMasatOrganizativeUshtriminVeprimtariseDpaGjateCovid19,
          },
          {
            name: "Shkarko urdhër",
            document:
              UrdherMasatOrganizativeUshtriminVeprimtariseDpaGjateCovid19,
          },
        ],
      },
      {
        title:
          "Urdhëri dhe Rregullorja për Funksionimin e Brendshëm të Strukturave të DPA-së",
        doc: [
          {
            name: "Shkarko rregulloren e DPA",
            document: RregulloreFunksioniminStrukturesBrendshmeDpa,
          },
          {
            name: "Shkarko urdhër",
            document: UrdherFunksioniminBrendshëmDPA,
          },
        ],
      },
    ],
  },
  {
    path: "/legjislacioni/dokumente-dhe-strategji",
    title: "Dokumente dhe strategji",
    leftContent: [
      {
        name: "Strategjia afatgjatë e zhvillimit të rrjetit arkivor kombëtar 2020-2030",
        document: StrategjiaAfatgjatëZhvillimitRrjetitArkivorKombëtar20202030,
      },
      {
        name: "Rregullore e njësuar e punës me dokumentet në autoritetet publike të Republikës së Shqipërisë",
        document: RregulloreNjësuarPunësDokumentetAutoritetetPublike,
      },
      {
        name: "Shkarko Modelet e Dokumenteve Administrative në word",
        document: ModeletDokumenteveAdministrativeWord,
      },
      {
        name: "Normat Tekniko Profesionale të Republikës së Shqipërisë",
        document: NormatTeknikoProfesionaleRSh,
      },
      {
        name: "Lista Tip me Afatet e Ruajtjes së Dokumenteve me Rëndësi Historike Kombëtare",
        document: ListaTipAfatetuajtjesDokumenteveRëndësiHistorikeKombëtare,
      },
      {
        name: "Standardet Ndërkombëtare të Arkivave",
        document: StandardetNdërkombëtareArkivave,
      },
    ],
    rightContent: "",
  },
];

import {
  ADD_POST,
  DELETE_POST,
  SET_POST_ON_EDIT,
  SET_POSTS,
  UPDATE_POST,
} from "../Actions/postsActions";

const initialState = {
  posts: [],
  postOnEdit: {},
};

const postReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_POSTS:
      return {
        ...state,
        posts: action.payload,
      };
    case SET_POST_ON_EDIT: {
      return {
        ...state,
        postOnEdit: action.payload,
      };
    }
    case UPDATE_POST: {
      const updatedPost = action.payload;

      return {
        ...state,
        posts: state.posts.map((post) =>
          post._id === updatedPost._id ? { ...post, ...updatedPost } : post
        ),
      };
    }

    case DELETE_POST:
      const newPost = state.posts.filter((item) => item._id !== action.payload);
      return {
        ...state,
        postOnEdit: {},
        posts: newPost,
      };

    case ADD_POST: {
      return {
        ...state,
        posts: [...state.posts, action.payload],
      };
    }

    default: {
      return state;
    }
  }
};

export default postReducer;

import React, { useEffect, useState } from "react";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./posts.css";
import { setPosts } from "../Redux/Actions/postsActions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const Posts = () => {
  const [posters, setPosters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPostsWithImages = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          "https://api.arkiva.gov.al/api/posts-with-images"
        );

        setPosters(response.data);

        dispatch(setPosts(response.data));
      } catch (error) {
        console.error("Error fetching posts and images:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPostsWithImages();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="carousel-container posts">
      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}
      <h2 className="carousel-title">Aktivitete</h2>
      <div className="swipper-container">
        <div className="custom-swiper-button-prev">
          <i className="fa-solid fa-chevron-left"></i>
        </div>
        <div className="custom-swiper-button-next">
          <i className="fa-solid fa-chevron-right"></i>
        </div>
        <Swiper
          key={posters.length}
          slidesPerView={windowWidth < 730 ? 1 : windowWidth < 992 ? 2 : 3}
          slidesPerGroup={windowWidth < 730 ? 1 : windowWidth < 992 ? 2 : 3}
          spaceBetween={20}
          loop={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
            pauseOnMouseEnter: false,
            waitForTransition: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={{
            nextEl: ".custom-swiper-button-next",
            prevEl: ".custom-swiper-button-prev",
          }}
          modules={[Autoplay, Navigation, Pagination]}
          className="post-swiper"
        >
          {posters.map((post) => (
            <SwiperSlide key={post._id}>
              <div
                className="card-item"
                onClick={() => navigate(`/aktivitete/${post.slug}`)}
              >
                <div className="card-image-wrapper">
                  {post.images && post.images.length > 0 ? (
                    <img
                      src={`/Assets/Images/Posts/${post.id}/${post.coverImage}`}
                      alt={post.title}
                      className="card-image"
                    />
                  ) : (
                    <div className="card-placeholder">No Image</div>
                  )}
                </div>
                <div className="card-title">{post.title}</div>
                <div className="card-date">{post.createdOn}</div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default Posts;

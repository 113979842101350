import { citizenServices, onlineServices, onlineShop } from "./sherbimetData";
import "./sherbimet.css";

const Sherbimet = () => {
  return (
    <div className="services">
      <div className="citizen-services">
        <div className="services-title">Shërbime për qytetarët</div>
        <div className="services-icons">
          {citizenServices.map((service) => (
            <a
              key={service.url}
              href={service.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={service.name} alt={service.url} />
            </a>
          ))}
        </div>
      </div>
      <div className="online-services">
        <div className="services-title">Shërbime online për studiuesin</div>
        <div className="services-icons">
          {onlineServices.map((service) => (
            <a
              key={service.url}
              href={service.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={service.name} alt={service.url} />
            </a>
          ))}
        </div>
      </div>
      <div className="online-shop">
        <div className="services-title">Dyqani Online</div>
        <div className="services-icons">
          {onlineShop.map((service) => (
            <a
              key={service.url}
              href={service.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={service.name} alt={service.url} />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Sherbimet;

const Manual = () => {
  return (
    <div className="page">
      <div className="page-header">
        <div className="headerTitleLine"></div>
        <h1 className="page-title">Manual</h1>
      </div>
      <div className="page-content-manual">
        <div className="left-content-manual">
          <p>
            Në këtë faqe do gjeni manualet për mënyrën e hedhjes së inventarëve
            nga institucionet të cilët do të dorëzojnë dokumentacionin në arkiv.
          </p>
          <a href="https://katalogu.arkiva.gov.al/">
            Klikoni linkun për të aksesuar katalogun
          </a>
        </div>
        <div className="right-content-manual"></div>
      </div>
    </div>
  );
};

export default Manual;

export const SET_BOTIME = "SET_BOTIME";
export const SET_BOTIME_ON_EDIT = "SET_BOTIME_ON_EDIT";
export const UPDATE_BOTIME = "UPDATE_BOTIME";
export const DELETE_BOTIME = "DELETE_BOTIME";
export const ADD_BOTIME = "ADD_BOTIME";

export const setBotime = (botime) => ({
  type: SET_BOTIME,
  payload: botime,
});

export const setBotimeOnEdit = (botimeOnEdit) => ({
  type: SET_BOTIME_ON_EDIT,
  payload: botimeOnEdit,
});

export const updateBotime = (updatedBotime) => ({
  type: UPDATE_BOTIME,
  payload: updatedBotime,
});

export const removeBotime = (botimeId) => ({
  type: DELETE_BOTIME,
  payload: botimeId,
});

export const addBotime = (botime) => ({
  type: ADD_BOTIME,
  payload: botime,
});

import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addRegjistriDokHumbura } from "../../Redux/Actions/regjDokHumburaActions";

const AddRegjistriDokHumbura = () => {
  const navigate = useNavigate();
  const [fondNr, setFondNr] = useState("");
  const [fond, setFond] = useState("");
  const [year, setYear] = useState("");
  const [folder, setFolder] = useState("");
  const [description, setDescription] = useState("");
  const [verificationDate, setVerificationDate] = useState("");
  const [foundDate, setFoundDate] = useState("");
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const loggedUser = useSelector((state) => state.users.loggedUser);
  const isLoggedIn = Object.keys(loggedUser).length > 0;

  if (!isLoggedIn) {
    navigate("/login");
  }

  const validateForm = () => {
    const errors = {};

    if (!fondNr.trim()) {
      errors.fondNr = "Nr. i fondit nuk mund të jetë bosh!";
    }

    if (!fond.trim()) {
      errors.fond = "Fondi nuk mund të jetë bosh!";
    }

    if (!year.trim()) {
      errors.year = "Viti nuk mund të jetë bosh!";
    }

    if (!folder.trim()) {
      errors.folder = "Dosja nuk mund të jetë bosh!";
    }

    if (!description.trim()) {
      errors.description = "Përshkrimi nuk mund të jetë bosh!";
    }

    if (!verificationDate.trim()) {
      errors.verificationDate =
        "Data e fletës së vërtetimit nuk mund të jetë bosh!";
    }

    if (!foundDate.trim()) {
      errors.foundDate =
        "Data e rigjetjes së dokumentit nuk mund të jetë bosh!";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleCancel = () => {
    navigate("/manage/regjistriDokHumbura");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    const regjistriDokHumburData = {
      fondNr,
      fond,
      year,
      folder,
      description,
      verificationDate,
      foundDate,
    };

    try {
      const response = await axios.post(
        "https://api.arkiva.gov.al/api/regjistriDokHumbura/create",
        regjistriDokHumburData
      );

      dispatch(addRegjistriDokHumbura(response.data));

      navigate("/manage/regjistriDokHumbura");
    } catch (err) {
      console.error("Error adding dokumnet te humbur:", err);
    }
  };

  const textareaRef = useRef(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [description]);

  return (
    <div className="edit-page">
      <div className="return">
        <button onClick={handleCancel}>
          <i className="fa-solid fa-chevron-left"></i>
        </button>
      </div>
      <p>Shto Dokument të Humbur</p>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="fondNr">Nr. i fondit</label>
          <input
            type="text"
            id="fondNr"
            value={fondNr}
            onChange={(e) => setFondNr(e.target.value)}
          />
          {errors.fondNr && <small className="error">{errors.fondNr}</small>}
        </div>
        <div className="form-group">
          <label htmlFor="fond">Fondi</label>
          <input
            type="text"
            id="fond"
            value={fond}
            onChange={(e) => setFond(e.target.value)}
          />
          {errors.fond && <small className="error">{errors.fond}</small>}
        </div>
        <div className="form-group">
          <label htmlFor="year">Viti</label>
          <input
            type="text"
            id="year"
            value={year}
            onChange={(e) => setYear(e.target.value)}
          />
          {errors.year && <small className="error">{errors.year}</small>}
        </div>
        <div className="form-group">
          <label htmlFor="folder">Dosja</label>
          <input
            type="text"
            id="folder"
            value={folder}
            onChange={(e) => setFolder(e.target.value)}
          />
          {errors.folder && <small className="error">{errors.folder}</small>}
        </div>

        <div className="form-group">
          <label htmlFor="description">
            Përshkrim i shkurtër i dokumentit që mungon
          </label>
          <textarea
            ref={textareaRef}
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          {errors.description && (
            <small className="error">{errors.description}</small>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="verificationDate">Data e fletës së vërtetimit</label>
          <input
            type="text"
            id="verificationDate"
            value={verificationDate}
            onChange={(e) => setVerificationDate(e.target.value)}
          />
          {errors.verificationDate && (
            <small className="error">{errors.verificationDate}</small>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="foundDate">Data e rigjetjes së dokumentit</label>
          <input
            type="text"
            id="foundDate"
            value={foundDate}
            onChange={(e) => setFoundDate(e.target.value)}
          />
          {errors.foundDate && (
            <small className="error">{errors.foundDate}</small>
          )}
        </div>

        <div className="edit-actions">
          <button type="submit" className="save">
            Shto dokument të humbur
          </button>
          <button type="button" className="cancel" onClick={handleCancel}>
            Anullo
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddRegjistriDokHumbura;

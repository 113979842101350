import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DefaultImage from "../../Assets/Images/SideBarLogo.png";
import { useDispatch, useSelector } from "react-redux";
import { addEkspozita } from "../../Redux/Actions/ekspozitaActions";

const AddEkspozita = () => {
  const navigate = useNavigate();
  const loggedUser = useSelector((state) => state.users.loggedUser);
  const isLoggedIn = Object.keys(loggedUser).length > 0;
  const [title, setTitle] = useState("");
  const [createdOn, setCreatedOn] = useState("");
  const [slug, setSlug] = useState("");
  const [galleryImages, setGalleryImages] = useState([]);
  const [coverImage, setCoverImage] = useState(null);
  const [coverName, setCoverName] = useState(null);
  const [coverPreview, setCoverPreview] = useState(DefaultImage);
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const [newId, setNewId] = useState(null);

  if (!isLoggedIn) navigate("/login");

  const validateForm = () => {
    const errors = {};
    const dateRegex = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/;
    const monthDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    const isLeapYear = (year) => {
      return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
    };

    const isValidDate = (day, month, year) => {
      if (month < 1 || month > 12) return false;
      const maxDays =
        month === 2 && isLeapYear(year) ? 29 : monthDays[month - 1];
      return day >= 1 && day <= maxDays;
    };

    if (!title.trim()) errors.title = "Titulli nuk mund të jetë bosh!";

    if (!createdOn.trim()) {
      errors.createdOn = "Data e krijimit nuk mund të jetë bosh";
    } else {
      const match = createdOn.match(dateRegex);
      if (!match) {
        errors.createdOn =
          "Data e krijimit duhet të jetë në formatin dd/mm/yyyy ose d/m/yyyy!";
      } else {
        const [_, day, month, year] = match.map(Number);
        const enteredDate = new Date(year, month - 1, day);
        const currentDate = new Date();

        if (!isValidDate(day, month, year)) {
          errors.createdOn =
            "Datë jo e saktë! Kontrolloni ditën, muajin dhe vitin!";
        } else if (enteredDate > currentDate) {
          errors.createdOn = "Data nuk mund të jetë në të ardhmen!";
        }
      }
    }

    if (!coverImage) errors.coverImage = "Imazhi nuk mund të jetë bosh";

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const uploadGalleryImages = (e) => {
    const files = Array.from(e.target.files);

    const previews = files.map((file) => URL.createObjectURL(file));

    setGalleryImages((prev) => [
      ...prev,
      ...files.map((file, index) => ({ file, preview: previews[index] })),
    ]);
  };

  const uploadCoverImage = (e) => {
    const file = e.target.files[0];
    setCoverName(file.name);

    if (file) {
      setCoverImage(file);
      setCoverPreview(URL.createObjectURL(file));
    }
  };

  const removeGalleryImage = (index) => {
    setGalleryImages((prev) => prev.filter((_, i) => i !== index));
  };

  const handleCancel = () => {
    navigate("/manage/ekspozita");
  };

  const basePath = "/Assets/Images/Ekspozita/";

  const filePaths = galleryImages.map(
    (item) => `${basePath}${newId}/${item.file.name}`
  );

  filePaths.push(`${basePath}${newId}/${coverName}`);

  const generateSlug = (input) => {
    const slug = input
      .toLowerCase()
      .replace(/ë/g, "e")
      .replace(/ç/g, "c")
      .replace(/\s+/g, "-")
      .replace(/[^a-z0-9-]/g, "");
    return slug;
  };

  const getLatestId = async () => {
    try {
      const response = await axios.get(
        "https://api.arkiva.gov.al/api/ekspozita/count"
      );
      return response.data.latestId + 1;
    } catch (error) {
      console.error("Error fetching latest ID:", error);
      return null;
    }
  };

  useEffect(() => {
    const fetchLatestId = async () => {
      const newId = await getLatestId();
      setNewId(newId);
    };
    fetchLatestId();
  }, []);

  const handleTitleChange = (e) => {
    const newTitle = e.target.value;
    setTitle(newTitle);
    setSlug(generateSlug(newTitle));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    const ekspozitaData = {
      title,
      createdOn,
      slug,
      nrImages: galleryImages.length,
      id: newId,
      coverImage: coverImage.name,
    };

    try {
      const response = await axios.post(
        "https://api.arkiva.gov.al/api/ekspozita/create",
        ekspozitaData
      );

      dispatch(addEkspozita(response.data));
      const formData = new FormData();

      galleryImages.forEach((img) => {
        formData.append("gallery", img.file);
      });

      formData.append("cover", coverImage);

      const resp = await axios.post(
        "https://api.arkiva.gov.al/api/ekspozita/uploadFiles",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },

          params: {
            folderId: newId,
          },
        }
      );

      navigate("/manage/ekspozita");
    } catch (err) {
      console.error("Error adding ekspozita:", err);
    }
  };

  return (
    <div className="edit-page">
      <div className="return">
        <button onClick={handleCancel}>
          <i className="fa-solid fa-chevron-left"></i>
        </button>
      </div>
      <p>Shto Ekspozitë</p>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="title">Titulli</label>
          <input
            type="text"
            id="title"
            value={title}
            onChange={handleTitleChange}
          />
          {errors.title && <small className="error">{errors.title}</small>}
        </div>

        <div className="form-group">
          <label htmlFor="createdOn">Data e krijimit</label>
          <input
            type="text"
            id="createdOn"
            value={createdOn}
            onChange={(e) => setCreatedOn(e.target.value)}
          />
          {errors.createdOn && (
            <small className="error">{errors.createdOn}</small>
          )}
        </div>

        <div className="form-group">
          <label htmlFor="slug">Slug (path)</label>
          <input type="text" id="slug" value={slug} disabled />
        </div>

        <div className="form-group gallery">
          <label>Imazhet</label>
          <div className="images-container">
            {galleryImages.map((img, index) => (
              <div className="image-wrapper" key={index}>
                <img
                  src={img.preview}
                  alt="upload"
                  style={{
                    width: "120px",
                    height: "120px",
                    objectFit: "cover",
                  }}
                />
                <button
                  type="button"
                  className="delete-button"
                  onClick={() => removeGalleryImage(index)}
                >
                  <i className="fa-solid fa-xmark"></i>
                </button>
              </div>
            ))}
          </div>
          <input
            type="file"
            accept="image/*"
            multiple
            onChange={uploadGalleryImages}
          />
        </div>

        <div className="form-group">
          <label>Kopertina</label>
          <div className="image-wrapper">
            <img
              src={coverPreview}
              alt="preview"
              style={{ width: "120px", height: "120px", objectFit: "cover" }}
            />
          </div>
          <input type="file" accept="image/*" onChange={uploadCoverImage} />
          {errors.coverImage && (
            <small className="error">{errors.coverImage}</small>
          )}
        </div>

        <div className="edit-actions">
          <button type="submit" className="save">
            Shto Ekspozitë
          </button>
          <button type="button" className="cancel" onClick={handleCancel}>
            Anullo
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddEkspozita;

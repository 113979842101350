import ligjiPerArkivat from "../../Assets/Documents/Ligji per arkivat.pdf";
import ligji27PerArkivat from "../../Assets/Documents/Ligji 'Per arkivat' i ndryshuar.pdf";
import LigjiPerNepunesinCivil from "../../Assets/Documents/Ligji per nepunesin civil.pdf";
import StrukturaOrganizativeOrganigrama from "../../Assets/Documents/Struktura dhe organika e DPA-së 2025.pdf";
import LigjiPPerTeDrejtenInformimit from "../../Assets/Documents/Ligji per te drejten e informimit.pdf";
import LigjiPerRregullatRtikesPerAdministratenPublike from "../../Assets/Documents/Ligji per rregullat e etikes per administraten publike.pdf";
import LigjiPerInformacioninSekretShteteror from "../../Assets/Documents/Ligji per informacionin Sekret Shteteror.pdf";
import LigjiPerInspektiminNeRSH from "../../Assets/Documents/Ligj-Nr.-10433-date-16.06.2011-Per-Inspektimin-ne-Republiken-e-Shqiperise.pdf";
import RregullorePerLicensiminArkivavePrivate from "../../Assets/Documents/Rregullore per licensimin e arkivave private.pdf";
import LigjKodiProceduraveAdministrative from "../../Assets/Documents/Ligj Kodi i procedurave administrative.pdf";
import UrdherRregullorePerHyrjeDaljetNeDpa from "../../Assets/Documents/Urdher dhe rregullore per hyrje-daljet ne dpa.pdf";
import RregullorePerParandaliminKonfliktitInteresave from "../../Assets/Documents/Rregullore per parandalimin e konfliktit te interesave.pdf";
import RregulloreMbrojtjenZjarriGodinatDpa from "../../Assets/Documents/Rregullore per mbrojtjen nga zjarri ne godinat e dpa.pdf";
import RregulloreZhvendosjenShmangienRisistemiminDokumentaveKoheVecante from "../../Assets/Documents/Rregullore per zhvendosjen, shmangien dhe risistemimin e dokumentave ne kohe te vecante.pdf";
import UrdherRregulloreHetiminAdministrativMbrojtjenFonfidencialitetit from "../../Assets/Documents/Urdher Rregullore per Hetimin administrativ dhe mbrojtjen e konfidencialitetit.pdf";
import AkteNenligjore from "../../Assets/Documents/Akte nenligjore.pdf";
import RaportVjetor2022 from "../../Assets/Documents/RAPORTI VJETOR I DPA PËR VITIN 2022.pdf";
import Bibliografi1 from "../../Assets/Documents/Bibliografi e Informacionit të Klasifikuar 2023.pdf";
import Bibliografi2 from "../../Assets/Documents/Bibliografi 2023 - 2.pdf";
import FormatAnkese from "../../Assets/Documents/Format Ankese.doc";
import FormatKerkese from "../../Assets/Documents/Format Kerkese.doc";
import RaportAuditimi from "../../Assets/Documents/Raport auditimi.pdf";
import FormatetRaportevePBA20232025 from "../../Assets/Documents/Formatet e Raporteve te PBA 2023-2025.pdf";
import RegjistriRealizimitIVPare2023 from "../../Assets/Documents/regjistri i realizimit IV i pare 2023.xlsx - 4 MUJORI I.pdf";
import RegjistriRealizimeve2022 from "../../Assets/Documents/regjistri i realizimeve 2022.pdf";
import PasqyratShteseBuxhetin20232025 from "../../Assets/Documents/Shtojca 1.A - Pasqyrat Shtese per Buxhetin 2023-2025.pdf";
import DokumenteTregojnëGjendjenShlyerjesDetyrimeveSubjektetLicencuara from "../../Assets/Documents/Dokumente që tregojnë gjendjen e shlyerjes së detyrimeve nga subjektet e licencuara.pdf";
import RaportiMonitorimit2023 from "../../Assets/Documents/raporti monitorimit per  vitit 2023.docx";
import BPPM_ANEKSI_4 from "../../Assets/Documents/BPPM_ANEKSI_4.xlsx";
import BPPM_ANEKSI_3_2 from "../../Assets/Documents/BPPM_ANEKSI_3.2.xlsx";
import BPPM_ANEKSI_3_1 from "../../Assets/Documents/BPPM_ANEKSI_3.1.xlsx";
import BPPM_ANEKSI_2 from "../../Assets/Documents/BPPM_ANEKSI_2.xlsx";
import BPPM_ANEKSI_2_1 from "../../Assets/Documents/BPPM_ANEKSI_2.1.xlsx";
import BPPM_ANEKSI_1 from "../../Assets/Documents/BPPM_ANEKSI_1.xlsx";
import BPPM_ANEKSI_1_2 from "../../Assets/Documents/BPPM_ANEKSI_1.2.xlsx";
import ListaDokumenteveAdministrohenAPMeAfatetRuajtjes from "../../Assets/Documents/Lista e dokumenteve që administrohen nga AP me afatet e ruajtjes.pdf";
import PasqyraCelësePërkohshme from "../../Assets/Documents/Pasqyra çelëse e përkohshme 2023.docx.pdf";
import RregjistriKërkesaveKordinatorit2022 from "../../Assets/Documents/Rregjistri i kërkesave të kordinatorit - viti 2022.pdf";
import RregjistriKërkesaveKordinatorit2023 from "../../Assets/Documents/Rregjistri i kërkesave të kordinatorit - viti 2023.pdf";
import RregjistriKërkesaveKordinatorit2025 from "../../Assets/Documents/Rregjistri i kërkesave të kordinatorit - viti 2025.docx";
import RregjistriKërkesaveKordinatorit2024 from "../../Assets/Documents/Rregjistri i kërkesave të kordinatorit - viti 2024.docx";
import RegjistriProkurimeve2023 from "../../Assets/Documents/Regjistri i Prokurimeve 2023.pdf";
import RegjistriProkurimeve2025 from "../../Assets/Documents/Regjistri i Prokurimeve 2025 - Janar i ndryshuar.pdf";
import RregjistriRealizimeve2023 from "../../Assets/Documents/Rregjistri i realizimeve për vitin 2023.pdf";

export const programiTransparencesData = [
  {
    title: "Programi i Transparencës - Hyrje",
    content: [
      "Bazuar në nenin 7 të ligjit nr. 119/2014 “Për të drejtën e informimit” (në vijim “LDI”), Autoriteti Publik (në vijim “AP”) duhet të publikojë Programin e Transparencës (në vijim “PT”) të miratuar nga Komisioneri për të Drejtën e Informimit dhe Mbrojtjen e të Dhënave Personale (në vijim “Komisioneri”). AP duhet të publikoj informacion sipas veprimtarisë së tij funksionale si dhe të rishikoj e përditësoj PT në çdo rast të ndryshimit të aktivitetit institucional, kuadrit ligjor rregullator apo çdo elementi tjetër që ka lidhje me transparencën proaktive.",
      "Në PT janë të specifikuara kategoritë e informacionit të detyrueshëm për t’u bërë publik. Informacioni publik pa kërkesë duhet të jetë: i plotë, i saktë, i përditësuar, i thjeshtë në konsultim, i kuptueshëm, lehtësisht i aksesueshëm dhe i pajtueshëm me dokumentet origjinale në posedim të A.P. Gjithashtu, përpara publikimit të informacionit AP duhet të vlerësojë nësë ka ndonjë kufizim ligjor për publikim.",
      "Aktet që përmbajnë rregulla, norma apo kufizime të të drejtave dhe lirive themelore të individit, si dhe me efekt të drejtpërdrejtë për ta, bëhen publike me anë të afishimit apo postimit në faqen zyrtare të internetit, brenda 48 orëve prej miratimit të aktit nga AP.",
      "Aktet që përmbajnë rregulla, norma apo kufizime të të drejtave dhe lirive themelore të individit, si dhe me efekt të drejtpërdrejtë për ta, bëhen publike me anë të afishimit apo postimit në faqen zyrtare të internetit, brenda 48 orëve prej miratimit të aktit nga AP.",
    ],
  },
  {
    title: "Rreth Autoritetit",
    content: [
      {
        title: "Struktura organizative/Organigrama",
        url: StrukturaOrganizativeOrganigrama,
      },
      {
        title: "Struktura e pagave",
        url: "https://drive.google.com/file/d/1MPaAZ5nNNzSqAAl2EWLUQrvPBX9omD2Z/view?usp=sharing",
      },
      {
        title: "Funksionet dhe detyrat e A.P",
        url: "https://drive.google.com/file/d/1lH8QQZ96r84bdJaQGshrQHmTzN3beaML/view?usp=sharing",
      },
      {
        title:
          "CV-të dhe pagat e nënpunësve që janë subjekt deklarimi sipas ligjit, përfshirë dhe anëtarët e kabinetit të titullarit të AP, përshkrim të    procedurës së zgjedhjes, kompetencat dhe detyrat e tyre.  ",
        listAnchorContent: [
          {
            title: "Ardit Bido",
            url: "https://www.arkiva.gov.al/drejtori",
          },
          {
            title: "Armando Boçe",
            url: "https://www.arkiva.gov.al/struktura/drejtoria-e-arkivit-qendror-shteteror/drejtori-i-arkivit-qendror-shteteror",
          },
          {
            title: "Elsa Saka",
            url: "http://localhost:3000/struktura/drejtoria-e-evidences-qendrore-dhe-komunikimit/drejtoresha-i-evidences-qendrore-dhe-komunikimit",
          },
          {
            title: "Lorenc Agalliu",
            url: "https://www.arkiva.gov.al/struktura/drejtori-i-arkivave-shteterore-vendore/drejtori-i-arkivave-shteterore-vendore",
          },
          {
            title: "Arben Dervishllari",
            url: "https://www.arkiva.gov.al/struktura/drejtoria-e-finances-dhe-sherbimeve-mbeshtetese/drejtori-i-finances-dhe-sherbimeve-mbeshtetese",
          },
          {
            title: "Endrit Musaj",
            url: "https://www.arkiva.gov.al/struktura/drejtoria-e-inspektimit-dhe-trajnimeve/drejtori-i-inspektimit-dhe-trajnimeve",
          },
          {
            title: "Armant Vogli",
            url: "",
          },
        ],
      },
    ],
  },
  {
    title: "Kuadri ligjor rregullator",
    content: [
      {
        title: "Legjislacion ndërkombëtar",
        url: "https://files.arkiva.gov.al/CBPS_2000_Guidelines_ISADG_Second-edition_EN.pdf",
      },

      {
        title: "Legjislacion kombëtar",
        url: "",
      },
      {
        title: "Ligji “Për Arkivat”",
        url: ligjiPerArkivat,
      },
      {
        title:
          "Ligji Nr. 27/2024 për disa shtesa dhe ndryshime në ligjin Nr.9154, “PËR ARKIVAT”",
        url: ligji27PerArkivat,
      },
      {
        title: "Ligji “Për Nëpunësin Civil”",
        url: LigjiPerNepunesinCivil,
      },
      {
        title: "Ligji “Për të Drejtën e Informimit”",
        url: LigjiPPerTeDrejtenInformimit,
      },
      {
        title: "Rregullorja për Funskionimin e Brendshëm të DPA",
        url: "https://1drv.ms/w/s!Ar2Nqoh7vKcHgX9yA7--trdbQxvb?e=qfAAgn",
      },
      {
        title:
          "Ligji nr.9131, datë – 08.09.2003 – Për rregullat e etikës në administratën   publike",
        url: LigjiPerRregullatRtikesPerAdministratenPublike,
      },
      {
        title: "Ligji “Për Informacionin e Klasifikuar Sekret Shtetëror”",
        url: LigjiPerInformacioninSekretShteteror,
      },
      {
        title:
          "Ligji nr. 10433, datë – 16.06.2011- Për Inspektimin në Republikën e Shqipërisë",
        url: LigjiPerInspektiminNeRSH,
      },
      {
        title: "Ligji Nr. 44, 2015 i Kodit të Procedurave Administrative",
        url: LigjKodiProceduraveAdministrative,
      },
      {
        title:
          "VKM nr. 35, datë 24.01.2018 Për miratimin e rregullores “Për Licencimin e Arkivave Private”",
        url: RregullorePerLicensiminArkivavePrivate,
      },
      {
        title:
          "VKM “Për Organizimin dhe Funksionimin e Drejtorisë së Përgjithshme të Arkivave”",
        url: "https://arkiva.gov.al/web2018/wp-content/uploads/2020/05/Rregulorre-per-DPA-2017.pdf",
      },
      {
        title:
          "Urdhër dhe Rregullore së Regjimit të Hyrje Daljeve dhe Sigurimin e Godinave të DPA-së",
        url: UrdherRregullorePerHyrjeDaljetNeDpa,
      },
      {
        title:
          "Rregullore e shfrytëzimit të dokumenteve arkivore, librave dhe fotografive",
        url: "https://1drv.ms/w/s!Ar2Nqoh7vKcHggH-jKWNrQ4K76hC?e=CHONjB",
      },
      {
        title:
          "Rregullore për parandalimin e konfliktit të interesave, si dhe për deklarimin e pasurive në ushtrimin e funksioneve publike në Drejtorinë e Përgjithshme të Arkivave. Datë 12.12.2019",
        url: RregullorePerParandaliminKonfliktitInteresave,
      },
      {
        title:
          "Vendim për miratimin e rregullores për zhvendosjen (evakuimin) shmangien dhe risistemimin e dokumenteve në kohë të veçantë",
        url: RregulloreZhvendosjenShmangienRisistemiminDokumentaveKoheVecante,
      },
      {
        title:
          "Urdhër dhe Rregullore për Hetimin Administrativ të Sinjalizimit dhe Mbrojtjen e Konfidencialitetit në DPA.",
        url: UrdherRregulloreHetiminAdministrativMbrojtjenFonfidencialitetit,
      },
      {
        title:
          "Urdhër dhe Rregullore për Mbrojtjen nga Zjarri dhe Shpëtimin në Godinat e DPA-së",
        url: RregulloreMbrojtjenZjarriGodinatDpa,
      },
      {
        title:
          "Urdhër për procedurat e planifikimit, vlerësimit dhe miratimit të botimeve të drejtorisë së përgjithshme të arkivave",
        url: "https://arkiva.gov.al/web2018/wp-content/uploads/2021/10/Rregullorja-per-Botimet.pdf",
      },
      {
        title: "Akte nënligjore",
        url: AkteNenligjore,
      },
      {
        title: "Rregullore e Brendshme",
        url: "https://drive.google.com/file/d/11l50AU7-MkSTnLnQtZ5gCjwDQxNjpdAr/view?usp=sharing",
      },
      {
        title: "Kodi i sjelljes (nuk ka informacion)",
        url: "",
      },
      {
        title:
          "Aktet që përmbajnë rregulla, norma apo kufizime të të drejtave dhe lirive themelore të individit",
        url: "https://drive.google.com/file/d/1kQdgfKOtgEMDHO0bq1S9xFMq-KmzFo0P/view?usp=sharing",
      },
      {
        title: "Strategji",
        url: "https://drive.google.com/file/d/1C_ybYk3OqAXlqnu8ZAc6_nT3cOKIQ1Ea/view?usp=sharing",
      },
      {
        title: "Raport vjetor",
        url: RaportVjetor2022,
      },
      {
        title:
          "Manuale/dokument politikash, që ka lidhje me funksionet e AP dhe që prek publikun (Nuk ka informacion)",
        url: "",
      },
      {
        title: "Bibliografi e Informacionit të Klasifikuar 2023",
        listAnchorContent: [
          {
            title: "(1)",
            url: Bibliografi1,
          },
          {
            title: "(2)",
            url: Bibliografi2,
          },
        ],
      },
    ],
  },
  {
    title: "E drejta për t'u informuar dhe për t'u ankuar",
    content: [
      {
        title: "Koordinatori/Gea Sulo",
        info: [
          "Gea.Sulo@arkiva.gov.al",
          "Nr. 04 454 17 98",
          "Tel : +355 692389333",
          "Adresa: Rr. “Jordan Misja”, 8303 Tiranë, Shqipëri",
          "Orari: E Hënë-E Enjte: 08.00-16.30 E Premte: 08.00-14.00",
        ],
      },

      {
        title:
          "Format kërkese / Shpjeguese (Hapat që duhet të ndiqen për të bërë një kërkesë për informim)",
        url: FormatKerkese,
      },
      {
        title:
          "Format ankese / Shpjeguese (Procedura e ankimit tek Komisioneri)",
        url: FormatAnkese,
      },
    ],
  },
  {
    title: "Mekanizmat kontrolluese dhe monitoruese që veprojnë mbi AP",
    content: [
      {
        title:
          "Rezolutë e Kuvendit të Shqipërisë (nëse ka të tillë) (nuk ka informacion)",
        url: "",
      },
      {
        title: "Planet strategjike të punës",
        url: "https://drive.google.com/file/d/1C_ybYk3OqAXlqnu8ZAc6_nT3cOKIQ1Ea/view",
      },
      {
        title: "Raporte monitorimi/auditimi (p.sh:KLSH)",
        url: RaportAuditimi,
      },
      {
        title:
          "Dokumente të tjera që përmbajnë tregues të performancës së AP (nuk ka informacion)",
        url: "",
      },
    ],
  },
  {
    title: "Informacion mbi buxhetin dhe të dhënat financiare të AP",
    content: [
      {
        title:
          "Regjistri i parashikimeve te procedurave te prokurimit publik per vitin 2019",
        url: "https://arkiva.gov.al/web2018/wp-content/uploads/2019/09/RREGJ.-PRO-2019-Copy.xlsx",
      },
      {
        title:
          "Regjistri i parashikimeve te procedurave te prokurimit publik per vitin 2020",
        url: "https://arkiva.gov.al/web2018/wp-content/uploads/2020/02/Rregjistri-i-prokurimeve-Drejtoria-P%C3%ABrgjithshme-e-Arkivave.xlsx",
      },
      {
        title:
          "Regjistri i realizimit te procedurave te prokurimit publik per vitin kalendarik 2020",
        url: "https://arkiva.gov.al/web2018/wp-content/uploads/2021/01/RREGJISTRI-i-Realizimeve-janar-dhjetor2020.xlsx",
      },
      {
        title:
          "Regjistri i parashikimeve te procedurave te prokurimit publik per vitin 2021",
        url: "https://arkiva.gov.al/web2018/wp-content/uploads/2021/01/REGJISTRI-2021-janar.xlsx",
      },
      {
        title: "Mbi monitorimin e shpenzimeve te vitit 2019",
        url: "https://arkiva.gov.al/web2018/wp-content/uploads/2019/02/exel-converted.pdf",
      },
      {
        title:
          "Mbi monitorimin e shpenzimeve te DPA per 4 mujorin e vitit 2020",
        url: "https://arkiva.gov.al/web2018/wp-content/uploads/2020/07/Monitorimi-i-Shpenzimeve-te-DPA-per-4-mujorin-e-vitit-2020.pdf",
      },
      {
        title: "Pasqyra shtese per buxhetin vjetor",
        url: "https://arkiva.gov.al/web2018/wp-content/uploads/2021/02/SHT-1.A-19-21-F.2-DREJTORI.xls",
      },
      {
        title: "Plan thesari per investimet per vitin 2021",
        url: "https://arkiva.gov.al/web2018/wp-content/uploads/2021/01/DETAJIMI-I-BUXHETIT-THES-2021.xlsx",
      },
      {
        title: "Raportet e monitorimit per vitin 2020",
        url: "https://arkiva.gov.al/web2018/wp-content/uploads/2021/01/anekset_e_monitorimit_viti_2020_2015-1.xls",
      },
      {
        title: "Formatet e Raporteve të PBA 2023-2025",
        url: FormatetRaportevePBA20232025,
      },
      {
        title: "Regjistri i realizimit IV i pare 2023",
        url: RegjistriRealizimitIVPare2023,
      },
      {
        title: "Rregjistri i realizimeve 2022",
        url: RegjistriRealizimeve2022,
      },
      {
        title: "Regjistri",
        url: "",
      },
      {
        title: "Raport vjetor mbi buxhetin",
        url: "https://arkiva.gov.al/web2018/wp-content/uploads/2021/02/SHT-1.A-19-21-F.2-DREJTORI.xls",
      },
      {
        title: "Pasqyra Shtesë për Buxhetin 2023-2025",
        url: PasqyratShteseBuxhetin20232025,
      },
      {
        title: "Plan thesari për investimet për vitin 2021",
        url: "https://arkiva.gov.al/web2018/wp-content/uploads/2021/02/SHT-1.A-19-21-F.2-DREJTORI.xls",
      },
      {
        title:
          "Dokumente që tregojnë gjendjen e shlyerjes së detyrimeve nga subjektet e licencuara",
        url: DokumenteTregojnëGjendjenShlyerjesDetyrimeveSubjektetLicencuara,
      },
      {
        title: "Grafiku i shlyerjes së detyrimeve për vitin 2020",
        url: "https://arkiva.gov.al/web2018/wp-content/uploads/2020/07/grafiku-1-2.xlsx",
      },
      {
        title: "Rregjistri i Prokurimeve për Vitin 2022",
        url: "https://1drv.ms/b/s!Ar2Nqoh7vKcHgXRsE_w7StbvX0lg?e=jMi4LT",
      },
      {
        title: "Raporti i monitorimit për vitin 2023",
        url: RaportiMonitorimit2023,
      },
      {
        title: "BPPM_ANEKSI_4",
        url: BPPM_ANEKSI_4,
      },
      {
        title: "BPPM_ANEKSI_3,1",
        url: BPPM_ANEKSI_3_1,
      },
      {
        title: "BPPM_ANEKSI_3,2",
        url: BPPM_ANEKSI_3_2,
      },
      {
        title: "BPPM ANEKSI",
        listAnchorContent: [
          {
            title: "2",
            url: BPPM_ANEKSI_2,
          },
          {
            title: "2.1",
            url: BPPM_ANEKSI_2_1,
          },
          {
            title: "1",
            url: BPPM_ANEKSI_1,
          },
          {
            title: "1.2",
            url: BPPM_ANEKSI_1_2,
          },
        ],
      },
    ],
    notes:
      "Shënim: Në ato raste kur autoriteti publik vetëfinancohet nga tarifat e licencave ose çdo formë tjetër e financimit të drejtpërdrejtë nga subjektet e rregulluara prej tij, bëhen publike edhe dokumentet që tregojnë gjendjen e shlyerjes së detyrimeve nga subjektet e licencuara.",
  },
  {
    title:
      "Informacion për procedurat e prokurimit/procedurat konkurruese të koncesionit/partneritetit publik privat",
    content: [
      {
        title:
          "Lista e kontratave të lidhura (përfshirë shumën e kontraktuar, palët kontraktuese dhe përshkrimin e shërbimeve)",
        listContent: [
          {
            title: "Lista e kontatave të lidhura për vitin 2020",
            url: "https://arkiva.gov.al/web2018/wp-content/uploads/2021/01/RREGJISTRI-i-Realizimeve-janar-dhjetor2020.xlsx",
          },
        ],
      },
      {
        title: "Regjistri i parashikimeve të prokurimit publik",
        listContent: [
          {
            title: "Regjistri i parashikimeve për vitin 2019",
            url: "https://arkiva.gov.al/web2018/wp-content/uploads/2019/09/RREGJ.-PRO-2019-Copy.xlsx",
          },
          {
            title: "Regjistri i parashikimeve për vitin 2020",
            url: "https://arkiva.gov.al/web2018/wp-content/uploads/2020/02/Rregjistri-i-prokurimeve-Drejtoria-P%C3%ABrgjithshme-e-Arkivave.xlsx",
          },
          {
            title: "Regjistri i parashikimeve për vitin 2021",
            url: "https://arkiva.gov.al/web2018/wp-content/uploads/2021/01/REGJISTRI-2021-janar.xlsx",
          },
          {
            title: "Rregjistri i parashikimeve për prokurimet për vitin 2022",
            url: "https://1drv.ms/b/s!Ar2Nqoh7vKcHgXRsE_w7StbvX0lg?e=gH08Gl",
          },
        ],
      },
      {
        title: "Regjistri i realizimeve të prokurimit publik",
        listContent: [
          {
            title: "Regjistri i realizimeve për vitin 2020",
            url: "https://arkiva.gov.al/web2018/wp-content/uploads/2021/01/RREGJISTRI-i-Realizimeve-janar-dhjetor2020.xlsx",
          },
          {
            title: "Regjistri pas Rialokimit për vitin 2022",
            url: "https://1drv.ms/x/s!Ar2Nqoh7vKcHgXxJqT0k0CQ15tMt?e=XrnADf",
          },
          {
            title: "Rregjistri i realizimeve për vitin 2023",
            url: RregjistriRealizimeve2023,
          },
        ],
      },

      {
        title:
          "Informacion mbi zbatimin dhe monitorimin e kontratave, si dhe udhëzues e politika të ndryshme",
        listContent: [
          {
            title: "Regjistri i Prokurimeve 2023",
            url: RegjistriProkurimeve2023,
          },
          {
            title: "Regjistri i Prokurimeve 2025",
            url: RegjistriProkurimeve2025,
          },
        ],
      },
    ],
  },
  {
    title: "Shërbimet që ofrohen nga AP",
    content: [
      {
        title: "Lista e Shërbimeve* që ofron AP, përfshirë",
        listContent: [
          {
            title: "Dokumente pronësie",
            url: "https://drive.google.com/file/d/1qhHR0N-7aF2FJU_sksiJI5lKGJdrFsld/view?usp=sharing",
          },
          {
            title: "Dokumente Biografike",
            url: "https://drive.google.com/file/d/141IS5twbiSv1LbBhZMYHbuXiT4ghLLfY/view?usp=sharing",
          },
          { title: "tarifat përkatëse (nëse ka)", url: "" },
          {
            title:
              "Dokumente pronësie – Në momentin e aplikimit do të paguani tarifën e pranimit, që është 1500 lekë dhe pagesën e shërbimit postar. Pasi të merrni përgjigjen do të paguani 100 lekë për çdo fletë të fotokopjuar.",
            url: "",
          },
          {
            title:
              "Dokumente Biografike – Në momentin e aplikimit do të paguani tarifën e pranimit, që është 500 lekë dhe pagesën e shërbimit postar. Pasi të merrni përgjigjen do të paguani 50 lekë për çdo fletë të fotokopjuar.",
            url: "",
          },
          {
            title:
              "Formular aplikimi(Gjendet pranë zyrave postare në të cilat kryhet aplikimi)",
            url: "",
          },
        ],
      },
      {
        title: "Afati kohor",
        listStringContent: [
          {
            title: "a) Dokumente Pronësore",
            listElements: [
              "Përgjigjia do të tërhiqet pranë degës postare ku është kryer aplikimi 23 (njëzet e tre) ditë pune pas kryejes së aplikimit.",
              "Duhet pasur parasysh që DPA kthen përgjigje në bazë të të dhënave të formularit të plotësuar nga kërkuesi, dhe në bazë të dokumentacionit që ajo disponon. Sa më specifike të jetë kërkesa, aq më specifike do të jetë dhe përgjigjia (psh. nëse një qytetar kërkon dokumente pronësore për një periudhë të caktuar, do t’i kthehet përgjigje vetëm për këtë të fundit, dhe nëse disponojmë të dhëna për këta të fundit, megjithëse në emrin e tij mund të figurojnë edhe të dhëna në vite të tjera).",
            ],
          },
        ],
      },
      {
        title:
          "Fotokopjet e shërbyera nuk mund të kthehen mbrapsht dhe do të paguhen përpara se të tërhiqen nga qytetari.",
        url: "",
      },
      {
        title:
          "Fotokopjet e patërhequra qëndrojnë në zyrën postare deri 30 ditë pas dërgimit në filialin përkatës. Nëse përgjigjia nuk tërhiqet brenda kësaj periudhe ato i kthehen DPA, nga ku mund të tërhiqen brenda 6 muajsh nga kryerja e aplikimit. Pas kësaj periudhe, kërkuesi duhet të kryejë aplikimin nga fillimi.",
        url: "",
      },
      {
        title: "Dokumente Biografike",
        url: "https://www.arkiva.gov.al/kerkim-me-pagese/dokumente-biografike",
        listElements: [
          "Përgjigjia do të tërhiqet pranë degës postare ku është kryer aplikimi 15 (pesëmbëdhjetë) ditë pune pas kryejes së aplikimit.",
          "Duhet pasur parasysh që DPA kthen përgjigje në bazë të të dhënave të formularit të plotësuar nga kërkuesi, dhe në bazë të dokumentacionit që ajo disponon. Sa më specifike të jetë kërkesa, aq më specifike do të jetë dhe përgjigjia (psh. nëse një qytetar kërkon vjetërsi pune për një vit të caktuar, do t’i kthehet përgjigje vetëm për këtë të fundit, dhe nëse disponojmë të dhëna për këta të fundit, megjithëse në emrin e tij mund të figurojnë edhe të dhëna në vite të tjera).",
        ],
      },
      {
        title:
          "Fotokopjet e shërbyera nuk mund të kthehen mbrapsht dhe do të paguhen përpara se të tërhiqen nga qytetari.",
        url: "",
      },
      {
        title: "procedurë ankimimi",
        url: "",
      },
      {
        title: "standardet për cilësinë e shërbimit",
        url: "",
      },
    ],
    notes: "Shënim: Lëshim dokumentacioni/Leje/Licenca/Autorizime etje.",
  },
  {
    title:
      "Procedurat/mekanizmat për të dhënë mendime lidhur me procesin e hartimit të akteve ligjore/nënligjore, politikave publike apo në lidhje me ushtrimin e funksioneve të AP",
    content: [
      {
        title: "Njoftimi dhe konsultimi publik",
        url: "https://konsultimipublik.gov.al/Konsultime/Detaje/462",
      },
      {
        title:
          "Emër mbiemër të koordinatorit për konsultimin publik, adresë postare/elektronike Gea Sulo / geasulo@arkiva.gov.al",
        url: "",
      },
    ],
  },
  {
    title:
      "Sistemi i mbajtjes së dokumentacionit, llojet dhe format e dokumenteve",
    content: [
      {
        title:
          "Lista e dokumenteve që administrohen nga AP me afatet e ruajtjes",
        url: ListaDokumenteveAdministrohenAPMeAfatetRuajtjes,
      },
      {
        title: "Pasqyra çelëse e përkohshme",
        url: PasqyraCelësePërkohshme,
      },
      {
        title:
          "Formën e dokumenteve/informacioneve (DPA disponon dokumenta dhe materiale në formatet elektronik, kopje fizike, audio, video dhe foto)",
        url: "",
      },
    ],
  },
  {
    title: "Regjistri i kërkesave dhe përgjigjeve",
    content: [
      {
        title: "Regjistri i Kërkesave dhe përgjigjeve i përditësuar",
        url: "",
      },
      {
        title: "Rregjistri  i kërkesave të kordinatorit- viti 2018",
        url: "https://arkiva.gov.al/web2018/wp-content/uploads/2019/06/Rregjistri-2018-1.docx",
      },
      {
        title: "Rregjistri  i kërkesave të kordinatorit- viti 2019",
        url: "https://arkiva.gov.al/web2018/wp-content/uploads/2020/01/Rregjistri-2019-Koordinatori.docx",
      },
      {
        title: "Rregjistri  i kërkesave të kordinatorit- viti 2020",
        url: "https://arkiva.gov.al/web2018/wp-content/uploads/2020/07/Rregjistri-2020-Koordinatori.docx",
      },
      {
        title: "Rregjistri  i kërkesave të kordinatorit- viti 2021",
        url: "https://arkiva.gov.al/web2018/wp-content/uploads/2021/11/REGJISTRI-2021-Model.docx",
      },
      {
        title: "Rregjistri  i kërkesave të kordinatorit- viti 2022",
        url: RregjistriKërkesaveKordinatorit2022,
      },
      {
        title: "Rregjistri  i kërkesave të kordinatorit- viti 2023",
        url: RregjistriKërkesaveKordinatorit2023,
      },
      {
        title: "Rregjistri  i kërkesave të kordinatorit- viti 2024",
        url: RregjistriKërkesaveKordinatorit2024,
      },
      {
        title: "Rregjistri  i kërkesave të kordinatorit- viti 2025",
        url: RregjistriKërkesaveKordinatorit2025,
      },
    ],
  },
  {
    title: "Ndihma shoqërore/subvencione të dhëna nga AP",
    content: [
      "Ndihma shoqërore/subvencioneve të dhëna nga AP (nuk ka informacion)",
    ],
  },
  {
    title:
      "Informacione/dokumente që kërkohen më shpesh dhe ato që gjykohen të dobishme për publikim nga AP",
    content: [
      { title: "Statistika", url: "https://aplikim.arkiva.gov.al/opendata" },
    ],
    notes:
      "Shënim: AP vlerëson publikimin duke patur parasysh kërkesat që merr më shpesh.",
  },
];

import { useState } from "react";
import Logo from "../Assets/Images/logo.png";
import Dropdown from "./Dropdown";
import "./navbar.css";
const Navbar = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const closeNav = () => {
    setIsNavOpen(false);
  };

  return (
    <div className="header">
      <div className="navbar-logo">
        <a href="https://www.arkiva.gov.al/">
          <img src={Logo} alt="logo" />
        </a>
      </div>
      <button
        className={`burger-button ${isNavOpen ? "open" : ""}`}
        onClick={toggleNav}
      >
        ☰
      </button>
      <div className={`navbar ${isNavOpen ? "open" : ""}`}>
        <ul className="navbar-nav">
          <li className="nav-item">
            Drejtoria
            <Dropdown
              items={[
                { name: "Historiku", url: "/drejtoria/historiku" },
                {
                  name: "KLA, KPE, KDZH, KQE",
                  url: "/drejtoria/kla-kpe-kdzh-kqe",
                },
                {
                  name: "Organizimi i Rrjetit Arkivor",
                  url: "/drejtoria/organizimi-i-rrjetit-arkivor",
                },
                { name: "Struktura e DPA-së", url: "/drejtoria/struktura" },
              ]}
              closeNav={closeNav}
            />
          </li>
          <li className="nav-item">
            Kërko
            <Dropdown
              items={[
                {
                  name: (
                    <>
                      Udhëzues për regjistrimin
                      <br />
                      dhe kërkimin në faqe
                    </>
                  ),
                  url: "/kerko/udhezues-per-regjistrimin-dhe-kerkimin-ne-faqe",
                },
                {
                  name: "Rregullorja e sallës",
                  url: "/kerko/rregullorja-e-salles",
                },
                { name: "Bilblioteka", url: "/kerko/biblioteka" },
                { name: "Fototeka", url: "/kerko/fototeka" },
                { name: "Fonoteka", url: "/kerko/fonoteka" },
              ]}
              closeNav={closeNav}
            />
          </li>
          <li className="nav-item">
            Kërkim <br /> <span className="subtitle">me pagesë</span>
            <Dropdown
              items={[
                {
                  name: "Dokumente pronësie",
                  url: "/kerkim-me-pagese/dokumente-pronesie",
                },
                {
                  name: "Dokumente biografike",
                  url: "/kerkim-me-pagese/dokumente-biografike",
                },
              ]}
              closeNav={closeNav}
            />
          </li>
          <li className="nav-item">
            Muzeu <br /> <span className="subtitle">virtual</span>
            <Dropdown
              items={[
                { name: "Ekspozita", url: "/muzeu-virtual/ekspozita" },
                {
                  name: "Objekte muzeale",
                  url: "/muzeu-virtual/objekte-muzeale",
                },
              ]}
              closeNav={closeNav}
            />
          </li>
          <li className="nav-item">
            Botime
            <Dropdown
              items={[
                { name: 'Revista "Arkivat Shqiptare"', url: "/botime/revista" },
                { name: "Botime të AQSH-së", url: "/botime/aqsh" },
              ]}
              closeNav={closeNav}
              Q
            />
          </li>
          <li className="nav-item">
            Legjislacioni <br /> <span className="subtitle">dhe Dokumente</span>
            <Dropdown
              items={[
                { name: "Legjislacioni", url: "/legjislacioni/ligje" },
                {
                  name: "Dokumente dhe strategji",
                  url: "/legjislacioni/dokumente-dhe-strategji",
                },
              ]}
              closeNav={closeNav}
            />
          </li>
          <li className="nav-item">
            Inspektimi
            <Dropdown
              items={[
                {
                  name: (
                    <>
                      Rregjistri i Dokumenteve RHK <br /> në Pronësi të
                      <br />
                      privatëve
                    </>
                  ),
                  url: "/inspektimi/rregjistri-i-dokumenteve-rhk-ne-pronesi-te-privateve",
                },
                {
                  name: (
                    <>
                      Tabela e rigjetjes së
                      <br /> dokumentave
                    </>
                  ),
                  url: "/inspektimi/tabela-e-rigjetjes-se-dokumentave",
                },
                { name: "Manual", url: "/inspektimi/manual" },
              ]}
              closeNav={closeNav}
            />
          </li>
          <li className="nav-item">
            Kontakte <br />{" "}
            <span className="subtitle">Programi i Transparencës</span>
            <Dropdown
              items={[
                {
                  name: "Programi i Transparencës",
                  url: "/programi-i-transparences",
                },
                {
                  name: "Politika e privatësisë",
                  url: "/politika-e-privatesise",
                },
                { name: "Kontakte", url: "/kontakte" },
              ]}
              closeNav={closeNav}
            />
          </li>
          <li className="nav-item">
            Laboratori i <br /> Restaurimit
            <Dropdown
              items={[
                {
                  name: "Laboratori i Restaurimit",
                  url: "/laboratori-i-restaurimit",
                },
              ]}
              closeNav={closeNav}
            />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;

import { kerkesa } from "./kerkesa";

const KerkesaSocialJuridike = () => {
  return (
    <div className="page">
      <div className="page-header">
        <div className="headerTitleLine"></div>
        <h1 className="page-title">
          Kërkesa Social-Juridike nëpërmjet e-albania
        </h1>
      </div>

      <div className="page-content-kerkesa">
        <ul>
          {kerkesa.map((item, index) => (
            <li className="kerkesa-item" key={index}>
              <a href={item.url} target="_blank" rel="noreferrer">
                <p className="kerkesa-item-title">{item.title}</p>
                {item.description.map((el, index) => (
                  <p className="kerkesa-item-description" key={index}>
                    {el}
                  </p>
                ))}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default KerkesaSocialJuridike;

export const kerkesa = [
  {
    title:
      "Vërtetime të Komisionit tre palësh (dëbimet administrative për periudhën 1949-1991)",
    description: [
      "‍Ky shërbim elektronik i pajis qytetarët me vërtetim lidhur me dëbimet administrative për periudhën 1949-1991 për shtesë pensioni.  ",
      "‍*Të gjithë qytetarët që kërkojnë vetëm periudhën e internimit me “Komision Qendror” duhet ti drejtohen “Autoritetit për Informim mbi dokumentet e ish- Sigurimit të Shtetit”, pasi që prej vitit 2017 dokumentacioni i kësaj kategorie administrohet nga ky institucion.",
    ],
    url: "https://e-albania.al/eAlbaniaServices/UseService.aspx?service_code=14134",
  },
  {
    title:
      "Vërtetime mbi Dekretet e Presidiumit të Kuvendit Popullor për falje dënimesh",
    description: [
      "Ky shërbim elektronik i pajis qytetarët me vërtetim lidhur me Dekretet e Presidiumit të Kuvendit Popullor për falje dënimesh për periudhën 1945-1990.",
    ],
    url: "https://e-albania.al/eAlbaniaServices/UseService.aspx?service_code=14126",
  },
  {
    title: "Vërtetime për arratisjet për periudhën 1949-1990",
    description: [
      "Ky shërbim elektronik i pajis qytetarët me vërtetim lidhur me arratisjet për periudhën 1949-1990.",
    ],
    url: "https://e-albania.al/eAlbaniaServices/UseService.aspx?service_code=14124",
  },
  {
    title: "Riatdhesimet në vitet 1945-1990",
    description: [
      "“Riatdhesimet në vitet 1945-1990 është një shërbim elektronik, i cili u mundëson qytetarëve pajisjen me vërtetim lidhur me riatdhesimin e tyre ose të një të afërmi në periudhën 1945 deri 1990",
    ],
    url: "https://e-albania.al/eAlbaniaServices/UseService.aspx?service_code=14127",
  },
  {
    title:
      "Vërtetim për nxjerrjen e të dhënave për vjetërsi shërbimi (oficer, nënoficer, punonjës civil dhe ilegal)",
    description: [
      "“Vërtetim për nxjerrjen e të dhënave për vjetërsi shërbimi (oficer, nënoficer, punonjës civil dhe ilegal)“ është një shërbim elektronik, i cili i pajis qytetarët me të dhëna për vjetërsi shërbimi nxjerrë nga dosja personale e punës ose e një të afërmi, në pozicionet: oficer, nënoficer, punonjës civil dhe illegal.",
      "*Për punonjësit që kane punuar në strukturat e Ministrisë së Brendshme por që kanë përfunduar karrierën në 10-vjeçarin e fundit, sugjerojmë të drejtohen pranë institucionin ku kanë përfunduar karrierën.",
    ],
    url: "https://e-albania.al/eAlbaniaServices/UseService.aspx?service_code=14121",
  },
  {
    title:
      "Vërtetime për urdhrat e fillimit dhe të përfundimit të detyrës në strukturat e policisë së shtetit për kuadrin oficer, nënoficer dhe civil",
    description: [
      "Ky shërbim elektronik i pajis qytetarët me vërtetim lidhur me urdhrat e fillimit dhe të përfundimit të detyrës në strukturat e policisë së shtetit për kuadrin oficer, nënoficer dhe civil.",
      "‍*Për punonjësit që kane punuar në strukturat e Ministrisë së Brendshme por që janë emëruar ose kanë përfunduar karrierën në 10-vjeçarin e fundit, sugjerojmë të drejtohen pranë institucionin ku kanë përfunduar karrierën.",
    ],
    url: "https://e-albania.al/eAlbaniaServices/UseService.aspx?service_code=14130",
  },
  {
    title:
      "Vërtetime për vuajtjen e dënimeve jopolitike për periudhën 1954-1991",
    description: [
      "Ky shërbim elektronik i pajis qytetarët me vërtetim lidhur me dënimet e kryera për vepra jopolitike vetëm për periudhën 1954-1991.",
    ],
    url: "https://e-albania.al/eAlbaniaServices/UseService.aspx?service_code=14133",
  },
  {
    title: "Vërtetime për vrasjet në kufi",
    description: [
      "Ky shërbim elektronik i pajis qytetarët me vërtetim lidhur me vrasjet e ndodhura në kufi në vitet 1945-1990.",
    ],
    url: "https://e-albania.al/eAlbaniaServices/UseService.aspx?service_code=14132",
  },
  {
    title: "Vërtetim për vendin e kryerjes së dënimit politik me burg",
    description: [
      "“Vërtetim për vendin e kryerjes së dënimit politik me burg“ është një shërbim elektronik, i cili u mundëson qytetarëve pajisjen me vërtetim lidhur me vendin e kryerjes së dënimit politik me burg që u nevojitet për pension.",
      "‍*Për persona që kërkojnë dosjen hetimore-gjyqësore të tyre/të afërmve sugjerojmë të drejtohen pranë Autoritetit për Informim mbi Dokumentet e ish-Sigurimit të Shtetit.",
    ],
    url: "https://e-albania.al/eAlbaniaServices/UseService.aspx?service_code=14486",
  },
  {
    title: "Vërtetim mbi vrasjet me dhe pa gjyq 1945-1990",
    description: [
      "“Vërtetim mbi vrasjet me dhe pa gjyq 1945-1990“ është një shërbim elektronik i cili u mundëson qytetarëve pajisjen me vërtetim lidhur me vrasjet e ndodhura me dhe pa gjyq gjatë viteve 1945-1990.",
    ],
    url: "https://e-albania.al/eAlbaniaServices/UseService.aspx?service_code=14123",
  },
  {
    title: "Vërtetim nxjerrë nga Komunikatat Ditore 1945-2004",
    description: [
      "“Vërtetime nxjerrë nga Komunikatat Ditore 1945-2004” është një shërbim elektronik, i cili u mundëson qytetarëve pajisjen me vërtetim lidhur me ngjarje të nxjerra nga Komunikatat Ditore (ardhur nga komisariatet e policisë në të gjithë Shqipërinë) për periudhën 1945-2004.",
    ],
    url: "https://e-albania.al/eAlbaniaServices/UseService.aspx?service_code=14939",
  },
  {
    title:
      "Vërtetime mbi ngjarje dhe informacione nxjerrë nga dokumentet administrative të Degëve të Punëve të Brendshme 1945-1991",
    description: [
      "Ky shërbim elektronik i pajis qytetarët me vërtetim mbi ngjarje dhe informacione nxjerrë nga dokumentet administrative të ish-Degëve të Punëve të Brendshme për vitet 1945-1991.  ",
    ],
    url: "https://e-albania.al/eAlbaniaServices/UseService.aspx?service_code=14122",
  },
  {
    title:
      "Aplikim për dokumente të veprimtarisë së Brigadave, Komandave dhe Divizioneve partizane",
    description: [
      "Ky shërbim i mundëson qytetarit pajisjen me fotokopje të veprimtarive të Brigadave, Komandave, të Qarqeve dhe Divizioneve partizane gjatë Luftës Nacionalçlirimtare.Nëse dokumentacioni i kërkuar është informacion i klasifikuar, mund të shërbehet në fotokopje vetëm pasi t`i nënshtrohet procesit të deklasifikimit.",
    ],
    url: "https://e-albania.al/eAlbaniaServices/UseService.aspx?service_code=14202",
  },
  {
    title:
      "Fotokopje dokumentesh teknike (gentplane, planimetri, etj) për repartet dhe objektet ushtarake",
    description: [
      "Ky shërbim i mundëson qytetarit pajisjen me fotokopje të dokumenteve teknike si harta, planimetri, detyra projektimi, gentplane të objekteve ushtarake.Nëse dokumentacioni i kërkuar është informacion i klasifikuar, mund të shërbehet në fotokopje vetëm pasi t`i nënshtrohet procesit të deklasifikimit.",
    ],
    url: "https://e-albania.al/eAlbaniaServices/UseService.aspx?service_code=14180",
  },
  {
    title:
      "Vërtetime për ngjarje të jashtëzakonshme të ndodhura në FA (informacione që nuk përmbajnë të dhëna sensitive)",
    description: [
      "Ky shërbim i mundëson qytetarit pajisjen me informacion për dokumente që nuk përmbajnë të dhëna sensitive dhe të paklasifikuara për ngjarje të jashtëzakonshme të ndodhura në Forcat e Armatosura.",
    ],
    url: "https://e-albania.al/eAlbaniaServices/UseService.aspx?service_code=14199",
  },
  {
    title: "Aplikim për shtypin ushtarak",
    description: [
      "Ky shërbim i mundëson qytetarit pajisjen me informacion për gazetat, revistat e Shtëpisë Botuese Ushtarake.",
    ],
    url: "https://e-albania.al/eAlbaniaServices/UseService.aspx?service_code=14209",
  },
  {
    title:
      "Fotokopje dokumentesh mbi gradime, titullime, emërime, transferime dhe largime nga detyra të personelit ushtarak dhe civil në Forcat e Armatosura",
    description: [
      "Ky shërbim i mundëson qytetarit pajisjen me një urdhër për emërim, gradim, titullim, transferim, nxjerrje në rezervë apo lirim, vënie në dispozicion, dalje në pension, dalje në asistencë, largim nga detyra.",
    ],
    url: "https://e-albania.al/eAlbaniaServices/UseService.aspx?service_code=14164",
  },
  {
    title:
      "Aplikim për fotokopje dokumentesh të karakterit historik për krijimin, veprimtarinë dhe suprimimin e strukturave të Forcave të Armatosura",
    description: [
      "Ky shërbim i mundëson qytetarit pajisjen me fotokopje të dokumenteve për krijimin, veprimtarinë, ngjarjet dhe suprimimin e institucioneve në Forcat e Armatosura për periudhën 1945-1993.",
    ],
    url: "https://e-albania.al/eAlbaniaServices/UseService.aspx?service_code=14172",
  },
  {
    title:
      "Aplikim për fotokopje dokumentesh të fondeve arkivore për efekt studimi ose botim, si dhe shërbimi të kuadrit ushtarak dhe civil në Forcat e Armatosura",
    description: [
      "Ky shërbim i mundëson qytetarit pajisjen me fotokopje të dokumenteve që disponon  Arkivi Qendror i Forcave të Armatosura për të gjitha kategoritë e kërkuesve për efekt studimi ose botimi, si dhe shërbimi të kuadrit ushtarak dhe civil në Forcat e Armatosura.",
    ],
    url: "https://e-albania.al/eAlbaniaServices/UseService.aspx?service_code=14172",
  },
  {
    title:
      "Fotokopje dokumentesh për dërgim me shërbim, studime, kualifikim jashtë vendit",
    description: [
      "Ky shërbim i mundëson qytetarit pajisjen me një urdhër për dërgim me studime jashtë vendit. Vendime, urdhra për dërgim në përfaqësitë e Nato-s, Atashetë Ushtarake, si dhe dërgimin në misionet paqeruajtëse jashtë vendit.",
    ],
    url: "https://e-albania.al/eAlbaniaServices/UseService.aspx?service_code=14166",
  },
  {
    title: "Fotokopje të programeve mësimore të shkollave të larta ushtarake",
    description: [
      "Ky shërbim i mundëson qytetarit pajisjen me fotokopje të programeve dhe planeve mësimore të shkollave të larta ushtarake.",
    ],
    url: "https://e-albania.al/eAlbaniaServices/UseService.aspx?service_code=14197",
  },
  {
    title:
      "Vërtetim dhe lista notash për kryerjen e studimeve në shkollat ushtarake",
    description: [
      "Ky shërbim i mundëson qytetarit pajisjen me fotokopje të amzës të Shkollës së Mesme “Skënderbej“, vërtetim me nota dhe pa nota për kryerjen e studimeve në shkollat ushtarake të Forcave të Armatosuara.",
    ],
    url: "https://e-albania.al/eAlbaniaServices/UseService.aspx?service_code=14198",
  },
  {
    title: "Fotokopje dokumentesh mbi kryerjen e shërbimit ushtarak",
    description: [
      "Ky shërbim i mundëson qytetarit pajisjen me dokument për kryerjen e shërbimit të detyrueshëm ushtarak të Degëve Ushtarake të rretheve (Qendrat e Mobilizim Rekrutimit).",
    ],
    url: "https://e-albania.al/eAlbaniaServices/UseService.aspx?service_code=14165",
  },
  {
    title: "Kartona evidence për të dhëna personale të oficerëve rezervistë",
    description: [
      "Ky shërbim i mundëson qytetarit pajisjen me kartonat e evidencës me të dhëna personale të oficerëve rezervistë të ardhura nga Degët Ushtarake të rretheve që disponon Arkivi Qendror i Forcave të Armatosura.",
    ],
    url: "https://e-albania.al/eAlbaniaServices/UseService.aspx?service_code=14169",
  },
  {
    title: "Aplikim për manuale dhe rregullore të veprimtarisë ushtarake në FA",
    description: [
      "Ky shërbim i mundëson qytetarit pajisjen me fotokopje të manualeve dhe rregulloreve të veprimtarisë ushtarake në FA.",
    ],
    url: "https://e-albania.al/eAlbaniaServices/UseService.aspx?service_code=14200",
  },
  {
    title:
      "Fotokopje dokumentesh për përfitime strehimi dhe dokumente teknike të objekteve ushtarake",
    description: [
      "Ky shërbim i mundëson qytetarit pajisjen me dokumente teknike si harta, planimetri, gentplane të objekteve ushtarake, Vendime, urdhra,  lista emërore, akte shitjeje për banim si dhe dhënie me qera të objekteve ushtarake.",
    ],
    url: "https://e-albania.al/eAlbaniaServices/UseService.aspx?service_code=14167",
  },
  {
    title:
      "Fotokopje dokumentesh për të dhëna nga dosjet personale për ushtarakët në FA, para vitit 1971",
    description: [
      "Ky shërbim i mundëson qytetarit pajisjen me dokumente nga dosjet personale si ushtarakë në FA, para vitit 1971.",
    ],
    url: "https://e-albania.al/eAlbaniaServices/UseService.aspx?service_code=14168",
  },
];

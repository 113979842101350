import { useLocation } from "react-router-dom";
import "./kerkimMePagese.css";

import { useState } from "react";
import { kerkimMePageseData } from "./kerkimMePageseData";

const KerkimMePagese = () => {
  const pagePath = useLocation();
  const pageData = kerkimMePageseData.find(
    (item) => item.url === pagePath.pathname
  );

  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleContent = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const renderContentText = (contentText, parentKey = "") => {
    if (Array.isArray(contentText)) {
      return (
        <ul key={parentKey}>
          {contentText.map((item, index) => (
            <li key={parentKey + "-" + index}>
              {renderContentText(item, parentKey + "-" + index)}
            </li>
          ))}
        </ul>
      );
    } else if (typeof contentText === "string") {
      const replacedContent = contentText
        .replace(/{name}/g, "John Doe")
        .replace(/{date}/g, "2024-11-14");

      return (
        <p
          key={replacedContent}
          dangerouslySetInnerHTML={{ __html: replacedContent }}
        />
      );
    } else {
      return <p>Invalid content</p>;
    }
  };

  if (!pageData) {
    return <div>Page not found</div>;
  }

  return (
    <div className="page">
      <div className="page-header">
        <div className="headerTitleLine"></div>
        <h1 className="page-title">{pageData.title}</h1>
      </div>
      <div className="page-content-mepagese">
        <div className="left-content-mepagese">
          <div className="documents">
            <ul>
              {pageData.content.map((item, index) => (
                <li key={item.title + "-" + index}>
                  <button
                    className={expandedIndex === index ? "active" : ""}
                    onClick={() => toggleContent(index)}
                  >
                    {item.title}
                  </button>
                  {expandedIndex === index && (
                    <div className="contentText">
                      {item.contentText && item.contentText.map
                        ? item.contentText.map((text, idx) =>
                            renderContentText(text, item.title + "-" + idx)
                          )
                        : renderContentText(item.contentText, item.title)}
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="right-content"></div>
      </div>
    </div>
  );
};

export default KerkimMePagese;

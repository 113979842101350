import { useLocation } from "react-router-dom";
import Formular from "../Assets/Documents/Formular i Ankesës së Studiuesit në Sallën e Studimit.docx";
import "./kerko.css";
import { kerkoData } from "./kerkoData";

const Kerko = () => {
  const pagePath = useLocation();
  const pageData = kerkoData.find((item) => item.path === pagePath.pathname);

  return (
    <div className="page">
      <div className="page-header">
        <div className="headerTitleLine"></div>
        <h1 className="page-title">{pageData.title}</h1>
      </div>
      <div
        className={`page-content-kerko ${
          pagePath.pathname === "/kerko/rregullorja-e-salles"
            ? "rregullorja"
            : "biblioteka"
        }`}
      >
        <div className="left-content-kerko">
          {pagePath.pathname ===
          "/kerko/udhezues-per-regjistrimin-dhe-kerkimin-ne-faqe" ? (
            <div className="udhezues">
              <ul>
                {Array.isArray(pageData.leftContent) &&
                  pageData.leftContent.map((item, index) => {
                    return (
                      <li key={item.url || `item-${index}`}>
                        {item.url ? (
                          <a href={item.url} target="_blank" rel="noreferrer">
                            {item.title}
                          </a>
                        ) : (
                          <button disabled>{item.title}</button>
                        )}
                      </li>
                    );
                  })}
              </ul>
            </div>
          ) : pagePath.pathname === "/kerko/rregullorja-e-salles" ? (
            <div className="rregullorja">
              <p className="subtitle">{pageData.subtitle}</p>
              {Array.isArray(pageData.leftContent) &&
                pageData.leftContent.map((item, index) => {
                  return (
                    <div key={item.title || item - `${index}`} className="neni">
                      <p className="title">{item.title}</p>
                      <p className="item-subtitle">{item.subtitle}</p>
                      <ol>
                        {item.content.map((contentItem, contentIndex) => {
                          if (typeof contentItem === "string") {
                            return <li key={contentIndex}>{contentItem}</li>;
                          } else if (Array.isArray(contentItem)) {
                            return (
                              <ul key={contentIndex}>
                                {contentItem.map((subItem, subIndex) => (
                                  <li key={subIndex}>{subItem}</li>
                                ))}
                              </ul>
                            );
                          }
                          return null;
                        })}
                      </ol>
                    </div>
                  );
                })}
            </div>
          ) : pagePath.pathname === "/kerko/biblioteka" ? (
            <div className="biblioteka">{pageData.leftContent}</div>
          ) : pagePath.pathname === "/kerko/fototeka" ? (
            <div className="fototeka">
              {Array.isArray(pageData.leftContent)
                ? pageData.leftContent.map((item, index) => (
                    <p key={`item-${index}`}>{item}</p>
                  ))
                : null}
            </div>
          ) : pagePath.pathname === "/kerko/fonoteka" ? (
            <div className="fonoteka">
              {Array.isArray(pageData.leftContent)
                ? pageData.leftContent.map((item, index) => (
                    <p key={`item-${index}`}>{item}</p>
                  ))
                : null}
            </div>
          ) : null}
        </div>

        <div className="right-content-kerko">
          {pagePath.pathname === "/kerko/rregullorja-e-salles" && (
            <div className="neni-doc">
              <ul>
                {Array.isArray(pageData.rightContent) &&
                  pageData.rightContent.map((item, index) => {
                    return (
                      <li key={`right-item-${index}`}>
                        {item.url ? (
                          <a
                            href={item.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {item.title}
                          </a>
                        ) : (
                          <a href={Formular} download>
                            {item.title}
                          </a>
                        )}
                      </li>
                    );
                  })}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Kerko;

import { useState } from "react";
import "./programiTransparences.css";
import { programiTransparencesData } from "./programiTransparencesData";

const ProgramiTransparences = () => {
  const [activeElement, setActiveElement] = useState(null);

  const handleElementClick = (id) => {
    setActiveElement(activeElement === id ? null : id);
  };

  const renderListItems = (items) => (
    <ul>
      {items.map((item, index) => (
        <li key={index}>
          {typeof item === "string" ? (
            <span dangerouslySetInnerHTML={{ __html: item }} />
          ) : item.url ? (
            item.url.trim() !== "" ? (
              <a href={item.url} target="_blank" rel="noopener noreferrer">
                {item.title}
              </a>
            ) : (
              item.title
            )
          ) : item.listAnchorContent ? (
            <>
              <>{item.title}:</>{" "}
              {item.listAnchorContent.map((link, subIndex) => (
                <span key={subIndex}>
                  <a href={link.url} target="_blank" rel="noopener noreferrer">
                    {link.title}
                  </a>
                  {subIndex < item.listAnchorContent.length - 1 && ", "}
                </span>
              ))}
            </>
          ) : item.info ? (
            <>
              <strong>{item.title}:</strong>
              <ul>
                {item.info.map((infoItem, infoIndex) => (
                  <li key={infoIndex}>{infoItem}</li>
                ))}
              </ul>
            </>
          ) : item.listContent ? (
            <>
              <>{item.title}:</>
              <ul>
                {item.listContent.map((link, subIndex) => (
                  <li key={subIndex}>
                    {link.url.trim() !== "" ? (
                      <a
                        href={link.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {link.title}
                      </a>
                    ) : (
                      link.title
                    )}
                  </li>
                ))}
              </ul>
            </>
          ) : item.listStringContent ? (
            <>
              <>{item.title}:</>
              <ul>
                {item.listStringContent.map((subItem, subIndex) => (
                  <li key={subIndex}>
                    <>{subItem.title}:</>
                    <ul>
                      {subItem.listElements.map((element, elementIndex) => (
                        <li key={elementIndex}>{element}</li>
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
            </>
          ) : (
            item.title
          )}
        </li>
      ))}
    </ul>
  );

  const renderContent = (content, notes) => (
    <div className="content">
      {Array.isArray(content) ? renderListItems(content) : null}
      {notes && <p className="notes">{notes}</p>}
    </div>
  );

  return (
    <div className="page">
      <div className="page-header">
        <div className="headerTitleLine"></div>
        <h1 className="page-title">Programi i Transparencës</h1>
      </div>
      <div className="grid-container">
        {programiTransparencesData.map((item, index) => (
          <div
            key={index}
            className={`grid-item item-${index + 1} ${
              index === 0 ? "first-row" : ""
            }`}
          >
            <div
              className={`element ${activeElement === index ? "active" : ""}`}
              onClick={() => handleElementClick(index)}
              role="button"
              tabIndex={0}
              onKeyDown={(e) => e.key === "Enter" && handleElementClick(index)}
            >
              {item.title}
            </div>
            {activeElement === index && renderContent(item.content, item.notes)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProgramiTransparences;

import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addRegjistriRhk } from "../../Redux/Actions/regjistriRhkActions";

const AddRegjistriRhk = () => {
  const navigate = useNavigate();
  const loggedUser = useSelector((state) => state.users.loggedUser);
  const isLoggedIn = Object.keys(loggedUser).length > 0;
  const [subject, setSubject] = useState("");
  const [location, setLocation] = useState("");
  const [year, setYear] = useState("");
  const [fond, setFond] = useState("");
  const [ownMethod, setOwnMethod] = useState("");
  const [description, setDescription] = useState("");
  const [pagesNr, setPagesNr] = useState("");
  const [phoneNr, setPhoneNr] = useState("");
  const [notes, setNotes] = useState("");
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});

  if (!isLoggedIn) {
    navigate("/login");
  }

  const validateForm = () => {
    const errors = {};
    if (!subject.trim()) {
      errors.subject = "Subjekt nuk mund të jetë bosh!";
    }

    if (!location.trim()) {
      errors.location = "Vendndodhja nuk mund të jetë bosh!";
    }

    if (!year.trim()) {
      errors.year = "Viti nuk mund të jetë bosh!";
    }

    if (!fond.trim()) {
      errors.fond = "Fondi nuk mund të jetë bosh!";
    }

    if (!ownMethod.trim()) {
      errors.ownMethod =
        "Mënyra e posedimit të dokumentit RHK nuk mund të jetë bosh!";
    }

    if (!description.trim()) {
      errors.description = "Përshkrimi nuk mund të jetë bosh!";
    }

    if (!pagesNr.trim()) {
      errors.pagesNr = "Numri i faqeve nuk mund të jetë bosh!";
    } else if (isNaN(pagesNr)) {
      errors.pagesNr = "Numri i faqeve duhet të jetë numër!";
    }

    if (!phoneNr.trim()) {
      errors.phoneNr = "Nr. telefonit nuk mund të jetë bosh!";
    } else if (isNaN(phoneNr)) {
      errors.phoneNr = "Nr. telefonit duhet të jetë numër!";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleCancel = () => {
    navigate("/manage/regjistriRhk");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    const regjistriRhkData = {
      subject,
      location,
      year,
      fond,
      ownMethod,
      description,
      pagesNr,
      phoneNr,
      notes,
    };

    try {
      const response = await axios.post(
        "https://api.arkiva.gov.al/api/regjistriRhk/create",
        regjistriRhkData
      );

      dispatch(addRegjistriRhk(response.data));

      navigate("/manage/regjistriRhk");
    } catch (err) {
      console.error("Error adding regjistri Rhk:", err);
    }
  };

  const textareaRef = useRef(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [description]);

  return (
    <div className="edit-page">
      <div className="return">
        <button onClick={handleCancel}>
          <i className="fa-solid fa-chevron-left"></i>
        </button>
      </div>
      <p>Add New Dokument RHK</p>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="subject">Emri i Subjektit që e Posedon</label>
          <input
            type="text"
            id="subject"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
          {errors.subject && <small className="error">{errors.subject}</small>}
        </div>
        <div className="form-group">
          <label htmlFor="location">
            Vendi në të cilin ndodhet Dokumenti (adresa)
          </label>
          <input
            type="text"
            id="location"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
          />
          {errors.location && (
            <small className="error">{errors.location}</small>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="year">Viti i Dokumentit</label>
          <input
            type="text"
            id="year"
            value={year}
            onChange={(e) => setYear(e.target.value)}
          />
          {errors.year && <small className="error">{errors.year}</small>}
        </div>
        <div className="form-group">
          <label htmlFor="fond">Emërtimi i Fondkrijuesit</label>
          <input
            type="text"
            id="fond"
            value={fond}
            onChange={(e) => setFond(e.target.value)}
          />
          {errors.fond && <small className="error">{errors.fond}</small>}
        </div>
        <div className="form-group">
          <label htmlFor="ownMethod">
            Mënyra e posedimit të dokumentit RHK
          </label>
          <input
            type="text"
            id="ownMethod"
            value={ownMethod}
            onChange={(e) => setOwnMethod(e.target.value)}
          />
          {errors.ownMethod && (
            <small className="error">{errors.ownMethod}</small>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="description">
            Përshkrimi i shkurtër i dokumentit
          </label>
          <textarea
            ref={textareaRef}
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          {errors.description && (
            <small className="error">{errors.description}</small>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="pagesNr">Sasia e fletëve</label>
          <input
            type="text"
            id="pagesNr"
            value={pagesNr}
            onChange={(e) => setPagesNr(e.target.value)}
          />
          {errors.pagesNr && <small className="error">{errors.pagesNr}</small>}
        </div>
        <div className="form-group">
          <label htmlFor="phoneNr">Numri i kontaktit i subjektit</label>
          <input
            type="text"
            id="phoneNr"
            value={phoneNr}
            onChange={(e) => setPhoneNr(e.target.value)}
          />
          {errors.phoneNr && <small className="error">{errors.phoneNr}</small>}
        </div>
        <div className="form-group">
          <label htmlFor="notes">Shënime</label>
          <textarea
            ref={textareaRef}
            id="notes"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
          />
        </div>

        <div className="edit-actions">
          <button type="submit" className="save">
            Shto Dokument RHK
          </button>
          <button type="button" className="cancel" onClick={handleCancel}>
            Anullo
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddRegjistriRhk;

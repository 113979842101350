export const SET_REGJSTRI_DOK_HUMBURA = "SET_REGJSTRI_DOK_HUMBURA";
export const SET_REGJSTRI_DOK_HUMBURA_ON_EDIT =
  "SET_REGJSTRI_DOK_HUMBURA_ON_EDIT";
export const UPDATE_REGJSTRI_DOK_HUMBURA = "UPDATE_REGJSTRI_DOK_HUMBURA";
export const DELETE_REGJSTRI_DOK_HUMBURA = "DELETE_REGJSTRI_DOK_HUMBURA";
export const ADD_REGJSTRI_DOK_HUMBURA = "DD_REGJSTRI_DOK_HUMBURA";

export const setRegjistriDokHumbura = (regjistriDokHumbura) => ({
  type: SET_REGJSTRI_DOK_HUMBURA,
  payload: regjistriDokHumbura,
});

export const setRegjistriDokHumburaOnEdit = (regjistriDokHumburaOnEdit) => ({
  type: SET_REGJSTRI_DOK_HUMBURA_ON_EDIT,
  payload: regjistriDokHumburaOnEdit,
});

export const updateRegjistriDokHumbura = (updatedRegjistriDokHumbura) => ({
  type: UPDATE_REGJSTRI_DOK_HUMBURA,
  payload: updatedRegjistriDokHumbura,
});

export const removeRegjistriDokHumbura = (regjistriDokHumburaId) => ({
  type: DELETE_REGJSTRI_DOK_HUMBURA,
  payload: regjistriDokHumburaId,
});

export const addRegjistriDokHumbura = (regjistriDokHumbura) => ({
  type: ADD_REGJSTRI_DOK_HUMBURA,
  payload: regjistriDokHumbura,
});

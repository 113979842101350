import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

const Ekspozita = () => {
  const { id } = useParams();
  const [ekspozitaItem, setEkspozitaItem] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchEkspozita = async () => {
      try {
        const response = await axios.get(
          `https://api.arkiva.gov.al/api/ekspozita/one/${id}`
        );
        setEkspozitaItem(response.data);
      } catch (error) {
        console.error("Error fetching ekspozita:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchEkspozita();
  }, [id]);

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  if (!ekspozitaItem) {
    return <div className="error">Ekspozita not found</div>;
  }

  const coverImage = `/Assets/Images/Ekspozita/${ekspozitaItem.id}/${ekspozitaItem.coverImage}`;
  const ekspozitaImages = ekspozitaItem.images.filter(
    (image) => image !== coverImage
  );

  const openModal = (index) => {
    setCurrentImageIndex(index);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const nextSlide = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex + 1) % ekspozitaImages.length
    );
  };

  const prevSlide = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? ekspozitaImages.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="ekspozita-page">
      <div className="ekspozita-page-title">
        <div className="ekspozita-page-subtitle">Ekspozita</div>
        <div className="ekspozita-item-title">{ekspozitaItem.title}</div>
      </div>
      <div className="ekspozita-item-images">
        {ekspozitaImages.map((image, index) => (
          <div className="ekspozita-item-image" key={index}>
            <img
              src={image}
              alt={`${index + 1}`}
              onClick={() => openModal(index)}
            />
          </div>
        ))}
      </div>

      {isModalOpen && (
        <div className="modal-post ekspozita">
          <button className="modal-close" onClick={closeModal}>
            &times;
          </button>
          <button className="modal-prev-post ekspozita" onClick={prevSlide}>
            &#10094;
          </button>
          <div className="modal-content-post ekspozita">
            <img
              src={ekspozitaImages[currentImageIndex]}
              alt={`Slide ${currentImageIndex + 1}`}
            />
          </div>
          <button className="modal-next-post" onClick={nextSlide}>
            &#10095;
          </button>
        </div>
      )}
    </div>
  );
};

export default Ekspozita;

import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../manage.css";
import axios from "axios";
import { setPostOnEdit, updatePost } from "../../Redux/Actions/postsActions";

const EditPost = () => {
  const dispatch = useDispatch();
  const postOnEdit = useSelector((state) => state.posts.postOnEdit);
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const loggedUser = useSelector((state) => state.users.loggedUser);
  const isLoggedIn = Object.keys(loggedUser).length > 0;
  const [imagesNr, setImagesNr] = useState(postOnEdit.nrImages);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    createdOn: "",
    slug: "",
    images: [],
    coverImage: "",
  });
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);

  const [file, setFile] = useState("");
  const [filename, setFilename] = useState("Choose File");
  const [uploadedFile, setUploadedFile] = useState({});

  if (!isLoggedIn) navigate("/login");

  useEffect(() => {
    if (!postOnEdit || !postOnEdit._id) return;

    setFormData({
      title: postOnEdit.title || "",
      description: postOnEdit.description || "",
      createdOn: postOnEdit.createdOn || "",
      slug: postOnEdit.slug || "",
      images: [],
      coverImage: postOnEdit.coverImage || "",
    });
  }, [postOnEdit]);

  useEffect(() => {
    const fetchImages = async () => {
      if (!postOnEdit || !postOnEdit._id) return;

      try {
        setLoading(true);
        const response = await axios.get(
          `https://api.arkiva.gov.al/api/posts/images/${postOnEdit.id}`
        );

        if (response.data.images) {
          const coverImagePath = response.data.images.find((img) =>
            img.includes(postOnEdit.coverImage)
          );

          const galleryImages = response.data.images.filter(
            (img) => img !== coverImagePath
          );

          setFormData((prevData) => ({
            ...prevData,
            coverImage: coverImagePath,
            images: galleryImages,
          }));
        }
      } catch (error) {
        console.error("Error fetching images:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchImages();
  }, [postOnEdit]);

  useEffect(() => {
    const generateSlug = (input) => {
      const slug = input
        .toLowerCase()
        .replace(/ë/g, "e")
        .replace(/ç/g, "c")
        .replace(/\s+/g, "-")
        .replace(/[^a-z0-9-]/g, "");
      return slug;
    };

    setFormData((prevData) => ({
      ...prevData,
      slug: generateSlug(prevData.title),
    }));
  }, [formData.title]);

  const validateForm = () => {
    const errors = {};
    const dateRegex = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/;
    const monthDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    const isLeapYear = (year) => {
      return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
    };

    const isValidDate = (day, month, year) => {
      if (month < 1 || month > 12) return false;
      const maxDays =
        month === 2 && isLeapYear(year) ? 29 : monthDays[month - 1];
      return day >= 1 && day <= maxDays;
    };

    if (!formData.title.trim()) errors.title = "Titulli nuk mund të jetë bosh!";
    if (!formData.description.trim())
      errors.description = "Përshkrimi nuk mund të jetë bosh!";
    if (!formData.createdOn.trim()) {
      errors.createdOn = "Data e krijimit nuk mund të jetë bosh!";
    } else {
      const match = formData.createdOn.match(dateRegex);
      if (!match) {
        errors.createdOn =
          "Data e krijimit duhet të jetë në formatin 'dd/mm/yyyy' ose 'd/m/yyyy'!";
      } else {
        const [_, day, month, year] = match.map(Number);
        const enteredDate = new Date(year, month - 1, day);
        const currentDate = new Date();

        if (!isValidDate(day, month, year)) {
          errors.createdOn =
            "Data e krijimit duhet të jetë në formatin 'dd/mm/yyyy' ose 'd/m/yyyy'!";
        } else if (enteredDate > currentDate) {
          errors.createdOn = "Data e krijimit nuk mund të jetë në të ardhmen!";
        }
      }
    }

    if (!formData.coverImage)
      errors.coverImage = "Kopertina nuk mund të jetë bosh!";
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleDeleteImage = async (index) => {
    const imagePath = formData.images[index];
    setLoading(true);

    try {
      const response = await fetch(
        "https://api.arkiva.gov.al/api/posts/delete-image",
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            imagePath: `/Assets/Images/Posts/${postOnEdit.id}/${imagePath}`,
            postId: postOnEdit._id,
            folderId: postOnEdit.id,
          }),
        }
      );

      if (response.ok) {
        const updatedImages = formData.images.filter((_, i) => i !== index);
        setImagesNr(imagesNr - 1);

        setFormData((prevData) => ({
          ...prevData,
          images: updatedImages,
        }));

        const refreshedData = await axios.get(
          `https://api.arkiva.gov.al/api/posts/${postOnEdit._id}`
        );

        if (refreshedData.data) {
          dispatch(setPostOnEdit(refreshedData.data));
          dispatch(updatePost(refreshedData.data));
        }
      } else {
        const errorResponse = await response.json();
        console.error("Failed to delete image:", errorResponse);
      }
    } catch (error) {
      console.error("Error deleting image:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({ ...prevData, [name]: value }));

    if (name === "description") {
      resizeTextarea();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    if (!postOnEdit._id) {
      console.error("Error: _id is undefined. Cannot proceed.");
      return;
    }

    try {
      const response = await axios.put(
        `https://api.arkiva.gov.al/api/posts/update/${postOnEdit._id}`,
        {
          title: formData.title,
          description: formData.description,
          createdOn: formData.createdOn,
          slug: formData.slug,
          nrImages: imagesNr,
        }
      );

      if (response.data) {
        dispatch(updatePost(response.data));
        navigate("/manage/aktivitete");
        dispatch(setPostOnEdit({}));
      } else {
        console.error("Something went wrong");
      }
    } catch (error) {
      console.error("Error updating post:", error);
    }
  };

  const handleCancel = () => {
    navigate("/manage/aktivitete");
  };

  const textareaRef = useRef(null);

  const resizeTextarea = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  useEffect(() => {
    resizeTextarea();
  }, [formData.description]);

  const onFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setFilename(selectedFile.name);

    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("folderId", postOnEdit.id);

    try {
      const res = await axios.post(
        "https://api.arkiva.gov.al/api/posts/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          body: {
            folderId: postOnEdit.id,
          },
        }
      );

      const { fileName, filePath } = res.data;

      setUploadedFile({ fileName, filePath });

      setFormData((prevData) => ({
        ...prevData,
        images: [...prevData.images, filePath],
      }));

      setImagesNr(imagesNr + 1);
    } catch (err) {
      console.error("Upload failed:", err);
    }
  };

  const handleReplaceCoverImage = async (e) => {
    e.preventDefault();

    if (!formData.coverImage) {
      alert("No cover image to replace.");
      return;
    }

    setLoading(true);

    try {
      const response = await fetch(
        "https://api.arkiva.gov.al/api/posts/delete-image",
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            imagePath: `/Assets/Images/Posts/${postOnEdit.id}/${postOnEdit.coverImage}`,
            postId: postOnEdit._id,
            folderId: postOnEdit.id,
          }),
        }
      );

      if (!response.ok) {
        console.error("Failed to delete current cover image.");
        return;
      }

      fileInputRef.current.click();
    } catch (error) {
      console.error("Error replacing cover image:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCoverImageUpload = async (e) => {
    const selectedFile = e.target.files[0];

    if (!selectedFile) return;

    setLoading(true);

    try {
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("folderId", postOnEdit.id);

      const uploadResponse = await axios.post(
        "https://api.arkiva.gov.al/api/posts/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const { fileName } = uploadResponse.data;

      const updateResponse = await axios.put(
        `https://api.arkiva.gov.al/api/posts/update/${postOnEdit._id}`,
        {
          coverImage: fileName,
        }
      );

      if (updateResponse.data) {
        setFormData((prevData) => ({
          ...prevData,
          coverImage: `/Assets/Images/Posts/${postOnEdit.id}/${fileName}`,
        }));
      } else {
        console.error("Failed to update the cover image in the database.");
      }
    } catch (error) {
      console.error("Error uploading new cover image:", error);
    } finally {
      setLoading(false);
    }
  };

  const returnHome = () => {
    navigate("/manage/aktivitete");
    dispatch(setPostOnEdit({}));
  };

  return (
    <div className="edit-page">
      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}
      <div className="return">
        <button onClick={returnHome}>
          <i className="fa-solid fa-chevron-left"></i>
        </button>
      </div>
      <p>Edito Aktivitet</p>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="title">Titulli</label>
          <input
            type="text"
            id="title"
            name="title"
            value={formData.title}
            onChange={handleChange}
          />
          {errors.title && <small className="error">{errors.title}</small>}
        </div>

        <div className="form-group">
          <label htmlFor="description">Përshkrimi</label>
          <textarea
            id="description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            ref={textareaRef}
            onInput={resizeTextarea}
            style={{ width: "100%", overflow: "hidden" }}
          />
          {errors.description && (
            <small className="error">{errors.description}</small>
          )}
        </div>

        <div className="form-group">
          <label htmlFor="createdOn">Data e krijimit</label>
          <input
            type="text"
            id="createdOn"
            name="createdOn"
            value={formData.createdOn}
            onChange={handleChange}
          />
          {errors.createdOn && (
            <small className="error">{errors.createdOn}</small>
          )}
        </div>

        <div className="form-group">
          <label htmlFor="slug">Slug (Path)</label>
          <input
            type="text"
            id="slug"
            name="slug"
            value={formData.slug}
            disabled
          />
        </div>

        <div className="form-group gallery">
          <label>Imazhet</label>
          <div className="images-container">
            {formData.images.map((image, index) => (
              <div className="image-wrapper" key={index}>
                <img
                  src={image}
                  alt={`${index + 1}`}
                  className="preview"
                  style={{
                    width: "120px",
                    height: "120px",
                    objectFit: "cover",
                  }}
                />
                <button
                  type="button"
                  className="delete-button"
                  onClick={() => handleDeleteImage(index)}
                >
                  <i className="fa-solid fa-xmark"></i>
                </button>
              </div>
            ))}
          </div>
          <div className="image-upload">
            <label htmlFor="imageUpload">Shto imazhe</label>
            <input
              type="file"
              id="imageUpload"
              name="imageUpload"
              ref={fileInputRef}
              accept="image/*"
              multiple
              onChange={onFileChange}
            />
          </div>
        </div>

        <div className="form-group cover">
          <label>Kopertina</label>
          {formData.coverImage && (
            <div className="image-wrapper">
              <img
                src={formData.coverImage}
                alt="preview"
                className="preview"
                style={{ width: "120px", height: "120px", objectFit: "cover" }}
              />
            </div>
          )}
          <input
            type="file"
            id="imageUpload"
            name="imageUpload"
            ref={fileInputRef}
            accept="image/*"
            multiple
            onChange={handleCoverImageUpload}
            style={{ display: "none" }}
          />
          {errors.coverImage && (
            <small className="error">{errors.coverImage}</small>
          )}
          <button className="replace" onClick={handleReplaceCoverImage}>
            Zëvendëso kopertinën
          </button>
        </div>

        <div className="edit-actions">
          <button type="submit" className="save">
            Ruaj ndryshimet
          </button>
          <button type="button" className="cancel" onClick={handleCancel}>
            Anullo
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditPost;

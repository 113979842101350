export const SET_POSTS = "SET_POSTS";
export const SET_POST_ON_EDIT = "SET_POST_ON_EDIT";
export const UPDATE_POST = "UPDATE_POST";
export const DELETE_POST = "DELETE_POST";
export const ADD_POST = "ADD_POST";

export const setPosts = (posts) => ({
  type: SET_POSTS,
  payload: posts,
});

export const setPostOnEdit = (postOnEdit) => ({
  type: SET_POST_ON_EDIT,
  payload: postOnEdit,
});

export const updatePost = (updatedPost) => ({
  type: UPDATE_POST,
  payload: updatedPost,
});

export const removePost = (postId) => ({
  type: DELETE_POST,
  payload: postId,
});

export const addPost = (post) => ({
  type: ADD_POST,
  payload: post,
});

export const SET_EKSPOZITA = "SET_EKSPOZITA";
export const SET_EKSPOZITA_ON_EDIT = "SET_EKSPOZITA_ON_EDIT";
export const UPDATE_EKSPOZITA = "UPDATE_EKSPOZITA";
export const DELETE_EKSPOZITA = "DELETE_EKSPOZITA";
export const ADD_EKSPOZITA = "ADD_EKSPOZITA";

export const setEkspozita = (posts) => ({
  type: SET_EKSPOZITA,
  payload: posts,
});

export const setEkspozitaOnEdit = (ekspozitaOnEdit) => ({
  type: SET_EKSPOZITA_ON_EDIT,
  payload: ekspozitaOnEdit,
});

export const updateEkspozita = (updatedEkspozita) => ({
  type: UPDATE_EKSPOZITA,
  payload: updatedEkspozita,
});

export const removeEkspozita = (ekspozitaId) => ({
  type: DELETE_EKSPOZITA,
  payload: ekspozitaId,
});

export const addEkspozita = (ekspozita) => ({
  type: ADD_EKSPOZITA,
  payload: ekspozita,
});
